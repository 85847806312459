import styled from "@emotion/styled";

export const PreviewMessage = styled.div`
  width: 100%;
  top: 0;
  padding: 20px;
  font-size: 14px;
  z-index: 999999;
  position: fixed;
  background-color: #fcffe4;
`;

export const StripeBanner = styled.div`
  width: 100%;
  top: 0;
  padding: 20px;
  font-size: 14px;
  z-index: 999999;
  position: fixed;
  background-color: #eeb4bd;
`;
export const TestModeBanner = styled.div`
  width: 100%;
  top: 0;
  padding: 20px;
  font-size: 14px;
  z-index: 999999;
  position: fixed;
  background-color: #fcffe4;
`;

export const InternetMessage = styled.div`
  width: 100%;
  top: 0;
  padding: 20px;
  font-size: 14px;
  z-index: 999999;
  position: fixed;
  background-color: #eeb4bd;
  color: #2a3845;
`;

export const WebinarThank = styled.div`
  width: 400px;
  height: 250px;
  top: 0;
  padding: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border: 1px solid #e0e0e0;
  margin: auto;
  border-radius: 4px;
  @media (max-width: 411px) {
    width: 80%;
  }

  img {
    margin: 0 auto;
    display: block;
  }

  .title {
    color: #333333;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    margin: 10px 0;
  }

  .text {
    color: #5a5a5a;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    margin: 5px 0;
  }
`;

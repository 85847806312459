/*eslint no-throw-literal: "off"*/
/*eslint no-eval: "off"*/
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { LoadingScreen } from "../Components";
import * as services from "./Services";
import { welcomeCss as singlePageCss } from "./PagesCss";
import $ from "jquery";
import queryString from "query-string";
import React, { Component } from "react";
import PlaceholderBuilder from "../Components/placeholderBuilder/PlaceholderBuilder";
import { browserName, deviceDetect } from "react-device-detect";
import { Helmet } from "react-helmet";
import { PreviewMessage } from "./ApplicationContainer.styled";
import { getScriptTagFromString, renderIframeUrl } from "../utils";

let isPreview = "",
  isNewPreview = "";

class SinglePageContainer extends Component {
  state = {
    singlePageHtml: {},
    showSinglePage: true,
    isLoadingInitialInfo: true,
    singlePageTemp: null,
  };

  createTaker = single_page_id => {
    const utm_params = queryString.parse(window.location.search);
    let taker_parameters_attributes = [];
    Object.keys(utm_params).forEach(utm => {
      taker_parameters_attributes.push({
        name: utm,
        value: utm_params[utm],
      });
    });
    const taker = {
      taker: {
        browser: browserName,
        language: navigator.language,
        single_page_id,
        ...deviceDetect(),
        taker_parameters_attributes,
      },
    };

    try {
      services.createTaker(taker);
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = async () => {
    try {
      let response = null;
      const { verification_token } = this.props.match.params;
      isPreview =
        window.location.href.includes("preview.") === true ||
        window.location.href.includes("preview-2.") === true
          ? true
          : false;

      if (isPreview)
        response = await services.getPreviewSinglePage(verification_token);
      else
        response = await services.getCloudfrontInfoSinglePage(
          verification_token,
        );

      if (response.data.use_redirect) {
        window.location.href = response.data.redirect_url;
        return;
      }

      /* Validate Domain */

      if (!isPreview) {
        const currentDomain = queryString.parseUrl(window.location.href).url;

        const dbDomain = queryString.parseUrl(response.data.page_link).url;
        if (currentDomain !== dbDomain) {
          this.props.history.replace("/404");
          return;
        }
      }
      /* Validate Subscription Active */
      if (typeof response.data.subscription_active !== "undefined") {
        if (!response.data.subscription_active) {
          this.props.history.replace("/404");
          return;
        }
      }

      //Add Tracking Codes
      if (!isPreview) {
        $(response.data.settings.headTrackingCode).appendTo("head");
        $(response.data.settings.bodyTrackingCode).appendTo("body");
      }

      if (isPreview) isNewPreview = true;

      if (response.data.status === "inactive" && !isPreview) {
        window.location.href = "/404";
      }

      //S Page
      const singlePageTemp = response.data;

      if (typeof singlePageTemp.settings.html === "undefined") {
        throw "Single Page Empty";
      }
      // ********************

      // create taker and welcome view
      !isNewPreview && this.createTaker(singlePageTemp.id);

      let contentSinglePage = `${singlePageTemp.settings.html}<style>${singlePageTemp.settings.css}${singlePageCss}`;
      let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

      let match;
      let scripsList = [];
      while ((match = re.exec(contentSinglePage))) {
        scripsList.push(match[1]);
      }

      // FOR SCRIPTS WISTIA
      let wistiaScript = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
      let wistiaMatch;
      let scriptsWistiaList = [];
      while ((wistiaMatch = wistiaScript.exec(contentSinglePage))) {
        if (wistiaMatch[1] === "") {
          const str = wistiaMatch[0];
          const script = getScriptTagFromString(str);

          document.body.appendChild(script);
        }
        // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
        scriptsWistiaList.push(wistiaMatch[1]);
      }
      contentSinglePage = renderIframeUrl(contentSinglePage);

      this.setState(
        {
          singlePageHtml: contentSinglePage,
          singlePageTemp,
          isLoadingInitialInfo: false,
          showSinglePage: true,
          metaImage: response.data.meta_image || {},
          settings: response.data.settings,
        },
        () => {
          try {
            scripsList.forEach(script => eval(script));
          } catch (e) {
            console.log(e);
          }
        },
      );
      //Anchor PostQuiz
      this.anchorPostWelcome();

      const anchorUrl = window.location.href.split("#");
      console.log(anchorUrl[1]);
      if (anchorUrl[1]) {
        let dest = `#${anchorUrl[1]}`;
        console.log(dest, $(dest), $(dest).offset());
        $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
      }
    } catch (e) {
      if (e === "Single Page Empty") {
        this.setState({ emptyCanvas: true, isLoadingInitialInfo: false });
      }
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/404");
      }
    }
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  render() {
    return (
      <div>
        {this.state.isLoadingInitialInfo ? (
          <LoadingScreen />
        ) : this.state.emptyCanvas ? (
          <PlaceholderBuilder type="emptyCanvas" />
        ) : !this.state.singlePageTemp.settings.html ? (
          <PlaceholderBuilder type="Welcome" />
        ) : (
          <React.Fragment>
            {this.state.settings && (
              <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.settings.metaTitle || "Untitled"}</title>
                {/* <!-- Twitter Card data --> */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@publisher_handle" />
                <meta
                  name="twitter:title"
                  content={`${this.state.settings.metaTitle || "Bucket.io"}`}
                />
                <meta
                  name="twitter:description"
                  content={`${this.state.settings.metaDescription ||
                    "No Description"}`}
                />
                {/* <!-- Twitter Summary card images must be at least 200x200px --> */}
                <meta
                  name="twitter:image"
                  content={`${this.state.metaImage.url || ""}`}
                />

                {/* <!-- Open Graph data --> */}
                <meta
                  property="og:title"
                  content={`${this.state.settings.metaTitle || "Bucket.io"}`}
                />
                <meta
                  property="og:description"
                  content={`${this.state.settings.metaDescription ||
                    "No Description"}`}
                />
                <meta
                  property="og:image"
                  itemProp="image"
                  content={`${this.state.metaImage.url || ""}`}
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
              </Helmet>
            )}
            {isNewPreview && !this.state.isInternet && (
              <PreviewMessage>
                <b>Note:</b> This is the bucket.io preview mode. To start
                collecting data, publish this Single Page.
              </PreviewMessage>
            )}
            {this.state.showSinglePage && (
              <div
                css={css`
                  padding-top: ${isNewPreview ? 60 : 0}px;
                `}
                dangerouslySetInnerHTML={{ __html: this.state.singlePageHtml }}
              ></div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SinglePageContainer;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaInformation = ({ settings, metaImage }) => {
  return (
    <Helmet>
      {/* <link
        rel="icon"
        href={`${metaImage.url || "%PUBLIC_URL%/favicon.ico"}`}
      /> */}
      <meta charSet="utf-8" />
      <title>{settings.metaTitle || "Untitled"}</title>
      {/* <!-- Twitter Card data --> */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@publisher_handle" />
      <meta
        name="twitter:title"
        content={`${settings.metaTitle || "Bucket.io"}`}
      />
      <meta
        name="twitter:description"
        content={`${settings.metaDescription || "No Description"}`}
      />
      {/* <!-- Twitter Summary card images must be at least 200x200px --> */}
      <meta name="twitter:image" content={`${metaImage.url || ""}`} />

      {/* <!-- Open Graph data --> */}
      <meta
        property="og:title"
        content={`${settings.metaTitle || "Bucket.io"}`}
      />
      <meta
        property="og:description"
        content={`${settings.metaDescription || "No Description"}`}
      />
      <meta
        property="og:image"
        itemProp="image"
        content={`${metaImage.url || ""}`}
      />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default MetaInformation;

MetaInformation.propTypes = {
  settings: PropTypes.any,
  metaImage: PropTypes.object,
};

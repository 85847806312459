import React, { useEffect } from "react";
import queryString from "query-string";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { getABTestingURL } from "../Containers/Services";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  background-color: #fff;

  .grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    width: 26pt;
    height: 26pt;
    align-items: center;
    justify-items: center;
  }

  .grid > * {
    border: 1.2pt solid rgba(240, 240, 240, 0.01);

    animation-name: grid-elem-animation;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .grid > *:nth-of-type(2) {
    animation-delay: 0.05s;
  }
  .grid > *:nth-of-type(3) {
    animation-delay: 0.1s;
  }
  .grid > *:nth-of-type(4) {
    animation-delay: 0.15s;
  }
  .grid > *:nth-of-type(5) {
    animation-delay: 0.2s;
  }
  .grid > *:nth-of-type(6) {
    animation-delay: 0.25s;
  }
  .grid > *:nth-of-type(7) {
    animation-delay: 0.3s;
  }
  .grid > *:nth-of-type(8) {
    animation-delay: 0.35s;
  }
  .grid > *:nth-of-type(9) {
    animation-delay: 0.4s;
  }

  @keyframes grid-elem-animation {
    0% {
      border-color: rgba(240, 240, 240, 0);
      border-radius: 2pt;
      width: 2pt;
      height: 2pt;
      background-color: rgba(80, 80, 255, 0);
    }
    50% {
      border-color: rgba(240, 240, 240, 0.15);
      border-radius: 2pt;
      width: 4pt;
      height: 4pt;
      background-color: #f2994a;
    }
    100% {
      border-color: rgba(240, 240, 240, 0);
      border-radius: 2pt;
      width: 2pt;
      height: 2pt;
      background-color: rgba(80, 80, 255, 0);
    }
  }
`;
const ABTestingContainer = props => {
  useEffect(() => {
    const { match, history } = props;
    const { verification_token } = match.params;

    (async function loadInfoRotator() {
      try {
        const { data } = await getABTestingURL(verification_token);
        let newUrl = utmParamsToStr(
          queryString.parse(window.location.search),
          data.data.redirect_to,
        );
        window.location = newUrl;
      } catch (e) {
        if (e.response && e.response.status === 404) {
          history.replace("/404");
        }
      }
    })();
  }, [props]);

  function utmParamsToStr(objectLocation, urlRedirect) {
    if (Object.keys(objectLocation).length === 0) {
      return urlRedirect;
    }
    let str = "";
    for (let key in objectLocation) {
      if (str !== "") {
        str += "&";
      }
      str += `${key}=${objectLocation[key]}`;
    }
    if (urlRedirect.indexOf("?") !== -1) {
      str = `&${str}`;
    } else {
      str = `?${str}`;
    }
    return urlRedirect + str;
  }
  return (
    <Container>
      <div className="grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default ABTestingContainer;

ABTestingContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

import React from "react";
import ContentLoader from "react-content-loader";

export const CardUpdateScreenDesktop = props => (
  <ContentLoader
    width={1280}
    height={768}
    viewBox="0 0 1280 768"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="272" y="99" rx="3" ry="3" width="9" height="518" />
    <rect x="272" y="613" rx="3" ry="3" width="729" height="9" />
    <rect x="1000" y="98" rx="3" ry="3" width="9" height="522" />
    <rect x="306" y="122" rx="16" ry="16" width="96" height="76" />
    <rect x="417" y="136" rx="3" ry="3" width="185" height="13" />
    <rect x="417" y="157" rx="3" ry="3" width="295" height="14" />
    <rect x="417" y="181" rx="3" ry="3" width="172" height="12" />
    <rect x="345" y="351" rx="3" ry="3" width="178" height="16" />
    <rect x="272" y="95" rx="3" ry="3" width="736" height="8" />
    <rect x="306" y="348" rx="3" ry="3" width="33" height="20" />
    <rect x="529" y="462" rx="14" ry="14" width="226" height="36" />
    <rect x="83" y="16" rx="3" ry="3" width="88" height="46" />
    <rect x="499" y="252" rx="0" ry="0" width="280" height="23" />
    <rect x="329" y="286" rx="0" ry="0" width="627" height="10" />
    <rect x="306" y="325" rx="0" ry="0" width="88" height="15" />
    <rect x="306" y="390" rx="0" ry="0" width="68" height="16" />
    <rect x="658" y="390" rx="0" ry="0" width="82" height="14" />
    <rect x="658" y="416" rx="0" ry="0" width="69" height="18" />
    <rect x="309" y="517" rx="0" ry="0" width="658" height="13" />
    <rect x="856" y="519" rx="0" ry="0" width="113" height="1" />
    <rect x="492" y="535" rx="0" ry="0" width="291" height="12" />
    <rect x="401" y="554" rx="0" ry="0" width="474" height="11" />
    <rect x="587" y="583" rx="0" ry="0" width="105" height="16" />
    <rect x="516" y="688" rx="0" ry="0" width="239" height="14" />
    <rect x="306" y="417" rx="0" ry="0" width="101" height="14" />
  </ContentLoader>
);

export const CardUpdateScreenMobile = props => (
  <ContentLoader
    speed={2}
    width={375}
    height={660}
    viewBox="0 0 375 660"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="142" y="5" rx="0" ry="0" width="93" height="63" />
    <rect x="19" y="80" rx="0" ry="0" width="340" height="5" />
    <rect x="354" y="80" rx="0" ry="0" width="6" height="559" />
    <rect x="18" y="80" rx="0" ry="0" width="6" height="559" />
    <rect x="98" y="97" rx="0" ry="0" width="183" height="15" />
    <rect x="75" y="121" rx="0" ry="0" width="228" height="14" />
    <rect x="80" y="141" rx="0" ry="0" width="218" height="21" />
    <rect x="115" y="176" rx="0" ry="0" width="140" height="18" />
    <rect x="139" y="203" rx="0" ry="0" width="100" height="100" />
    <rect x="62" y="330" rx="0" ry="0" width="255" height="24" />
    <rect x="56" y="377" rx="0" ry="0" width="267" height="13" />
    <rect x="57" y="398" rx="0" ry="0" width="264" height="13" />
    <rect x="50" y="449" rx="0" ry="0" width="85" height="12" />
    <rect x="50" y="468" rx="0" ry="0" width="278" height="22" />
    <rect x="50" y="512" rx="0" ry="0" width="61" height="12" />
    <rect x="18" y="635" rx="0" ry="0" width="340" height="6" />
    <rect x="50" y="530" rx="0" ry="0" width="135" height="22" />
    <rect x="55" y="579" rx="0" ry="0" width="267" height="45" />
    <rect x="193" y="512" rx="0" ry="0" width="61" height="12" />
    <rect x="193" y="530" rx="0" ry="0" width="135" height="22" />
  </ContentLoader>
);

/**@jsx jsx */
import { jsx } from "@emotion/core";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import checkCircle from "../../assets/check-circle-tk.svg";
//Images
import lockSecureImage from "../../assets/secure-icon.svg";
import warningCircle from "../../assets/warning-circle-tk.svg";
import MetaInformation from "../../Components/MetaInformation/MetaInformation";
//Utils
import { getCurrencyFormat, toastError } from "../../utils";
//Services
import * as services from "../Services";
//Components
import PaymentMessaggeScreen from "./PaymentMessageScreen";
import { CardUpdateScreenDesktop, CardUpdateScreenMobile } from "./Placeholder";
import SubmitButton from "./SubmitButton";
//Constants
import { currentStateStripeScreen } from "./UpdateCreditCardStripe.constants";
//Styles
import * as styles from "./UpdateCreditCardStripe.styled";

const initialValuesScreen = {
  relatedProductHeader: "Pending payment related to",
  headline: "Update Payment Method",
  subheadline:
    // eslint-disable-next-line max-len
    "Your new payment method will be applied to your pending payment and future billing dates. To perform the update you must do the following",
  buttonText: "Update Your Payment Method",
  footerBoxTitle: "For more information contact us here:",
};
const UpdateCreditCardStripe = () => {
  const [currentState, setCurrentState] = useState(
    currentStateStripeScreen.FETCH_DATA,
  );

  const [data, setData] = useState({ invoice_info: {}, article: {} });
  const [billingDetailsData, setBillingDetailsData] = useState({
    name: "",
    postalCode: "",
    billingAddress: "",
  });
  const [stripeClient, setStripeClient] = useState(null);

  const token = useParams().token || null;
  const elements = useRef(null);
  const clientSecret = useRef(null);

  const imgUrl =
    "https://bucket-app-utils.s3.amazonaws.com/email-template/placeholder-invoice.png";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await services.verifyRecoveryToken(token);

        const newData = {
          article: {
            image: getImage(response.data.article.image),
            name: response.data.article.name,
            price: getCurrencyFormat(
              response.data.article.currency,
              response.data.price / 100,
            ),
            number_of_installments: response.data.number_of_installments,
            current_installment: response.data.current_installment,
          },
          invoice_info: {
            image: getImage(response.data.provider_information.image),
            email_contact: response.data.provider_information.email,
            phone: response.data.provider_information.phone,
            address: response.data.provider_information.address,
            vat_id: response.data.provider_information.vat_id,
          },
          country_code: response.data.country_code,
          transactional_email_copys: getTransactionalEmailInfo(
            response.data.transactional_email,
          ),
        };
        const stripe = await loadStripe(response.data.public_key);

        clientSecret.current = response.data.client_secret;

        setStripeClient(stripe);

        const elementOptions = {
          fonts: [
            {
              cssSrc:
                // eslint-disable-next-line max-len
                "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap",
            },
          ],
          clientSecret: response.data.client_secret,
          locale: "us",
          appearance: {
            theme: "stripe",
            variables: {
              colorText: "#8a8a8a",
              fontFamily: "Open Sans, sans-serif",
              fontSizeBase: "14px",
            },
          },
        };

        const paymentOptions = {
          terms: {
            card: "never",
          },
          fields: {
            billingDetails: {
              name: "auto",
              address: {
                line1: "auto",
                country: "auto",
                postal_code: "auto",
              },
            },
          },
        };
        elements.current = stripe.elements(elementOptions);

        const paymentElement = elements.current.create(
          "payment",
          paymentOptions,
        );
        setData(newData);
        paymentElement.mount("#payment-element");
        if (elements.current) {
          elements.current["_elements"][0].on("ready", function(_) {
            setCurrentState(currentStateStripeScreen.IDLE);
          });
        }
      } catch (err) {
        console.log(err);
        setCurrentState(currentStateStripeScreen.TOKEN_ERROR);
      }
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  const getTransactionalEmailInfo = data => {
    if (!data || (data && !data.credit_card_update_payment_body))
      return initialValuesScreen;

    let dataSaved = {};

    Object.keys(initialValuesScreen).forEach(element => {
      dataSaved[element] = data.credit_card_update_payment_body[
        element
      ].outer.replace("contenteditable", "");
    });
    return dataSaved;
  };

  const getImage = image => {
    if (!image) {
      return imgUrl;
    }
    return image.url;
  };

  const confirmPayment = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await stripeClient.confirmPayment({
          elements: elements.current,
          redirect: "if_required",
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: billingDetailsData.name,
                address: {
                  line1: billingDetailsData.billingAddress,
                  country: data.country_code,
                  postal_code: billingDetailsData.postalCode,
                },
              },
            },
          },
        });

        if (response.error) {
          toastError({
            message: response.error.message,
          });
          reject(response.error);
        }
        if (response.paymentIntent) {
          resolve(response);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleOnChangeInput = ({ target }, field) => {
    const cloneBillingDetailsData = { ...billingDetailsData };
    cloneBillingDetailsData[field] = target.value;
    setBillingDetailsData(cloneBillingDetailsData);
  };

  const onSubmitForm = async e => {
    e.preventDefault();
    try {
      setCurrentState(currentStateStripeScreen.SUBMIT_FORM);
      const data = await confirmPayment();
      services.invalidateRecoveryToken(token);
      setCurrentState(currentStateStripeScreen.PAYMENT_SUCCEDEED);
      console.log(data);
    } catch (e) {
      setCurrentState(currentStateStripeScreen.IDLE);
      console.log(e);
    }
  };

  if (currentState === currentStateStripeScreen.PAYMENT_SUCCEDEED)
    return (
      <PaymentMessaggeScreen
        image={checkCircle}
        title="Well done!"
        description="Your payment method has been successfully updated."
      />
    );
  if (currentState === currentStateStripeScreen.TOKEN_ERROR)
    return (
      <PaymentMessaggeScreen
        image={warningCircle}
        title="Update payment process is no longer available"
        description="This may be due to a wrong, expired or used token."
      />
    );

  return (
    <Fragment>
      <div css={styles.wrapperLoading({ currentState })}>
        <CardUpdateScreenDesktop className="loading-desktop" />
        <CardUpdateScreenMobile className="loading-mobile" />{" "}
      </div>
      <div css={styles.container({ currentState })}>
        <MetaInformation
          settings={{
            metaTitle: "Update Payment Method",
            metaDescription:
              "Your new payment method will be applied to your pending payment and upcoming billing dates.",
          }}
          metaImage={{ url: data.invoice_info.image }}
        />
        <Fragment>
          <header className={!data.invoice_info.image ? "lowHeader" : "header"}>
            <div className="wrapper">
              {data.invoice_info.image && (
                <img src={data.invoice_info.image} alt="Invoice Info" />
              )}
            </div>
          </header>
          <main>
            <div className="box">
              <div className="product-section">
                <div className="product-section__image">
                  <img src={data.article.image} alt="ArticleImage" />
                </div>
                <div className="product-section__info">
                  <p
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html:
                        data &&
                        data.transactional_email_copys &&
                        data.transactional_email_copys.relatedProductHeader,
                    }}
                  />
                  <p className="product-name">{data.article.name}</p>
                  <p className="installment-info">
                    Installment {data.article.current_installment} of{" "}
                    {data.article.number_of_installments} - {data.article.price}
                  </p>
                </div>
              </div>
              <div className="card-info-section">
                <h2
                  className="card-info-section__headline"
                  dangerouslySetInnerHTML={{
                    __html:
                      data &&
                      data.transactional_email_copys &&
                      data.transactional_email_copys.headline,
                  }}
                />
                <p
                  className="card-info-section__subheadline"
                  dangerouslySetInnerHTML={{
                    __html:
                      data &&
                      data.transactional_email_copys &&
                      data.transactional_email_copys.subheadline,
                  }}
                />
                <form
                  className="card-info-section__form"
                  onSubmit={onSubmitForm}
                >
                  <div className="inputs">
                    <div className="inputs__name-zipcode">
                      <div className="input-container">
                        <label className="label">Cardholder Name</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Customer Name"
                          onChange={e => handleOnChangeInput(e, "name")}
                        />
                      </div>
                    </div>
                    <div id="payment-element"></div>
                    <div className="inputs__billing-address">
                      <div className="input-container">
                        <label className="label">Zip code</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="000000"
                          onChange={e => handleOnChangeInput(e, "zipCode")}
                        />
                      </div>
                      <div className="input-container">
                        <label className="label">Billing Address</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Billing Address"
                          onChange={e =>
                            handleOnChangeInput(e, "billingAddress")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="buttons">
                    <SubmitButton
                      submitting={
                        currentState === currentStateStripeScreen.SUBMIT_FORM
                      }
                      text={
                        data &&
                        data.transactional_email_copys &&
                        data.transactional_email_copys.buttonText
                      }
                    />
                  </div>
                </form>
                <div className="card-info-section__disclaimer">
                  <p
                    className="first-paragraph"
                    dangerouslySetInnerHTML={{
                      __html:
                        data &&
                        data.transactional_email_copys &&
                        data.transactional_email_copys.footerBoxTitle,
                    }}
                  ></p>
                  <p className="second-paragraph">
                    {data.invoice_info.email_contact} -{" "}
                    {data.invoice_info.phone} <br />
                    {data.invoice_info.address
                      ? `${data.invoice_info.address}`
                      : ""}{" "}
                    {data.invoice_info.vat_id
                      ? ` - TAX ID ${data.invoice_info.vat_id}`
                      : ""}
                  </p>
                </div>
                <div className="card-info-section__secure_image">
                  <img src={lockSecureImage} alt="secure" />
                  <span>Secure Server</span>
                </div>
              </div>
            </div>
          </main>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default UpdateCreditCardStripe;

import { css } from "@emotion/core";

export const progressBar = (
  numBar,
  colorProgressBar,
  textColorProgressBar,
) => css`
  width: 100%;
  height: 1px;
  background: #bdbdbd;
  position: relative;
  .mainProgressBar {
    max-width: 100%;
    width: ${numBar};
    height: 8px;
    background: ${colorProgressBar};
    position: absolute;
    top: -3px;
    border-radius: 5px;
    transition: width 0.35s;
    &:after {
      content: "${parseInt(numBar) > 100 ? "100%" : numBar}";
      position: absolute;
      right: 0px;
      top: -4px;
      width: 34px;
      height: 16px;
      background: ${colorProgressBar};
      border-radius: 5px;
      font-weight: 600;
      font-size: 9px;
      color: ${textColorProgressBar};
      text-align: center;
      line-height: 16px;
    }
    @media (max-width: 450px) {
      &:after {
        right: -15px;
      }
    }
  }
  &.disabledPB{
    visibility: hidden;
  }
`;

import styled from "@emotion/styled";

export const Dialog = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: ${props => (props.open === true ? "block" : "none")};
  opacity: ${props => (props.open === true ? 1 : 0)};
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
  

  .mainLeadCapture {
    top: ${props => (props.isPreview ? 80 : 0)}px;
    position: relative;
    max-width: 770px;
    height: 497px;
    margin: 10% auto;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 1 ), 0px 0px 25px rgba( 0, 0, 0, 1 );
    border-radius: 10px;

    #newBody {
      display: inline-block;
      width: 100%;
      min-height: 497px;
      background-color: #ffffff;
      overflow: hidden;
    }

    .invalid {
      border-bottom: 2px solid red !important;
    }

    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 450px) {
      width: 80%;
    }

    @media (max-width: 330px) {
      height: 350px;
      top: ${props => (props.isPreview ? 103 : 0)}px;
    }

    @media (max-width: 1280px) and (max-height:750px) {
      margin: 1% auto;
    }

    @media (min-width: 1281px) and (max-height:750px) {
      margin: 1% auto;
      top: 70px;
    }

    @media (max-width: 830px) and (max-height: 450px) {
      min-height: 270px; 
      max-height: 270px; 
      margin: 1% auto;
      max-width: 90%;
    }

    @media (max-width: 830px) and (max-height: 330px) {
      min-height: 220px; 
      max-height: 220px; 
    }
  }

  .close {
    position: absolute;
    right: 6px;
    text-align: center;
    top: 1px;
    width: 25px;
    padding: 3px;
    height: 26px;
    cursor:pointer;
}
  }
  .close:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius:100px;
  }

`;

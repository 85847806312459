/* eslint no-eval: 0 */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { LoadingScreen } from "../Components";
import * as services from "./Services";
import { welcomeCss } from "./PagesCss";
import * as _ from "lodash";
import $ from "jquery";
import React, { Component } from "react";

import PlaceholderBuilder from "../Components/placeholderBuilder/PlaceholderBuilder";
import {
  getScriptTagFromString,
  getSymbolCurrency,
  insertPoweredByBucketFooter,
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
} from "../utils";

import { PreviewMessage, InternetMessage } from "./ApplicationContainer.styled";
import { initialStateApplicationContainer } from "./initialState";

let isPreview = "";
let isNewPreview = "";

class PageContainer extends Component {
  state = initialStateApplicationContainer;

  componentDidMount = async () => {
    try {
      let response = null;
      const { verification_token, page_base64_id } = this.props.match.params;

      isPreview =
        window.location.href.includes("preview.") === true ||
        window.location.href.includes("preview-2.") === true
          ? true
          : false;

      if (isPreview) response = await services.getPreview(verification_token);

      /* Validate Subscription Active */
      if (typeof response.data.subscription_active !== "undefined") {
        if (!response.data.subscription_active) {
          this.props.history.replace("/404");
          return;
        }
      }

      if (isPreview) isNewPreview = true;
      //Welcome Page
      const contentCurrentPage = response.data.pages.find(page => {
        return page.id === Number(atob(page_base64_id));
      });

      //console.log("welcome--->", contentCurrentPage);

      if (typeof contentCurrentPage === "undefined") {
        throw new Error("Page Empty");
      }
      // ********************

      let contentPage = `${
        contentCurrentPage.fields.html
      }<style>${replaceBackgroundImagesByBackgrounds(
        contentCurrentPage.fields.css,
      )}${welcomeCss}`;

      contentPage = this.replaceProductPrice(contentPage, contentCurrentPage);

      // FOR SCRIPTS WISTIA
      let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
      let match;
      let scripsList = [];
      while ((match = re.exec(contentPage))) {
        if (match[1] === "") {
          const str = match[0];
          const script = getScriptTagFromString(str);

          document.body.appendChild(script);
        }
        // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
        scripsList.push(match[1]);
      }

      // FOR VIMEO/YOUTUBE VIDEOS
      contentPage = renderIframeUrl(contentPage);

      this.setState(
        {
          contentPage: `${contentPage}`,
          contentCurrentPage: contentCurrentPage,
          isLoadingInitialInfo: false,
          showPage: true,
        },
        () => {
          // eval(match[1]);
          try {
            scripsList.forEach(script => eval(script));
            this.scrollToAnchor();
          } catch (e) {
            console.log(e);
          }

          response.data.powered_by_bucket && insertPoweredByBucketFooter();

          window.setTimeout(() => {
            const elements = document.querySelectorAll(".calendly-button");
            elements.forEach(el => {
              el.addEventListener(
                "click",
                evt => {
                  const btn = evt.target.closest(".calendly-button");
                  const event = btn.getAttribute("scheduling_url");
                  if (!event) return;
                  const params = [];
                  if (btn.getAttribute("hide_gdpr_banner") !== null)
                    params.push("hide_gdpr_banner=1");
                  if (btn.getAttribute("hide_landing_page_details") !== null)
                    params.push("hide_landing_page_details=1");
                  if (btn.getAttribute("hide_event_type_details") !== null)
                    params.push("hide_event_type_details=1");
                  if (window.Calendly) {
                    const calendlyFullLink = `${event}?${params.join("&")}`;
                    window.Calendly.showPopupWidget(calendlyFullLink);
                  }
                },
                el,
                this,
              );
            });
          }, 100);
        },
      );

      //Install Calendly Script
      if (response.data.use_calendly) {
        const scriptCalendly =
          "<script src=\"https://assets.calendly.com/assets/external/widget.js\"/>";
        $(scriptCalendly).prependTo("head");
        const stylesCalendly =
          "<link href=\"https://assets.calendly.com/assets/external/widget.css\" rel=\"stylesheet\">";
        $(stylesCalendly).prependTo("head");
      }

      // Anchor Animation
      this.anchorPostWelcome();
    } catch (e) {
      //console.log(e, e.name, e.message);
      if (e === "Error: Page Empty") {
        this.setState({ emptyCanvas: true, isLoadingInitialInfo: false });
      }
      if (e.response && Number(e.response.status) === 404) {
        this.props.history.replace("/404");
      }
    }
  };

  replaceProductPrice(contentPage, contentCurrentPage) {
    //Replace ProductInfo (Price) for Sales Page
    if (
      contentCurrentPage &&
      contentCurrentPage.fields &&
      contentCurrentPage.fields.products_detail
    ) {
      const htmlDom = new DOMParser().parseFromString(contentPage, "text/html");
      const selection = htmlDom.body.getElementsByClassName("product-info");
      const elements = Array.prototype.slice.call(selection);
      elements.forEach(el => {
        if (el.dataset["productId"]) {
          const product = contentCurrentPage.fields.products_detail.find(
            product => product.id === Number(el.dataset["productId"]),
          );
          el.innerHTML = el.innerHTML.replace(
            /\[PRICE\]/g,
            getSymbolCurrency(product.currency) + product.unit_price,
          );
        }
      });
      contentPage = htmlDom.body.innerHTML;
    }
    return contentPage;
  }

  scrollToAnchor = () => {
    const anchorUrl = _.split(window.location.href, "#");
    if (anchorUrl[1]) {
      let dest = `#${anchorUrl[1]}`;
      window.setTimeout(function() {
        const offsetTop = document.querySelector(dest)?.offsetTop;

        window.scroll({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  render() {
    //console.log(this.state.emptyCanvas, this.state.isLoadingInitialInfo);
    return (
      <div>
        {this.state.isLoadingInitialInfo ? (
          <LoadingScreen />
        ) : this.state.emptyCanvas ? (
          <PlaceholderBuilder type="emptyCanvas" />
        ) : !this.state.contentCurrentPage.fields.html ? (
          <PlaceholderBuilder type="StandardPage" />
        ) : (
          <React.Fragment>
            {isNewPreview && !this.state.isInternet && (
              <PreviewMessage>
                <b>Note:</b> This is the bucket.io page preview mode, and this
                view does not represent a functional page.
              </PreviewMessage>
            )}
            {this.state.isInternet && (
              <InternetMessage>
                Your Internet connection seems to be unstable - please verify
                your connection and submit your answer again.
              </InternetMessage>
            )}
            {this.state.showPage && (
              <div
                css={css`
                  padding-top: ${isNewPreview ? 60 : 0}px;
                `}
                dangerouslySetInnerHTML={{ __html: this.state.contentPage }}
              ></div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PageContainer;

PageContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

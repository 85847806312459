/* eslint no-eval: 0 */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import $ from "jquery";
import * as _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Component, Fragment } from "react";
import { browserName, deviceDetect } from "react-device-detect";

import imgCheck from "../assets/check-circle-tk.svg";
import { LoadingScreen } from "../Components";
import { StandartModal2 } from "../Components/Dialog/Dialog2.component";
import MetaInformation from "../Components/MetaInformation/MetaInformation";
import { PaymentForm, PostQuizPage, WelcomePage } from "../Components/Pages";
import StripeLoading from "../Components/Pages/StripeLoading";
import PlaceholderBuilder from "../Components/placeholderBuilder/PlaceholderBuilder";
import {
  checkWebinarWhenOnlyOneExist,
  executeTrackingCodeForPage,
  getSanitizedHtml,
  getScriptTagFromString,
  redirectWithUtmParameters,
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
  replaceCustomVariables,
  replaceFieldLogicValues,
  replaceWebinarDateToLocalDate,
  toastError,
  insertPoweredByBucketFooter,
  removeLastCharacterEqualTo,
} from "../utils";
import { cleanInput } from "../utils/cardHelpers";
import {
  generateScriptFraudnet,
  generateSessionId,
} from "../utils/fraudnetFunctions";
import {
  InternetMessage,
  PreviewMessage,
  StripeBanner,
  TestModeBanner,
  WebinarThank,
} from "./ApplicationContainer.styled";
//Constants
import {
  applicationContainerStatus,
  defaultDesignOptions,
  internetConnectionStatus,
  modalContainerStatus,
} from "./constants";
import { PostQuizCss, welcomeCss } from "./PagesCss";
//Services
import * as services from "./Services";
import { loadStripe } from "@stripe/stripe-js";

export default class ApplicationContainer2 extends Component {
  state = {
    isPreview: false,
    current: applicationContainerStatus.FETCHING_DATA,
    splitUrl: "",
    facebookPixelTrackerSettings: [],
    GATrackerSettings: [],
    outcomeType: "",
    UTMShow: "false",
    passVariable: "false",
    passVariableQuestion: "false",
    passScore: "false",
    lcAllFields: [],
    modalContainerStatus: modalContainerStatus.IDLE,
    internetConnectionStatus: internetConnectionStatus.ONLINE,
    design: {},
    questions: [],
    nodesPath: null,
    fieldLogicChoicesArray: [],
    metaImage: { url: null },
    nextDisabled: true,
    nextOther: false,
    nextOtherAlone: false,
    nextCheckOther: false,
    questionsPassed: 0,
    postQuizPageInfo: {},
    winnerOutcome: null,
    quizScore: 0,
    leadCaptureFieldsVariables: [],
    welcomeContinue: false,
    pagesTrackingCodes: [],
    allPages: [],
    contentPageHtml: "",
    modelsDiagramNodes: [],
    modelsDiagramLinks: [],
    leadCaptures: [],
    settings: [],
    questionsDB: [],
    outcomes: [],
    currentQuestion: {},
    fieldLogicArrayFull: [],
    currentProduct: {},
    purchasedProducts: [],
    showStripeLoadingProgress: false,
    useStripe: true,
    showBannerEmptyPaymentGateway: false,
    showBannerTestMode: false,
    paymentMode: "test_mode",
    taxesProductConfig: {
      taxes: [],
      tax_in_total: false,
    },
  };
  takerToken = null;
  allQuestions = [];
  allQuestions = [];
  firstQuestion = null;
  cantActual = [];
  startQuizRef = null;
  setIntervalInternet = null;
  outcomeArrayWinner = [];
  currentQuestion = {};
  winnerOutcomeGlobal = {};
  pagesViewSent = [];
  globalAnswersJson = {};
  answerLCCont = true;
  fbc = null;
  fbp = null;
  url = null;
  //Stripe Vars
  stripeClient = null;
  stripeElements = null;
  cardElement = null;
  stripeCardResponse = null;
  sourcePagePayment = null;
  paymentIntent = null;
  paymenGatewayLeadCapture = null;
  locationData = null;
  taxesTotal = 0;
  paymentFormInfo = null;
  userCurrencySetting = null;
  paypalClientMetadataId = generateSessionId();
  paymentTransactionLogs = [];
  authorizeAccessToken = null;

  async componentDidMount() {
    try {
      let response = null;
      const { verification_token: verificationToken } = this.props.match.params;

      const isPreview = this.getIsPreview();

      if (isPreview) response = await services.getPreview(verificationToken);
      else response = await services.getCloudfrontInfo(verificationToken);

      //Validate if a exists any page
      if (!response.data.pages.length) {
        this.setState({
          current: applicationContainerStatus.EMPTY_CANVAS,
        });
        return;
      }

      //Validate if the welcome page is empty

      const welcomePage = response.data.pages.find(page => {
        return page.page_type === "welcome";
      });

      if (welcomePage && !welcomePage.fields.html) {
        this.setState({
          current: applicationContainerStatus.WELCOME_PAGE_EMPTY,
        });
        return;
      }

      const {
        appendDataLeadCapture,
        passUtmVariables,
        appendDataQuestions,
        passScore = "false",
      } = response.data.settings;

      //UTM LC and Pass toggles
      const UTMShow = !appendDataLeadCapture ? "false" : appendDataLeadCapture;
      const passVariable = !passUtmVariables ? "false" : passUtmVariables;
      const passVariableQuestion = !appendDataQuestions
        ? "false"
        : appendDataQuestions;

      //Pass UTM
      const splitUrlFull = _.split(window.location.href, "?");
      const splitUrl = !splitUrlFull[1] ? "" : splitUrlFull[1];
      if (response.data.use_redirect) {
        const urlFull = this.redirectLinkUtm(
          response.data.redirect_url,
          passVariable,
          splitUrl,
          passVariableQuestion,
          "false",
          response.data.outcomes_type,
          0,
        );
        window.location.href = urlFull;
        return;
      }

      //Validate Domain
      this.validateDomainAndFunnelActive(
        isPreview,
        response.data.output_link,
        response.data.status,
      );

      //Validate Subscription
      this.validateSubscription(response.data.subscription_active);

      //Set Trackers Google Analytics/Facebook Pixel/ Tracking Pages Code
      const {
        facebookPixelTrackerSettings,
        GATrackerSettings,
        pagesTrackingCodes,
      } = this.setTrackers(isPreview, response.data);

      // create taker and welcome view
      !isPreview && (await this.createTaker(response.data.id, welcomePage.id));

      //Get all LC fields
      const lcAllFields = this.getAllLCFields(response.data.lead_captures);

      //Get HTML and Script for Welcome Page
      const { pageHtml, scriptList } = this.getContentPage(
        welcomePage,
        welcomeCss,
      );

      //Get Questions
      this.questions = [...response.data.sections[0].questions].map(q => {
        return {
          ...q,
          checkView: false,
          answer: null,
          answerValue: null,
        };
      });

      //Field Logic Array From Questions
      let fieldLogicArrayFull = [];
      this.questions.forEach(q => {
        if (q.field_logic_data)
          fieldLogicArrayFull.push(...q.field_logic_data.values);
      });

      const { modelsDiagramNodes, modelsDiagramLinks } = this.getModelsDiagram(
        response.data.sections[0],
      );

      //Verify connections for questions
      const statusConnectionForQuestions = this.verifyConnectionsForQuestions(
        this.questions,
        modelsDiagramLinks,
      );

      //Outcomes
      const outcomes = response.data.outcomes.map(outcome => {
        return {
          ...outcome,
          sum: 0,
        };
      });

      //Set Design Options Object
      const design = this.organizeDesignOptions(
        response.data.funnel_design_options,
      );

      if (this.firstQuestion) {
        this.allQuestions.push(this.firstQuestion);
        //console.log(allQuestions, firstQuestion);
        if (this.firstQuestion.random_choice_order) {
          if (
            this.firstQuestion.question_type === "radio" ||
            this.firstQuestion.question_type === "check"
          ) {
            const firstQuestionChoiceTemp = this.shuffle(
              this.firstQuestion.option_choices,
            );
            this.firstQuestion.option_choices = firstQuestionChoiceTemp;
          }
        }
        this.newProgress(this.firstQuestion, design);
      }

      ///Set State
      this.setState(
        {
          UTMShow,
          isPreview,
          passVariable,
          passScore,
          passVariableQuestion,
          splitUrl,
          facebookPixelTrackerSettings,
          GATrackerSettings,
          pagesTrackingCodes,
          allPages: response.data.pages,
          lcAllFields,
          metaImage: response.data.meta_image,
          contentPageHtml: pageHtml,
          current: applicationContainerStatus.SHOWING_WELCOME_PAGE,
          modalContainerStatus: statusConnectionForQuestions,
          modelsDiagramNodes,
          modelsDiagramLinks,
          fieldLogicArrayFull,
          outcomeType: response.data.outcomes_type,
          leadCaptures: response.data.lead_captures,
          settings: response.data.settings,
          outcomes,
          currentQuestion: this.firstQuestion,
          design,
          paymentMode: response.data.payment_mode,
          showBannerTestMode: response.data.payment_mode === "test_mode",
          welcomePage,
          // useStripe: response.data.use_stripe,
        },
        () => {
          const welcomeNode = _.find(modelsDiagramNodes, el => {
            return el.id === welcomePage.internal_id;
          });
          let firstOutPort = null;
          if (welcomeNode) firstOutPort = welcomeNode.portsOutOrder[0];
          // ******* TRACKING CODE FOR PAGES */
          executeTrackingCodeForPage(pagesTrackingCodes, welcomePage);

          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();

          try {
            this.executeScripts(scriptList);
            this.scrollToAnchor();
          } catch (e) {}

          //Set EventListener for old button of the welcome page
          this.setEventListener(".quiz-start", "click", evt => {
            if (welcomeNode && firstOutPort)
              this.goNextNodeLinkedInConnector(welcomePage, firstOutPort, evt);
            // this.startQuiz(welcomePage,  evt),
          });

          //For Quiz Button N, New button in Welcome Page
          this.setEventListener(
            ".btn-start-quiz-with-connector[link_type*='start_quiz']",
            "click",
            async evt => {
              evt.preventDefault();
              console.log(evt);
              const target = evt.target.closest(
                ".btn-start-quiz-with-connector",
              );

              let target_port = target.getAttribute("output_port");
              if (!target_port) {
                target_port = firstOutPort;
              }
              let product = null;

              const productID = this.getProductByElement(
                target,
                "target_product",
              );
              //New Products
              if (Number(productID) && Number(productID) !== -1)
                product = this.getProductByID(
                  welcomePage.fields.products_detail,
                  productID,
                );

              if (product) {
                await this.setState({ currentProduct: product });
              }
              this.goNextNodeLinkedInConnector(welcomePage, target_port, evt);
            },
          );
          //For SingleProduct Component
          this.setEventListener(
            ".product-info[data-product][data-target-port]",
            "click",
            async evt => {
              const parentNode = evt.target.closest(
                ".product-info[data-product][data-target-port]",
              );
              const target_port = parentNode.getAttribute("data-target-port");

              let product = null;
              const productID = Number(
                parentNode.getAttribute("data-product-id"),
              );
              //New Products
              if (Number(productID) && Number(productID) !== -1)
                product = this.getProductByID(
                  welcomePage.fields.products_detail,
                  productID,
                );

              if (product) {
                await this.setState({ currentProduct: product });
              }
              this.goNextNodeLinkedInConnector(welcomePage, target_port, evt);
            },
          );

          //For Webinar, New button in Welcome Page
          this.setEventListener(
            ".btn-webinar-start-quiz-with-connector",
            "click",
            async evt => {
              evt.preventDefault();

              const {
                nextToPage,
                webinarSelectedOfPage,
                webinarsOfPage,
              } = this.validateWebinarSelected();

              let target = evt.target;
              if (target.tagName === "P" || target.tagName === "SPAN")
                target = evt.target.parentNode;
              let target_port = target.getAttribute("output_port");
              if (!target_port) {
                target_port = firstOutPort;
              }
              if (nextToPage) {
                if (webinarsOfPage.length) {
                  this.saveSelectedWebinar(webinarSelectedOfPage);
                }
                this.goNextNodeLinkedInConnector(welcomePage, target_port, evt);
              }
            },
          );
          //Calendly Button
          this.addListenerToCalendlyButtons();

          // Anchor Animation
          this.anchorAnimation();

          // Quiz Autostart
          this.startQuizRef = welcomePage.fields.type_welcome;
          this.currentQuestion = this.firstQuestion;
          if (this.startQuizRef === "auto" && firstOutPort) {
            //window.stopRedirect = true; not longer used
            this.goNextNodeLinkedInConnector(welcomePage, firstOutPort);
          }
          response.data.powered_by_bucket && insertPoweredByBucketFooter();
        },
      );

      //Set Currency Setting
      this.userCurrencySetting = response.data.currency_setting || null;

      //Find Some Node that related to payments
      // response.data.pages.find(page => page.page_type === 'commerce_sales_page')
      const payment_gateway_settings = response.data.payment_gateway_settings;

      if (Object.keys(payment_gateway_settings).length) {
        this.paymentFormInfo = {
          payment_mode: response.data.payment_mode,
        };

        if (payment_gateway_settings.use_stripe) {
          const stripeKey =
            response.data.payment_mode === "live"
              ? payment_gateway_settings.stripe_public_key
              : payment_gateway_settings.stripe_publishable_key_test_mode;
          this.stripeClient = await loadStripe(stripeKey);
          this.stripeElements = this.stripeClient.elements();
          this.paymentFormInfo = {
            ...this.paymentFormInfo,
            stripe_key: stripeKey,
          };
        }

        if (payment_gateway_settings.use_paypal) {
          generateScriptFraudnet(this.paypalClientMetadataId);
          this.paymentFormInfo = {
            ...this.paymentFormInfo,
          };
        }

        if (payment_gateway_settings.use_authorize_net) {
          this.paymentFormInfo = {
            ...this.paymentFormInfo,
          };
        }
      } else {
        const hasPaymentForm = response.data.lead_captures.find(
          lc => lc.lead_capture_type === "payment_form",
        );
        if (hasPaymentForm) {
          this.setState({ showBannerEmptyPaymentGateway: true });
        }
      }

      //Install Calendly Script
      if (response.data.use_calendly) {
        const scriptCalendly =
          '<script src="https://assets.calendly.com/assets/external/widget.js"/>';
        $(scriptCalendly).prependTo("head");
        const stylesCalendly =
          '<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">';
        $(stylesCalendly).prependTo("head");
      }
    } catch (error) {
      if (error.response && Number(error.response.status) === 404) {
        console.log("MAIN STATUS 404", error);
        this.props.history.replace("/404");
      }
    }
  }

  getIsPreview() {
    return window.location.href.includes("preview.") === true ||
      window.location.href.includes("preview-2.") === true
      ? true
      : false;
  }

  redirectLinkUtm = (
    link,
    passVariable,
    splitUrlVar,
    passVariableQuestion,
    passScore,
    outcomeType,
    quizScore,
  ) => {
    // PASS UTM
    let URLFinalR = "";
    const splitDefaultUrl = _.split(link, "?");
    if (passVariable === "true") {
      if (splitUrlVar === "") {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinalR = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
        } else {
          URLFinalR = splitDefaultUrl[0];
        }
      } else {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinalR = `${splitDefaultUrl[0]}?${splitUrlVar}&${splitDefaultUrl[1]}`;
        } else {
          URLFinalR = `${splitDefaultUrl[0]}?${splitUrlVar}`;
        }
      }
    } else {
      if (typeof splitDefaultUrl[1] !== "undefined") {
        URLFinalR = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
      } else {
        URLFinalR = splitDefaultUrl[0];
      }
    }

    //console.log(URLFinalRInit);
    if (passVariableQuestion === "true") {
      const URLFinalRInit = _.split(URLFinalR, "?");
      if (typeof URLFinalRInit[1] === "undefined") {
        URLFinalR += "?";
      }
      URLFinalR = this.questionUtmFunction(URLFinalR);
    }

    if (passScore === "true" && outcomeType === "scored") {
      const splitURLFinalR = _.split(URLFinalR, "?");
      //console.log(splitURLFinalR[1], URLFinalR);
      if (typeof splitURLFinalR[1] === "undefined") {
        URLFinalR += `?quiz_score=${quizScore}`;
      } else {
        if (splitURLFinalR[1] === "") {
          URLFinalR += `quiz_score=${quizScore}`;
        } else {
          URLFinalR += `&quiz_score=${quizScore}`;
        }
      }
    }

    return URLFinalR;
  };

  removeDuplicateInAllQuestions = allQuestions => {
    const resultMap = new Map();

    allQuestions.forEach(item => {
      // Use the "id" as the key in the map
      const key = item.id;

      // Check if the map already has an item for this key
      if (resultMap.has(key)) {
        // If the existing item doesn't have "answerValue" but the current one does, replace it
        if (!resultMap.get(key).answerValue && item.answerValue) {
          resultMap.set(key, item);
        }
      } else {
        // If the key is not in the map, add the current item
        resultMap.set(key, item);
      }
    });

    return Array.from(resultMap.values());
  };

  questionUtmFunction = URLFinalR => {
    const allQ = this.removeDuplicateInAllQuestions(this.allQuestions);
    allQ.forEach(qTemp => {
      const splitURLFinalRTemp = _.split(URLFinalR, "?");
      //console.log(splitURLFinalRTemp[1]);
      if (qTemp.utm_parameters.length > 0) {
        if (qTemp.utm_parameters[0].state) {
          if (
            qTemp.utm_parameters[0].utm_value !== "" &&
            qTemp.utm_parameters[0].utm_value !== null
          ) {
            URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
              qTemp.utm_parameters[0].utm_tag
            }=${qTemp.utm_parameters[0].utm_value}`;
          } else {
            if (
              qTemp.question_type === "short_text" ||
              qTemp.question_type === "long_text"
            ) {
              if (qTemp.answerValue !== null) {
                URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                  qTemp.utm_parameters[0].utm_tag
                }=${qTemp.answerValue}`;
              }
            }
            if (qTemp.question_type === "radio") {
              const qChoiceAns = _.find(qTemp.option_choices, el => {
                return el.internal_id === qTemp.answerValue;
              });
              if (qChoiceAns) {
                if (qChoiceAns.other) {
                  URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                    qTemp.utm_parameters[0].utm_tag
                  }=${qChoiceAns.answer}`;
                } else {
                  URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                    qTemp.utm_parameters[0].utm_tag
                  }=${getSanitizedHtml({
                    html: qChoiceAns.name,
                    unescape: true,
                  })}`;
                }
              }
            }
          }
        }
      }
      if (qTemp.question_type === "radio") {
        const splitURLFinalRTemp2 = _.split(URLFinalR, "?");
        //console.log(splitURLFinalRTemp2[1]);
        const qChoiceAns = _.find(qTemp.option_choices, el => {
          return el.internal_id === qTemp.answerValue;
        });
        if (
          qChoiceAns &&
          qChoiceAns.utm_parameters.length > 0 &&
          qChoiceAns.utm_parameters[0].state
        ) {
          if (
            qChoiceAns.utm_parameters[0].utm_value !== "" &&
            qChoiceAns.utm_parameters[0].utm_value !== null
          ) {
            URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
              qChoiceAns.utm_parameters[0].utm_tag
            }=${qChoiceAns.utm_parameters[0].utm_value}`;
          } else {
            if (qChoiceAns.other) {
              URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
                qChoiceAns.utm_parameters[0].utm_tag
              }=${qChoiceAns.answer}`;
            } else {
              URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
                qChoiceAns.utm_parameters[0].utm_tag
              }=${getSanitizedHtml({
                html: qChoiceAns.name,
                unescape: true,
              })}`;
            }
          }
        }
      }
      if (qTemp.question_type === "check") {
        const splitURLFinalRTemp3 = _.split(URLFinalR, "?");
        //console.log(splitURLFinalRTemp3[1]);
        qTemp.answerValue &&
          qTemp.answerValue.forEach(ans => {
            if (ans.checked === true) {
              const qChoiceAns = _.find(qTemp.option_choices, el => {
                return el.internal_id === ans.id;
              });
              if (
                qChoiceAns.utm_parameters.length > 0 &&
                qChoiceAns.utm_parameters[0].state
              ) {
                if (
                  qChoiceAns.utm_parameters[0].utm_value !== "" &&
                  qChoiceAns.utm_parameters[0].utm_value !== null
                ) {
                  URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                    qChoiceAns.utm_parameters[0].utm_tag
                  }=${qChoiceAns.utm_parameters[0].utm_value}`;
                } else {
                  if (qChoiceAns.other) {
                    URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                      qChoiceAns.utm_parameters[0].utm_tag
                    }=${qChoiceAns.answer}`;
                  } else {
                    URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                      qChoiceAns.utm_parameters[0].utm_tag
                    }=${getSanitizedHtml({
                      html: qChoiceAns.name,
                      unescape: true,
                    })}`;
                  }
                }
              }
            }
          });
      }
    });
    return URLFinalR;
  };

  validateDomainAndFunnelActive(isPreview, output_link, funnel_status) {
    if (!isPreview && window.location.hostname !== "localhost") {
      const currentDomain = removeLastCharacterEqualTo(
        queryString.parseUrl(window.location.href).url,
        "/",
      );
      const dbDomain = queryString.parseUrl(output_link).url;
      console.log("🚀 ~", currentDomain, dbDomain);
      if (currentDomain !== dbDomain) {
        this.props.history.replace("/404");
        return;
      }
    }

    if (funnel_status === "inactive" && !isPreview) {
      console.log(
        "404 validateDomainAndFunnelActive funnel_status === inactive",
      );
      window.location.href = "/404";
    }
  }

  validateSubscription(subscription_active) {
    if (typeof subscription_active !== "undefined") {
      if (!subscription_active) {
        console.log("404 validateSubscription ");
        this.props.history.replace("/404");
        return;
      }
    }
  }

  setFacebookVars() {
    let fbp = document.cookie
      .split(";")
      .filter(c => c.includes("_fbp="))
      .map(c => c.split("_fbp=")[1]);
    let fbc = document.cookie
      .split(";")
      .filter(c => c.includes("_fbc="))
      .map(c => c.split("_fbc=")[1]);
    fbp = (fbp.length && fbp[0]) || null;
    fbc = (fbc.length && fbc[0]) || null;
    if (!this.fbc) this.fbc = fbc;
    if (!this.fbp) this.fbp = fbp;
    if (!this.url) this.url = window.location.origin + window.location.pathname;
  }

  setTrackers(isPreview, data) {
    let facebookPixelActive = false,
      facebookPixelTrackerSettings = [],
      GAActive = false,
      GATrackerSettings = [];
    const facebook_tracker = data.trackers
      ? data.trackers.filter(opt => opt.tracker_type === "facebook_pixel")
      : [];

    if (facebook_tracker && facebook_tracker.length > 0) {
      facebookPixelActive = facebook_tracker[0].active;
      facebookPixelTrackerSettings = facebook_tracker[0].tracker_settings;
      if (facebookPixelActive) {
        $(facebook_tracker[0].metadata.script).prependTo("body");
        window.setTimeout(() => {
          this.setFacebookVars();
        }, 2000);
      }
    }

    /* Google Analytics */
    const ga_trackers = data.trackers
      ? data.trackers.filter(opt => opt.tracker_type === "google_analytics")
      : [];

    if (ga_trackers && ga_trackers.length > 0) {
      GAActive = ga_trackers[0].active;
      GATrackerSettings = ga_trackers[0].tracker_settings;
      const ga_script = `<!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=${ga_trackers[0].metadata.script}"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga_trackers[0].metadata.script}');
    </script>`;

      if (GAActive) {
        try {
          $(ga_script).appendTo("head");
        } catch (e) {
          console.log(e);
        }
      }
    }

    /* Tracking Pages */
    const pagesTrackingCodes = data.trackers
      ? data.trackers.filter(opt => opt.tracker_type === "tracking_pages")
      : [];

    //Add Tracking Codes GA and Facebook Pixel

    if (data.settings.checkHeadTrackingCode === "true") {
      $(data.settings.headTrackingCode).prependTo("head");
    } else {
      $(data.settings.headTrackingCode).appendTo("head");
    }
    $(data.settings.bodyTrackingCode).appendTo("body");

    this.GAActive = GAActive;
    this.facebookPixelActive = facebookPixelActive;

    return {
      facebookPixelTrackerSettings,
      GATrackerSettings,
      pagesTrackingCodes,
    };
  }

  createTaker = async (id, idWelcome) => {
    const utm_params = queryString.parse(window.location.search);
    let taker_parameters_attributes = [];
    Object.keys(utm_params).forEach(utm => {
      taker_parameters_attributes.push({
        name: utm,
        value: utm_params[utm],
      });
    });
    const taker = {
      taker: {
        browser: browserName,
        language: navigator.language,
        funnel_id: id,
        ...deviceDetect(),
        taker_parameters_attributes,
      },
    };
    //console.log(taker);

    try {
      const responseTaker = await services.createTaker(taker);
      this.takerToken = responseTaker.data.access_token;
      //console.log(takerToken);
      this.createPageView(this.takerToken, idWelcome);
    } catch (e) {
      console.log(e);
    }
  };

  getAllLCFields(leadCaptures) {
    let lcAllFields = [];
    leadCaptures.forEach(lc => {
      const fields = lc.lead_capture_fields.map(field =>
        `${field.title}`.replace(/\s|-/g, "_").toUpperCase(),
      );
      lcAllFields.push(...fields);
    });
    lcAllFields = _.uniq(lcAllFields);
    return lcAllFields;
  }
  //This function is used to get content of the page with css and js
  getContentPage(welcomePage, additionalCss) {
    let pageHtml = "";
    const { html = "", css = "" } = welcomePage.fields;
    pageHtml = `${html}<style>${replaceBackgroundImagesByBackgrounds(
      css,
    )}${additionalCss}`;

    // FOR SCRIPTS WISTIA
    let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    let match;
    let scriptList = [];
    while ((match = re.exec(pageHtml))) {
      if (match[1] === "") {
        const str = match[0];
        const script = getScriptTagFromString(str);

        document.body.appendChild(script);
      }
      // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
      scriptList.push(match[1]);
    }

    // FOR VIMEO/YOUTUBE VIDEOS
    pageHtml = renderIframeUrl(pageHtml);

    const newPageHtml = this.winnerPostQuizReplaceFunction(
      pageHtml,
      [],
      welcomePage,
    );

    return { pageHtml: newPageHtml, scriptList };
  }

  //Execute scripts from the page
  executeScripts(scriptList) {
    scriptList.forEach(script => {
      try {
        eval(script);
      } catch (ee) {
        console.log(ee);
      }
    });
  }

  //Set EventListener to selector
  setEventListener = (selector, event, callback) => {
    window.setTimeout(() => {
      const elements = document.querySelectorAll(selector);
      elements.forEach(el => {
        el.addEventListener(event, callback, el, this);
      });
    }, 100);
  };

  scrollToAnchor() {
    const anchorUrl = _.split(window.location.href, "#");
    if (anchorUrl[1]) {
      let dest = `#${anchorUrl[1]}`.replace("?", "");
      window.setTimeout(function() {
        const elem = document.querySelector(dest);
        if (elem) {
          const offsetTop = document.querySelector(dest).offsetTop;
          window.scroll({
            top: offsetTop,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 700);
    }
  }

  // Anchor Animation Post Quiz
  anchorAnimation() {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  }

  // Organize Design Options
  organizeDesignOptions(funnel_design_options) {
    const optionsInJSON = [
      "shadowColor",
      "bgBox",
      "next_button_color",
      "back_button_color",
    ];
    const designFull =
      funnel_design_options.length === 0
        ? defaultDesignOptions
        : funnel_design_options;
    let options = {};
    designFull.forEach(item => {
      // Object String
      let value = "";
      if (optionsInJSON.indexOf(item.option) !== -1) {
        value = JSON.parse(item.value);
      } else {
        value = item.value;
      }
      options = {
        ...options,
        [item.option]: value,
      };
    });
    return options;
  }

  //Start Quiz
  startQuiz = welcomePage => {
    // const { id: welcomeId, internal_id: welcomeInternal } = welcomePage;
    // const { modelsDiagramLinks, modelsDiagramNodes, isPreview } = this.state;
    //Hide Scroll
    this.setStatusScroll("hidden");
    if (Object.keys(this.firstQuestion).length > 0) {
      this.setState({
        current: applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL,
      });
      /* Facebook Pixel View Question */
      this.facebookPixelActive && this.FBPixelEvent(this.firstQuestion.id);
      /* Google Analytics View Question */
      this.GAActive && this.GAEvent(this.firstQuestion.id);
    } else {
      this.setState({
        current: applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL,
        modalContainerStatus: modalContainerStatus.QUESTION_WITHOUT_LINK,
      });
    }
  };

  goNextNodeLinkedInConnector = async (welcomePage, targetPort, evt) => {
    // evt && evt.preventDefault();
    const {
      modelsDiagramNodes,
      modelsDiagramLinks,
      leadCaptures,
      allPages,
      isPreview,
    } = this.state;
    if (window.navigator.onLine) {
      if (this.startQuizRef !== "auto") {
        document
          .querySelectorAll(".countdown-bucket-cont, .countdown-cont")
          .forEach(el => {
            let id = el.getAttribute("interval");
            clearInterval(id);
          });
      }

      if (targetPort) {
        const targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.sourcePort === targetPort;
        });
        if (targetNodeNextQuestion) {
          const nextNode = _.find(modelsDiagramNodes, el => {
            return el.id === targetNodeNextQuestion.target;
          });

          if (nextNode.typeNode === "QUESTION") {
            this.firstQuestion = _.find(this.questions, el => {
              return el.internal_id === nextNode.id;
            });
            if (this.firstQuestion) {
              this.allQuestions.push(this.firstQuestion);
              this.setState({ currentQuestion: this.firstQuestion }, () => {
                this.startQuiz(welcomePage);
              });
            }
          } else {
            const info = {
              from: "page",
              targetNodeFinish: targetNodeNextQuestion,
            };
            switch (nextNode.type) {
              case "js-bucket-node":
                this.nextBucketNode(nextNode);
                break;
              case "js-payment-form-node":
              case "js-lead-node":
              case "js-webinar-capture-node":
                const winnerLeadCapture = _.find(leadCaptures, el => {
                  return el.internal_id === nextNode.id;
                });
                if (typeof winnerLeadCapture !== "undefined") {
                  this.showLeadCaptureContainer(winnerLeadCapture, welcomePage);
                }
                break;
              case "js-commerce-sales-page-node":
              case "js-outcome-only-node":
              case "js-external-redirect-node":
              case "js-outcome-page-node":
              case "js-thankyou-node":
                const nextPage = _.find(allPages, el => {
                  return el.internal_id === nextNode.id;
                });
                this.showNextPage(nextPage, info, 0);
                break;
              default:
                break;
            }
          }
        } else {
          this.setStatusScroll("hidden");
          this.setState({
            current: applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL,
            modalContainerStatus: modalContainerStatus.QUESTION_WITHOUT_LINK,
          });
        }
      }

      if (!isPreview) {
        const setIntervalAuto = setInterval(() => {
          //console.log(takerToken);
          if (this.takerToken !== "") {
            this.createPageContinue(this.takerToken, welcomePage.id);
            if (this.firstQuestion) {
              const checkViewQuestion = _.find(this.questions, el => {
                return el.id === this.firstQuestion.id;
              });
              if (typeof checkViewQuestion !== "undefined") {
                if (checkViewQuestion.checkView === false) {
                  this.createQuestionView(
                    this.takerToken,
                    this.firstQuestion.id,
                  );
                  checkViewQuestion.checkView = true;
                }
              }
            }
            clearInterval(setIntervalAuto);
          }
        }, 200);
      }
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  setStatusScroll = status => {
    const htmlBody = document.getElementsByTagName("html");
    htmlBody[0].style.overflow = status;
  };

  FBPixelEvent = async Id => {
    const { facebookPixelTrackerSettings } = this.state;
    const trackerQuestionOrOutcome = _.find(
      facebookPixelTrackerSettings,
      el => {
        return el.trackable_id === Id;
      },
    );
    //console.log(trackerQuestionOrOutcome);
    if (typeof trackerQuestionOrOutcome !== "undefined") {
      if (trackerQuestionOrOutcome.active) {
        const objTAG = {};
        objTAG[trackerQuestionOrOutcome.external_data.tag.toString()] =
          trackerQuestionOrOutcome.external_data.value;
        //console.log(objTAG, window.FB("track", "PageView"));
        const randomID = new Date().getTime();
        window.fbq(
          "track",
          trackerQuestionOrOutcome.external_data.event,
          objTAG,
          { eventID: randomID },
        );
        //Verify and Set Facebook Vars
        this.setFacebookVars();
        try {
          //Verify and Set Facebook Vars
          this.setFacebookVars();

          const body = {
            type: "custom_data",
            event: trackerQuestionOrOutcome.external_data.event,
            data: objTAG,
            fbc: this.fbc,
            fbp: this.fbp,
            event_id: randomID,
            url: this.url,
          };

          await services.FBConversionAPI(this.takerToken, body);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  FBPixelEventLeadCapture = async (leadId, values) => {
    const { facebookPixelTrackerSettings } = this.state;

    const trackerQuestionForLeadCapture = _.find(
      facebookPixelTrackerSettings,
      el => {
        return el.trackable_id === leadId;
      },
    );
    console.log(trackerQuestionForLeadCapture);
    if (typeof trackerQuestionForLeadCapture !== "undefined") {
      if (trackerQuestionForLeadCapture.active) {
        const facebokPixelObject = {};
        const fieldsEnabled = trackerQuestionForLeadCapture.external_data.fields.filter(
          field => field.sw,
        );

        const defaultFields = ["fn", "ln", "em", "ph"];
        const userData = {};
        const customData = {};

        fieldsEnabled.forEach(field => {
          const value = this.getFieldValue(field.lead_capture_field_id, values);

          if (value !== null) {
            facebokPixelObject[field.tag] = value;

            if (defaultFields.includes(field.tag)) {
              userData[field.tag] = value;
            } else {
              customData[field.tag] = value;
            }
          }
        });

        const randomID = new Date().getTime();
        window.fbq(
          "track",
          trackerQuestionForLeadCapture.external_data.event,
          facebokPixelObject,
          {
            eventID: randomID,
          },
        );

        //Verify and Set Facebook Vars
        this.setFacebookVars();

        try {
          //Verify and Set Facebook Vars
          this.setFacebookVars();
          const body = {
            type: "user_data",
            event: trackerQuestionForLeadCapture.external_data.event,
            data: userData,
            custom: customData,
            fbc: this.fbc,
            fbp: this.fbp,
            event_id: randomID,
            url: this.url,
          };

          await services.FBConversionAPI(this.takerToken, body);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  getFieldValue = (field, values) => {
    const obj = values.find(el => el.lead_capture_field_id === field);
    if (obj) return obj.value;
    return null;
  };

  GAEvent = Id => {
    const { GATrackerSettings } = this.state;
    const trackerQuestionOrOutcome = _.find(GATrackerSettings, el => {
      return el.trackable_id === Id;
    });
    //console.log(trackerQuestionOrOutcome);
    if (typeof trackerQuestionOrOutcome !== "undefined") {
      if (trackerQuestionOrOutcome.active) {
        window.gtag(
          "event",
          trackerQuestionOrOutcome.external_data.event_name,
          {
            [trackerQuestionOrOutcome.external_data.parameter_name]:
              trackerQuestionOrOutcome.external_data.parameter_value,
          },
        );
      }
    }
  };

  //Get ModelsDiagram
  getModelsDiagram(section) {
    const {
      section_json: { layers },
      questions,
    } = section;

    const diagramsNode = layers.find(el => {
      return el.type === "diagram-nodes";
    });

    const diagramsLinks = layers.find(el => {
      return el.type === "diagram-links";
    });

    const welcomeNode = _.find(diagramsNode.models, el => {
      return el.type === "js-welcome-node";
    });

    const idWelcome = welcomeNode.id;
    let targetNodeFirstQuestion = _.filter(diagramsLinks.models, el => {
      return el.source === idWelcome;
    });
    //console.log(targetNodeFirstQuestion);
    if (targetNodeFirstQuestion.length === 1) {
      targetNodeFirstQuestion = targetNodeFirstQuestion[0];
      if (targetNodeFirstQuestion) {
        this.firstQuestion = _.find(questions, el => {
          return el.internal_id === targetNodeFirstQuestion.target;
        });
      }
    }

    return {
      modelsDiagramNodes: diagramsNode.models,
      modelsDiagramLinks: diagramsLinks.models,
    };
  }

  verifyConnectionsForQuestions(questions, links) {
    let statusConnectionForQuestions =
      modalContainerStatus.SHOWING_QUESTIONS_MODAL;
    questions.forEach(q => {
      if (q.question_type !== "radio") {
        let currentLinkSource = _.find(links, link => {
          return q.internal_id === link.source;
        });
        if (typeof currentLinkSource === "undefined") {
          statusConnectionForQuestions =
            modalContainerStatus.QUESTION_WITHOUT_LINK;
        }
      } else {
        q.option_choices.forEach(choice => {
          let currentLink = _.find(links, link => {
            return choice.internal_id === link.sourcePort;
          });
          /* flag to search a radio without connection */
          if (!choice.other) {
            if (typeof currentLink === "undefined") {
              statusConnectionForQuestions =
                modalContainerStatus.QUESTION_WITHOUT_LINK;
            }
          }
        });
      }
    });
    return statusConnectionForQuestions;
  }

  shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  newProgress(current, designOptions) {
    try {
      // const  questions = this.questions

      if (!designOptions) {
        designOptions = this.state.design;
      }
      const questions_length = this.questions.length;
      const valueDefault = parseInt(designOptions.valueProgressBar);
      const totalBar = (100 - valueDefault) / questions_length;
      const findCurrentActual = _.find(this.cantActual, el => {
        return current.id === el.current.id;
      });
      console.log(">cantActual", this.cantActual, current);
      if (typeof findCurrentActual === "undefined") {
        console.log("--->if");
        let totalBarFull = 0;
        if (this.cantActual[this.cantActual.length - 1]) {
          totalBarFull =
            this.cantActual[this.cantActual.length - 1].num + totalBar;
        } else {
          totalBarFull = valueDefault;
        }
        this.cantActual.push({ current: current, num: totalBarFull });
        this.setState({ nodesPath: totalBarFull });
      } else {
        console.log("--->else", this.cantActual);
        const newNum = this.cantActual[this.cantActual.length - 1].num;
        this.cantActual.push({ current: current, num: newNum });
        this.setState({ nodesPath: newNum });
      }
    } catch (e) {
      console.log(e);
    }
  }

  internetVerification() {
    this.setIntervalInternet = setInterval(() => {
      this.internetVerificationDisabled();
    }, 2000);
  }

  internetVerificationDisabled() {
    if (window.navigator.onLine) {
      clearInterval(this.setIntervalInternet);
      this.setIntervalInternet = null;
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
    }
  }

  closeModal = () => {
    this.setState({ current: applicationContainerStatus.SHOWING_WELCOME_PAGE });
    this.setStatusScroll("auto");
  };

  changeOtherValue = (value, choice) => {
    const checkRadio = _.find(this.questions, el => {
      return el.id === choice.question_id;
    });
    const checkChoiceRadio = _.find(checkRadio.option_choices, el => {
      return el.other === true;
    });
    checkChoiceRadio.answer = value;

    if (value === "") {
      this.setState({ nextDisabled: true, nextOther: true });
    } else {
      this.setState({ nextDisabled: false, nextOther: false });
    }
  };

  nextRadio = (value, optionChoice, radioQ) => {
    const {
      design: { switchNextButton },
      modelsDiagramLinks,
      modelsDiagramNodes,
      outcomeType,
      outcomes,
      isPreview,
    } = this.state;
    let { ...currentQuestion } = this.state;

    if (window.navigator.onLine) {
      let lastAnswerRadio = false;
      const useFieldLogic = radioQ.use_field_logic;
      const radioQuestion = radioQ;
      const fieldLogicChoice =
        optionChoice === null ? "" : optionChoice.field_logic_data;
      const questionAnswer = this.allQuestions[this.allQuestions.length - 1];
      let currentQuestionToShow = {};
      const answerQuestionRadioChoice = optionChoice;
      if (switchNextButton !== "true" && !optionChoice.other) {
        const targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.sourcePort === value;
        });
        if (typeof targetNodeNextQuestion !== "undefined") {
          const searchNextQuestion = this.questions.find(el => {
            return el.internal_id === targetNodeNextQuestion.target;
          });

          // Sum outcome
          if (outcomeType === "mapped") {
            optionChoice.mapped_outcomes.forEach(element => {
              const outcomeMap = _.find(outcomes, el => {
                return el.id === element.outcome_id;
              });

              this.outcomeArrayWinner.push({
                outcome: outcomeMap,
                outcomeId: outcomeMap.id,
                sum: 1,
                position: outcomeMap.position,
                questionId: radioQ.id,
              });
            });
          }
          if (outcomeType === "scored") {
            this.outcomeArrayWinner.push({
              outcome: "",
              outcomeId: "",
              sum: optionChoice.score === null ? 0 : optionChoice.score,
              position: "",
              choice: optionChoice.internal_id,
              questionId: radioQ.id,
            });
          }
          // *********
          if (typeof searchNextQuestion !== "undefined") {
            currentQuestion = searchNextQuestion;
            if (
              currentQuestion.answerValue !== "" &&
              currentQuestion.answerValue !== null
            ) {
              this.setState({ nextDisabled: false });
            }
            // Random Choice
            if (currentQuestion.random_choice_order) {
              if (
                currentQuestion.question_type === "radio" ||
                currentQuestion.question_type === "check"
              ) {
                const currentQuestionChoiceTemp = this.shuffle(
                  currentQuestion.option_choices,
                );
                currentQuestion.option_choices = currentQuestionChoiceTemp;
              }
            }
            //*********** */
            //Field Logic
            currentQuestionToShow = this.addAndReplaceFieldLogic(
              fieldLogicChoice,
              this.state.fieldLogicChoicesArray,
              currentQuestion,
              answerQuestionRadioChoice,
              useFieldLogic,
              radioQuestion,
            );
            //*********** */
            this.allQuestions.push(searchNextQuestion);
            // this.setState({
            //   currentQuestion: currentQuestionToShow,
            //   questionsPassed: this.allQuestions.length,
            // });
            // View Question
            const checkViewQuestion = _.find(this.questions, el => {
              return el.id === currentQuestion.id;
            });
            if (checkViewQuestion.checkView === false) {
              !isPreview &&
                this.createQuestionView(this.takerToken, currentQuestion.id);
              checkViewQuestion.checkView = true;
            }
            /* Facebook Pixel */
            this.facebookPixelActive && this.FBPixelEvent(currentQuestion.id);
            /* Google Analytics View Question */
            this.GAActive && this.GAEvent(currentQuestion.id);
            /****** */
          } else {
            //console.log(outcomes);
            const bucketNode = _.find(modelsDiagramNodes, el => {
              return el.id === targetNodeNextQuestion.target;
            });
            this.nextBucketNode(bucketNode);
            lastAnswerRadio = true;
          }
          // Send Data
          const answerQuestion = {
            answer: {
              other_choice_text: "",
              skipped: false,
              text_answer: "",
              question_id: answerQuestionRadioChoice.question_id,
              answer_option_choices_attributes: [
                { option_choice_id: answerQuestionRadioChoice.id },
              ],
            },
          };

          const addAnswerQuestion = _.find(this.allQuestions, el => {
            return el.id === answerQuestion.answer.question_id;
          });
          addAnswerQuestion.sent_data = answerQuestion;

          currentQuestionToShow = this.replaceOptionChoiceContentWithMappingLogic(
            currentQuestionToShow,
            answerQuestion,
          );

          this.setState({
            currentQuestion: currentQuestionToShow,
            questionsPassed: this.allQuestions.length,
          });

          //console.log(answerQuestion, answerQuestionRadioChoice);
          !isPreview &&
            this.createAnswerQuestion(
              this.takerToken,
              answerQuestion,
              lastAnswerRadio,
            );
          questionAnswer.answerValue = answerQuestionRadioChoice.internal_id;
          //this.newCalc(currentQuestion);
          /* Facebook Pixel Choice Question */
          this.facebookPixelActive &&
            this.FBPixelEvent(answerQuestionRadioChoice.id);
          /* Google Analytics View Question */
          this.GAActive && this.GAEvent(answerQuestionRadioChoice.id);
          this.newProgress(currentQuestion);
        }
      } else {
        if (optionChoice.other) {
          if (
            optionChoice.answer !== "" &&
            typeof optionChoice.answer !== "undefined"
          ) {
            this.setState({
              nextDisabled: false,
              nextOther: false,
              nextOtherAlone: true,
            });
          } else {
            this.setState({
              nextDisabled: true,
              nextOther: true,
              nextOtherAlone: true,
            });
          }
        } else {
          this.setState({ nextDisabled: false, nextOther: false });
        }
      }
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  nextCheck = (items, value, item, CQ) => {
    const CQTemp = _.find(this.questions, el => {
      return el.id === CQ.id;
    });

    CQTemp.answerValue = items;
    const searchCheckItem = _.filter(items, el => {
      return el.checked === false;
    });

    if (!searchCheckItem.length) {
      this.setState({ nextDisabled: false });
    } else {
      if (searchCheckItem.length > 0) {
        const searchCheckItem2 = _.filter(items, el => {
          return el.checked === true;
        });
        if (searchCheckItem2.length > 0) {
          this.setState({ nextDisabled: false });
        } else {
          this.setState({ nextDisabled: true });
        }
      } else {
        this.setState({ nextDisabled: false });
      }
    }
    if (item.other) {
      if (value.target.checked) {
        if (typeof item.answer !== "undefined" && item.answer !== "") {
          this.setState({ nextCheckOther: false });
        } else {
          this.setState({ nextCheckOther: true });
        }
      } else {
        this.setState({ nextCheckOther: false });
      }
    }
  };

  changeOtherValueCheck = (value, question) => {
    const otherChoices = _.find(question.option_choices, el => {
      return el.other === true;
    });
    const checkFromQuestions = _.find(this.questions, el => {
      return el.id === question.id;
    });

    const checkChoiceFromQuestions = _.find(
      checkFromQuestions.option_choices,
      el => {
        return el.id === otherChoices.id;
      },
    );

    checkChoiceFromQuestions.answer = value;

    if (value === "") {
      this.setState({ nextCheckOther: true });
    } else {
      this.setState({ nextCheckOther: false });
    }
  };

  changeShortLong = (value, id, typeIfUrl, valueTypeIfUrl) => {
    let nextDisabled = false,
      nextDisabledUrlShort = false;
    const CQ = _.find(this.allQuestions, el => {
      return el.id === id;
    });

    CQ.answerValue = value;

    if (value === "") {
      nextDisabled = true;
    }

    if (CQ.required === false && valueTypeIfUrl !== "" && typeIfUrl === false) {
      nextDisabledUrlShort = true;
    }

    this.setState({
      // currentQuestion: CQ, //Optional
      nextDisabled,
      nextDisabledUrlShort,
    });
  };

  backQuestion = () => {
    if (window.navigator.onLine) {
      const { isPreview } = this.state;
      this.allQuestions.pop();
      const currentQuestionNode = this.getCurrentQuestionNode();
      _.remove(this.outcomeArrayWinner, function(n) {
        return n.questionId === currentQuestionNode.id;
      });

      const newFieldLogicChoicesArray = this.state.fieldLogicChoicesArray.filter(
        el => {
          return el.questionId !== currentQuestionNode.id;
        },
      );

      let currentQuestionToShow = this.addAndReplaceFieldLogic(
        null,
        newFieldLogicChoicesArray,
        currentQuestionNode,
        null,
        null,
        null,
      );
      /*********************** */

      currentQuestionToShow = this.replaceOptionChoiceContentWithMappingLogic(
        currentQuestionToShow,
        currentQuestionNode,
      );

      this.setState({
        currentQuestion: currentQuestionToShow,
        questionsPassed: this.allQuestions.length - 1,
        nextDisabled: false,
        fieldLogicChoicesArray: newFieldLogicChoicesArray,
      });

      !isPreview &&
        this.deleteAnswerQuestion(
          this.takerToken,
          currentQuestionNode.answer.id,
        );

      /* Progress Bar*/
      this.cantActual.pop();
      this.cantActual.pop();

      this.newProgress(currentQuestionNode);
      /************************/
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  nextQuestion = (itemCheck, currentOtherValueCheck) => {
    if (window.navigator.onLine) {
      let lastQuestion = false;
      this.setState({ nextDisabled: true });
      const { outcomeType, isPreview } = this.state;

      const currentQuestionNode = this.getCurrentQuestionNode();
      const nextNode = this.getNextNode(currentQuestionNode);
      if (currentQuestionNode.question_type === "check") {
        this.addInfoToOutcomeScoreMapped(
          currentQuestionNode,
          itemCheck,
          outcomeType,
        );
      }

      if (nextNode) {
        switch (nextNode.type) {
          case "question":
            this.currentQuestion = nextNode.nextQuestion;
            //Validate already answer
            if (
              this.currentQuestion.answerValue !== "" &&
              this.currentQuestion.answerValue !== null
            ) {
              this.setState({ nextDisabled: false });
            }
            //*********************** */
            // Random Choice
            if (this.currentQuestion.random_choice_order) {
              if (
                this.currentQuestion.question_type === "radio" ||
                this.currentQuestion.question_type === "check"
              ) {
                const currentQuestionChoiceTemp = this.shuffle(
                  this.currentQuestion.option_choices,
                );
                this.currentQuestion.option_choices = currentQuestionChoiceTemp;
              }
            }
            //*********** */
            /* field Logic */
            let currentQuestionToShow = this.addAndReplaceFieldLogic(
              null,
              this.state.fieldLogicChoicesArray,
              this.currentQuestion,
              null,
              null,
              null,
            );
            /*********************** */

            this.setState({
              currentQuestion: currentQuestionToShow,
              questionsPassed: this.allQuestions.length,
            });
            this.allQuestions.push(nextNode.nextQuestion);
            // View Question
            const checkViewQuestion = _.find(this.questions, el => {
              return el.id === this.currentQuestion.id;
            });
            if (checkViewQuestion.checkView === false) {
              !isPreview &&
                this.createQuestionView(
                  this.takerToken,
                  this.currentQuestion.id,
                );
              checkViewQuestion.checkView = true;
            }
            // ******************
            /* Facebook Pixel */
            this.facebookPixelActive &&
              this.FBPixelEvent(this.currentQuestion.id);
            /* Google Analytics View Question */
            this.GAActive && this.GAEvent(this.currentQuestion.id);
            /****** */
            break;
          case "bucket":
            this.nextBucketNode(nextNode.bucketNode);
            lastQuestion = true;
            break;
          default:
            console.log("Node not allowed");
            break;
        }
      }
      // Send Answers

      if (
        currentQuestionNode.question_type === "short_text" ||
        currentQuestionNode.question_type === "long_text"
      ) {
        const answerQShow = _.find(this.allQuestions, el => {
          return el.internal_id === currentQuestionNode.internal_id;
        });
        const answerQuestion = {
          answer: {
            other_choice_text: "",
            skipped: answerQShow.answerValue ? false : true,
            text_answer: answerQShow.answerValue,
            question_id: currentQuestionNode.id,
            answer_option_choices_attributes: [],
          },
        };

        !isPreview &&
          this.createAnswerQuestion(
            this.takerToken,
            answerQuestion,
            lastQuestion,
          );
        this.newProgress(this.currentQuestion);
      }
      if (currentQuestionNode.question_type === "check") {
        const answerQuestion = {
          answer: {
            other_choice_text: currentOtherValueCheck,
            skipped: currentQuestionNode.answerValue ? false : true,
            text_answer: "",
            question_id: currentQuestionNode.id,
            answer_option_choices_attributes: [],
          },
        };
        itemCheck.forEach(element => {
          if (element.checked === true) {
            const choiceTemp = _.find(
              currentQuestionNode.option_choices,
              el => {
                return el.internal_id === element.id;
              },
            );
            answerQuestion.answer.answer_option_choices_attributes.push({
              option_choice_id: choiceTemp.id,
            });
            /* Facebook Pixel Choice Check */
            this.facebookPixelActive && this.FBPixelEvent(choiceTemp.id);
            /* Google Analytics View Question */
            this.GAActive && this.GAEvent(choiceTemp.id);
          }
        });

        !isPreview &&
          this.createAnswerQuestion(
            this.takerToken,
            answerQuestion,
            lastQuestion,
          );
        currentQuestionNode.answerValue = itemCheck;
        this.newProgress(this.currentQuestion);
      }
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  createPageView = async (token, id) => {
    try {
      await services.createView(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createPageViewPost = async (token, { id, ...page }) => {
    if (!this.pagesViewSent.includes[id]) {
      this.pagesViewSent.push(id);
      let data = {};
      if (
        page.page_type === "commerce_sales_page" ||
        page.page_type === "commerce_one_click"
      ) {
        data = {
          payment_mode: this.state.paymentMode,
          payment_gateway: this.getPaymentGateway(page),
        };
      }
      try {
        await services.createView(token, id, data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  getPaymentGateway = ({ internal_id, page_type }) => {
    let paymentGatewayLead = null;
    const {
      modelsDiagramLinks,
      modelsDiagramNodes,
      winnerLeadCapture,
    } = this.state;
    if (page_type === "commerce_one_click") {
      paymentGatewayLead = winnerLeadCapture.payment_gateway;
    } else {
      const link = _.find(modelsDiagramLinks, el => {
        return el.source === internal_id;
      });
      const nodeTarget = _.find(modelsDiagramNodes, el => {
        return el.id === link.target;
      });
      if (nodeTarget) {
        const nextPaymentForm = this.state.leadCaptures.find(el => {
          return el.internal_id === nodeTarget.id;
        });
        if (nextPaymentForm) {
          paymentGatewayLead = nextPaymentForm.payment_gateway;
        }
      }
    }
    return paymentGatewayLead;
  };

  createPageContinue = async (token, id) => {
    //console.log(token, id);
    if (this.state.welcomeContinue === false) {
      try {
        await services.createContinue(token, id);
        this.setState({ welcomeContinue: true });
      } catch (e) {
        console.log(e);
      }
    }
  };

  createQuestionView = async (token, id) => {
    try {
      await services.createQuestionView(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createAnswerQuestion = async (token, data, lastQuestion) => {
    try {
      const { outcomeType } = this.state;
      if (lastQuestion) {
        const { winnerOutcome, sumTotal } = this.calculaterWinnerOutcome();
        const taker = {
          answer_json: {},
        };
        let newAnswers = [];
        this.allQuestions.forEach(q => {
          const sent_data = q.sent_data ? q.sent_data.answer : data.answer;
          const ans = {
            ...sent_data,
          };
          newAnswers.push(ans);
        });
        //Verify and Set Facebook Vars
        this.setFacebookVars();
        const takerItem = {
          outcome_id: winnerOutcome.id,
          completion: true,
          fbc: this.fbc,
          fbp: this.fbp,
        };
        if (outcomeType === "scored") takerItem.score = sumTotal;
        taker.answer_json = { questions_answers: newAnswers, taker: takerItem };
        this.globalAnswersJson = taker.answer_json;
        data = { ...data, taker };
        this.setState({ winnerOutcome, quizScore: sumTotal || 0 });
      }

      const addAnswerQuestion = _.find(this.allQuestions, el => {
        return el.id === data.answer.question_id;
      });
      const responseAnswer = await services.createAnswerQuestion(token, data);
      addAnswerQuestion.answer = responseAnswer.data;
    } catch (e) {
      console.log(e);
    }
  };

  createLeadCaptureView = async (token, id) => {
    try {
      await services.leadCaptureView(token, id);
    } catch (e) {
      //console.log(e);
    }
  };

  createWinnerOutcomeComplition = async (
    token,
    id_outcome,
    typeMap,
    sumScore,
  ) => {
    try {
      await services.createWinnerOutcome(token, id_outcome, typeMap, sumScore);
    } catch (e) {
      console.log(e);
    }
  };

  deleteAnswerQuestion = async (token, id) => {
    try {
      await services.deleteAnswerQuestion(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createAnswerLead = async (
    lead,
    inputs,
    webinarSelected,
    checkGDPR,
    textGDPR,
  ) => {
    //console.log(checkGDPR.checked, textGDPR.textContent);
    let leadTemp = {
      lead_capture_answers_params: {
        values: [],
      },
      taker: {
        webinar_integrations: {},
        compliance: {},
      },
    };
    const integrations = {};
    const leadFields = lead.lead_capture_fields;
    let valuesLead = "";

    webinarSelected.forEach(webinar => {
      const provider = webinar.getAttribute("data-webinar-provider");
      const webinarId = webinar.getAttribute("data-webinar-id");
      const occurrenceId = webinar.getAttribute("data-occurrence-id");
      const webinarKey = webinar.getAttribute("data-webinar-key");

      integrations[provider] = [];

      integrations[provider] = integrations[provider].concat({
        id: webinarId,
        occurrence_id: occurrenceId,
        webinar_key: webinarKey,
      });
    });

    leadTemp.taker.webinar_integrations = { ...integrations };
    leadTemp.taker.answer_json = { lead_capture_answers: [] };
    leadTemp.taker.answer_json = {
      ...this.globalAnswersJson,
      lead_capture_answers: [],
    };

    //Verify and Set Facebook Vars
    this.setFacebookVars();

    if (Object.keys(this.globalAnswersJson).length === 0) {
      leadTemp.taker.answer_json = {
        ...leadTemp.taker.answer_json,
        taker: {
          fbc: this.fbc,
          fbp: this.fbp,
        },
      };
    }

    if (this.stripeCustomer) {
      leadTemp.taker.customer = this.stripeCustomer;
    }
    Object.keys(inputs).forEach(el => {
      //console.log(inputs[el].value, inputs[el].name, inputs[el].getAttribute("datanewid"));
      const leadFieldTemp = leadFields.find(le => {
        return le.internal_id === inputs[el].getAttribute("datanewid");
      });
      //console.log(leadFieldTemp);
      if (
        typeof leadFieldTemp !== "undefined" &&
        inputs[el].value.trim() !== ""
      ) {
        valuesLead = {
          option: inputs[el].name,
          value:
            inputs[el].tagName === "SELECT"
              ? inputs[el].options[inputs[el].selectedIndex].text
              : inputs[el].value,
          name: inputs[el].getAttribute("typecustom"),
          lead_capture_id: lead.id,
          lead_capture_field_id: leadFieldTemp.id,
        };
        leadTemp.lead_capture_answers_params.values.push(valuesLead);
      }
      if (inputs[el].getAttribute("datanewid")) {
        const newInput = {
          internal_id: inputs[el].getAttribute("datanewid"),
          title: inputs[el].name,
          name: inputs[el].getAttribute("typecustom"),
          value:
            inputs[el].tagName === "SELECT"
              ? inputs[el].options[inputs[el].selectedIndex].text
              : inputs[el].value,
        };
        leadTemp.taker.answer_json.lead_capture_answers.push(newInput);
      }
    });
    //GDPR
    if ((checkGDPR !== null) & (textGDPR !== null)) {
      const dateTimeGDPR = moment()
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const compliance = {
        gdpr: {
          value: checkGDPR.checked,
          text: textGDPR.textContent,
          date: `${dateTimeGDPR} UTC`,
        },
      };
      console.log(compliance, dateTimeGDPR);
      leadTemp.taker.compliance = compliance;
    }

    this.FBPixelEventLeadCapture(
      lead.id,
      leadTemp.lead_capture_answers_params.values,
    );

    //console.log(leadTemp, answerLCCont);
    if (this.answerLCCont) {
      this.answerLCCont = false;
      try {
        return await services.createAnswerLead(this.takerToken, leadTemp);
      } catch (e) {
        console.log(e);
      }
    }
  };

  nextBucketNode = bucketNode => {
    if (bucketNode.type === "js-bucket-node") {
      const {
        modelsDiagramLinks,
        leadCaptures,
        allPages,
        isPreview,
        welcomePage,
      } = this.state;
      let { winnerOutcome, sumTotal } = this.calculaterWinnerOutcome();

      this.winnerOutcomeGlobal = winnerOutcome;

      const targetNodeFinish = _.find(modelsDiagramLinks, el => {
        return el.sourcePort === winnerOutcome.internal_id;
      });

      this.setState({ winnerOutcome, quizScore: sumTotal || 0 }, () => {
        if (typeof targetNodeFinish !== "undefined") {
          const winnerLeadCapture = _.find(leadCaptures, el => {
            return el.internal_id === targetNodeFinish.target;
          });
          if (typeof winnerLeadCapture !== "undefined") {
            this.showLeadCaptureContainer(winnerLeadCapture, welcomePage);
          } else {
            const nextPage = _.find(allPages, el => {
              return el.internal_id === targetNodeFinish.target;
            });

            if (nextPage.page_type !== "commerce_sales_page") {
              this.executeOutcomeTrackerEvents();
            }

            this.showNextPage(
              nextPage,
              { winnerOutcome, targetNodeFinish, from: "page" },
              sumTotal,
            );
          }

          !isPreview &&
            this.createWinnerOutcomeComplition(
              this.takerToken,
              winnerOutcome.id,
              this.state.outcomeType,
              sumTotal,
            );
        } else {
          this.setState({
            current: applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL,
            modalContainerStatus: modalContainerStatus.QUESTION_WITHOUT_LINK,
          });
        }
      });
    }
  };

  getProductByElement = (element, key = "data-product") => {
    if (element.getAttribute(key) === "-1" || element.getAttribute(key) === "")
      return -1;
    const buff = Buffer.from(element.getAttribute(key), "base64");
    const product = buff.toString("utf8");
    return JSON.parse(product);
  };

  getProductByID = (products, id) => {
    return products.find(el => {
      return el.id === Number(id);
    });
  };

  executeOutcomeTrackerEvents = () => {
    const { winnerOutcome } = this.state;
    if (winnerOutcome) {
      /* Facebook Pixel */
      this.facebookPixelActive && this.FBPixelEvent(winnerOutcome.id);
      /* Google Analytics View Question */
      this.GAActive && this.GAEvent(winnerOutcome.id);
    }
  };

  //function created to clean all counters before going to next page
  stopCounterCurrentPage = () => {
    document
      .querySelectorAll(".countdown-bucket-cont, .countdown-cont")
      .forEach(el => {
        let id = el.getAttribute("interval");
        clearInterval(id);
      });
  };

  showNextPage = (nextPage, info = {}, sumTotal) => {
    this.stopCounterCurrentPage();
    this.setState({
      postQuizPageInfo: nextPage,
      current:
        nextPage.page_type === "redirect"
          ? applicationContainerStatus.SHOWING_WELCOME_PAGE
          : applicationContainerStatus.SHOWING_POSTQUIZ_PAGE,
    });
    const {
      allPages,
      leadCaptures,
      modelsDiagramLinks,
      isPreview,
    } = this.state;
    switch (nextPage.page_type) {
      case "thank_you":
      case "sales_page":
      case "outcome_only":
        this.winnerOutcomeFunction(nextPage, info.winnerOutcome);
        //Calendly Button
        this.addListenerToCalendlyButtons();
        break;
      case "webinar_page":
        this.winnerOutcomeFunction(nextPage, info.winnerOutcome);
        //Calendly Button
        this.addListenerToCalendlyButtons();
        setTimeout(() => {
          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();
          this.setEventListener(".buttonWebinarPageRegister", "click", evt =>
            this.btnNextWebinarPage(),
          );
        }, 100);

        break;
      case "redirect":
        if (info.from === "page") {
          this.redirectFuntionWithoutLC(
            allPages,
            info.targetNodeFinish,
            info.winnerOutcome,
            "direct",
          );
        } else {
          setTimeout(() => {
            this.externalURlLC(info.targetNodeFinish, "direct");
          }, 300);
        }
        break;
      case "outcome":
        this.winnerOutcomeFunction(nextPage, info.winnerOutcome);
        //Calendly Button
        this.addListenerToCalendlyButtons();
        setTimeout(() => {
          replaceWebinarDateToLocalDate();
          checkWebinarWhenOnlyOneExist();
          this.setEventListener(".outcome-next-button", "click", evt =>
            this.btnNextOutcomePage(nextPage.internal_id, info),
          );
        }, 100);
        break;
      case "commerce_sales_page":
        this.winnerOutcomeFunction(nextPage, info.winnerOutcome);
        //Calendly Button
        this.addListenerToCalendlyButtons();
        //Temporal for testing Products
        setTimeout(() => {
          const singleProductTemp = document.querySelectorAll(
            ".product-info, .link-bk-button[link_type='commerce_sales_page']",
          );
          //console.log(btnOutcomeTemp);
          const cardSingleProduct = Array.prototype.slice.call(
            singleProductTemp,
          );
          //console.log(btnOutcome);
          cardSingleProduct.forEach(el => {
            el.addEventListener("click", async e => {
              e.preventDefault();
              let product = null;
              if (!isPreview && !this.paymentFormInfo) return;
              const targetNodeFinish = _.find(modelsDiagramLinks, el => {
                return el.source === nextPage.internal_id;
              });
              if (targetNodeFinish) {
                const winnerLeadCapture = _.find(leadCaptures, el => {
                  return el.internal_id === targetNodeFinish.target;
                });

                if (winnerLeadCapture) {
                  //Validate Product Stock if the Sales Page is connected
                  if (el.tagName === "DIV" && el.dataset.product) {
                    product = nextPage.fields.products_detail.find(
                      p => p.id.toString() === el.dataset.productId,
                    );
                    this.setState({ currentProduct: product });
                  } else if (el.tagName === "A") {
                    const productID = this.getProductByElement(
                      el,
                      "target_product",
                    );
                    //New Products
                    if (Number(productID) && Number(productID) !== -1)
                      product = this.getProductByID(
                        this.state.postQuizPageInfo.fields.products_detail,
                        productID,
                      );

                    if (product) {
                      this.setState({ currentProduct: product });
                    } else {
                      if (!isPreview) {
                        toastError({
                          message:
                            "A product has not been configured in this button",
                        });
                        return;
                      }
                    }
                  }

                  if (!isPreview && product) {
                    try {
                      const { data } = await services.validateProductStock(
                        this.takerToken,
                        product.id,
                        {
                          ...this.paymentFormInfo,
                          payment_gateway_type:
                            winnerLeadCapture.payment_gateway,
                        },
                      );
                      const {
                        article,
                        payment_gateway_access_token,
                        ...rest
                      } = data;
                      this.setState({
                        taxesProductConfig: {
                          taxes: article.taxes,
                          tax_in_total: article.tax_in_total,
                          location: { ...rest },
                        },
                      });
                      if (winnerLeadCapture.payment_gateway === "authorize_net")
                        this.authorizeAccessToken = payment_gateway_access_token;
                    } catch (error) {
                      let errorMessage = "Product is out of stock";
                      if (error.response.data.errors) {
                        const message = error.response.data.errors.message;
                        errorMessage =
                          message.charAt(0).toUpperCase() + message.slice(1);
                      }
                      toastError({ message: errorMessage });
                      return;
                    }
                  }

                  const pageSource = nextPage;
                  this.showLeadCaptureContainer(winnerLeadCapture, pageSource);
                }
              }
            });
          });
        }, 100);
        break;
      case "commerce_one_click":
        this.winnerOutcomeFunction(nextPage, info.winnerOutcome);
        //Calendly Button
        this.addListenerToCalendlyButtons();
        setTimeout(() => {
          const btnsCommerceOneClick = document.querySelectorAll(
            '[link_type="commerce_one_click"]',
          );

          if (btnsCommerceOneClick.length) {
            btnsCommerceOneClick.forEach(el => {
              el.addEventListener("click", async e => {
                e.preventDefault();
                const target = el.getAttribute("output_port");
                if (target === "-1") return;

                let product = null;

                const targetNodeFinish = _.find(modelsDiagramLinks, el => {
                  return el.sourcePort === target;
                });

                const nextPage = _.find(allPages, el => {
                  return el.internal_id === targetNodeFinish.target;
                });

                const newInfo = {
                  winnerOutcome: info.winnerOutcome,
                  targetNodeFinish,
                  lead: this.state.winnerLeadCapture,
                  inputs: this.state.leadCaptureInputs,
                  from: "oneClick",
                };

                if (el.className.includes("btn-one-click")) {
                  let productID = -1;
                  if (el.getAttribute("output_port"))
                    productID = this.getProductByElement(el, "target_product");

                  //New Products
                  if (Number(productID) && Number(productID) !== -1)
                    product = this.getProductByID(
                      this.state.postQuizPageInfo.fields.products_detail,
                      productID,
                    );

                  if (!product && !isPreview) {
                    toastError({
                      message: "A product has not been configured in this link",
                    });
                    return;
                  }
                  try {
                    !isPreview &&
                      (await this.payOneClickProduct(
                        product,
                        this.state.postQuizPageInfo.id,
                      ));
                    //Request for buy products await
                    this.setState(
                      prev => ({
                        purchasedProducts: [...prev.purchasedProducts, product],
                      }),
                      () => {
                        this.showNextPage(nextPage, newInfo, sumTotal);
                      },
                    );
                  } catch (e) {
                    if (e.status && e.status === 428) {
                      toastError({
                        message: "Transaction pending approval.",
                      });

                      const buttons = document.querySelectorAll(
                        "a[link_type='commerce_one_click']",
                      );
                      if (buttons.length > 0) {
                        buttons.forEach(btn => {
                          btn.classList.add("disabled");
                        });
                      }
                      return;
                    }

                    if (
                      e.data & Array.isArray(e.data.errors) &&
                      e.data.errors.length > 0
                    ) {
                      toastError({
                        message: e.data.errors[0].replace("Stripe error: ", ""),
                      });
                    } else if (e.data.errors.message === "out of stock") {
                      toastError({
                        message: "Product is out of stock",
                      });
                    }
                  }
                } else {
                  this.showNextPage(nextPage, newInfo, sumTotal);
                }
              });
            });
          }
        }, 300);
        break;

      default:
        console.log("Page not allowed");
        break;
    }
  };

  winnerOutcomeFunction = async (winnerPostQuiz, winnerOutcome) => {
    const {
      splitUrl,
      passVariable,
      isPreview,
      passVariableQuestion,
      passScore,
      outcomeType,
      quizScore,
    } = this.state;
    let contentPagePostQuiz = `${
      winnerPostQuiz.fields.html
    }<style>${replaceBackgroundImagesByBackgrounds(
      winnerPostQuiz.fields.css,
    )}${PostQuizCss}`;
    let rePost2 = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    await this.setState({ winnerOutcome });
    let matchPost2;
    let scripsListPost2 = [];
    while ((matchPost2 = rePost2.exec(contentPagePostQuiz))) {
      if (matchPost2[1] === "") {
        const str = matchPost2[0];
        const script = getScriptTagFromString(str);

        document.body.appendChild(script);
      }
      // full matchPost2 is in matchPost2[0], whereas captured groups are in ...[1], ...[2], etc.
      scripsListPost2.push(matchPost2[1]);
    }

    // FOR VIMEO/YOUTUBE VIDEOS
    contentPagePostQuiz = renderIframeUrl(contentPagePostQuiz);

    const winnerPostquizHtmlReplace = this.winnerPostQuizReplaceFunction(
      contentPagePostQuiz,
      this.state.fieldLogicChoicesArray,
      winnerPostQuiz,
    );

    this.setState(
      {
        postQuizHtml: winnerPostquizHtmlReplace,
        postQuizWinner: winnerPostQuiz,
        current: applicationContainerStatus.SHOWING_POSTQUIZ_PAGE,
      },
      () => {
        // ******* TRACKING CODE FOR POST-QUIZ PAGES */
        executeTrackingCodeForPage(
          this.state.pagesTrackingCodes,
          this.state.postQuizWinner,
        );

        redirectWithUtmParameters(
          passVariable,
          splitUrl,
          passVariableQuestion,
          passScore,
          outcomeType,
          quizScore,
          this.redirectLinkUtm,
          this.state.winnerLeadCapture,
          this.state.leadCaptureFieldsVariables,
        );

        scripsListPost2.forEach(script => {
          try {
            eval(script);
          } catch (ee) {
            console.log(ee);
          }
        });
      },
    );

    this.setStatusScroll("auto");
    $("html").animate({ scrollTop: "0px" }, 150);

    !isPreview && this.createPageViewPost(this.takerToken, winnerPostQuiz);
    //Commented for unify the code EDWIN
    // /* Facebook Pixel */
    // this.facebookPixelActive && this.FBPixelEvent(winnerOutcome.id);
    // /* Google Analytics View Question */
    // this.GAActive && this.GAEvent(winnerOutcome.id);
    /****** */
    //Anchor PostQuiz
    this.anchorPostWelcome();
  };

  redirectFuntionWithoutLC = (
    allPages,
    targetNodeFinish,
    winnerOutcome,
    type,
  ) => {
    const {
      splitUrl,
      passVariable,
      passVariableQuestion,
      passScore,
      outcomeType,
      quizScore,
    } = this.state;
    let redirect = "";

    if (type === "direct") {
      redirect = _.find(allPages, el => {
        return el.internal_id === targetNodeFinish.target;
      });
    }

    if (type === "btn") {
      redirect = targetNodeFinish;
    }

    let URLFinalFull = "";

    // PASS UTM
    URLFinalFull = this.redirectLinkUtm(
      redirect.fields.url,
      passVariable,
      splitUrl,
      passVariableQuestion,
      passScore,
      outcomeType,
      quizScore,
    );
    /*****************/
    //console.log(redirect);
    //Commented for unify the code EDWIN
    /* Facebook Pixel */
    // this.facebookPixelActive && this.FBPixelEvent(winnerOutcome.id);
    // /* Google Analytics View Question */
    // this.GAActive && this.GAEvent(winnerOutcome.id);
    /****** */

    window.setTimeout(() => {
      console.log("redirect----bkt-redirectFuntionWithoutLC");
      window.location.href = URLFinalFull;
    }, 300);
  };

  winnerPostQuizReplaceFunction = (html, arrayFieldParams, page) => {
    let contentPagePostQuizDefault = replaceCustomVariables(
      html,
      this.state.winnerOutcome,
      this.state.quizScore,
      "page",
      this.state.leadCaptureFieldsVariables,
      this.state.lcAllFields,
      this.state.purchasedProducts,
      page,
      this.taxesTotal,
      this.userCurrencySetting,
    );

    return replaceFieldLogicValues(
      arrayFieldParams,
      contentPagePostQuizDefault,
      this.state.fieldLogicArrayFull,
    );
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  btnNextOutcomePage = (nodeNextOptionLeadID, info) => {
    const {
      nextToPage,
      webinarSelectedOfPage,
      webinarsOfPage,
    } = this.validateWebinarSelected();
    const { modelsDiagramLinks, allPages, quizScore } = this.state;
    if (nextToPage) {
      if (webinarsOfPage.length) {
        this.saveSelectedWebinar(webinarSelectedOfPage);
      }
      const targetNodeFinish = _.find(modelsDiagramLinks, el => {
        return el.source === nodeNextOptionLeadID;
      });
      const resultPage = _.find(allPages, el => {
        return el.internal_id === targetNodeFinish.target;
      });

      if (resultPage.page_type === "redirect") {
        setTimeout(() => {
          this.externalURlLC(resultPage, "btn");
        }, 300);
      } else {
        this.showNextPage(
          resultPage,
          {
            winnerOutcome: this.winnerOutcomeGlobal,
            targetNodeFinish,
            from: "page",
          },
          quizScore,
        );
      }
    }
  };

  btnNextWebinarPage = () => {
    const {
      nextToPage,
      webinarSelectedOfPage,
    } = this.validateWebinarSelected();
    if (nextToPage) {
      this.saveSelectedWebinar(webinarSelectedOfPage);
      this.setState({
        current:
          applicationContainerStatus.SHOWING_DEFAULT_WEBINAR_THANKYOU_PAGE,
      });
    }
  };

  saveSelectedWebinar = webinarSelectedOfPage => {
    let leadTemp = {
      taker: {
        webinar_integrations: {},
      },
    };
    const integrations = {};

    webinarSelectedOfPage.forEach(webinar => {
      const provider = webinar.getAttribute("data-webinar-provider");
      const webinarId = webinar.getAttribute("data-webinar-id");
      const occurrenceId = webinar.getAttribute("data-occurrence-id");
      const webinarKey = webinar.getAttribute("data-webinar-key");

      integrations[provider] = [];

      integrations[provider] = integrations[provider].concat({
        id: webinarId,
        occurrence_id: occurrenceId,
        webinar_key: webinarKey,
      });
    });

    leadTemp.taker.webinar_integrations = { ...integrations };
    // console.log(leadTemp);
    if (!this.state.isPreview) {
      try {
        return services.updateTaker(this.takerToken, leadTemp);
      } catch (e) {
        console.log(e);
      }
    }
  };

  externalURlLC = (targetNodeNextOptionLead, type) => {
    //console.log("lead-->", lead, inputs, targetNodeNextOptionLead, allPages);
    const {
      allPages,
      UTMShow,
      passVariable,
      passVariableQuestion,
      passScore,
      quizScore,
      splitUrl,
      outcomeType,
      winnerLeadCapture: lead,
      inputs = [],
    } = this.state;

    let urlUTMLC = "";
    let URLFinal = "";
    let redirect = "";
    if (type === "direct") {
      redirect = _.find(allPages, el => {
        return el.internal_id === targetNodeNextOptionLead.target;
      });
    }

    if (type === "btn") {
      redirect = targetNodeNextOptionLead;
    }

    const splitDefaultUrl = _.split(redirect.fields.url, "?");

    if (UTMShow === "true") {
      /* UTMs **** */
      if (lead && lead.utm_parameters.length > 0) {
        if (lead.utm_parameters[0].state) {
          Object.keys(inputs).forEach(el => {
            if (
              inputs[el].className !== "checkbox-gdpr" &&
              inputs[el].className !== "checkbox-input"
            ) {
              const leadFieldTemp = lead.lead_capture_fields.find(le => {
                return le.internal_id === inputs[el].getAttribute("datanewid");
              });
              //console.log("field, input--->", leadFieldTemp, lead);
              if (leadFieldTemp) {
                leadFieldTemp.utm_parameters.forEach(utmP => {
                  if (
                    utmP.state &&
                    inputs[el].value !== "" &&
                    inputs[el].value !== null
                  ) {
                    urlUTMLC += `${urlUTMLC !== "" ? "&" : ""}${utmP.utm_tag}=${
                      inputs[el].value
                    }`;
                  }
                });
              }
            }
          });
        }
      }
      if (passVariable === "true") {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          if (urlUTMLC !== "") {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${urlUTMLC}&${splitDefaultUrl[1]}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}&${splitDefaultUrl[1]}`;
            }
          } else {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${splitDefaultUrl[1]}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
            }
          }
        } else {
          if (urlUTMLC !== "") {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${urlUTMLC}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}`;
            }
          } else {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}`;
            }
          }
        }
      } else {
        //console.log("entrooo sin pass->",splitDefaultUrl[0], urlUTMLC);
        if (urlUTMLC === "") {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
          } else {
            URLFinal = splitDefaultUrl[0];
          }
        } else {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}&${splitDefaultUrl[1]}`;
          } else {
            URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}`;
          }
        }
      }
      /**************************/
    } else {
      if (passVariable === "true") {
        //console.log("entro sin LC pero con PAss--->",splitDefaultUrl[1], splitUrl);
        //URLFinal = splitDefaultUrl[0] + "?" + splitUrl;
        if (splitUrl === "") {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
          } else {
            URLFinal = splitDefaultUrl[0];
          }
        } else {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${splitDefaultUrl[1]}`;
          } else {
            URLFinal = `${splitDefaultUrl[0]}?${splitUrl}`;
          }
        }
      } else {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
        } else {
          URLFinal = splitDefaultUrl[0];
        }
      }
    }
    //console.log(splitUrl);
    if (passVariableQuestion === "true") {
      const URLFinalRInit = _.split(URLFinal, "?");
      if (typeof URLFinalRInit[1] === "undefined") {
        URLFinal += "?";
      }
      URLFinal = this.questionUtmFunction(URLFinal);
    }
    if (passScore === "true" && outcomeType === "scored") {
      const splitURLFinal = _.split(URLFinal, "?");
      if (typeof splitURLFinal[1] === "undefined") {
        URLFinal += `?quiz_score=${quizScore}`;
      } else {
        if (splitURLFinal[1] === "") {
          URLFinal += `quiz_score=${quizScore}`;
        } else {
          URLFinal += `&quiz_score=${quizScore}`;
        }
      }
    }
    // Commented for unify code EDWIN
    // /* Facebook Pixel */
    // this.facebookPixelActive && this.FBPixelEvent(this.winnerOutcomeGlobal.id);
    // /* Google Analytics View Question */
    // this.GAActive && this.GAEvent(this.winnerOutcomeGlobal.id);
    /****** */
    window.setTimeout(() => {
      console.log("redirect----bkt-externalURlLC");
      window.location.href = URLFinal;
    }, 300);
  };

  validateWebinarSelected = () => {
    const webinarsOfPage = Array.from(
      document.querySelectorAll(".webinars-list .webinar-list__item"),
    );

    const webinarSelectedOfPage = webinarsOfPage.filter(webinar => {
      const inputRadio = webinar.querySelector("input[type= 'radio']");
      return inputRadio.checked;
    });

    if (!webinarSelectedOfPage.length && webinarsOfPage.length) {
      toastError({ message: "You must select a webinar" });
      return { nextToPage: false, webinarSelectedOfPage, webinarsOfPage };
    }
    return { nextToPage: true, webinarSelectedOfPage, webinarsOfPage };
  };

  showLeadCaptureContainer = async (winnerLeadCapture, pageSource = null) => {
    const { isPreview } = this.state;
    this.answerLCCont = true; //To resubmit lead_capture_answers
    let contentPageLCTemp = `${winnerLeadCapture.pages[0].fields.html}`;
    const isEmpty =
      winnerLeadCapture.pages[0].fields &&
      (Object.keys(winnerLeadCapture.pages[0].fields).length === 0 ||
        winnerLeadCapture.pages[0].fields.html === "");
    let reLC = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

    let matchLC;
    let scripsListLC = [];
    while ((matchLC = reLC.exec(contentPageLCTemp))) {
      if (matchLC[1] === "") {
        const str = matchLC[0];
        const script = getScriptTagFromString(str);

        document.body.appendChild(script);
      }
      // full matchLC is in matchLC[0], whereas captured groups are in ...[1], ...[2], etc.
      scripsListLC.push(matchLC[1]);
    }

    let settingView = {
      current: applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL,
      modalContainerStatus: isEmpty
        ? modalContainerStatus.SHOWING_EMPTY_LEAD_CAPTURE_MODAL
        : modalContainerStatus.SHOWING_LEAD_CAPTURE_MODAL,
    };

    const isPaymentForm =
      winnerLeadCapture.lead_capture_type === "payment_form";
    if (isPaymentForm) {
      let urlPaypalApprovalBA = "";
      if (!isPreview && winnerLeadCapture.payment_gateway === "paypal") {
        const response = await this.createBillingAgreementToken();
        this.billing_agreement_token = response.ba_token;
        urlPaypalApprovalBA = response.url.href;
      }
      settingView = {
        current: isEmpty
          ? applicationContainerStatus.SHOWING_EMPTY_PAYMENTFORM_PAGE
          : applicationContainerStatus.SHOWING_PAYMENTFORM_PAGE,
        modalContainerStatus: null,
        urlPaypalApprovalBA,
      };
      this.sourcePagePayment = pageSource;
      this.setStatusScroll("auto");
    }

    this.setState(
      {
        ...settingView,
        winnerLeadCapture: winnerLeadCapture,
      },
      () => {
        // ******* TRACKING CODE FOR LC PAGE */
        executeTrackingCodeForPage(
          this.state.pagesTrackingCodes,
          this.state.winnerLeadCapture.pages[0],
        );
        window.setTimeout(() => {
          try {
            scripsListLC.forEach(script => {
              try {
                eval(script);
              } catch (ee) {
                console.log(ee);
              }
            });
          } catch (e) {
            console.log(e);
          }
        }, 100);
      },
    );
    !isPreview &&
      this.createLeadCaptureView(this.takerToken, winnerLeadCapture.id);
  };

  setLocationData = customerInfo => {
    if (this.locationData && !this.locationData.sublocation) {
      let sublocation = null;
      const element = document.querySelector(".field-state-billing-address");
      if (element) {
        sublocation = {
          write_by_user: true,
          name: element.value,
        };
      }
      this.locationData.sublocation = sublocation;
    }
    if (!this.locationData) {
      this.locationData = {
        location: {},
        sublocation: {},
      };
    } else {
      customerInfo["Billing State"] = this.locationData.sublocation.name;
    }

    return customerInfo;
  };

  createAuthorizeOpaqueData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const inputCardNumber = document.querySelector("#card-number");
        const inputCardDate = document.querySelector("#card-date");
        const inputCardCvv = document.querySelector("#card-cvv");

        const cardData = {
          cardNumber: cleanInput(inputCardNumber.value),
          expirationDate: inputCardDate.value,
          cardCode: inputCardCvv.value,
        };
        const { data } = await services.createOpaqueData(
          cardData,
          this.authorizeAccessToken,
          this.state.paymentMode,
        );
        if (data.messages.resultCode === "Error") {
          reject(data.messages.message[0]);
        }
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  };

  confirmAuthorizePayment = (leadCapture, customerInfo, opaqueData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const transactionData = {
          data_descriptor: opaqueData.dataDescriptor,
          data_value: opaqueData.dataValue,
        };
        const { data } = await services.confirmAuthorizePayment(
          this.takerToken,
          this.state.currentProduct.id,
          this.sourcePagePayment.id,
          customerInfo,
          leadCapture.payment_gateway,
          this.state.paymentMode,
          this.locationData,
          transactionData,
        );
        this.paymenGatewayLeadCapture = leadCapture.payment_gateway;
        if (!this.state.currentProduct.tax_in_total)
          this.taxesTotal += data.tax_value / 100;

        resolve(data);
      } catch (error) {
        reject(error.response);
      }
    });
  };

  createPaymentIntent = (productId, pageId, customerInfo, paymentGateway) => {
    return new Promise(async (resolve, reject) => {
      try {
        //Verify if exist a payment intent created
        if (this.paymentIntent) {
          services.deleteCustomerPayment(
            this.takerToken,
            this.paymentIntent,
            this.stripeCustomer,
            this.state.currentProduct.user_id,
            paymentGateway,
            this.state.paymentMode,
          );
        }
        customerInfo = this.setLocationData(customerInfo);
        const { data } = await services.createPaymentIntent(
          this.takerToken,
          productId,
          pageId,
          customerInfo,
          paymentGateway,
          this.state.paymentMode,
          this.locationData,
        );
        this.paymentIntent = data.payment_intent;
        this.client_secret = data.client_secret;
        this.stripeCustomer = data.customer;
        this.paymenGatewayLeadCapture = paymentGateway;
        if (!this.state.currentProduct.tax_in_total)
          this.taxesTotal += data.tax_value / 100;

        // this.setStatusScroll("auto");
        resolve(data);
      } catch (e) {
        reject(e.response.data.errors);
      }
    }, 2000);
  };

  createBillingAgreementToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await services.createBillingAgreementToken(
          this.takerToken,
          this.state.currentProduct.id,
          "paypal",
          this.state.paymentMode,
        );
        resolve(data);
      } catch (e) {
        reject(e.response.data.errors);
      }
    }, 2000);
  };

  payOneClickProduct = (product, pageId) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setStatusLoadingPayment(true);
        const { data } = await services.payOneClickProduct(
          this.takerToken,
          product.id,
          pageId,
          this.paymenGatewayLeadCapture,
          this.state.paymentMode,
          this.locationData,
          this.paypalClientMetadataId,
        );
        if (this.paymenGatewayLeadCapture === "stripe") {
          this.client_secret = data.client_secret;
          this.stripeElements = this.stripeClient.elements();
        }
        if (!product.tax_in_total) this.taxesTotal += data.tax_value / 100;

        this.setStatusScroll("auto");
        this.setStatusLoadingPayment(false);
        resolve(data);
      } catch (e) {
        this.setStatusLoadingPayment(false);
        reject(e.response);
      }
    }, 2000);
  };

  addInfoToOutcomeScoreMapped = (
    currentQuestionNode,
    itemCheck,
    outcomeType,
  ) => {
    const { outcomes } = this.state;
    if (outcomeType === "mapped") {
      itemCheck.forEach(element => {
        if (element.checked === true) {
          const choiceTemp = _.find(currentQuestionNode.option_choices, el => {
            return el.internal_id === element.id;
          });
          if (typeof choiceTemp !== "undefined") {
            choiceTemp.mapped_outcomes.forEach(element => {
              const outcomeMap = _.find(outcomes, el => {
                return el.id === element.outcome_id;
              });
              // outcomeMap.sum = outcomeMap.sum + 1;
              this.outcomeArrayWinner.push({
                outcome: outcomeMap,
                outcomeId: outcomeMap.id,
                sum: 1,
                position: outcomeMap.position,
                questionId: currentQuestionNode.id,
              });
            });
          }
        }
      });
    }

    if (outcomeType === "scored") {
      itemCheck.forEach(element => {
        if (element.checked === true) {
          const choiceTemp = _.find(currentQuestionNode.option_choices, el => {
            return el.internal_id === element.id;
          });
          this.outcomeArrayWinner.push({
            outcome: "",
            outcomeId: "",
            sum: choiceTemp.score === null ? 0 : choiceTemp.score,
            position: "",
            choice: choiceTemp.internal_id,
            questionId: currentQuestionNode.id,
          });
        }
      });
    }
  };

  getNextNode = currentNode => {
    const { modelsDiagramLinks, modelsDiagramNodes } = this.state;
    const targetNode = _.find(
      modelsDiagramLinks,
      el => el.source === currentNode.internal_id,
    );

    if (targetNode) {
      const nextQuestion = this.questions.find(el => {
        return el.internal_id === targetNode.target;
      });
      if (nextQuestion) {
        return { nextQuestion, type: "question", targetNode };
      } else {
        const bucket = _.find(modelsDiagramNodes, el => {
          return el.id === targetNode.target;
        });
        return { type: "bucket", bucketNode: bucket, targetNode };
      }
    }
    return null;
  };

  getCurrentQuestionNode = () => {
    return this.allQuestions[this.allQuestions.length - 1];
  };

  findRelatedChoice = (inherit_mapping_question_id, choice) => {
    const mappedQuestion = this.allQuestions.find(
      q => q.id === inherit_mapping_question_id,
    );
    if (mappedQuestion) {
      const selectedChoiceId =
        mappedQuestion.sent_data.answer.answer_option_choices_attributes[0]
          .option_choice_id;

      const findChoice = mappedQuestion.option_choices.find(
        op => op.id === selectedChoiceId,
      );

      if (findChoice && findChoice.inherit_mapping_question_id) {
        return this.findRelatedChoice(
          findChoice.inherit_mapping_question_id,
          findChoice,
        );
      }
      return findChoice;
    }
    return choice;
  };

  replaceOptionChoiceContentWithMappingLogic = question => {
    if (question.use_inherit_mapping) {
      question.option_choices = question.option_choices.map(op => {
        let name = op.name;
        let mapped_outcomes = op.mapped_outcomes;
        let image_url = op.image_url;
        if (op.inherit_mapping_question_id) {
          const op_choice = this.findRelatedChoice(
            op.inherit_mapping_question_id,
            op,
          );

          if (op_choice) {
            name = op_choice.name;
            mapped_outcomes = op_choice.mapped_outcomes;
            image_url = op_choice.image_url;
          }
        }
        return {
          ...op,
          name,
          mapped_outcomes,
          image_url,
        };
      });
    }

    return question;
  };

  nextQuestionDisabled = (value, radioQ, otherValue) => {
    if (window.navigator.onLine) {
      let lastAnswerRadioD = false;
      this.setState({ nextDisabled: true });
      const {
        modelsDiagramLinks,
        outcomes,
        isPreview,
        modelsDiagramNodes,
      } = this.state;

      const useFieldLogic = radioQ.use_field_logic;
      const radioQuestion = radioQ;
      const fieldLogicChoice = value === null ? "" : value.field_logic_data;
      const answerQuestionRadioChoice = value;

      const questionAnswer = this.getCurrentQuestionNode();
      let currentQuestionToShow = {};
      let targetNodeNextQuestion = null;
      if (value !== null) {
        targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.sourcePort === value.internal_id;
        });
      }

      if (targetNodeNextQuestion !== null) {
        const searchNextQuestion = _.find(this.questions, el => {
          return el.internal_id === targetNodeNextQuestion.target;
        });
        // Sum outcome
        if (this.state.outcomeType === "mapped") {
          value.mapped_outcomes.forEach(element => {
            const outcomeMap = _.find(outcomes, el => {
              return el.id === element.outcome_id;
            });

            this.outcomeArrayWinner.push({
              outcome: outcomeMap,
              outcomeId: outcomeMap.id,
              sum: 1,
              position: outcomeMap.position,
              questionId: radioQ.id,
            });
          });
        }
        if (this.state.outcomeType === "scored") {
          this.outcomeArrayWinner.push({
            outcome: "",
            outcomeId: "",
            sum: value.score === null ? 0 : value.score,
            position: "",
            choice: value.internal_id,
            questionId: radioQ.id,
          });
        }
        // *********
        if (typeof searchNextQuestion !== "undefined") {
          this.currentQuestion = searchNextQuestion;
          if (
            this.currentQuestion.answerValue !== "" &&
            this.currentQuestion.answerValue !== null
          ) {
            this.setState({ nextDisabled: false });
          }
          // Random Choice
          if (this.currentQuestion.random_choice_order) {
            if (
              this.currentQuestion.question_type === "radio" ||
              this.currentQuestion.question_type === "check"
            ) {
              const currentQuestionChoiceTemp = this.shuffle(
                this.currentQuestion.option_choices,
              );
              this.currentQuestion.option_choices = currentQuestionChoiceTemp;
            }
          }
          //*********** */
          //Field Logic

          currentQuestionToShow = this.addAndReplaceFieldLogic(
            fieldLogicChoice,
            this.state.fieldLogicChoicesArray,
            this.currentQuestion,
            answerQuestionRadioChoice,
            useFieldLogic,
            radioQuestion,
          );

          //*********** */
          // this.setState({
          //   currentQuestion: currentQuestionToShow,
          //   questionsPassed: this.allQuestions.length,
          // });
          this.allQuestions.push(searchNextQuestion);
          // View Question
          const checkViewQuestion = _.find(this.questions, el => {
            return el.id === this.currentQuestion.id;
          });
          if (checkViewQuestion.checkView === false) {
            !isPreview &&
              this.createQuestionView(this.takerToken, this.currentQuestion.id);
            checkViewQuestion.checkView = true;
          }
          /* Facebook Pixel */
          this.facebookPixelActive &&
            this.FBPixelEvent(this.currentQuestion.id);
          /* Google Analytics View Question */
          this.GAActive && this.GAEvent(this.currentQuestion.id);
          /****** */
        } else {
          //Field Logic
          this.addAndReplaceFieldLogicBucket(
            fieldLogicChoice,
            this.state.fieldLogicChoicesArray,
            answerQuestionRadioChoice,
            useFieldLogic,
            radioQuestion,
          );
          //*********** */
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.id === targetNodeNextQuestion.target;
          });
          this.nextBucketNode(bucketNode);
          lastAnswerRadioD = true;
        }
        // Send Data
        const answerQuestion = {
          answer: {
            other_choice_text: otherValue,
            skipped: false,
            text_answer: "",
            question_id: answerQuestionRadioChoice.question_id,
            answer_option_choices_attributes: [
              { option_choice_id: answerQuestionRadioChoice.id },
            ],
          },
        };

        questionAnswer.answerValue = answerQuestionRadioChoice.internal_id;

        const addAnswerQuestion = _.find(this.allQuestions, el => {
          return el.id === answerQuestion.answer.question_id;
        });
        addAnswerQuestion.sent_data = answerQuestion;

        /// Option Choice Mapping Logic Implementation
        currentQuestionToShow = this.replaceOptionChoiceContentWithMappingLogic(
          currentQuestionToShow,
          answerQuestion,
        );

        this.setState({
          currentQuestion: currentQuestionToShow,
          questionsPassed: this.allQuestions.length,
        });

        !isPreview &&
          this.createAnswerQuestion(
            this.takerToken,
            answerQuestion,
            lastAnswerRadioD,
          );

        /* Facebook Pixel Choice Question */
        this.facebookPixelActive &&
          this.FBPixelEvent(answerQuestionRadioChoice.id);
        /* Google Analytics View Question */
        this.GAActive && this.GAEvent(answerQuestionRadioChoice.id);
        this.newProgress(this.currentQuestion);
      } else {
        if (radioQ.skip_goes_to === "-1" || radioQ.skip_goes_to === null) {
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.type === "js-bucket-node";
          });
          this.nextBucketNode(bucketNode);
          lastAnswerRadioD = true;
        } else {
          if (radioQ.skip_goes_to !== null) {
            const searchNextQuestion = _.find(this.questions, el => {
              return el.internal_id === radioQ.skip_goes_to;
            });
            this.currentQuestion = searchNextQuestion;
            // Random Choice
            if (this.currentQuestion.random_choice_order) {
              if (
                this.currentQuestion.question_type === "radio" ||
                this.currentQuestion.question_type === "check"
              ) {
                const currentQuestionChoiceTemp = this.shuffle(
                  this.currentQuestion.option_choices,
                );
                this.currentQuestion.option_choices = currentQuestionChoiceTemp;
              }
            }
            //*********** */
            //Field Logic
            let currentQuestionToShow = {};

            currentQuestionToShow = this.addAndReplaceFieldLogic(
              fieldLogicChoice,
              this.state.fieldLogicChoicesArray,
              this.currentQuestion,
              answerQuestionRadioChoice,
              useFieldLogic,
              radioQuestion,
            );
            //*********** */

            this.setState({
              currentQuestion: currentQuestionToShow,
              questionsPassed: this.allQuestions.length,
            });
            this.allQuestions.push(searchNextQuestion);
            // View Question
            const checkViewQuestion = _.find(this.questions, el => {
              return el.id === this.currentQuestion.id;
            });
            if (checkViewQuestion.checkView === false) {
              !isPreview &&
                this.createQuestionView(
                  this.takerToken,
                  this.currentQuestion.id,
                );
              checkViewQuestion.checkView = true;
            }
            /* Facebook Pixel */
            this.facebookPixelActive &&
              this.FBPixelEvent(this.currentQuestion.id);
            /* Google Analytics View Question */
            this.GAActive && this.GAEvent(this.currentQuestion.id);
            /****** */
            console.log(searchNextQuestion);
          }
        }
        // Send Data
        const answerQuestion = {
          answer: {
            other_choice_text: "",
            skipped: true,
            text_answer: "",
            question_id: radioQ.id,
            answer_option_choices_attributes: [{ option_choice_id: [] }],
          },
        };

        !isPreview &&
          this.createAnswerQuestion(
            this.takerToken,
            answerQuestion,
            lastAnswerRadioD,
          );
        questionAnswer.answerValue =
          answerQuestionRadioChoice !== null
            ? answerQuestionRadioChoice.internal_id
            : "";
        this.facebookPixelActive &&
          this.FBPixelEvent(answerQuestionRadioChoice.id);
        /* Google Analytics View Question */
        this.GAActive && this.GAEvent(answerQuestionRadioChoice.id);
        this.newProgress(this.currentQuestion);
      }
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  nextQuestionDisabledCheck = (itemCheck, currentOtherValueCheck) => {
    this.nextQuestion(itemCheck, currentOtherValueCheck);
  };

  addAndReplaceFieldLogic = (
    fieldLogicChoice,
    fieldLogicChoicesArray,
    currentQuestion,
    answerQuestionRadioChoice,
    useFieldLogic,
    radioQuestion,
  ) => {
    if (useFieldLogic === true && fieldLogicChoice !== null) {
      Object.keys(fieldLogicChoice).forEach(item => {
        //console.log(item, fieldLogicChoice[item]);
        fieldLogicChoicesArray.push({
          question: radioQuestion,
          choice: answerQuestionRadioChoice,
          name: item,
          value: fieldLogicChoice[item],
        });
      });
    }
    const currentQuestionWithFieldLogic = { ...currentQuestion };

    const tempFieldLogicArray = fieldLogicChoicesArray.map(item => item);
    const tempFieldLogicArrayForChoices = fieldLogicChoicesArray
      .map(item => item)
      .reverse();

    tempFieldLogicArray.reverse().forEach(el => {
      const replace = `[${el.name}]`;
      let nameTemp = currentQuestionWithFieldLogic.name
        .toString()
        .split(replace)
        .join(el.value);

      let descriptionTemp = currentQuestionWithFieldLogic.description
        .toString()
        .split(replace)
        .join(el.value);

      currentQuestionWithFieldLogic.name = nameTemp;
      currentQuestionWithFieldLogic.description = descriptionTemp;
    });

    // //Replace empty fieldlogicvalues for name
    this.state.fieldLogicArrayFull.forEach(value => {
      currentQuestionWithFieldLogic.name = currentQuestionWithFieldLogic.name
        .split(`[${value}]`)
        .join("");
      currentQuestionWithFieldLogic.description = currentQuestionWithFieldLogic.description
        .split(`[${value}]`)
        .join("");
    });

    //Field Logic for Option Choices
    const option_choices = currentQuestionWithFieldLogic.option_choices.map(
      opt => ({ ...opt }),
    );
    option_choices.forEach(opt => {
      tempFieldLogicArrayForChoices.forEach(el => {
        const replace = `[${el.name}]`;
        const nameTemp = opt.name
          .toString()
          .split(replace)
          .join(el.value);
        opt.name = nameTemp;
      });

      this.state.fieldLogicArrayFull.forEach(value => {
        opt.name = opt.name.split(`[${value}]`).join("");
      });
    });
    currentQuestionWithFieldLogic.option_choices = option_choices;
    return currentQuestionWithFieldLogic;
  };

  addAndReplaceFieldLogicBucket = (
    fieldLogicChoice,
    fieldLogicChoicesArray,
    answerQuestionRadioChoice,
    useFieldLogic,
    radioQuestion,
  ) => {
    if (useFieldLogic === true && fieldLogicChoice !== null) {
      Object.keys(fieldLogicChoice).forEach(item => {
        fieldLogicChoicesArray.push({
          question: radioQuestion,
          choice: answerQuestionRadioChoice,
          name: item,
          value: fieldLogicChoice[item],
        });
      });
    }
  };

  calculaterWinnerOutcome = () => {
    let winnerOutcome = "";
    let sumTotal;
    const { outcomes, outcomeType } = this.state;
    if (outcomeType === "mapped") {
      if (this.outcomeArrayWinner.length > 0) {
        const outcomeArrayWinnerTemp = _.orderBy(
          this.outcomeArrayWinner,
          ["position"],
          ["asc"],
        );
        let summed = _(outcomeArrayWinnerTemp)
          .groupBy("outcomeId")
          .map((objs, key) => {
            //console.log(objs, key);
            return {
              outcomeId: key,
              position: objs[0].position,
              outcome: objs[0].outcome,
              sum: _.sumBy(objs, "sum"),
            };
          })
          .value();
        const winnerOutcomeTemp = _.orderBy(
          ...[summed],
          ["sum", "position"],
          ["desc", "asc"],
        );
        winnerOutcome = winnerOutcomeTemp[0].outcome;
      } else {
        const winnerOutcomeTemp2 = _.orderBy(outcomes, ["position"], ["asc"]);
        winnerOutcome = winnerOutcomeTemp2[0];
      }
    } else {
      sumTotal = _.sumBy(this.outcomeArrayWinner, "sum");
      const outcomeArrayWinnerTemp = _.filter(outcomes, el => {
        if (el.score_min !== null && el.score_max !== null) {
          return sumTotal >= el.score_min && sumTotal <= el.score_max;
        }
      });
      if (outcomeArrayWinnerTemp.length > 0) {
        winnerOutcome = outcomeArrayWinnerTemp[0];
      } else {
        const winnerOutcomeTemp2 = _.orderBy(outcomes, ["position"], ["asc"]);
        winnerOutcome = winnerOutcomeTemp2[0];
      }
    }
    return { winnerOutcome, sumTotal };
  };

  submitLeadCapture = async (
    lead,
    inputs,
    webinarSelected,
    checkGDPR,
    textGDPR,
  ) => {
    if (window.navigator.onLine) {
      const {
        modelsDiagramLinks,
        allPages,
        isPreview,
        leadCaptures,
        welcomePage,
      } = this.state;
      document
        .querySelectorAll(
          ".mainLeadCapture .countdown-bucket-cont, .mainLeadCapture .countdown-cont, .payment-form .countdown-bucket-cont, .payment-form .countdown-cont",
        )
        .forEach(el => {
          let id = el.getAttribute("interval");
          clearInterval(id);
        });
      const targetNodeFinish = _.find(modelsDiagramLinks, el => {
        return el.source === lead.internal_id;
      });

      if (typeof targetNodeFinish !== "undefined") {
        const nextPage = _.find(allPages, el => {
          return el.internal_id === targetNodeFinish.target;
        });

        this.createCustomVariablesFromWinnerLC(inputs);

        //Send Outcome Event
        this.executeOutcomeTrackerEvents();

        !isPreview &&
          (await this.createAnswerLead(
            lead,
            inputs,
            webinarSelected,
            checkGDPR,
            textGDPR,
          ));
        //Testing loading

        const info = {
          lead: lead,
          inputs: inputs,
          targetNodeFinish,
          winnerOutcome: this.winnerOutcomeGlobal,
          from: "leadCapture",
        };

        this.setStatusLoadingPayment(false);

        //If nextPage is not defined, it's because it's connected to LeadCapture or PaymentForm
        if (nextPage) {
          this.showNextPage(nextPage, info, this.state.quizScore);
        } else {
          const winnerLeadCapture = _.find(leadCaptures, el => {
            return el.internal_id === targetNodeFinish.target;
          });
          if (winnerLeadCapture) {
            this.showLeadCaptureContainer(winnerLeadCapture, welcomePage);
          }
        }
      }
    } else {
      this.setState({
        internetConnectionStatus: internetConnectionStatus.OFFLINE,
      });
      if (this.setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  createCustomVariablesFromWinnerLC = inputs => {
    let answers_lc = [];
    Object.keys(inputs).forEach(el => {
      if (inputs[el].getAttribute("datanewid")) {
        const newInput = {
          internal_id: inputs[el].getAttribute("datanewid"),
          title: inputs[el].name,
          name: inputs[el].getAttribute("typecustom"),
          value: inputs[el].value,
        };
        answers_lc.push(newInput);
      }
    });
    const leadCaptureFieldsVariables = [];
    answers_lc.forEach(aw => {
      const key = `${aw.title}`.replace(/\s|-/g, "_").toUpperCase();
      leadCaptureFieldsVariables.push({
        key,
        value: aw.value,
        internal_id: aw.internal_id,
      });
    });
    this.setState({ leadCaptureFieldsVariables, inputs });
  };

  setCardElement = element => {
    this.cardElement = element;
    const stripeContainer = document.querySelector(
      ".card-elements.StripeElement",
    );
    if (stripeContainer) {
      stripeContainer.style.display = "block";
    }
  };

  getCustomerInfo(formInputs) {
    let answers_lc = {};
    Object.keys(formInputs).forEach(el => {
      if (formInputs[el].getAttribute("datanewid")) {
        if (
          ["first-name", "last-name", "email", "phone"].includes(
            formInputs[el].getAttribute("typecustom"),
          )
        )
          answers_lc = {
            ...answers_lc,
            [formInputs[el].getAttribute("typecustom")]: formInputs[el].value,
          };
        else {
          const value =
            formInputs[el].tagName === "SELECT"
              ? formInputs[el].options[formInputs[el].selectedIndex].text
              : formInputs[el].value;
          answers_lc = {
            ...answers_lc,
            [formInputs[el].getAttribute("name")]: value,
          };
        }
      }
    });
    return answers_lc;
  }

  createPaypalPayment = (
    productId,
    pageId,
    customerInfo,
    paymentGateway,
    ba_token,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        customerInfo = this.setLocationData(customerInfo);
        const { data } = await services.createPaymentInPaypal(
          this.takerToken,
          productId,
          pageId,
          customerInfo,
          paymentGateway,
          this.state.paymentMode,
          this.locationData,
          ba_token,
          this.paypalClientMetadataId,
        );
        this.paymenGatewayLeadCapture = paymentGateway;
        if (!this.state.currentProduct.tax_in_total)
          this.taxesTotal += data.tax_value / 100;
        this.sendPaymentTransactionJson(data, "success", customerInfo);
        resolve(data);
      } catch (e) {
        this.sendPaymentTransactionJson(e.response.data, "error", customerInfo);
        reject(e.response.data.errors);
      }
    }, 2000);
  };

  handleSubmitPaymentForm = async (
    formInputs,
    webinarSelected,
    checkGDPR,
    textGDPR,
    locationData,
  ) => {
    this.setStatusLoadingPayment(true);
    this.locationData = locationData;
    const { winnerLeadCapture: leadCapture, isPreview } = this.state;
    const customerInfo = this.getCustomerInfo(formInputs);
    switch (leadCapture.payment_gateway) {
      case "stripe":
        try {
          !isPreview &&
            (await this.createPaymentIntent(
              this.state.currentProduct.id,
              this.sourcePagePayment.id,
              customerInfo,
              leadCapture.payment_gateway,
            ));
          !isPreview && (await this.confirmCardPayment()); //With cardElement
          this.updateProductStateAndSendLeadCapture(
            formInputs,
            webinarSelected,
            leadCapture,
            checkGDPR,
            textGDPR,
          );
        } catch (e) {
          this.setStatusLoadingPayment(false);
          if (!this.stripeClient) {
            // alert("This funnel has not been integrated with Stripe")
            toastError({
              message: "This funnel has not been integrated with Stripe",
            });
            return;
          }
          if (e.message) {
            if (e.message === "out of stock")
              toastError({
                message: "Product is out of stock",
              });
            else
              toastError({
                message: e.message,
              });
          }
        }
        break;
      case "paypal":
        try {
          try {
            !isPreview &&
              (await this.createPaypalPayment(
                this.state.currentProduct.id,
                this.sourcePagePayment.id,
                customerInfo,
                leadCapture.payment_gateway,
                this.billing_agreement_token,
              ));
            this.updateProductStateAndSendLeadCapture(
              formInputs,
              webinarSelected,
              leadCapture,
              checkGDPR,
              textGDPR,
            );
          } catch (e) {
            console.log(e);
            this.setStatusLoadingPayment(false);
            if (e.message === "Business error") {
              toastError({
                message: "Transaction has been canceled by the user.",
              });
              return;
            }
            toastError({
              message: "An error has occurred. Try again.",
            });
          }
        } catch (e) {
          console.log(e);
        }
        break;
      case "authorize_net":
        try {
          if (!isPreview) {
            const data = await this.createAuthorizeOpaqueData();
            await this.confirmAuthorizePayment(
              leadCapture,
              customerInfo,
              data.opaqueData,
            ); //With cardElement
          }
          this.updateProductStateAndSendLeadCapture(
            formInputs,
            webinarSelected,
            leadCapture,
            checkGDPR,
            textGDPR,
          );
        } catch (e) {
          this.setStatusLoadingPayment(false);
          if (e.status && e.status === 428) {
            toastError({
              message: "Transaction pending approval.",
            });
            const button = document.querySelector(".button.buttonPaymentForm");
            if (button) {
              button.disabled = true;
              button.classList.add("disabled");
            }
            let p = document.createElement("p");
            p.setHTML(
              `Transaction pending approval, please contact the supplier at <strong>${e.data.merchant_email}</strong> and ask them to complete this purchase.`,
            );
            p.style.cssText =
              "font-size: 13px;font-weight: 600;line-height: 18px;color: red!important;margin:0;text-align:justify;";
            document.querySelector(".authorize_net-card-fields").appendChild(p);
            return;
          }

          if (e.text) {
            toastError({
              message: e.text,
            });
            return;
          }
          if (e.data && e.data.errors && e.data.errors[0]) {
            toastError({
              message: e.data.errors[0],
            });
          }
        }
        break;
      default:
        console.log("Payment Gateway not defined");
    }
  };

  sendPaymentTransactionJson = (data, status, customerInfo) => {
    const paymentTransactionData = {
      customer: customerInfo,
      location: this.locationData,
      status: status,
      response: data,
    };
    this.paymentTransactionLogs.push(paymentTransactionData);
    const takerInfo = {
      taker: {
        payment_json: this.paymentTransactionLogs,
      },
    };
    services.updateTaker(this.takerToken, takerInfo);
  };

  confirmCardPayment = customerInfo => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.stripeClient.confirmCardPayment(
          this.client_secret,
          {
            payment_method: {
              card: this.cardElement,
              billing_details: {
                name: "test",
              },
            },
          },
        );

        if (data.error) {
          this.sendPaymentTransactionJson(data, "error", customerInfo);
          toastError({
            message: data.error.message,
          });
          reject(data.error);
        }
        if (data.paymentIntent) {
          this.sendPaymentTransactionJson(data, "success", customerInfo);
          this.stripeCardResponse = data.paymentIntent;
          resolve(data);
        }
      } catch (error) {
        this.sendPaymentTransactionJson(
          error.response.data,
          "error",
          customerInfo,
        );
        reject(error);
      }
    });
  };

  updateProductStateAndSendLeadCapture = (
    formInputs,
    webinarSelected,
    leadCapture,
    checkGDPR,
    textGDPR,
  ) => {
    this.setState(
      prev => ({
        purchasedProducts: [...prev.purchasedProducts, prev.currentProduct],
        leadCaptureInputs: formInputs,
      }),
      () => {
        this.submitLeadCapture(
          leadCapture,
          formInputs,
          webinarSelected,
          checkGDPR,
          textGDPR,
        );
      },
    );
  };

  setStatusLoadingPayment(status) {
    $("html").animate({ scrollTop: "0px" }, 150, () => {
      this.setState({
        showStripeLoadingProgress: status,
      });
      const display = status ? "hidden" : "auto";
      this.setStatusScroll(display);
    });
  }

  addListenerToCalendlyButtons = () => {
    window.setTimeout(() => {
      //Listener for CalendlyButton click
      this.setEventListener(".calendly-button", "click", evt => {
        const btn = evt.target.closest(".calendly-button");
        const event = btn.getAttribute("scheduling_url");
        if (!event) return;
        const params = [];
        if (btn.getAttribute("hide_gdpr_banner") !== null)
          params.push("hide_gdpr_banner=1");
        if (btn.getAttribute("hide_landing_page_details") !== null)
          params.push("hide_landing_page_details=1");
        if (btn.getAttribute("hide_event_type_details") !== null)
          params.push("hide_event_type_details=1");
        if (window.Calendly) {
          const calendlyFullLink = `${event}?${params.join("&")}`;
          window.Calendly.showPopupWidget(calendlyFullLink);
        }
      });
    }, 500);
  };

  render() {
    const {
      current,
      isPreview,
      contentPageHtml,
      internetConnectionStatus: statusInternet,
      design: designOptions,
      nodesPath,
      currentQuestion,
      settings,
      metaImage,
      postQuizPageInfo,
      postQuizHtml,
      showStripeLoadingProgress,
      showBannerEmptyPaymentGateway,
      showBannerTestMode,
    } = this.state;
    return (
      <Fragment>
        {current === applicationContainerStatus.FETCHING_DATA && (
          <LoadingScreen />
        )}
        {current === applicationContainerStatus.EMPTY_CANVAS && (
          <PlaceholderBuilder type="emptyCanvas" />
        )}
        {current === applicationContainerStatus.WELCOME_PAGE_EMPTY && (
          <PlaceholderBuilder type="Welcome" />
        )}

        {/* MetaInformation */}
        {settings && (
          <MetaInformation
            settings={settings}
            metaImage={metaImage}
            env={process.env.REACT_APP_ENVIRONMENT_NAME_FOR_TITLE}
          />
        )}

        {/* Banners for Headers  */}
        {isPreview && statusInternet === internetConnectionStatus.ONLINE && (
          <PreviewMessage>
            <b>Note:</b> This is the bucket.io preview mode. To start collecting
            data, publish this Funnel.
          </PreviewMessage>
        )}

        {!isPreview &&
          statusInternet === internetConnectionStatus.ONLINE &&
          showBannerEmptyPaymentGateway && (
            <StripeBanner>
              <b>Note:</b> This funnel has not been integrated with any Payment
              Gateway.
            </StripeBanner>
          )}
        {!isPreview &&
          statusInternet === internetConnectionStatus.ONLINE &&
          showBannerTestMode && (
            <TestModeBanner>
              <b>Note:</b> This Funnel is currently in <b>Test Mode</b>, switch
              to <b>Live Mode</b> to accept real payments.
            </TestModeBanner>
          )}

        {statusInternet === internetConnectionStatus.OFFLINE && (
          <InternetMessage>
            Your Internet connection seems to be unstable - please verify your
            connection and submit your answer again.
          </InternetMessage>
        )}

        {/* //Pages */}
        {(current === applicationContainerStatus.SHOWING_WELCOME_PAGE ||
          current ===
            applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL) && (
          <WelcomePage
            isPreview={isPreview}
            showBannerEmptyPaymentGateway={
              showBannerEmptyPaymentGateway || showBannerTestMode
            }
            contentPage={contentPageHtml}
          />
        )}

        {/* {Dialogs} */}
        <StandartModal2
          showDialog={
            current ===
            applicationContainerStatus.SHOWING_WELCOME_PAGE_WITH_MODAL
          }
          designOptions={designOptions}
          isPreview={isPreview}
          nodesPath={nodesPath}
          closeModal={this.closeModal}
          currentQuestion={currentQuestion}
          changeOtherValue={this.changeOtherValue}
          nextRadio={this.nextRadio}
          nextCheck={this.nextCheck}
          changeOtherValueCheck={this.changeOtherValueCheck}
          changeShortLong={this.changeShortLong}
          backQuestion={this.backQuestion}
          questionsPassed={this.state.questionsPassed}
          nextOther={this.state.nextOther}
          nextDisabled={this.state.nextDisabled}
          nextOtherAlone={this.state.nextOtherAlone}
          nextCheckOther={this.state.nextCheckOther}
          nextDisabledUrlShort={this.state.nextDisabledUrlShort}
          nextQuestion={this.nextQuestion}
          nextQuestionDisabled={this.nextQuestionDisabled}
          nextQuestionDisabledCheck={this.nextQuestionDisabledCheck}
          modalStatus={this.state.modalContainerStatus}
          leadCapture={this.state.winnerLeadCapture}
          quizScore={this.state.quizScore}
          winnerOutcome={this.state.winnerOutcome}
          fieldLogicChoicesArray={this.state.fieldLogicChoicesArray}
          fieldLogicArrayFull={this.state.fieldLogicArrayFull}
          submitLeadCapture={this.submitLeadCapture}
        />

        {current === applicationContainerStatus.SHOWING_POSTQUIZ_PAGE && (
          <PostQuizPage
            isPreview={isPreview}
            showBannerEmptyPaymentGateway={
              showBannerEmptyPaymentGateway || showBannerTestMode
            }
            page={postQuizPageInfo}
            postQuizHtml={postQuizHtml}
          />
        )}
        {current ===
          applicationContainerStatus.SHOWING_DEFAULT_WEBINAR_THANKYOU_PAGE && (
          <WebinarThank>
            <img src={imgCheck} alt={imgCheck} />
            <p className="title">All Done!</p>
            <p className="text">Your answers were sent successfully.</p>
          </WebinarThank>
        )}

        <PaymentForm
          visible={
            current === applicationContainerStatus.SHOWING_PAYMENTFORM_PAGE
          }
          isPreview={isPreview}
          showBannerEmptyPaymentGateway={
            showBannerEmptyPaymentGateway || showBannerTestMode
          }
          leadCapture={this.state.winnerLeadCapture}
          quizScore={this.state.quizScore}
          winnerOutcome={this.state.winnerOutcome}
          fieldLogicChoicesArray={this.state.fieldLogicChoicesArray}
          fieldLogicArrayFull={this.state.fieldLogicArrayFull}
          setCardElement={this.setCardElement}
          stripeElements={this.stripeElements}
          handleSubmitPaymentForm={this.handleSubmitPaymentForm}
          useStripe={this.state.useStripe}
          currentProduct={this.state.currentProduct}
          taxesProductConfig={this.state.taxesProductConfig}
          takerToken={this.takerToken}
          userCurrencySetting={this.userCurrencySetting}
          urlPaypalApprovalBA={this.state.urlPaypalApprovalBA}
          setStatusLoadingPayment={this.setStatusLoadingPayment}
        />

        {current ===
          applicationContainerStatus.SHOWING_EMPTY_PAYMENTFORM_PAGE && (
          <PlaceholderBuilder type="PaymentForm" />
        )}

        {showStripeLoadingProgress && <StripeLoading />}
      </Fragment>
    );
  }
}

ApplicationContainer2.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

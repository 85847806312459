import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";

const blowUpContent = keyframes`
  0% {
    transform:scale(0);
    opacity:0;
  }
  79.9% {
    transform:scale(1);
    opacity:1;
  }
  100% {
    transform:scale(1);
  }
`;

const modalAni = keyframes`
  0%{ opacity: 0;  }
  25%{ opacity: 0.25;  }
  50%{ opacity: 0.50;  }
  75%{ opacity: 0.75;  }
  100{ opacity: 1;  }
`;

export const Dialog = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: ${props => (props.open === true ? "block" : "none")};
  opacity: ${props => (props.open === true ? 1 : 0)};
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;

  .mainQuestion {
    top: ${props => (props.isNewPreview ? 80 : 0)}px;
    max-width: ${props =>
      props.newDesignOpt["width"]
      ? props.newDesignOpt["width"] + props.newDesignOpt["selectWidth"]
      : "705px"};
    min-height: 325px;
    max-height: 535px;
    overflow-y: auto;
    position: relative;
    margin: 10% auto;
    padding: 30px 50px 20px 50px;
    border-radius: 10px;
    background-color: rgba(
      ${props =>
        typeof props.newDesignOpt.bgBox !== "undefined"
          ? props.newDesignOpt.bgBox.r
      : "250"},
      ${props =>
    typeof props.newDesignOpt.bgBox !== "undefined"
          ? props.newDesignOpt.bgBox.g
          : "250"},
      ${props =>
        typeof props.newDesignOpt.bgBox !== "undefined"
          ? props.newDesignOpt.bgBox.b
      : "250"},
      ${props =>
        typeof props.newDesignOpt.bgBox !== "undefined"
          ? props.newDesignOpt.bgBox.a
      : "1"}
    );

    box-shadow: ${props => props.newDesignOpt.shadow_x}px
        ${props => props.newDesignOpt.shadow_y}px 5px
        rgba(
          ${props =>
            typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.r
      : "0"},
          ${props =>
            typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.g
              : "0"},
          ${props =>
    typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.b
      : "0"},
          ${props =>
            typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.a
              : "0"}
        ),
      ${props => props.newDesignOpt.shadow_x}px
        ${props => props.newDesignOpt.shadow_y}px 25px
        rgba(
          ${props =>
            typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.r
              : "0"},
          ${props =>
            typeof props.newDesignOpt.shadowColor !== "undefined"
      ? props.newDesignOpt.shadowColor.g
              : "0"},
          ${props =>
    typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.b
              : "0"},
          ${props =>
    typeof props.newDesignOpt.shadowColor !== "undefined"
              ? props.newDesignOpt.shadowColor.a
              : "0"}
        );

    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    
    .mainQuestionWrapper{
      &.aniModal{
        animation:${modalAni}  0.25s ease-in forwards;*/
      }
    } 

    &.disabledShadow {
      box-shadow: none;
    }

    .mainTitle {
      margin-bottom: 3px;
    }

    .simpleChoices {
      margin-bottom: 18px;
    }

    .imagesChoices {
      display: inline-block;
      vertical-align: top;
      ${""}

      .MuiFormControlLabel-root{
        align-items: flex-start;

        .MuiRadio-root{
          padding-top: 3px;
        }

        .MuiCheckbox-root{
          padding-top:3px;
        }
      }
      
      .imgShow {
        width: 100%;
        height: auto;
        max-width: 300px;
        cursor: pointer;
      }
      &.rows4{
        margin: 5px;
      }

      &.rows3{
        margin: 6px;
      }

      &.rows2{
        margin: 10px;
      }

      @media (max-width: 450px) {
        width: 47%;
        margin: 0px 3px 15px 3px !important;
      }

      @media (max-width: 330px) {
        width: 100%;
        margin: 0px 0px 15px 0px !important;
      }
    }

    .inputShort {
      margin-top: 20px;
      border: 1px solid #c4c4c4;
      border-radius: 3px;
      height: 35px;
      width: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
      font-family: "Open Sans", sans-serif;
      box-shadow:none;
    }

    .inputLong {
      margin-top: 20px;
      border: 1px solid #c4c4c4;
      border-radius: 3px;
      width: 100%;
      font-family: "Open Sans", sans-serif;
      padding: 10px;
      max-width: 100%;
      min-width: 100%;
      box-sizing: border-box;
      resize: none;
    }

    .inputOther {
      position: relative;
      width: 85%;
      border-radius: 4px;
      border: 1px solid #bdbdbd;
      padding: 9px 10px;
      box-sizing: border-box;
      height: auto;
      text-align: left;
      margin-left: 5px;
      margin-top:10px;
      font-family: "Open Sans", sans-serif;
      font-size:12px;
    }

    @media (max-width: 450px) {
      width: 80%;
      padding: 30px 30px 20px 30px;
      margin: 3% auto;
    }

    @media (max-width: 330px) {
      margin: 10% auto;
    }

    input[type=radio] {
      .ease-out;
      -webkit-appearance: none;
      background-color: #ff;
      border: 2px solid #000;
      border-radius: 100%;
      cursor: pointer;
      display: inline-block;
      height: 16px;
      position: relative;
      outline: none;
      width: 16px;
      vertical-align: text-bottom;

      &:after {
        .ease-out;
        background-color: #ff;
        border-radius: 50%;
        content: '';
        height: 16px;
        left: -2px;
        position: absolute;
        pointer-events: none;
        top: -2px;
        .transform(scale(0));
        .transform-origin(50% 50%);
        overflow: hidden;
        width: 16px;
      }

      &:checked:after {
        transition:all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        background-color: #000;
        border-color: #000;
        transform: scale(0.5);
      }
    }

    input[type='checkbox'] {
      -webkit-appearance: none;

      
    }


    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }
    input[type="checkbox"]:not(:checked) + div,
    input[type="checkbox"]:checked + div {
      position: relative;
      padding-left: 1.95em;
      cursor: pointer;
    }

   
    input[type="checkbox"]:not(:checked) + div:before,
    input[type="checkbox"]:checked + div:before {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 0.875em; height: 0.875em;
      border: 2px solid black;
      background: #fff;
      box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    }
   
    input[type="checkbox"]:not(:checked) + div:after,
    input[type="checkbox"]:checked + div:after {
      content: '✓';
      position: absolute;
      top: 2px;
      left: 2px;
      font-size: 1.2em;
      line-height: 0.8;
      color: black;
      transition: all .2s;
      font-family: "Open Sans", sans-serif;
    }
    
    input[type="checkbox"]:not(:checked) + div:after {
      opacity: 0;
      transform: scale(0);
    }
    input[type="checkbox"]:checked + div:after {
      opacity: 1;
      transform: scale(1);
    }
   
    input[type="checkbox"]:disabled:not(:checked) + div:before,
    input[type="checkbox"]:disabled:checked + div:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
    input[type="checkbox"]:disabled:checked + div:after {
      color: #999;
    }
    input[type="checkbox"]:disabled + div {
      color: #aaa;
    }
    
    &.four {
      z-index:0;
      transform:scale(1);
      z-index:1;
      animation:${blowUpContent} .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    .mainImage{
      max-width:100%;
      margin: 0 auto;
      margin-bottom:20px;
      display: block;
    }

    @media (max-width: 830px) and (max-height: 450px) {
      min-height: 270px; 
      max-height: 270px; 
      margin: 1% auto;
      max-width: 90%;
    }

    @media (max-width: 830px) and (max-height: 325px) {
      max-height: ${props => (props.isNewPreview ? 210 : 250)}px;
      min-height: ${props => (props.isNewPreview ? 210 : 250)}px;
    }

    @media (max-width: 325px){
      max-height: ${props => (props.isNewPreview ? 400 : 450)}px;
    }

    @media (min-width: 1279px) and (max-height: 750px) {
      margin: 1% auto;
    }

  }

  .mainLeadCapture {
    top: ${props => (props.isNewPreview ? 80 : 0)}px;
    position: relative;
    max-width: 770px;
    height: 497px;
    margin: 10% auto;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 1 ), 0px 0px 25px rgba( 0, 0, 0, 1 );
    border-radius: 10px;

    #newBody {
      display: inline-block;
      width: 100%;
      min-height: 497px;
      
    }

    .invalid {
      border-bottom: 2px solid red !important;
    }

    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 450px) {
      width: 80%;
    }

    @media (max-width: 330px) {
      height: 350px;
      top: ${props => (props.isNewPreview ? 103 : 0)}px;
    }

    @media (max-width: 1280px) and (max-height:750px) {
      margin: 1% auto;
    }

    @media (min-width: 1281px) and (max-height:750px) {
      margin: 1% auto;
      top: 70px;
    }

    @media (max-width: 830px) and (max-height: 450px) {
      min-height: 270px; 
      max-height: 270px; 
      margin: 1% auto;
      max-width: 90%;
    }

    @media (max-width: 830px) and (max-height: 330px) {
      min-height: 220px; 
      max-height: 220px; 
    }
  }

  .close {
    position: absolute;
    right: -39px;
    text-align: center;
    top: -28px;
    width: 25px;
    padding: 3px;
    height: 26px;
    cursor:pointer;

    @media (max-width: 450px) {
      right: -28px;
    }
}
  }
  .close:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius:100px;
  }

  footer {
    height: 50px;
    width: 100%;
    position: relative;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0px;
    left: 0;
    ${
      "" /* position: absolute;
    bottom: 0;
    left: 0;
    padding-left:50px;
    padding-right:50px;
    padding-bottom: 40px; */
    }

    button {
      padding: 10px 15px;
      min-width: 100px;
      color: black;
      border-radius: 4px;
      border: none;
      outline: none;

      @media (max-width: 326px) {
        min-width: inherit;
      }

      &.buttonBack {
        float: left;
        background-color: rgba(
          ${props =>
            typeof props.newDesignOpt.back_button_color !== "undefined"
              ? props.newDesignOpt.back_button_color.r
              : "244"},
          ${props =>
            typeof props.newDesignOpt.back_button_color !== "undefined"
      ? props.newDesignOpt.back_button_color.g
              : "179"},
          ${props =>
    typeof props.newDesignOpt.back_button_color !== "undefined"
              ? props.newDesignOpt.back_button_color.b
      : "1"},
          ${props =>
            typeof props.newDesignOpt.back_button_color !== "undefined"
              ? props.newDesignOpt.back_button_color.a
      : "1"}
        );
        color: ${props => props.newDesignOpt.back_button_text_color};
        cursor: pointer;

        &:hover{
          opacity: 0.9;
        }
      }
      &.buttonNext {
        float: right;
        background-color: rgba(
          ${props =>
            typeof props.newDesignOpt.next_button_color !== "undefined"
              ? props.newDesignOpt.next_button_color.r
      : "244"},
          ${props =>
    typeof props.newDesignOpt.next_button_color !== "undefined"
              ? props.newDesignOpt.next_button_color.g
      : "179"},
          ${props =>
    typeof props.newDesignOpt.next_button_color !== "undefined"
              ? props.newDesignOpt.next_button_color.b
      : "1"},
          ${props =>
    typeof props.newDesignOpt.next_button_color !== "undefined"
              ? props.newDesignOpt.next_button_color.a
      : "1"}
        );
        color: ${props => props.newDesignOpt.next_button_text_color};
        cursor: pointer;

        &.nextDisabled {
          pointer-events: none;
          background: #dddddd;
        }

        &:hover{
          opacity: 0.9;
        }
      }
    }
  }

  .messageMissing{
    margin: 0;
    position: absolute;
    width: 88%;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close{
      top:-67px;
      right: -37px;
    }
    
    h2{
      margin-bottom:20px;
      margin-top:0px;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #585858;
    }

    p{
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #585858;
      margin-top:0;
      margin-bottom:0px;
    }
  } 
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 1px;
  background: #bdbdbd;
  position: relative;
  .mainProgressBar {
    max-width: 100%;
    width: ${props => props.numBar};
    height: 8px;
    background: ${props => props.newDesignOpt.colorProgressBar};
    position: absolute;
    top: -3px;
    border-radius: 5px;
    transition: width 0.35s;
    &:after {
      content: "${props =>
    parseInt(props.numBar) > 100 ? "100%" : props.numBar}";
      position: absolute;
      right: 0px;
      top: -4px;
      width: 34px;
      height: 16px;
      background: ${props => props.newDesignOpt.colorProgressBar};
      border-radius: 5px;
      font-weight: 600;
      font-size: 9px;
      color: ${props => props.newDesignOpt.textColorProgressBar};
      text-align: center;
      line-height: 16px;
    }
    @media (max-width: 450px) {
      &:after {
        right: -15px;
      }
    }
  }
  &.disabledPB{
    visibility: hidden;
  }
`;

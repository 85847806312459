/**@jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import * as _ from "lodash";

//Utils
import {
  checkWebinarWhenOnlyOneExist,
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
  replaceCustomVariables,
  replaceFieldLogicValues,
  replaceWebinarDateToLocalDate,
  toastError,
} from "../../../../utils";

//Styles
import { LCCss } from "../../../../Containers/PagesCss";
import { wrapper } from "./LeadCapture.styled";

export const LeadCapture = ({
  isPreview,
  leadCapture,
  winnerOutcome,
  quizScore,
  fieldLogicArrayFull,
  fieldLogicChoicesArray,
  handleSubmitLeadCapture,
}) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (html) {
      //   setTimeout(() => {
      const form = document.querySelector(".leadCaptureForm");
      if (form) {
        const formInputs = form.getElementsByTagName("input");

        //console.log(formInputs);

        Object.keys(formInputs).forEach(element => {
          //console.log(formInputs[element]);
          formInputs[element].addEventListener("keyup", e => {
            e.target.classList.remove("invalid");
          });
        });
        //console.log(document.querySelector(".buttonLeadCapture"));
        if (document.querySelector(".buttonLeadCapture") !== null) {
          const form = document.querySelector(".leadCaptureForm");
          const buttonForm = document.querySelector(".buttonLeadCapture");
          const checkGDPR = document.querySelector(".checkbox-gdpr");
          const textGDPR = document.querySelector(".paragraph-gdpr");

          //console.log(checkGDPR, textGDPR);
          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();

          form.addEventListener("submit", preventDefault, true);

          buttonForm.addEventListener("click", e => {
            //console.log("entro lead");
            let contSubmitLead = 0;

            const gdprCheck = document.querySelector(
              "label[data-gdpr-mandatory=true]",
            );
            const inputCheckGdpr = document.querySelector(".checkbox-gdpr");
            if (gdprCheck && inputCheckGdpr && !inputCheckGdpr.checked) {
              const warningMessageGDPR = gdprCheck.getAttribute(
                "data-gdpr-mandatory-text",
              );
              toastError({
                message: warningMessageGDPR
                  ? warningMessageGDPR
                  : "Privacy Policy checkbox is required to submit the form",
              });
              contSubmitLead = contSubmitLead + 1;
            }

            const webinars = Array.from(
              document.querySelectorAll(
                "#newBody .webinars-list .webinar-list__item",
              ),
            );

            const webinarSelected = webinars.filter(webinar => {
              const inputRadio = webinar.querySelector("input[type= 'radio']");
              return inputRadio.checked;
            });

            const inputEmail = _.filter(formInputs, function(o) {
              return o.type === "email";
            });

            const inputWithoutEmail = _.filter(formInputs, function(o) {
              return o.type !== "email";
            });

            if (!webinarSelected.length && webinars.length) {
              toastError({ message: "You must select a webinar" });
              contSubmitLead = contSubmitLead + 1;
            }

            inputEmail.forEach(el => {
              //console.log(isValidEmail(el.value, el.value.trim()));
              if (
                (el.value.trim() === "" && el.required === true) ||
                (el.value.trim() !== "" && el.required === false)
              ) {
                //console.log("entro 1");
                if (isValidEmail(el.value) === false) {
                  //console.log("entro 2");
                  el.classList.add("invalid");
                  contSubmitLead = contSubmitLead + 1;
                } else {
                  //console.log("entro 4");
                  el.classList.remove("invalid");
                }
              } else {
                if (el.value.trim() === "" && el.required === false) {
                  //console.log("entro 3");
                  el.classList.remove("invalid");
                }
                if (el.value.trim() !== "" && el.required === true) {
                  if (isValidEmail(el.value) === false) {
                    //console.log("entro 5");
                    el.classList.add("invalid");
                    contSubmitLead = contSubmitLead + 1;
                  } else {
                    //console.log("entro 6");
                    el.classList.remove("invalid");
                  }
                }
              }
            });

            inputWithoutEmail.forEach(el => {
              if (el.required === true && el.value === "") {
                el.classList.add("invalid");
                contSubmitLead = contSubmitLead + 1;
              } else {
                el.classList.remove("invalid");
              }
            });

            if (contSubmitLead === 0) {
              handleSubmitLeadCapture(
                leadCapture,
                formInputs,
                webinarSelected,
                checkGDPR,
                textGDPR,
              );

              form.removeEventListener("submit", preventDefault, true);
            }
          });
        }
      }
      //   }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  useEffect(() => {
    if (leadCapture) {
      let newCss = replaceBackgroundImagesByBackgrounds(
        leadCapture.pages[0].fields.css,
      );
      newCss = _.replace(newCss, new RegExp("body{", "g"), "#newBody{");
      let contentPageLC = replaceCustomVariables(
        leadCapture.pages[0].fields.html,
        winnerOutcome,
        quizScore,
        "lc",
        [],
      );
      // Replace Field Logic Values
      contentPageLC = replaceFieldLogicValues(
        fieldLogicChoicesArray,
        contentPageLC,
        fieldLogicArrayFull,
      );

      contentPageLC = renderIframeUrl(contentPageLC);
      setHtml(`${contentPageLC}<style>${newCss}${LCCss}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadCapture]);

  function preventDefault(e) {
    e.preventDefault();
  }

  function isValidEmail(mail) {
    // eslint-disable-next-line no-useless-escape
    return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(mail);
  }

  return (
    <div css={wrapper(isPreview)}>
      <div
        id="newBody"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import ImagePagePlaceholderWelcome from "../../assets/imagePagePlaceholderWelcome.svg";
import ImagePagePlaceholderLead from "../../assets/imagePagePlaceholderLeadCapture.svg";
import ImagePagePlaceholderPaymentForm from "../../assets/imagePagePlaceholderPaymentForm.png";
import ImagePagePlaceholderPostQuiz from "../../assets/imagePagePlaceholderPostQuiz.svg";
import ImagePagePlaceholderStandarPage from "../../assets/imagePagePlaceholderStandarPage.svg";
import Grid from "@material-ui/core/Grid";

const ContainerPlaceholder = styled.div`
  @media (min-width: 1000px) {
    max-width: 1000px;
    height: 540px;
    margin: 0 auto;
  }

  .MuiGrid-item {
    &.grid1 {
      padding: 66px 30px 66px 150px;
      @media (max-width: 768px) {
        padding: 10px;
        img {
          margin: 0 auto;
          display: block;
        }
      }
      @media (min-width: 1024px) {
        height: 546px;
      }
    }

    &.grid2 {
      padding: 66px 150px 66px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 768px) {
        padding: 10px;
        align-items: center;
      }
    }

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #585858;
      margin-top: 0;
      margin-bottom: 0px;
    }

    .text {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #585858;
      margin-bottom: 0px;
    }
  }
`;

const ContainerPlaceholderLead = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100vh;
  @media (min-width: 1000px) {
    max-width: 1000px;
    /* height: 500px;
    margin: 0 auto; */
  }

  .MuiGrid-container {
    height: 500px;
  }

  .MuiGrid-item {
    &.grid1 {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 768px) {
        padding: 10px;
        img {
          margin: 0 auto;
          display: block;
        }
      }
      @media (min-width: 1024px) {
        height: 500px;
        width: 350px;
      }
    }

    &.grid2 {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 768px) {
        padding: 10px;
        align-items: center;
      }
    }

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #585858;
      margin-top: 0;
      margin-bottom: 0px;
    }

    .text {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #585858;
      margin-bottom: 0px;
    }
  }
`;
const PlaceholderBuilder = ({ type, isPreview = false }) => {
  return (
    <div
      css={css`
        padding-top: ${isPreview ? 60 : 0}px;
      `}
    >
      {type === "Welcome" && (
        <ContainerPlaceholder>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img src={ImagePagePlaceholderWelcome} alt="ImagePHWelcome" />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Landing Page is Empty!</p>
              <p className="text">
                It seems you haven’t added anything to your Funnel’s Landing
                Page yet.
              </p>

              <p className="text">
                To add content to your Landing Page, click Edit on your Pre-Quiz
                Page in the Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholder>
      )}

      {type === "Lead" && (
        <ContainerPlaceholderLead>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img src={ImagePagePlaceholderLead} alt="ImagephLead" />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Lead Capture Page is Empty!</p>
              <p className="text">
                It seems you haven’t added anything to your Funnel’s Lead
                Capture Page yet.
              </p>

              <p className="text">
                To add content to your Lead Capture Page, click Edit on your
                Lead Capture Page in the Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholderLead>
      )}
      {type === "PaymentForm" && (
        <ContainerPlaceholderLead>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img src={ImagePagePlaceholderPaymentForm} alt="ImagephLead" />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Payment Form Page is Empty!</p>
              <p className="text">
                It seems you haven’t added anything to your Funnel’s Payment
                Form Page yet.
              </p>

              <p className="text">
                To add content to your Payment Form Page, click Edit on your
                Payment Form Page in the Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholderLead>
      )}

      {type === "PostQuiz" && (
        <ContainerPlaceholder>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img src={ImagePagePlaceholderPostQuiz} alt="ImagePhPostQuiz" />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Post-Quiz Page is Empty!</p>
              <p className="text">
                It seems you haven’t added anything to your Funnel’s Post-Quiz
                Page yet.
              </p>

              <p className="text">
                To add content to your Post-Quiz Page, click Edit on your
                Post-Quiz Page in the Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholder>
      )}
      {type === "emptyCanvas" && (
        <ContainerPlaceholder>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img src={ImagePagePlaceholderWelcome} alt="ImagePHwelcome2" />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Canvas is Empty!</p>
              <p className="text">
                It seems you haven’t added any content to the canvas yet. To fix
                it, drag a Pre-Quiz Page into the Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholder>
      )}
      {type === "StandardPage" && (
        <ContainerPlaceholder>
          <Grid container>
            <Grid item xs={12} md={6} className="grid1">
              <img
                src={ImagePagePlaceholderStandarPage}
                alt="ImagePhPostQuiz"
              />
            </Grid>
            <Grid item xs={12} md={6} className="grid2">
              <p className="title">Your Page is Empty!</p>
              <p className="text">
                It seems you haven’t added anything to your Funnel’s Page yet.
              </p>

              <p className="text">
                To add content to your Page, click Edit on your Page in the
                Canvas.
              </p>
            </Grid>
          </Grid>
        </ContainerPlaceholder>
      )}
    </div>
  );
};

export default PlaceholderBuilder;

export function renderIframeUrl(content) {
  const html = new DOMParser().parseFromString(content, "text/html");
  const articles = html.body.querySelectorAll("[video_type]");

  articles.forEach(article => {
    const videoType = article.getAttribute("video_type");

    if (videoType === "youtube" || videoType === "vimeo") {
      const videoConfig = JSON.parse(
        article.getAttribute(`${videoType}_config`),
      );
      const { url, timestamp } = videoConfig;

      content = content.replace(`timestamp="${timestamp}"`, `src="${url}"`);
    }
  });

  return content;
}

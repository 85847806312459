/**@jsx jsx */
import { jsx } from "@emotion/core";
// import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import checkCircle from "../../assets/check-circle-tk.svg";
//Images
import lockSecureImage from "../../assets/secure-icon.svg";
import warningCircle from "../../assets/warning-circle-tk.svg";
import MetaInformation from "../../Components/MetaInformation/MetaInformation";
//Utils
import { getCurrencyFormat } from "../../utils";
//Services
import * as services from "../Services";
//Components
import PaymentMessaggeScreen from "../UpdateCreditCardStripe/PaymentMessageScreen";
import {
  CardUpdateScreenDesktop,
  CardUpdateScreenMobile,
} from "../UpdateCreditCardStripe/Placeholder";
import SubmitButton from "../UpdateCreditCardStripe/SubmitButton";
//Constants
import { currentStateAuthorizeScreen } from "./UpdateCreditCardAuthorize.constants";
import countries from "../../assets/resources/countries.json";
//Styles
import * as styles from "./UpdateCreditCardAuthorize.styled";
import {
  cleanInput,
  cvvCheck,
  dateCheck,
  formatCreditCard,
  isValidZipCode,
  validateInputs,
} from "../../utils/cardHelpers";

const initialValuesScreen = {
  relatedProductHeader: "Pending payment related to",
  headline: "Update Payment Method",
  subheadline:
    // eslint-disable-next-line max-len
    "Your new payment method will be applied to your pending payment and future billing dates. To perform the update you must do the following",
  buttonText: "Update Your Payment Method",
  footerBoxTitle: "For more information contact us here:",
};
const UpdateCreditCardAuthorize = () => {
  const [currentState, setCurrentState] = useState(
    currentStateAuthorizeScreen.FETCH_DATA,
  );
  const [countryCode, setCountryCode] = useState("");
  const [validation, setValidation] = useState({});
  const [data, setData] = useState({ invoice_info: {}, article: {} });
  const [billingDetailsData, setBillingDetailsData] = useState({
    name: "",
    cardNumber: "",
    expireDate: "",
    cvvCode: "",
    postalCode: "",
    billingAddress: "",
  });

  const token = useParams().token || null;

  const authorizeNetInfoRef = useRef(null);

  const imgUrl =
    "https://bucket-app-utils.s3.amazonaws.com/email-template/placeholder-invoice.png";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await services.verifyRecoveryToken(
          token,
          "authorize_net",
        );

        const newData = {
          article: {
            image: getImage(response.data.article.image),
            name: response.data.article.name,
            price: getCurrencyFormat(
              response.data.article.currency,
              response.data.price / 100,
            ),
            number_of_installments: response.data.number_of_installments,
            current_installment: response.data.current_installment,
          },
          invoice_info: {
            image: getImage(response.data.provider_information.image),
            email_contact: response.data.provider_information.email,
            phone: response.data.provider_information.phone,
            address: response.data.provider_information.address,
            vat_id: response.data.provider_information.vat_id,
          },
          country_code: response.data.country_code,
          transactional_email_copys: getTransactionalEmailInfo(
            response.data.transactional_email,
          ),
        };
        setData(newData);
        authorizeNetInfoRef.current = {
          authorizeAccessToken: response.data.authorize_net_access_token,
          paymentMode: response.data.paymentMode,
        };
        setCurrentState(currentStateAuthorizeScreen.IDLE);
        setCountryCode(response.data.country_code);
      } catch (err) {
        console.log(err);
        setCurrentState(currentStateAuthorizeScreen.TOKEN_ERROR);
      }
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  const getTransactionalEmailInfo = data => {
    if (!data || (data && !data.credit_card_update_payment_body))
      return initialValuesScreen;

    let dataSaved = {};

    Object.keys(initialValuesScreen).forEach(element => {
      dataSaved[element] = data.credit_card_update_payment_body[
        element
      ].outer.replace("contenteditable", "");
    });
    return dataSaved;
  };

  const getImage = image => {
    if (!image) {
      return imgUrl;
    }
    return image.url;
  };

  const createAuthorizeOpaqueData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const cardData = {
          cardNumber: cleanInput(billingDetailsData["cardNumber"]),
          expirationDate: billingDetailsData["expireDate"],
          cardCode: billingDetailsData["cvvCode"],
        };
        const {
          authorizeAccessToken,
          paymentMode,
        } = authorizeNetInfoRef.current;
        const { data } = await services.createOpaqueData(
          cardData,
          authorizeAccessToken,
          paymentMode,
        );
        if (data.messages.resultCode === "Error") {
          reject(data.messages.message[0]);
        }
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleOnChangeInput = ({ target }, field) => {
    const cloneBillingDetailsData = { ...billingDetailsData };
    cloneBillingDetailsData[field] = target.value;
    setValidation({ ...validation, [field]: false });
    setBillingDetailsData(cloneBillingDetailsData);
  };

  const handleOnChangeCreditCardInput = ({ target }, field) => {
    const cloneBillingDetailsData = { ...billingDetailsData };
    //cloneBillingDetailsData[field] = target.value;
    if (field === "cardNumber")
      cloneBillingDetailsData[field] = formatCreditCard(target.value);
    if (field === "expireDate")
      cloneBillingDetailsData[field] = dateCheck(target.value);
    if (field === "cvvCode")
      cloneBillingDetailsData[field] = cvvCheck(target.value);
    setValidation({ ...validation, [field]: false });
    setBillingDetailsData(cloneBillingDetailsData);
  };

  const onSubmitForm = async e => {
    e.preventDefault();

    //Validate the update payment data
    const checkValidPaymentData = validateInputs(
      billingDetailsData.cardNumber,
      billingDetailsData.expireDate,
      billingDetailsData.cvvCode,
    );

    if (!checkValidPaymentData) {
      setValidation({
        cardNumber: true,
        expireDate: true,
        cvvCode: true,
      });
      return;
    }

    //Validate ZipCode
    const checkValidZipCode = isValidZipCode(
      { value: billingDetailsData.zipCode },
      { value: countryCode },
    );

    if (!checkValidZipCode) {
      setValidation({
        zipCode: true,
      });
      return;
    }

    try {
      setCurrentState(currentStateAuthorizeScreen.SUBMIT_FORM);
      const data = await createAuthorizeOpaqueData();
      const splitName = billingDetailsData.name.split(" ");
      const customerInfo = {
        "first-name": splitName.shift(),
        "last-name": splitName.join(" "),
        "Billing Street Address": billingDetailsData.billingAddress,
        "Zip Postal Code": billingDetailsData.zipCode,
      };
      const transactionData = {
        data_descriptor: data.opaqueData.dataDescriptor,
        data_value: data.opaqueData.dataValue,
      };

      await services.confirmUpdateProfilePaymentAuthorizeNet(
        token,
        customerInfo,
        countryCode,
        transactionData,
      );

      setCurrentState(currentStateAuthorizeScreen.PAYMENT_SUCCEDEED);
    } catch (e) {
      setCurrentState(currentStateAuthorizeScreen.IDLE);
      console.log(e);
    }
  };

  if (currentState === currentStateAuthorizeScreen.PAYMENT_SUCCEDEED)
    return (
      <PaymentMessaggeScreen
        image={checkCircle}
        title="Well done!"
        description="Your payment method has been successfully updated."
      />
    );
  if (currentState === currentStateAuthorizeScreen.TOKEN_ERROR)
    return (
      <PaymentMessaggeScreen
        image={warningCircle}
        title="This option is no longer available"
        description="This may be due to a wrong, expired or used token."
      />
    );

  return (
    <Fragment>
      <div css={styles.wrapperLoading({ currentState })}>
        <CardUpdateScreenDesktop className="loading-desktop" />
        <CardUpdateScreenMobile className="loading-mobile" />{" "}
      </div>
      <div css={styles.container({ currentState })}>
        <MetaInformation
          settings={{
            metaTitle: "Update Payment Method",
            metaDescription:
              "Your new payment method will be applied to your pending payment and upcoming billing dates.",
          }}
          metaImage={{ url: data.invoice_info.image }}
        />
        <Fragment>
          <header className={!data.invoice_info.image ? "lowHeader" : "header"}>
            <div className="wrapper">
              {data.invoice_info.image && (
                <img src={data.invoice_info.image} alt="Invoice Info" />
              )}
            </div>
          </header>
          <main>
            <div className="box">
              <div className="product-section">
                <div className="product-section__image">
                  <img src={data.article.image} alt="ArticleImage" />
                </div>
                <div className="product-section__info">
                  <p
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.transactional_email_copys?.relatedProductHeader,
                    }}
                  />
                  <p className="product-name">{data.article.name}</p>
                  <p className="installment-info">
                    Installment {data.article.current_installment} of{" "}
                    {data.article.number_of_installments} - {data.article.price}
                  </p>
                </div>
              </div>
              <div className="card-info-section">
                <h2
                  className="card-info-section__headline"
                  dangerouslySetInnerHTML={{
                    __html: data?.transactional_email_copys?.headline,
                  }}
                />
                <p
                  className="card-info-section__subheadline"
                  dangerouslySetInnerHTML={{
                    __html: data?.transactional_email_copys?.subheadline,
                  }}
                />
                <form
                  className="card-info-section__form"
                  onSubmit={onSubmitForm}
                >
                  <div className="inputs">
                    <div className="inputs__name-zipcode">
                      <div className="input-container">
                        <label className="label">Cardholder Name</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Customer Name"
                          onChange={e => handleOnChangeInput(e, "name")}
                        />
                      </div>
                    </div>
                    <div className="inputs__name-zipcode">
                      <div className="input-container">
                        <label className="label">Card number</label>
                        <input
                          className={`input ${
                            validation?.cardNumber ? "invalid" : ""
                          }`}
                          type="text"
                          placeholder="1234 1234 1234 1234"
                          required="required"
                          value={billingDetailsData.cardNumber}
                          onChange={e =>
                            handleOnChangeCreditCardInput(e, "cardNumber")
                          }
                        />
                      </div>
                    </div>
                    <div className="inputs__billing-address">
                      <div className="input-container">
                        <label className="label">Expiration</label>
                        <input
                          className={`input ${
                            validation?.expireDate ? "invalid" : ""
                          }`}
                          type="text"
                          value={billingDetailsData.expireDate}
                          placeholder="MM / YY"
                          required="required"
                          onChange={e =>
                            handleOnChangeCreditCardInput(e, "expireDate")
                          }
                        />
                      </div>
                      <div className="input-container">
                        <label className="label">CVC</label>
                        <input
                          className={`input ${
                            validation?.cvvCode ? "invalid" : ""
                          }`}
                          type="text"
                          placeholder="CVC"
                          required="required"
                          value={billingDetailsData.cvvCode}
                          onChange={e =>
                            handleOnChangeCreditCardInput(e, "cvvCode")
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="inputs__name-zipcode"
                      style={{ marginTop: "15px" }}
                    >
                      <div className="input-container">
                        <label className="label">Country</label>
                        <select
                          className="select"
                          value={countryCode}
                          onChange={e => setCountryCode(e.target.value)}
                        >
                          {countries.map(({ name, abbreviation }, index) => {
                            return (
                              <option key={abbreviation} value={abbreviation}>
                                {name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* <div id="payment-element"></div> */}
                    <div className="inputs__billing-address">
                      <div className="input-container">
                        <label className="label">Zip code</label>
                        <input
                          className={`input ${
                            validation?.zipCode ? "invalid" : ""
                          }`}
                          type="text"
                          placeholder="000000"
                          onChange={e => handleOnChangeInput(e, "zipCode")}
                        />
                      </div>
                      <div className="input-container">
                        <label className="label">Billing Address</label>
                        <input
                          className="input"
                          type="text"
                          placeholder="Billing Address"
                          onChange={e =>
                            handleOnChangeInput(e, "billingAddress")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="buttons">
                    <SubmitButton
                      submitting={
                        currentState === currentStateAuthorizeScreen.SUBMIT_FORM
                      }
                      text={
                        data &&
                        data.transactional_email_copys &&
                        data.transactional_email_copys.buttonText
                      }
                    />
                  </div>
                </form>
                <div className="card-info-section__disclaimer">
                  <p
                    className="first-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: data?.transactional_email_copys?.footerBoxTitle,
                    }}
                  />
                  <p className="second-paragraph">
                    {data.invoice_info.email_contact} -{" "}
                    {data.invoice_info.phone} <br />
                    {data.invoice_info.address
                      ? `${data.invoice_info.address}`
                      : ""}{" "}
                    {data.invoice_info.vat_id
                      ? ` - TAX ID ${data.invoice_info.vat_id}`
                      : ""}
                  </p>
                </div>
                <div className="card-info-section__secure_image">
                  <img src={lockSecureImage} alt="secure" />
                  <span>Secure Server</span>
                </div>
              </div>
            </div>
          </main>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default UpdateCreditCardAuthorize;

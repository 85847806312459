import img from "../assets/footer-icon-bucket.svg";

const makeId = length => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

let classesArr = [
  "bucketiofoo_ter",
  "buc_ketteam_colombia",
  "premiumplanhidethis",
  "poweredbybucket",
  "bucketdevantihack",
  "foo_ter_bucket",
  "antihackfooter",
  "bucketfooter",
  `c${makeId(8)}`,
];

export const insertPoweredByBucketFooter = () => {
  const time = new Date().getTime();
  let randomSelection =
    classesArr[Math.ceil(Math.random() * classesArr.length - 1)];
  const spanBefore = Math.ceil(Math.random() * classesArr.length - 1);
  const spanAfter = Math.ceil(Math.random() * classesArr.length - 1);
  document.body.insertAdjacentHTML(
    "beforeend",
    `
    ${getHtmlElementsRandom(spanBefore)}
    <span id="${randomSelection}${time}">
                <a
                  class="text" href="https://bucket.io" target="_blank">
                  Powered By <img src="${img}" alt="logo-footer" />
                  <b>Bucket.io</b>
                </a>
     </span>
     ${getHtmlElementsRandom(spanAfter)}
    <style>
    #${randomSelection}${time}{
      height: 38px;
      background-color: #F2F2F2;
      border-top: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #${randomSelection}${time} a.text{
      display: flex;
      align-items: center;
      gap: 3.75px;
      color: #8a8a8a;
      font-size: 10px;
      text-decoration: none;
    }
    #${randomSelection}${time} a.text img{
      max-height: 30px;
    }
    </style>`,
  );
};

const getHtmlElementsRandom = number => {
  return Array.from(Array(number).keys())
    .map(key => "<span></span>")
    .join("");
};

import { css } from "@emotion/core";

export const isPreview = value => css`
  padding-top: ${value ? 60 : 0}px;
`;

export const loadingStripe = css`
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .message {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #3a00de;
    margin-bottom: 15px;
    text-align: center;
  }

  @keyframes loader {
    0% {
      width: 0;
    }

    20% {
      width: 20%;
    }

    40% {
      width: 40%;
    }

    60% {
      width: 60%;
    }

    80% {
      width: 80%;
    }

    100% {
      width: 100%;
    }
  }

  .progress-bar {
    border-radius: 60px;
    overflow: hidden;
    width: 200px;

    span {
      display: block;
    }
  }

  .bar {
    background: rgba(0, 0, 0, 0.075);
  }

  .progress {
    animation: loader 5s ease infinite;

    background: #3a00de;
    color: #fff;
    padding: 2px;
    width: 0;
  }
`;

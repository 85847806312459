/* eslint no-eval: 0 */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { LoadingScreen, DialogLeadCaptureViewer } from "../Components";
import * as services from "./Services";
import { welcomeCss } from "./PagesCss";
import * as _ from "lodash";
import $ from "jquery";
import React, { Component } from "react";

import PlaceholderBuilder from "../Components/placeholderBuilder/PlaceholderBuilder";
import {
  getScriptTagFromString,
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
} from "../utils";

import { PreviewMessage } from "./ApplicationContainer.styled";

let newDesignOpt = [];
let isPreview = "";
let isNewPreview = "";

class LeadCaptureViewerContainer extends Component {
  state = {
    welcomePageHtml: {},
    showWelcomePage: true,
    showModal: false,
    design: [
      { option: "switchShadow", value: "true" },
      { option: "switchProgressBar", value: "true" },
      { option: "colorProgressBar", value: "#F4b301" },
      { option: "valueProgressBar", value: 50 },
      { option: "switchNextButton", value: "true" },
      { option: "next_button_text_color", value: "#585858" },
      { option: "next_button", value: "Next" },
      { option: "switchBackButton", value: "true" },
      { option: "back_button_text_color", value: "#585858" },
      { option: "back_button", value: "Back" },
    ],
    isLoadingInitialInfo: true,
    showLeadCapture: false,
    winnerLeadCapture: {},
    welcomePageTemp: null,
    showQuestionWithoutLink: false,
  };

  componentDidMount = async () => {
    try {
      let response = null;
      const { verification_token, lead_base64_id } = this.props.match.params;
      isPreview =
        window.location.href.includes("preview.") === true ||
        window.location.href.includes("preview-2.") === true
          ? true
          : false;

      if (isPreview) response = await services.getPreview(verification_token);

      /* Validate Subscription Active */
      if (typeof response.data.subscription_active !== "undefined") {
        if (!response.data.subscription_active) {
          this.props.history.replace("/404");
          return;
        }
      }

      if (isPreview) isNewPreview = true;

      //console.log("response-->", response.data);

      if (response.data.status === "inactive" && !isPreview) {
        window.location.href = "/404";
      }

      //Welcome Page
      const welcomePageTemp = response.data.pages.find(wel => {
        return wel.page_type === "welcome";
      });

      const contentCurrentLeadCapture = response.data.lead_captures.find(lc => {
        return lc.id === Number(atob(lead_base64_id));
      });

      if (typeof welcomePageTemp === "undefined") {
        throw new Error("Welcome Page Empty");
      }

      if (typeof contentCurrentLeadCapture === "undefined") {
        throw new Error("Lead Capture Empty");
      }

      //**************** */

      let contentPageWelcome = `${
        welcomePageTemp.fields.html
      }<style>${replaceBackgroundImagesByBackgrounds(
        welcomePageTemp.fields.css,
      )}${welcomeCss}`;

      // FOR SCRIPTS WISTIA
      let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
      let match;
      let scripsList = [];
      while ((match = re.exec(contentPageWelcome))) {
        if (match[1] === "") {
          const str = match[0];
          const script = getScriptTagFromString(str);

          document.body.appendChild(script);
        }
        // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
        scripsList.push(match[1]);
      }

      // FOR VIMEO/YOUTUBE VIDEOS
      contentPageWelcome = renderIframeUrl(contentPageWelcome);

      this.setState(
        {
          welcomePageHtml: `${contentPageWelcome}`,
          welcomePageTemp: welcomePageTemp,
          isLoadingInitialInfo: false,
          showWelcomePage: true,
          metaImage: response.data.meta_image,
        },
        () => {
          // eval(match[1]);
          try {
            scripsList.forEach(script => eval(script));
            this.scrollToAnchor();
          } catch (e) {
            console.log(e);
          }
        },
      );

      // Anchor Animation
      this.anchorPostWelcome();

      // design
      const designFull =
        response.data.funnel_design_options.length === 0
          ? this.state.design
          : response.data.funnel_design_options;
      const optionsInJSON = [
        "shadowColor",
        "bgBox",
        "next_button_color",
        "back_button_color",
      ];
      designFull.forEach(item => {
        // Object String
        let value = "";
        if (optionsInJSON.indexOf(item.option) !== -1) {
          value = JSON.parse(item.value);
        } else {
          value = item.value;
        }
        //console.log(value);
        newDesignOpt = {
          ...newDesignOpt,
          [item.option]: value,
        };
        // **************
      });

      window.stopRedirect = true;

      if (typeof contentCurrentLeadCapture !== "undefined") {
        let contentPageLCTemp = `${contentCurrentLeadCapture.pages[0].fields.html}`;
        let reLC = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

        let matchLC;
        let scripsListLC = [];
        while ((matchLC = reLC.exec(contentPageLCTemp))) {
          if (matchLC[1] === "") {
            const str = matchLC[0];
            const script = getScriptTagFromString(str);

            document.body.appendChild(script);
          }
          scripsListLC.push(matchLC[1]);
        }
        this.setState(
          {
            showLeadCapture: true,
            showModal: true,
            design: newDesignOpt,
            winnerLeadCapture: contentCurrentLeadCapture,
          },
          () => {
            try {
              scripsListLC.forEach(script => eval(script));
            } catch (e) {
              console.log(e);
            }
          },
        );
      }
    } catch (e) {
      if (e === "Error: Welcome Page Empty") {
        this.setState({ emptyCanvas: true, isLoadingInitialInfo: false });
      }
      if (e.response && Number(e.response.status) === 404) {
        this.props.history.replace("/404");
      }
    }
  };

  scrollToAnchor = () => {
    const anchorUrl = _.split(window.location.href, "#");
    if (anchorUrl[1]) {
      let dest = `#${anchorUrl[1]}`;
      window.setTimeout(function() {
        const offsetTop = document.querySelector(dest)?.offsetTop;

        window.scroll({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  render() {
    return (
      <div>
        {this.state.isLoadingInitialInfo ? (
          <LoadingScreen />
        ) : this.state.emptyCanvas ? (
          <PlaceholderBuilder type="emptyCanvas" />
        ) : !this.state.welcomePageTemp.fields.html ? (
          <PlaceholderBuilder type="Welcome" />
        ) : (
          <React.Fragment>
            {isNewPreview && !this.state.isInternet && (
              <PreviewMessage>
                <b>Note:</b> This is the bucket.io page preview mode, and this
                view does not represent a functional page.
              </PreviewMessage>
            )}
            {this.state.showWelcomePage && (
              <div
                css={css`
                  padding-top: ${isNewPreview ? 60 : 0}px;
                `}
                dangerouslySetInnerHTML={{ __html: this.state.welcomePageHtml }}
              ></div>
            )}
            <DialogLeadCaptureViewer
              showDialog={this.state.showModal}
              design={this.state.design}
              showLeadCapture={this.state.showLeadCapture}
              leadCapture={this.state.winnerLeadCapture}
              isNewPreview={isNewPreview}
            ></DialogLeadCaptureViewer>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default LeadCaptureViewerContainer;

LeadCaptureViewerContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

import { css } from "@emotion/core";
//Constants
import { currentStatePaypalScreen } from "./UpdateCreditCardPaypal.constants";

export const wrapperLoading = ({ currentState }) => css`
 display: ${currentState === currentStatePaypalScreen.IDLE ? "none" : "flex"};
 justify-content: center;
 background: #fff;
 .loading-desktop,
 .loading-mobile {
   align-self: center;
   display: none;
 }
 @media (min-width: 769px) 
 .loading-desktop {
     display: flex;
   }
 }
 @media (max-width: 768px) {
   .loading-mobile {
     display: flex;
   }
 }
`;

export const container = ({ currentState }) => css`
  * {
    margin: 0;
  }
  display: ${currentState === currentStatePaypalScreen.FETCH_DATA
    ? "none"
    : "flex"};
  justify-content: center;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;

  header {
    height: 80px;

    &.lowHeader {
      height: 40px;
    }

    .wrapper {
      max-width: 1280px;
      margin-left: 84px;
      display: flex;
      height: 100%;
      align-items: center;
      img {
        max-height: 80%;
      }

      @media (max-width: 768px) {
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  main {
    background: #e5e5e5;
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    .box {
      max-width: 738px;
      width: 100%;
      background: #fff;
      margin: 45px auto;
      @media (max-width: 768px) {
        max-width: 90%;
        height: auto;
      }
      .product-section {
        display: flex;
        align-items: center;
        padding: 16px 32px;
        border-bottom: 1px solid #e0e0e0;
        @media (max-width: 768px) {
          flex-direction: column-reverse;
          &__info {
            margin-bottom: 16px;
            text-align: center;
          }
        }
        &__image {
          min-width: 100px;
          img {
            max-width: 100px;
            width: 100%;
          }
          margin-right: 16px;
        }
        &__info {
          color: #585858;
          p.message {
            font-size: 14px;
            margin-bottom: 2px;
          }
          p.product-name {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          p.installment-info {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .card-info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px 32px;
        text-align: center;
        &__headline {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          margin-bottom: 8px;
        }
        &__subheadline {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
        }
        &__form {
          width: 85%;
          @media (max-width: 768px) {
            width: 100%;
          }
          .inputs {
            min-height: 140px;
            margin-top: 25px;
            width: 100%;
            &__name-zipcode {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .input-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                width: 100%;
                .label {
                  text-align: start;
                  font-size: 13px;
                  color: #808080;
                  margin-bottom: 3px;
                  font-weight: 400;
                }
                .input {
                  height: 16px;
                  padding: 18.6px 10px;
                  border-radius: 5px;
                  border: 1px solid #dcdcdc;
                  color: #808080;
                  &.invalid {
                    border: 1px solid red;
                  }
                  :focus {
                    outline: hsla(210, 96%, 45%, 25%) solid 3px;
                  }
                  ::placeholder {
                    color: #808080;
                    opacity: 1; /* Firefox */
                  }
                  :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #808080;
                  }
                  ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #808080;
                  }
                }
                .select {
                  height: 39px;
                  padding: 10px 10px;
                  border-radius: 5px;
                  border: 1px solid #dcdcdc;
                  color: #808080;
                  :focus {
                    outline: hsla(210, 96%, 45%, 25%) solid 3px;
                  }
                  ::placeholder {
                    color: #808080;
                    opacity: 1; /* Firefox */
                  }
                  :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #808080;
                  }
                  ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #808080;
                  }
                }
              }
            }
            &__billing-address {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .input-container {
                display: flex;
                flex-direction: column;
                width: 49%;
                .label {
                  text-align: start;
                  font-size: 13px;
                  color: #808080;
                  margin-bottom: 3px;
                  font-weight: 400;
                }
                .input {
                  height: 16px;
                  padding: 18.6px 10px;
                  border-radius: 5px;
                  border: 1px solid #dcdcdc;
                  color: #808080;
                  &.invalid {
                    border: 1px solid red;
                  }
                  :focus {
                    outline: hsla(210, 96%, 45%, 25%) solid 3px;
                  }
                  ::placeholder {
                    color: #808080;
                    opacity: 1; /* Firefox */
                  }
                  :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #808080;
                  }
                  ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #808080;
                  }
                }
              }
            }
          }
          .buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 16px;
          }
        }
        &__disclaimer {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          margin-bottom: 16px;
          color: #585858;
          .first-paragraph {
            margin-bottom: 5px;
          }
          .second-paragraph {
            font-weight: 400;
            font-size: 10px;
          }
        }
        &__secure_image {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
          span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #8a8a8a;
          }
        }
      }
    }
  }
  footer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    .email-link {
      color: #f2994a;
      font-weight: 600;
      margin-left: 7px;
      text-decoration: none;
    }
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import PropTypes from "prop-types";
import { Fragment } from "react";

export const OptionGroupRadio = ({
  currentQuestion,
  handleChange,
  currentRadioValue,
  currentRadioChoice,
  CurrentOtherValue,
  handleChangeOther,
}) => {
  //console.log(currentQuestion, currentRadioChoice);
  return (
    <FormControl
      component="fieldset"
      css={css`
        margin: 0;
        width: 100%;
        border: 1px solid red;
        .MuiTypography-body1 {
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          user-select: none;
          word-break: break-word;
        }
        .MuiFormControlLabel-label {
          width: 100%;
          display: flex;
          min-height: 22px;
        }
        .MuiFormControlLabel-root {
          margin-bottom: 6px;
          align-items: flex-start;
        }
        .MuiIconButton-label {
          input[type="radio"] {
            display: none !important;
          }
        }
        .MuiRadio-root {
          padding: 2px 5px;
          margin-left: 5px;
          margin-right: 7px;
        }
        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.7);
          font-size: 18px;
        }
      `}
    >
      <RadioGroup
        className="options"
        name="options"
        value={currentRadioValue}
        onClick={handleChange}
      >
        {currentQuestion.option_choices.map(item => {
          //console.log(item);
          return (
            <Fragment key={item.internal_id}>
              {!item.other && (
                <FormControlLabel
                  value={item.internal_id}
                  control={<Radio color="default" />}
                  label={
                    <span
                      css={css`
                        width: 100%;
                      `}
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    ></span>
                  }
                />
              )}
            </Fragment>
          );
        })}
        {currentQuestion.option_choices.map(item => {
          //console.log(item);
          return (
            <Fragment key={item.internal_id}>
              {item.other && item.active && (
                <Fragment>
                  <FormControlLabel
                    css={css`
                      margin-bottom: 0 !important;
                    `}
                    value={item.internal_id}
                    control={<Radio color="default" />}
                    label={
                      <span
                        css={css`
                          width: 100%;
                        `}
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></span>
                    }
                  />
                  {currentRadioChoice !== null &&
                    currentRadioChoice.other === true && (
                    <input
                      css={css`
                          margin-top: 10px;
                        `}
                      className="inputOther"
                      type="text"
                      value={CurrentOtherValue}
                      onClick={e => e.stopPropagation()}
                      placeholder={item.placeholder}
                      onChange={e => handleChangeOther(e, currentRadioChoice)}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

OptionGroupRadio.propTypes = {
  currentQuestion: PropTypes.object,
  currentRadioValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  currentRadioChoice: PropTypes.object,
  CurrentOtherValue: PropTypes.string,
  handleChangeOther: PropTypes.func.isRequired,
};

/**@jsx jsx */
import { jsx, css } from "@emotion/core";

const wrapper = css`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  .box-message {
    background: #fff;
    width: 540px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05),
      0px 2px 2px rgba(0, 0, 0, 0.05);
    @media (max-width: 768px) {
      width: 90%;
    }
    .image {
      text-align: center;
      margin-bottom: 15px;
      img {
        width: 52px;
      }
    }
    .title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #585858;
    }
    .description {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #585858;
    }
  }
`;

const PaymentMessaggeScreen = ({ image, title, description }) => {
  return (
    <div css={wrapper}>
      <div className="box-message">
        <div className="image">
          <img src={image} alt={image} />
        </div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default PaymentMessaggeScreen;

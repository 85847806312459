export function generateSessionId() {
  let i;
  let id = "";

  for (i = 0; i < 32; i++) {
    id += Math.floor(Math.random() * 16).toString(16);
  }

  return id;
}

export function generateScriptFraudnet(PAYPAL_CLIENT_METADATA_ID) {
  let el = document.body.appendChild(document.createElement("script"));
  el.type = "application/json";
  el.setAttribute("fncls", "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99");
  el.text = JSON.stringify({
    f: PAYPAL_CLIENT_METADATA_ID,
    s: "BUCKET_Q88TQ4AXZB2XS_PYMNT",
  });
  createThirdPartyBlock();
}

export function createThirdPartyBlock() {
  let dom, doc;
  let scriptBaseURL = "https://www.paypalobjects.com/webstatic/r/fb/";
  let iframe = document.createElement("iframe");

  iframe.src = "about:blank";
  iframe.title = "";
  iframe.role = "presentation"; // a11y
  (iframe.frameElement || iframe).style.cssText =
    "width: 0; height: 0; border: 0; position: absolute; z-index: 50";
  document.body.appendChild(iframe);

  try {
    doc = iframe.contentWindow.document;
  } catch (e) {
    dom = document.domain;
    iframe.src = 'javascript:var d=document.open();d.domain="' + dom + '";void(0);'; // eslint-disable-line
    doc = iframe.contentWindow.document;
  }

  doc.open()._l = function() {
    let js = this.createElement("script");

    if (dom) {
      this.domain = dom;
    }
    js.id = "js-iframe-async";
    js.src = `${scriptBaseURL}fb-all-prod.pp.min.js`;
    this.body.appendChild(js);
  };

  function listener() {
    doc._l();
  }

  if (iframe.addEventListener) {
    iframe.addEventListener("load", listener, false);
  } else if (iframe.attachEvent) {
    iframe.attachEvent("onload", listener);
  } else {
    doc.write("<body onload=\"document._l();\">");
  }

  doc.close();

  return iframe;
}

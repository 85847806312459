/**@jsx jsx */
import { css, jsx } from "@emotion/core";
import * as _ from "lodash";
import $ from "jquery";
import { Fragment, useEffect, useRef, useState } from "react";
import countries from "../../assets/resources/countries.json";
import default_states from "../../assets/resources/states.json";
import { LCCss } from "../../Containers/PagesCss";
import {
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
  replaceCustomVariables,
  replaceFieldLogicValues,
  toastError,
  getNewDataProductWithPaymentPlan,
  getCurrencyFormat,
  replaceWebinarDateToLocalDate,
  checkWebinarWhenOnlyOneExist,
} from "../../utils";
import * as services from "../../Containers/Services";
import {
  cvvCheck,
  dateCheck,
  formatCreditCard,
  getCardErrors,
  isValidZipCode,
  validateInputs,
} from "../../utils/cardHelpers";
import zoid from "zoid";
// import pragmatic from "jsx-pragmatic";

export const PaymentForm = ({
  leadCapture,
  isPreview,
  visible,
  winnerOutcome,
  quizScore,
  fieldLogicArrayFull,
  fieldLogicChoicesArray,
  handleSubmitPaymentForm,
  setCardElement,
  stripeElements,
  currentProduct,
  showBannerEmptyPaymentGateway,
  taxesProductConfig,
  takerToken,
  userCurrencySetting: currencyName,
  urlPaypalApprovalBA,
}) => {
  const [html, setHtml] = useState("");
  const locationRef = useRef([]);
  const locationData = useRef(null);
  const form = useRef(null);
  const authorizeCardElementRef = useRef(null);
  const billingCountryElement = useRef(null);
  const billingStateElement = useRef(null);
  const buttonForm = useRef(null);
  const initialAttrsStateField = useRef(null);
  let pragmatic = require("jsx-pragmatic");

  useEffect(() => {
    locationRef.current = organizeLocationsFromTaxes(taxesProductConfig.taxes);
    if (html) {
      setTimeout(() => {
        //Set Scroll to top with animation
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        form.current = document.querySelector(".paymentForm");
        if (form.current) {
          buttonForm.current = document.querySelector(".buttonPaymentForm");

          /**
           * webinar functions
           */
          replaceWebinarDateToLocalDate();
          checkWebinarWhenOnlyOneExist();

          billingCountryElement.current = document.getElementsByClassName(
            "select-country-billing-address",
          )[0];

          billingStateElement.current = document.getElementsByClassName(
            "field-state-billing-address",
          )[0];
          //Set billingStateAttributes before change Billing Country
          if (billingStateElement.current) {
            const attrs = billingStateElement.current
              .getAttributeNames()
              .reduce((acc, name) => {
                return {
                  ...acc,
                  [name]: billingStateElement.current.getAttribute(name),
                };
              }, {});
            initialAttrsStateField.current = attrs;
          }

          if (billingCountryElement.current) {
            populateCountries(countries, billingCountryElement.current);
            billingCountryElement.current.addEventListener(
              "change",
              changeCountryBillingAddress,
            );

            if (
              taxesProductConfig.location &&
              taxesProductConfig.location.location_code
            ) {
              billingCountryElement.current.value =
                taxesProductConfig.location.location_code;
              billingCountryElement.current.dispatchEvent(new Event("change"));
            }
          }

          if (billingStateElement.current) {
            billingStateElement.current.addEventListener(
              "change",
              changeStateBillingAddress,
            );
          }
          if (leadCapture.payment_gateway === "stripe") {
            isPreview && setCardElement(createDummyStripeElements());
            !isPreview && setCardElement(createStripeElements());
          }
          if (leadCapture.payment_gateway === "authorize_net") {
            replacePaymentFormAuthorize();
          }

          if (buttonForm.current) {
            form.current.addEventListener("submit", preventDefault, true);
            buttonForm.current.addEventListener("click", submitFormListener);
          }
        }
      }, 100);
    }

    return () => {
      removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  function submitFormListener(e) {
    const checkGDPR = document.querySelector(".checkbox-gdpr");
    const textGDPR = document.querySelector(".paragraph-gdpr");

    const formInputs = form.current.querySelectorAll(
      "input:not([class^='card-']), .billing-information-parent select",
    );

    formInputs.forEach(element => {
      element.addEventListener("keyup", e => {
        e.target.classList.remove("invalid");
      });
    });

    let contSubmitLead = 0;

    const gdprCheck = document.querySelector("label[data-gdpr-mandatory=true]");
    const inputCheck = document.querySelector(
      "label[data-check-input-required=true]",
    );

    const inputEmail = _.filter(formInputs, function(o) {
      return o.type === "email";
    });

    const inputWithoutEmail = _.filter(formInputs, function(o) {
      return o.type !== "email";
    });

    inputEmail.forEach(el => {
      //console.log(isValidEmail(el.value, el.value.trim()));
      if (
        (el.value.trim() === "" && el.required === true) ||
        (el.value.trim() !== "" && el.required === false)
      ) {
        //console.log("entro 1");
        if (isValidEmail(el.value) === false) {
          //console.log("entro 2");
          el.classList.add("invalid");
          contSubmitLead = contSubmitLead + 1;
        } else {
          //console.log("entro 4");
          el.classList.remove("invalid");
        }
      } else {
        if (el.value.trim() === "" && el.required === false) {
          //console.log("entro 3");
          el.classList.remove("invalid");
        }
        if (el.value.trim() !== "" && el.required === true) {
          if (isValidEmail(el.value) === false) {
            //console.log("entro 5");
            el.classList.add("invalid");
            contSubmitLead = contSubmitLead + 1;
          } else {
            //console.log("entro 6");
            el.classList.remove("invalid");
          }
        }
      }
    });

    inputWithoutEmail.forEach((el, index) => {
      console.log(`Index-${index}-->`, el.required, el.nodeName, el.value);
      if (
        el.required === true &&
        ((el.nodeName === "INPUT" && el.value === "") ||
          (el.nodeName === "SELECT" && el.value === "-1"))
      ) {
        el.classList.add("invalid");
        contSubmitLead = contSubmitLead + 1;
      } else {
        el.classList.remove("invalid");
      }
    });

    if (inputEmail.length === 0) {
      toastError({
        message: "The Payment Form must contain an email field.",
      });
      contSubmitLead = contSubmitLead + 1;
      return;
    } else {
      if (!inputEmail[0].required) {
        toastError({
          message: "The Email field must be mandatory.",
        });
        contSubmitLead = contSubmitLead + 1;
        return;
      }
    }

    const inputCheckGdpr = document.querySelector(".checkbox-gdpr");
    if (gdprCheck && inputCheckGdpr && !inputCheckGdpr.checked) {
      const warningMessageGDPR = gdprCheck.getAttribute(
        "data-gdpr-mandatory-text",
      );
      toastError({
        message: warningMessageGDPR
          ? warningMessageGDPR
          : "Privacy Policy checkbox is required to submit the form",
      });
      contSubmitLead = contSubmitLead + 1;
      return;
    }

    //Validate Terms and Conditions Checkbox
    const inputCheckRequired = document.querySelector(".checkbox-input");
    if (inputCheck && inputCheckRequired && !inputCheckRequired.checked) {
      toastError({
        message: "Terms and Conditions check is required to submit the form",
      });
      contSubmitLead = contSubmitLead + 1;
      return;
    }

    //Validate Webinars Options
    const webinars = Array.from(
      document.querySelectorAll(".webinars-list .webinar-list__item"),
    );

    const webinarSelected = webinars.filter(webinar => {
      const inputRadio = webinar.querySelector("input[type= 'radio']");
      return inputRadio.checked;
    });
    if (!webinarSelected.length && webinars.length) {
      toastError({ message: "You must select a webinar" });
      contSubmitLead = contSubmitLead + 1;
      return;
    }
    //Validate ZipCode
    const inputZipCode = document.querySelector("[name='Zip Postal Code']");
    if (inputZipCode && billingCountryElement.current) {
      if (!isValidZipCode(inputZipCode, billingCountryElement.current)) {
        inputZipCode.classList.add("invalid");
        contSubmitLead = contSubmitLead + 1;
        return;
      }
      inputZipCode.classList.remove("invalid");
    }

    //Validate AuthorizeNet Card Fields
    if (leadCapture.payment_gateway === "authorize_net") {
      console.log(authorizeCardElementRef.current);
      const {
        inputCardNumber,
        inputCardDate,
        inputCardCvv,
      } = authorizeCardElementRef.current;
      const inputCardValidator = validateInputs(
        inputCardNumber.value,
        inputCardDate.value,
        inputCardCvv.value,
      );
      if (!inputCardValidator) {
        getCardErrors(inputCardNumber, inputCardDate, inputCardCvv);
        contSubmitLead = contSubmitLead + 1;
        return;
      }
    }

    if (contSubmitLead > 0) return;

    if (leadCapture.payment_gateway === "paypal") {
      if (!isPreview) {
        if (window.MyLoginZoidComponent) {
          zoid.destroy({ tag: "paypal-form-component" });
        }
        window.MyLoginZoidComponent = zoid.create({
          tag: "paypal-form-component",
          url: urlPaypalApprovalBA,
          dimensions: {
            width: "400px",
            height: "400px",
          },

          containerTemplate: ({ uid, tag, context, focus, close, doc }) => {
            function closeComponent(event) {
              event.preventDefault();
              event.stopPropagation();
              return close();
            }

            function focusComponent(event) {
              event.preventDefault();
              event.stopPropagation();
              return focus();
            }

            return pragmatic
              .node(
                "div",
                {
                  id: uid,
                  onClick: focusComponent,
                  class: `${tag} ${tag}-context-${context} ${tag}-focus`,
                },

                pragmatic.node("a", {
                  href: "#",
                  onClick: closeComponent,
                  class: `${tag}-close`,
                }),

                pragmatic.node(
                  "style",
                  null,
                  `
                  #${uid} {
                      position: fixed;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.8);
                  }
  
                  #${uid}.${tag}-context-${zoid.CONTEXT.POPUP} {
                      cursor: pointer;
                  }
  
                  #${uid} .${tag}-close {
                      position: absolute;
                      right: 16px;
                      top: 16px;
                      width: 16px;
                      height: 16px;
                      opacity: 0.6;
                  }
  
                  #${uid} .${tag}-close:hover {
                      opacity: 1;
                  }
  
                  #${uid} .${tag}-close:before,
                  #${uid} .${tag}-close:after {
                      position: absolute;
                      left: 8px;
                      content: ' ';
                      height: 16px;
                      width: 2px;
                      background-color: white;
                  }
  
                  #${uid} .${tag}-close:before {
                      transform: rotate(45deg);
                  }
  
                  #${uid} .${tag}-close:after {
                      transform: rotate(-45deg);
                  }
              `,
                ),
              )
              .render(pragmatic.dom({ doc }));
          },
        });
        window
          .MyLoginZoidComponent({
            onClose: async () => {
              handleSubmitPaymentForm(
                formInputs,
                webinarSelected,
                checkGDPR,
                textGDPR,
                locationData.current,
              );
              form.current.removeEventListener("submit", preventDefault, true);
            },
          })
          .render("body", zoid.CONTEXT.POPUP);
      } else {
        handleSubmitPaymentForm(
          formInputs,
          webinarSelected,
          checkGDPR,
          textGDPR,
          locationData.current,
        );
      }
    } else {
      handleSubmitPaymentForm(
        formInputs,
        webinarSelected,
        checkGDPR,
        textGDPR,
        locationData.current,
      );
      form.current.removeEventListener("submit", preventDefault, true);
    }
  }

  function removeAllListeners() {
    if (billingCountryElement.current)
      billingCountryElement.current.removeEventListener(
        "change",
        changeCountryBillingAddress,
      );
    if (billingStateElement.current)
      billingStateElement.current.removeEventListener(
        "change",
        changeStateBillingAddress,
      );
    if (buttonForm.current)
      buttonForm.current.removeEventListener("click", submitFormListener);
  }

  useEffect(() => {
    if (leadCapture && leadCapture.lead_capture_type === "payment_form") {
      let newCss = replaceBackgroundImagesByBackgrounds(
        leadCapture.pages[0].fields.css,
      );
      // newCss = _.replace(newCss, new RegExp("body{", "g"), "#newBody{");
      let contentPageLC = replaceCustomVariables(
        leadCapture.pages[0].fields.html,
        winnerOutcome,
        quizScore,
        "lc",
        [],
        [],
        [],
        null,
        currentProduct,
      );
      // Replace Field Logic Values
      contentPageLC = replaceFieldLogicValues(
        fieldLogicChoicesArray,
        contentPageLC,
        fieldLogicArrayFull,
      );
      const htmlDom = new DOMParser().parseFromString(
        contentPageLC,
        "text/html",
      );

      if (taxesProductConfig.tax_in_total) {
        const taxesBox = htmlDom.body.querySelector(".row-form.row-tax-info");
        if (taxesBox) {
          taxesBox.style.display = "none";
        }
      }

      const products = htmlDom.body.querySelectorAll(".product-info");
      if (products && Object.keys(currentProduct).length > 0) {
        contentPageLC = replaceFieldsWithCurrentProduct({
          products,
          htmlDom,
          currentProduct,
        });
      }

      contentPageLC = renderIframeUrl(contentPageLC);
      setHtml(`${contentPageLC}<style>${newCss}${LCCss}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadCapture]);

  function organizeLocationsFromTaxes(taxes) {
    const locations = [];
    taxes.forEach(tax => {
      tax.config_body.data.forEach(location => {
        if (location.sublocation.length === 0) {
          if (location.active) locations.push(location);
        } else {
          if (location.sublocation.some(item => item.active))
            locations.push(location);
        }
      });
    });
    return locations;
  }

  function populateCountries(countries, countryElement) {
    // given the id of the <select> tag as function argument, it inserts <option> tags
    countryElement.length = 0;
    countryElement.options[0] = new Option("Select Country", "-1");
    countryElement.options[0].disabled = true;
    countryElement.selectedIndex = 0;
    for (let i = 0; i < countries.length; i++) {
      countryElement.options[countryElement.length] = new Option(
        countries[i].name,
        countries[i].abbreviation,
      );
    }
  }

  function createSelectState(states) {
    let selectState = document.createElement("select");
    let option = document.createElement("option");
    option.value = "-1";
    option.text = "Select State";
    option.selected = true;
    option.disabled = true;
    selectState.appendChild(option);
    states.forEach(state => {
      let option = document.createElement("option");
      option.value = state.abbreviation || state.name;
      option.text = state.name;
      selectState.appendChild(option);
    });
    return selectState;
  }

  function changeCountryBillingAddress(e) {
    billingStateElement.current = document.getElementsByClassName(
      "field-state-billing-address",
    )[0];

    let countryInTaxes = locationRef.current.find(
      location => location.abbreviation === e.target.value,
    );

    if (!countryInTaxes && ["US", "CA"].includes(e.target.value)) {
      countryInTaxes = {
        name: e.target.value === "US" ? "United States" : "Canada",
        abbreviation: e.target.value,
        sublocation: default_states[e.target.value],
      };
    }

    let element = null;
    if (countryInTaxes && countryInTaxes.sublocation.length > 0) {
      element = createSelectState(countryInTaxes.sublocation);
      element.addEventListener("change", changeStateBillingAddress);

      //Set Select with IPStack Info
      setSublocationInfo(element, countryInTaxes);
    } else {
      element = document.createElement("input");
      element.setAttribute("type", "text");
      calculateTaxes(e.target.value);
    }
    if (initialAttrsStateField.current) {
      Object.keys(initialAttrsStateField.current).forEach(key => {
        element.setAttribute(key, initialAttrsStateField.current[key]);
      });
    }
    countryInTaxes && element.classList.add("select");

    buttonForm.current.removeEventListener("click", submitFormListener);
    billingStateElement.current.replaceWith(element);
    buttonForm.current.addEventListener("click", submitFormListener);
  }

  function setSublocationInfo(element, countryInTaxes) {
    if (
      taxesProductConfig.location &&
      taxesProductConfig.location.sublocation
    ) {
      if (countryInTaxes) {
        const searchSublocation = countryInTaxes.sublocation.find(
          item => item.name === taxesProductConfig.location.sublocation,
        );
        if (searchSublocation) {
          element.value = searchSublocation.abbreviation;
          element.dispatchEvent(new Event("change"));
        }
      }
    }
  }

  function changeStateBillingAddress(e) {
    if (billingCountryElement.current)
      calculateTaxes(billingCountryElement.current.value, e.target.value);
  }

  function createStripeElements() {
    const paymentElement = stripeElements.create("card", {
      hidePostalCode: true,
    });

    paymentElement.mount(".card-elements");
    return paymentElement;
  }

  function createDummyStripeElements() {
    if (!window.stripe) {
      const script = "<script src=\"https://js.stripe.com/v3/\"></script>";
      $(script).prependTo("head");
    }
    window.setTimeout(() => {
      if (window.Stripe) {
        const client = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

        const elements = client.elements();
        const dummyCardElement = elements.create("card", {
          hidePostalCode: true,
        });
        dummyCardElement.mount(".card-elements");
        return dummyCardElement;
      }
    }, 1000);
  }

  function preventDefault(e) {
    e.preventDefault();
  }

  function isValidEmail(mail) {
    // eslint-disable-next-line no-useless-escape
    return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(mail);
  }

  function replaceFieldsWithCurrentProduct({
    products,
    htmlDom,
    currentProduct,
  }) {
    const { image, name, description } = currentProduct;

    const imgUrl =
      "https://bucket-app-utils.s3.amazonaws.com/email-template/placeholder-invoice.png";

    if (currentProduct.prices_plan && currentProduct.prices_plan.length) {
      currentProduct.has_plan = true;
    }
    const { price, paymentPlanDescription } = getNewDataProductWithPaymentPlan(
      currentProduct,
      currencyName,
    );

    products.forEach(product => {
      let newProducts = "";
      let currentImage = image.url ? image.url : imgUrl;
      const domImages = product.querySelectorAll(".product-image");
      domImages.forEach(domImage => {
        if (domImage) {
          domImage.src = currentImage;
        }
      });
      const defaultItem = product.outerHTML;
      const priceWithFormat = taxesProductConfig.location
        ? taxesProductConfig.location.total / 100
        : price;

      newProducts += defaultItem
        .replace(/\[NAME\]|\[PRODUCT_NAME\]/g, name)
        .replace(/\[DESCRIPTION\]|\[PRODUCT_DESCRIPTION\]/g, description)
        .replace(
          /\[PRICE\]|\[PRODUCT_PRICE\]/g,
          `${getCurrencyFormat(
            currencyName,
            price,
          )}  ${paymentPlanDescription}`,
        )
        .replace(
          /\[TOTAL_PRICE\]/g,
          `${getCurrencyFormat(currencyName, priceWithFormat)}`,
        );
      product.outerHTML = newProducts;
    });
    //Find Tax Value

    const taxValue = htmlDom.querySelector(".row-tax-info .tax-value");
    if (taxValue) {
      if (taxesProductConfig.location) {
        taxValue.innerHTML = `${getCurrencyFormat(
          currencyName,
          taxesProductConfig.location.tax_value / 100,
        )}`;
      } else {
        taxValue.innerHTML = `${getCurrencyFormat(currencyName, 0)}`;
      }
    }

    return htmlDom.body.innerHTML;
  }

  function findCountryByCode(code) {
    const country = countries.find(country => country.abbreviation === code);
    let locationwitSublocations = locationRef.current.find(
      location => location.abbreviation === code,
    );
    if (!locationwitSublocations && ["US", "CA"].includes(code)) {
      //Find in default states
      locationwitSublocations = {
        name: code === "US" ? "United States" : "Canada",
        abbreviation: code,
        sublocation: default_states[code],
      };
    }
    if (
      locationwitSublocations &&
      locationwitSublocations.sublocation.length > 0
    ) {
      country.sublocation = locationwitSublocations.sublocation;
    }
    return country;
  }

  async function calculateTaxes(country_code, state_code) {
    const location = findCountryByCode(country_code);
    let sublocation = null;

    if (state_code) {
      const tempSublocation = location.sublocation.find(
        state => state.name === state_code || state.abbreviation === state_code,
      );
      sublocation = {
        write_by_user: false,
        name: tempSublocation.name,
        abbreviation: tempSublocation.abbreviation
          ? tempSublocation.abbreviation
          : tempSublocation.name.toUpperCase(),
      };
    }
    locationData.current = {
      location: {
        name: location.name,
        abbreviation: location.abbreviation,
      },
      sublocation,
    };
    if (!taxesProductConfig.tax_in_total) {
      try {
        const { data } = await services.calculateTaxes(
          takerToken,
          currentProduct.id,
          locationData.current,
        );

        const { total, tax_value } = data;
        const totalOrderElement = document.querySelector(".total-order");
        if (totalOrderElement) {
          totalOrderElement.innerHTML = `${getCurrencyFormat(
            currencyName,
            total / 100,
          )}`;
        }

        const taxValueElement = document.querySelector(".tax-value");
        if (taxValueElement) {
          taxValueElement.innerHTML = `${getCurrencyFormat(
            currencyName,
            tax_value / 100,
          )}`;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function replacePaymentFormAuthorize() {
    const container = document.querySelector(".card-elements");
    const form = `
      <div class="authorize_net-card-fields">
        <div class="card-number-container">
          <input
            type="text"
            class="card-input" id="card-number"
            placeholder="Card Number"
          />
        </div>
        <div class="card-date-cvv-container">
          <input type="text" class="card-input" id="card-date" placeholder="MM/YY" />
          <input type="text" class="card-input" id="card-cvv" placeholder="CVV" />
        </div>
      </div>
    `;

    container.outerHTML = form;
    const inputCardNumber = document.querySelector("#card-number");
    const inputCardDate = document.querySelector("#card-date");
    const inputCardCvv = document.querySelector("#card-cvv");

    authorizeCardElementRef.current = {
      inputCardNumber,
      inputCardDate,
      inputCardCvv,
    };

    copyStylesTo([inputCardNumber, inputCardDate, inputCardCvv]);

    inputCardNumber.addEventListener("keyup", e => {
      let userCardInput = formatCreditCard(inputCardNumber.value);
      inputCardNumber.classList.remove("invalid");
      inputCardNumber.value = userCardInput;
    });
    inputCardDate.addEventListener("keyup", e => {
      let userCardInput = dateCheck(inputCardDate.value);
      inputCardDate.classList.remove("invalid");
      inputCardDate.value = userCardInput;
    });
    inputCardCvv.addEventListener("keyup", e => {
      let userCardInput = cvvCheck(inputCardCvv.value);
      inputCardCvv.classList.remove("invalid");
      inputCardCvv.value = userCardInput;
    });
  }

  function copyStylesTo(elements) {
    const boxStyles = window.getComputedStyle(
      document.querySelector(".input.text"),
    );
    const styleList = [
      "backgroundColor",
      "color",
      "padding",
      "borderColor",
      "border",
      "marginBottom",
      "borderRadius",
      "height",
    ];
    if (boxStyles) {
      elements.forEach(element => {
        styleList.forEach(property => {
          element.style[property] = boxStyles[property];
        });
      });
    }
  }

  return (
    <Fragment>
      <div
        css={css`
          padding-top: ${isPreview || showBannerEmptyPaymentGateway ? 60 : 0}px;
          display: ${visible ? "block" : "none"};
          .invalid {
            border-bottom: 2px solid red !important;
          }
        `}
        className="payment-form"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Fragment>
  );
};

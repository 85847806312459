export const initialStateApplicationContainer = {
  welcomePage: {},
  welcomePageHtml: {},
  showWelcomePage: true,
  showDialogQuestions: false,
  design: [
    { option: "switchShadow", value: "true" },
    { option: "switchProgressBar", value: "true" },
    { option: "colorProgressBar", value: "#F4b301" },
    { option: "valueProgressBar", value: 50 },
    { option: "switchNextButton", value: "true" },
    { option: "next_button_text_color", value: "#585858" },
    { option: "next_button", value: "Next" },
    { option: "switchBackButton", value: "true" },
    { option: "back_button_text_color", value: "#585858" },
    { option: "back_button", value: "Back" },
  ],
  questions: [],
  currentQuestion: {},
  isLoadingInitialInfo: true,
  showQuestions: true,
  showLeadCapture: false,
  winnerLeadCapture: {},
  postQuizPageHtml: {},
  showPostQuizPage: false,
  nextDisabled: true,
  nodesPath: null,
  questionsPassed: 0,
  welcomeContinue: false,
  welcomePageTemp: null,
  postQuizWinner: null,
  showQuestionWithoutLink: false,
  nextOther: false,
  nextOtherAlone: false,
  nextCheckOther: false,
  metaImage: { url: null },
  emptyCanvas: false,
  nextDisabledUrlShort: false,
  showThankWebinarPage: false,
  winnerOutcome: null,
  quizScore: 0,
  leadCaptureFieldsVariables: [],
};

import { getVariablesPricesPlan } from "./getVariablesPricesPlan";
import { getCurrencyFormat } from "./getCurrencyFormat";

export const getNewDataProductWithPaymentPlan = (product, currencyName) => {
  const {
    new_unit_price: price,
    pricePerInstallments,
    numberOfPayments,
  } = getVariablesPricesPlan(product);
  const message = getFrecuencyPayments(numberOfPayments, product);
  const paymentPlanDescription = product.has_plan
    ? `+ ${numberOfPayments} payment${
        numberOfPayments > 1 ? "s" : ""
      } of <b>  ${getCurrencyFormat(
        currencyName,
        pricePerInstallments,
    )}${message}`
    : "";
  return { price, paymentPlanDescription };
};

const getFrecuencyPayments = (numberOfPayments, product) => {
  if (numberOfPayments === 1) {
    return "";
  } else {
    if (Number(product.charge_frecuency) === 1)
      return ` (${getFrecuency(product.interval_frecuency)})</b>`;
    else
      return `</b> every <b>${product.charge_frecuency} ${product.interval_frecuency}s</b>`;
  }
};

function getFrecuency(frequency) {
  switch (frequency) {
    case "day":
      return "Daily";
    case "week":
      return "Weekly";
    case "month":
      return "Monthly";
    default:
      return "Not defined";
  }
}

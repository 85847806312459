export const getVariablesPricesPlan = ({
  unit_price,
  prices_plan,
  has_plan,
}) => {
  let new_unit_price = Number(unit_price);
  let pricePerInstallments = 1;
  let numberOfPayments = 1;
  if (has_plan) {
    new_unit_price = Number(
      prices_plan.find(item => Number(item.installment_number) === 1).value,
    );
    numberOfPayments = prices_plan.length - 1;
    pricePerInstallments = prices_plan.find(
      item => Number(item.installment_number) === 2,
    ).value;
  }
  return {
    new_unit_price,
    pricePerInstallments,
    numberOfPayments,
  };
};

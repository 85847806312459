import $ from "jquery";

export function executeTrackingCodeForPage(pages_tracking_codes, page) {
  try {
    if (pages_tracking_codes && pages_tracking_codes.length > 0) {
      const pageTrackingCode = pages_tracking_codes[0].tracker_settings.find(
        trackable_page =>
          trackable_page.trackable_id === page.id && trackable_page.active,
      );
      if (pageTrackingCode) {
        // !isPreview &&
        $(pageTrackingCode.external_data.code).appendTo("body");
      }
    }
  } catch (error) {
    console.error(error, page);
  }
}

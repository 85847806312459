import { css } from "@emotion/core";

export const container2 = css`
  .messageMissing {
    margin: 0;
    position: absolute;
    width: 88%;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      margin-bottom: 20px;
      margin-top: 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #585858;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #585858;
      margin-top: 0;
      margin-bottom: 0px;
    }
  }
  .close {
    top: 1px !important;
    right: 6px !important;
  }
`;

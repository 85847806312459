/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
export const Message = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    max-width: 650px;
    min-height: 140px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;

    .title {
      margin-bottom: 20px;
      margin-top: 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #585858;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #585858;
      margin-top: 0;
      margin-bottom: 0px;
    }
  }
`;
console.log("entro");
export const Page404 = () => (
  <Message>
    <div className="main">
      <span className="title">404 - Not Found</span>
      <p>
        It seems the page you’re trying to access was either Unpublished or
        Deleted.
      </p>
    </div>
  </Message>
);

import moment from "moment-timezone";

export const replaceWebinarDateToLocalDate = () => {
  const webinars = document.querySelectorAll(".webinar-list__item");

  webinars.forEach(webinar => {
    const webinarDate = webinar.querySelector(".webinar-date");

    if (webinarDate) {
      const tz = moment.tz.guess();
      const { startTime, format } = webinarDate.dataset;

      webinarDate.textContent = moment(startTime)
        .tz(tz)
        .format(format);
    }
  });
};

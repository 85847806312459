/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import iconClose from "../../assets/iconClose.svg";
import PlaceholderBuilder from "../../Components/placeholderBuilder/PlaceholderBuilder";
import {
  OptionGroupCheckBox,
  OptionGroupCheckWithImages,
  OptionGroupRadio,
  OptionGroupRadioWithImages,
} from "../../Components";
import { Dialog, ProgressBar } from "./Dialog.styled";

import * as _ from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";

import urlRegex from "url-regex";

import {
  toastError,
  renderIframeUrl,
  checkWebinarWhenOnlyOneExist,
  replaceWebinarDateToLocalDate,
  replaceCustomVariables,
  replaceFieldLogicValues,
  replaceBackgroundImagesByBackgrounds,
} from "../../utils";

import { LCCss } from "../../Containers/PagesCss";
import $ from "jquery";

//import { useState, useEffect } from "react";
let newStyleLC = "";
let contentPageLC = "";
// let choiceCheckRequired = [];
let numBar = 0;

function preventDefault(e) {
  e.preventDefault();
}

export const StandartModal = props => {
  //console.log(props);
  const [currentRadioValue, setCurrentRadioValue] = useState(null);
  const [choiceCheckRequired, setChoiceCheckRequired] = useState([]);
  const [currentRadioChoice, setCurrentRadioChoice] = useState(null);
  const [currentOtherValue, setCurrentOtherValue] = useState(null);
  const [currentOtherValueCheck, setCurrentOtherValueCheck] = useState(null);
  const [currentShortLongValue, setCurrentShortLongValue] = useState("");

  // let allQuestions = _.orderBy(
  //   [...props.questions],
  //   ["position"],
  //   ["asc"],
  // );

  //let newDesignOpt = [];
  //const [currentQuestion, setCurrentQuestion] = useState(props.currentQuestion);
  //let [iter, setIter] = useState(0);

  useEffect(() => {
    //console.log(props.currentQuestion.answerValue);
    let temp = [];

    if (
      props.currentQuestion.question_type === "short_text" ||
      props.currentQuestion.question_type === "long_text"
    ) {
      setCurrentShortLongValue(props.currentQuestion.answerValue || "");
    }
    if (props.currentQuestion.question_type === "check") {
      temp = props.currentQuestion.option_choices.map(el => {
        return {
          id: el.internal_id,
          checked: false,
        };
      });
      //console.log(props.currentQuestion.answerValue);
      if (props.currentQuestion.answerValue !== null) {
        const otherChoices = _.find(
          props.currentQuestion.option_choices,
          el => {
            return el.other === true;
          },
        );
        //console.log(otherChoices);
        setCurrentOtherValueCheck(otherChoices.answer);
      }
    }
    //console.log("----->>", props.currentQuestion.answerValue, temp);
    setChoiceCheckRequired(props.currentQuestion.answerValue || temp);
    setCurrentRadioValue(props.currentQuestion.answerValue || null);
    if (props.currentQuestion.question_type === "radio") {
      const choicesTempAnswer = _.find(
        props.currentQuestion.option_choices,
        el => {
          return el.internal_id === props.currentQuestion.answerValue;
        },
      );
      setCurrentRadioChoice(choicesTempAnswer || null);
      //console.log("choice temp", choicesTempAnswer);
      if (choicesTempAnswer) {
        setCurrentOtherValue(choicesTempAnswer.answer);
      }
    }

    $(".mainQuestion").animate({ scrollTop: "0px" }, 150);
  }, [props.currentQuestion]);

  // const optionsInJSON = [
  //   "shadowColor",
  //   "bgBox",
  //   "next_button_color",
  //   "back_button_color",
  // ];
  // props.design.forEach(item => {
  //   // Object String
  //   let value = "";
  //   if (optionsInJSON.indexOf(item.option) !== -1) {
  //     value = JSON.parse(item.value);
  //   } else {
  //     value = item.value;
  //   }
  //   //console.log(value);
  //   newDesignOpt = {
  //     ...newDesignOpt,
  //     [item.option]: value,
  //   };
  //   // **************
  // });
  //console.log(newDesignOpt, props.design);

  //setCurrentQuestion(allQuestions[iter]);
  //console.log(allQuestions[0]);
  //console.log(currentQuestion);

  //console.log(allQuestions);

  const nextQuestion = type => {
    //console.log(props);
    props.nextQuestion(choiceCheckRequired, currentOtherValueCheck);
    //console.log(document.getElementById(props.currentQuestion.id));
    if (window.navigator.onLine) {
      setChoiceCheckRequired([]);
      setCurrentOtherValueCheck("");
      setCurrentShortLongValue("");
      if (document.getElementById(props.currentQuestion.id) !== null) {
        document.getElementById(props.currentQuestion.id).value = "";
      }
    }
    animationModalQuestions();
  };

  const nextQuestionDisabledRadio = radioQ => {
    //console.log("entro nextDisabledRadio");
    props.nextQuestionDisabled(currentRadioChoice, radioQ, currentOtherValue);
    //if(window.navigator.onLine){
    setCurrentOtherValue("");
    setCurrentShortLongValue("");
    //}
    animationModalQuestions();
  };

  const nextQuestionDisabledCheck = () => {
    props.nextQuestionDisabledCheck(
      choiceCheckRequired,
      currentOtherValueCheck,
    );
    if (window.navigator.onLine) {
      setChoiceCheckRequired([]);
      setCurrentOtherValueCheck("");
      setCurrentShortLongValue("");
    }
    animationModalQuestions();
  };

  const backQuestion = () => {
    // choiceCheckRequired = [];
    setChoiceCheckRequired([]);
    setCurrentShortLongValue("");
    props.backQuestion();
    animationModalQuestions();
  };

  const changeRadioNew = e => {
    //console.log(e.target.value);
    if (e.target.value) {
      const optionChoice = props.currentQuestion.option_choices.find(opt => {
        return opt.internal_id === e.target.value;
      });
      //console.log("TCL: optionChoice", optionChoice, e.target.value);
      if (window.navigator.onLine) {
        setCurrentRadioValue(e.target.value);
        setCurrentRadioChoice(optionChoice);
        setCurrentOtherValue(optionChoice.answer);
      } else {
        setCurrentRadioValue(null);
        setCurrentRadioChoice(null);
        setCurrentOtherValue(null);
      }

      props.nextRadio(e.target.value, optionChoice, props.currentQuestion);
      if (props.design.switchNextButton === "false" && !optionChoice.other) {
        animationModalQuestions();
      }
    }
  };

  const changeCheck = (e, item) => {
    //console.log(e.target.checked, e.target.value, item);
    const searchCheckItem = _.find(choiceCheckRequired, el => {
      return el.id === e.target.value;
    });
    //console.log(searchCheckItem);
    if (typeof searchCheckItem === "undefined") {
      setChoiceCheckRequired([
        ...choiceCheckRequired,
        {
          id: e.target.value,
          checked: e.target.checked,
        },
      ]);
      // choiceCheckRequired.push({
      //   id: e.target.value,
      //   checked: e.target.checked,
      // });
    } else {
      searchCheckItem.checked = e.target.checked;
    }
    props.nextCheck(choiceCheckRequired, e, item, props.currentQuestion);
    //console.log(choiceCheckRequired, item);
  };
  const changeRadioOther = (e, choice) => {
    //console.log(e.target.value);
    setCurrentOtherValue(e.target.value);
    props.changeOtherValue(e.target.value, choice);
  };
  const changeRadioOtherCheck = (e, question) => {
    //console.log(e.target.value, question);
    setCurrentOtherValueCheck(e.target.value);
    props.changeOtherValueCheck(e.target.value, question);
  };

  if (props.showLeadCapture) {
    const newCss = replaceBackgroundImagesByBackgrounds(
      props.leadCapture.pages[0].fields.css,
    );

    newStyleLC = _.replace(newCss, new RegExp("body{", "g"), "#newBody{");
    //console.log(props.fieldLogicChoicesArray);

    //Replace CustomVariables
    const { quizScore, winnerOutcome } = props.customVariablesInfo;

    contentPageLC = replaceCustomVariables(
      props.leadCapture.pages[0].fields.html,
      winnerOutcome,
      quizScore,
      "lc",
      [],
    );
    // Replace Field Logic Values
    contentPageLC = replaceFieldLogicValues(
      props.fieldLogicChoicesArray,
      contentPageLC,
      props.fieldLogicArrayFull,
    );

    // FOR VIMEO/YOUTUBE VIDEOS
    contentPageLC = renderIframeUrl(contentPageLC);

    setTimeout(() => {
      const form = document.querySelector(".leadCaptureForm");
      if (form) {
        const formInputs = form.getElementsByTagName("input");

        //console.log(formInputs);

        Object.keys(formInputs).forEach(element => {
          //console.log(formInputs[element]);
          formInputs[element].addEventListener("keyup", e => {
            e.target.classList.remove("invalid");
          });
        });
        //console.log(document.querySelector(".buttonLeadCapture"));
        if (document.querySelector(".buttonLeadCapture") !== null) {
          const form = document.querySelector(".leadCaptureForm");
          const buttonForm = document.querySelector(".buttonLeadCapture");
          const checkGDPR = document.querySelector(".checkbox-gdpr");
          const textGDPR = document.querySelector(".paragraph-gdpr");

          //console.log(checkGDPR, textGDPR);
          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();

          form.addEventListener("submit", preventDefault, true);

          buttonForm.addEventListener("click", e => {
            //console.log("entro lead");
            let contSubmitLead = 0;

            const gdprCheck = document.querySelector(
              "label[data-gdpr-mandatory=true]",
            );
            const inputCheckGdpr = document.querySelector(".checkbox-gdpr");
            if (gdprCheck && inputCheckGdpr && !inputCheckGdpr.checked) {
              const warningMessageGDPR = gdprCheck.getAttribute(
                "data-gdpr-mandatory-text",
              );
              toastError({
                message: warningMessageGDPR
                  ? warningMessageGDPR
                  : "Privacy Policy checkbox is required to submit the form",
              });
              contSubmitLead = contSubmitLead + 1;
            }

            const webinars = Array.from(
              document.querySelectorAll(".webinars-list .webinar-list__item"),
            );

            const webinarSelected = webinars.filter(webinar => {
              const inputRadio = webinar.querySelector("input[type= 'radio']");
              return inputRadio.checked;
            });

            const inputEmail = _.filter(formInputs, function(o) {
              return o.type === "email";
            });

            const inputWithoutEmail = _.filter(formInputs, function(o) {
              return o.type !== "email";
            });

            if (!webinarSelected.length && webinars.length) {
              toastError({ message: "You must select a webinar" });
              contSubmitLead = contSubmitLead + 1;
            }

            inputEmail.forEach(el => {
              //console.log(isValidEmail(el.value, el.value.trim()));
              if (
                (el.value.trim() === "" && el.required === true) ||
                (el.value.trim() !== "" && el.required === false)
              ) {
                //console.log("entro 1");
                if (isValidEmail(el.value) === false) {
                  //console.log("entro 2");
                  el.classList.add("invalid");
                  contSubmitLead = contSubmitLead + 1;
                } else {
                  //console.log("entro 4");
                  el.classList.remove("invalid");
                }
              } else {
                if (el.value.trim() === "" && el.required === false) {
                  //console.log("entro 3");
                  el.classList.remove("invalid");
                }
                if (el.value.trim() !== "" && el.required === true) {
                  if (isValidEmail(el.value) === false) {
                    //console.log("entro 5");
                    el.classList.add("invalid");
                    contSubmitLead = contSubmitLead + 1;
                  } else {
                    //console.log("entro 6");
                    el.classList.remove("invalid");
                  }
                }
              }
            });

            inputWithoutEmail.forEach(el => {
              if (el.required === true && el.value === "") {
                el.classList.add("invalid");
                contSubmitLead = contSubmitLead + 1;
              } else {
                el.classList.remove("invalid");
              }
            });

            if (contSubmitLead === 0) {
              //console.log(formInputs);
              // formInputs.forEach(el => {
              //   console.log(el);
              // });
              props.submitLeadCapture(
                props.leadCapture,
                formInputs,
                webinarSelected,
                checkGDPR,
                textGDPR,
              );
              //console.log("submit lead capture");

              form.removeEventListener("submit", preventDefault, true);
            }

            //console.log(formInputs, inputEmail, contSubmitLead);
          });
        }
      }
    }, 100);

    function isValidEmail(mail) {
      // eslint-disable-next-line no-useless-escape
      return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(mail);
    }

    //console.log(newStyleLC);
  }

  /*numBar =
    props.nodesPath &&
    `${parseInt(props.design.valueProgressBar) +
      props.questionsPassed *
        ((100 - parseInt(props.design.valueProgressBar)) /
          (props.questionsPassed + props.nodesPath[1]))}`;*/
  numBar = props.nodesPath;
  //console.log(numBar);
  if (numBar !== null) numBar = `${parseFloat(numBar).toFixed(0)}%`;

  const changeShortLong = (type, e, id) => {
    if (type === "short" || type === "long") {
      //console.log(e.target.type);
      if (e.target.type === "url") {
        //console.log(urlRegex({ exact: true, strict: true }).test(e.target.value));
        if (
          urlRegex({ exact: true, strict: true }).test(e.target.value) === false
        ) {
          setCurrentShortLongValue(e.target.value, id);
          props.changeShortLong("", id, false, e.target.value);
        } else {
          setCurrentShortLongValue(e.target.value, id);
          props.changeShortLong(e.target.value, id, true);
        }
      } else {
        //console.log("entro normal");
        setCurrentShortLongValue(e.target.value, id);
        props.changeShortLong(e.target.value, id);
      }
    }
  };

  const animationModalQuestions = () => {
    $(".mainQuestionWrapper").addClass("aniModal");
    //$(".mainQuestion").removeClass("four");
    setTimeout(() => {
      $(".mainQuestionWrapper").removeClass("aniModal");
    }, 500);
  };

  // props.showLeadCapture &&
  //   console.log(Object.keys(props.leadCapture.pages[0].fields));
  //console.log(numBar);
  return (
    <Dialog
      open={props.showDialog}
      newDesignOpt={props.design}
      isNewPreview={props.isNewPreview}
    >
      <div
        className={`${
          props.design.switchShadow === "true" ? "" : "disabledShadow"
        } ${
          props.showLeadCapture === false ? "mainQuestion" : "mainLeadCapture"
        } ${props.showDialog && props.showLeadCapture === false ? "four" : ""}`}
      >
        {props.showQuestions && !props.showQuestionWithoutLink && (
          <div
            className="mainQuestionWrapper"
            css={css`
              position: relative;
              min-height: 275px;
            `}
          >
            <div
              css={css`
                padding-bottom: 80px;
              `}
            >
              <ProgressBar
                newDesignOpt={props.design}
                numBar={numBar}
                className={`${
                  props.design.switchProgressBar === "true" ? "" : "disabledPB"
                }`}
              >
                <div className="mainProgressBar"></div>
              </ProgressBar>
              <div
                className="close"
                onClick={() => {
                  props.closeModal();
                  $(".mainQuestion").removeClass("four");
                }}
              >
                <img src={iconClose} alt="imgClose" />
              </div>
              <br />
              {typeof props.currentQuestion !== "undefined" ? (
                <div
                  className="mainTitle"
                  dangerouslySetInnerHTML={{
                    __html: props.currentQuestion.name,
                  }}
                ></div>
              ) : (
                ""
              )}
              {typeof props.currentQuestion !== "undefined" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.currentQuestion.description,
                  }}
                ></div>
              ) : (
                ""
              )}
              <br />
              {typeof props.currentQuestion !== "undefined" &&
              props.currentQuestion.use_image ? (
                  <img
                  className="mainImage"
                  alt="imgUseImage"
                  src={props.currentQuestion.image_url}
                />
                ) : (
                  ""
              )}
              {props.currentQuestion.option_choices &&
                props.currentQuestion.question_type === "radio" && (
                  <Fragment>
                  {!props.currentQuestion.add_image_to_choices ? (
                    <OptionGroupRadio
                      handleChange={changeRadioNew}
                        //handleClick={clickRadioNew}
                      currentQuestion={props.currentQuestion}
                      currentRadioValue={currentRadioValue}
                      currentRadioChoice={currentRadioChoice}
                        CurrentOtherValue={currentOtherValue}
                      handleChangeOther={changeRadioOther}
                    />
                    ) : (
                      <OptionGroupRadioWithImages
                      handleChange={changeRadioNew}
                        currentQuestion={props.currentQuestion}
                      currentRadioValue={currentRadioValue}
                      currentRadioChoice={currentRadioChoice}
                      CurrentOtherValue={currentOtherValue}
                        handleChangeOther={changeRadioOther}
                    />
                  )}
                  </Fragment>
              )}
              {props.currentQuestion.option_choices &&
                props.currentQuestion.question_type === "check" && (
                <Fragment>
                    {!props.currentQuestion.add_image_to_choices ? (
                    <OptionGroupCheckBox
                      handleChange={changeCheck}
                      currentQuestion={props.currentQuestion}
                        currentCheckValues={choiceCheckRequired}
                      CurrentOtherValueCheck={currentOtherValueCheck}
                      handleChangeOtherCheck={changeRadioOtherCheck}
                    />
                  ) : (
                      <OptionGroupCheckWithImages
                      handleChange={changeCheck}
                      currentQuestion={props.currentQuestion}
                        currentCheckValues={choiceCheckRequired}
                      CurrentOtherValueCheck={currentOtherValueCheck}
                      handleChangeOtherCheck={changeRadioOtherCheck}
                      />
                    )}
                </Fragment>
                )}
              {props.currentQuestion.question_type === "short_text" && (
                <input
                  type={
                    props.currentQuestion.numeric_only
                      ? "number"
                      : props.currentQuestion.url_only
                        ? "url"
                      : "text"
                  }
                  className="inputShort"
                  placeholder={props.currentQuestion.placeholder}
                  id={props.currentQuestion.id}
                  value={currentShortLongValue}
                  pattern={
                    props.currentQuestion.url_only ? "https?://.+" : null
                  }
                  onChange={e => {
                    changeShortLong("short", e, props.currentQuestion.id);
                  }}
                />
              )}

              {props.currentQuestion.question_type === "long_text" && (
                <textarea
                  type="text"
                  className="inputLong"
                  placeholder={props.currentQuestion.placeholder}
                  rows={props.currentQuestion.text_box_height}
                  id={props.currentQuestion.id}
                  value={currentShortLongValue}
                  onChange={e => {
                    changeShortLong("long", e, props.currentQuestion.id);
                  }}
                />
              )}
            </div>
            <footer>
              {props.design.switchBackButton === "true" &&
                props.questionsPassed > 0 && (
                <button className="buttonBack" onClick={() => backQuestion()}>
                  {props.design.back_button}
                </button>
                )}
              {props.design.switchNextButton === "true" &&
                props.currentQuestion.question_type === "radio" && (
                <button
                    className={`${
                    (props.currentQuestion.required && props.nextDisabled) ||
                      (props.nextOther && props.nextDisabled)
                      ? "nextDisabled"
                      : ""
                    } buttonNext`}
                  onClick={() => {
                    nextQuestionDisabledRadio(props.currentQuestion);
                    }}
                >
                    {props.design.next_button}
                </button>
                )}
              {props.design.switchNextButton === "false" &&
                props.currentQuestion.question_type === "radio" &&
                props.nextOtherAlone && (
                <button
                  className={`${
                    props.nextDisabled ? "nextDisabled" : ""
                    } buttonNext`}
                  onClick={() => {
                    nextQuestionDisabledRadio(props.currentQuestion);
                    }}
                >
                  {props.design.next_button}
                  </button>
              )}
              {props.currentQuestion.question_type !== "radio" &&
                !props.currentQuestion.required && (
                <button
                    className={`${
                    props.nextCheckOther || props.nextDisabledUrlShort
                      ? "nextDisabled"
                      : ""
                  } buttonNext`}
                    onClick={() => nextQuestion()}
                >
                  {props.design.next_button}
                </button>
                )}
              {props.currentQuestion.question_type !== "radio" &&
                props.currentQuestion.required && (
                  <button
                  className={`${
                      (props.currentQuestion.required && props.nextDisabled) ||
                      props.nextCheckOther
                      ? "nextDisabled"
                        : ""
                  } buttonNext`}
                  onClick={() => {
                      if (props.currentQuestion.question_type === "check") {
                      nextQuestionDisabledCheck();
                    } else {
                      nextQuestion();
                      }
                  }}
                >
                    {props.design.next_button}
                </button>
                )}
              {/* {props.nodesPath} -- {props.questionsPassed} --
              {props.nodesPath &&
                `${props.questionsPassed *
                  ((100) / (props.questionsPassed + props.nodesPath[1]))}%`} */}
            </footer>
          </div>
        )}

        {props.showLeadCapture &&
          !props.showQuestionWithoutLink &&
          Object.keys(props.leadCapture.pages[0].fields).length !== 0 && (
          <div
            id="newBody"
              css={css`
                background-color: white;
              `}
              dangerouslySetInnerHTML={{
              __html: `${contentPageLC}<style>${newStyleLC}${LCCss}`,
            }}
            ></div>
        )}
        {props.showLeadCapture &&
          !props.showQuestionWithoutLink &&
          Object.keys(props.leadCapture.pages[0].fields).length === 0 && (
          <div
              id="newBody"
            css={css`
                background: white;
              `}
          >
            <PlaceholderBuilder type="Lead" />
          </div>
          )}

        {props.showQuestionWithoutLink && (
          <div className="messageMissing">
            <div className="close" onClick={props.closeModal}>
              <img src={iconClose} alt="imgClose" />
            </div>
            <h2>Your Funnel Flow is missing connections.</h2>
            <p>It seems your Funnel Flow is broken.</p>
            <p>
              To fix it, connect the red nodes by clicking and dragging to other
              components in the Canvas.
            </p>
          </div>
        )}
      </div>
    </Dialog>
  );
};

StandartModal.propTypes = {
  design: PropTypes.any,
  questions: PropTypes.array.isRequired,
  showDialog: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.object,
  nextQuestion: PropTypes.func.isRequired,
  nextRadio: PropTypes.func.isRequired,
  showQuestions: PropTypes.bool.isRequired,
  showLeadCapture: PropTypes.bool.isRequired,
  leadCapture: PropTypes.object.isRequired,
  submitLeadCapture: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  nextQuestionDisabled: PropTypes.func.isRequired,
  nextQuestionDisabledSL: PropTypes.func.isRequired,
  changeShortLong: PropTypes.func.isRequired,
  nextCheck: PropTypes.func.isRequired,
  nextQuestionDisabledCheck: PropTypes.func.isRequired,
  backQuestion: PropTypes.func.isRequired,
  showQuestionWithoutLink: PropTypes.bool.isRequired,
  changeOtherValueCheck: PropTypes.func.isRequired,
};
// export default StandartModal;

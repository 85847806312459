/**@jsx jsx */
import { jsx } from "@emotion/core";
import iconClose from "../../../../assets/iconClose.svg";
import { container } from "../Question/Question.styled";
import { container2 } from "./QuestionWitoutLink.styled";

const QuestionWithoutLink = ({ designOptions = {}, isPreview, closeModal }) => {
  const newDesignOpt = {
    ...designOptions,
    width: "705px",
    backgroundColor: "rgba(250, 250, 250, 1)",
    boxShadow: "none",
  };
  return (
    <div
      className={`${
        designOptions.switchShadow === "true" ? "" : "disabledShadow"
      }`}
      css={[container({ designOptions: newDesignOpt, isPreview }), container2]}
    >
      <div className="close" onClick={closeModal}>
        <img src={iconClose} alt="imgClose" />
      </div>
      <div className="messageMissing">
        <h2>Your Funnel Flow is missing connections.</h2>
        <p>It seems your Funnel Flow is broken.</p>
        <p>
          To fix it, connect the red nodes by clicking and dragging to other
          components in the Canvas.
        </p>
      </div>
    </div>
  );
};

export default QuestionWithoutLink;

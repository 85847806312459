import { decode } from "html-entities";
export function getScriptTagFromString(str) {
  try {
    const strDecoded = decode(str);
    const regex = /<script.*?src=\\?"(.*?)\\?"/;
    const src = regex.exec(strDecoded)[1];
    const script = document.createElement("script");
    script.setAttribute("src", src);
    return script;
  } catch (e) {
    console.error(e);
    return null;
  }
}

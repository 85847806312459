/**@jsx jsx */
import { jsx, css } from "@emotion/core";
import approveUrlIcon from "../../assets/check-circle-tk.svg";
import cancelUrlIcon from "../../assets/warning-circle-tk.svg";
import PropTypes from "prop-types";

const styles = css`
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    background-color: #ffffff;
    width: 540px;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .icon {
      width: 52px;
      margin-bottom: 15px;
    }
    .headline {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #585858;
    }
    .subheadline {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #585858;
      margin-bottom: 16px;
    }
    .btn-back {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      background-color: #80bc3a;
      border: 1px solid #80bc3a;
      color: #ffffff;
      padding: 8px 16px;
      border-radius: 3px;
    }
  }
  @media (max-width: 450px) {
    .box {
      max-width: 90%;
      .icon {
        width: 38.4px;
      }
    }
  }
`;

const PayPalReturnPages = ({ type }) => {
  let icon = approveUrlIcon;
  let headline = "Payment successful";
  let subheadline = "Your payment process has been completed successfully";
  if (type !== "approve_url") {
    icon = cancelUrlIcon;
    headline = "An error has occurred";
    subheadline = "There was an error in the payment process, please try again";
  }

  window.close();
  const closeWindow = () => {
    window.open("", "_parent", "");
    window.close();
  };
  return (
    <div css={styles}>
      <div className="box">
        <img src={icon} alt={headline} className="icon" />
        <span className="headline">{headline}</span>
        <span className="subheadline">{subheadline}</span>
        <button onClick={closeWindow} className="btn-back">
          Return to previous page
        </button>
      </div>
    </div>
  );
};

PayPalReturnPages.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PayPalReturnPages;

import React from "react";
import "./App.css";
import ApplicationContaniner from "./Containers/ApplicationContainer";
import ApplicationContainer2 from "./Containers/ApplicationContainer2";
import SinglePageContainer from "./Containers/SinglePageContainer";
import ABTestingContainer from "./Containers/ABTestingContainer";
import PageContainer from "./Containers/PageContainer";
import LeadCaptureViewerContainer from "./Containers/LeadPageViewerContainer";
import { Page404 } from "./Components/404";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PaymentFormViewerContainer from "./Containers/PaymentFormViewerContainer";
import UpdateCreditCardStripe from "./Containers/UpdateCreditCardStripe";
import UpdateCreditCardAuthorize from "./Containers/UpdateCreditCardAuthorize/UpdateCreditCardAuthorize";
import UpdateCreditCardPaypal from "./Containers/UpdateCreditCardPaypal/UpdateCreditCardPaypal";
import PayPalReturnPages from "./Components/Pages/PayPalReturnPages";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/sf2/:verification_token"
          component={ApplicationContaniner}
        />
        <Route
          exact
          path="/sf/:verification_token"
          component={ApplicationContainer2}
        />
        <Route
          exact
          path="/page/:verification_token/:page_base64_id"
          component={PageContainer}
        />
        <Route
          exact
          path="/lead/:verification_token/:lead_base64_id"
          component={LeadCaptureViewerContainer}
        />
        <Route
          exact
          path="/payment_form/:verification_token/:lead_base64_id"
          component={PaymentFormViewerContainer}
        />
        <Route
          exact
          path="/ab/:verification_token"
          component={ABTestingContainer}
        />
        <Route
          exact
          path="/sp/:verification_token/:suffix?"
          component={SinglePageContainer}
        />

        <Route
          exact
          path="/update_credit_card/:token"
          component={UpdateCreditCardStripe}
        />

        <Route
          exact
          path="/update_profile_authorize_net/:token"
          component={UpdateCreditCardAuthorize}
        />

        <Route
          exact
          path="/update_paypal_billing/:token"
          component={UpdateCreditCardPaypal}
        />

        <Route
          exact
          path="/paypal_approve/:query?"
          component={() => <PayPalReturnPages type="approve_url" />}
        />
        <Route
          exact
          path="/paypal_cancel/:query?"
          component={() => <PayPalReturnPages type="cancel_url" />}
        />

        <Route path="/404" component={Page404} />
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

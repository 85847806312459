export const redirectWithUtmParameters = (
  passVariable,
  splitUrl,

  passVariableQuestion,
  passScore,
  outcomeType,
  quizScore,
  redirectLinkUtm,
  winnerLc,
  leadInfo,
) => {
  const a = document.querySelectorAll(
    'a.link-bk-button:not([link_type="commerce_sales_page"])',
  );
  a.forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      const href = el.getAttribute("href");
      const target = el.getAttribute("target") || "_self";
      let url = redirectLinkUtm(
        href,
        passVariable,
        splitUrl,
        passVariableQuestion,
        passScore,
        outcomeType,
        quizScore,
      );
      const utmArray = [];
      // debugger;
      if (
        passVariable &&
        winnerLc &&
        winnerLc.utm_parameters &&
        winnerLc.utm_parameters.length > 0
      ) {
        winnerLc.lead_capture_fields.forEach(field => {
          if (field.utm_parameters.length && field.utm_parameters[0].state) {
            const current_lcf = leadInfo.find(
              lcf => lcf.internal_id === field.internal_id,
            );
            if (current_lcf) {
              utmArray.push(
                `${field.utm_parameters[0].utm_tag}=${current_lcf.value}`,
              );
            }
          }
        });
        if (url.includes("?")) url = `${url}&${utmArray.join("&")}`;
        else url = `${url}?${utmArray.join("&")}`;
      }

      window.open(url, target);
    });
  });
};

/** @jsx jsx */
import { jsx } from "@emotion/core";
import * as styles from "./Pages.styles";

export const WelcomePage = ({
  isPreview,
  contentPage,
  showBannerEmptyPaymentGateway,
}) => {
  return (
    <div>
      <div
        css={styles.isPreview(isPreview || showBannerEmptyPaymentGateway)}
        dangerouslySetInnerHTML={{ __html: contentPage }}
      />
    </div>
  );
};

/**@jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { container, mainQuestionWrapper } from "./Question.styled";
import ProgressBar from "./ProgressBar/ProgressBar";
import $ from "jquery";
import iconClose from "../../../../assets/iconClose.svg";
import { Fragment, useEffect, useState } from "react";
import {
  OptionGroupCheckBox,
  OptionGroupCheckWithImages,
  OptionGroupRadio,
  OptionGroupRadioWithImages,
} from "../../..";
import urlRegex from "url-regex";
import Footer from "./Footer/Footer";

const Question = ({
  numBar,
  colorProgressBar,
  textColorProgressBar,
  closeModal,
  currentQuestion,
  designOptions = {},
  isPreview,
  changeOtherValue,
  nextRadio,
  nextCheck,
  changeOtherValueCheck,
  changeShortLong,
  backQuestion,
  nextQuestion,
  nextQuestionDisabled,
  nextQuestionDisabledCheck,
  ...rest
}) => {
  const [currentRadioValue, setCurrentRadioValue] = useState(null);
  const [choiceCheckRequired, setChoiceCheckRequired] = useState([]);
  const [currentRadioChoice, setCurrentRadioChoice] = useState(null);
  const [currentOtherValue, setCurrentOtherValue] = useState(null);
  const [currentOtherValueCheck, setCurrentOtherValueCheck] = useState(null);
  const [currentShortLongValue, setCurrentShortLongValue] = useState("");

  useEffect(() => {
    if (currentQuestion) {
      let temp = [];

      if (
        currentQuestion.question_type === "short_text" ||
        currentQuestion.question_type === "long_text"
      ) {
        setCurrentShortLongValue(currentQuestion.answerValue || "");
      }
      if (currentQuestion.question_type === "check") {
        temp = currentQuestion.option_choices.map(el => {
          return {
            id: el.internal_id,
            checked: false,
          };
        });
        //console.log(currentQuestion.answerValue);
        if (currentQuestion.answerValue !== null) {
          const otherChoices = currentQuestion.option_choices.find(el => {
            return el.other === true;
          });
          //console.log(otherChoices);
          setCurrentOtherValueCheck(otherChoices.answer);
        }
      }
      //console.log("----->>", currentQuestion.answerValue, temp);
      setChoiceCheckRequired(currentQuestion.answerValue || temp);
      setCurrentRadioValue(currentQuestion.answerValue || null);
      if (currentQuestion.question_type === "radio") {
        const choicesTempAnswer = currentQuestion.option_choices.find(el => {
          return el.internal_id === currentQuestion.answerValue;
        });
        setCurrentRadioChoice(choicesTempAnswer || null);
        //console.log("choice temp", choicesTempAnswer);
        if (choicesTempAnswer) {
          setCurrentOtherValue(choicesTempAnswer.answer);
        }
      }

      $(".question-container").animate({ scrollTop: "0px" }, 150);
    }
  }, [currentQuestion]);

  const changeRadioNew = e => {
    if (e.target.value) {
      const optionChoice = currentQuestion.option_choices.find(opt => {
        return opt.internal_id === e.target.value;
      });
      //console.log("TCL: optionChoice", optionChoice, e.target.value);
      if (window.navigator.onLine) {
        setCurrentRadioValue(e.target.value);
        setCurrentRadioChoice(optionChoice);
        setCurrentOtherValue(optionChoice.answer);
      } else {
        setCurrentRadioValue(null);
        setCurrentRadioChoice(null);
        setCurrentOtherValue(null);
      }

      nextRadio(e.target.value, optionChoice, currentQuestion);
      if (designOptions.switchNextButton === "false" && !optionChoice.other) {
        animationModalQuestions();
      }
    }
  };

  const animationModalQuestions = () => {
    $(".mainQuestionWrapper").addClass("aniModal");
    setTimeout(() => {
      $(".mainQuestionWrapper").removeClass("aniModal");
    }, 500);
  };

  const changeRadioOther = (e, choice) => {
    setCurrentOtherValue(e.target.value);
    changeOtherValue(e.target.value, choice);
  };

  const changeCheck = (e, item) => {
    const searchCheckItem = choiceCheckRequired.find(
      el => el.id === e.target.value,
    );
    //console.log(searchCheckItem);
    if (typeof searchCheckItem === "undefined") {
      setChoiceCheckRequired([
        ...choiceCheckRequired,
        {
          id: e.target.value,
          checked: e.target.checked,
        },
      ]);
    } else {
      searchCheckItem.checked = e.target.checked;
    }
    nextCheck(choiceCheckRequired, e, item, currentQuestion);
  };

  const changeRadioOtherCheck = (e, question) => {
    setCurrentOtherValueCheck(e.target.value);
    changeOtherValueCheck(e.target.value, question);
  };

  const changeShortLongValue = (e, id) => {
    if (e.target.type === "url") {
      if (
        urlRegex({ exact: true, strict: true }).test(e.target.value) === false
      ) {
        setCurrentShortLongValue(e.target.value, id);
        changeShortLong("", id, false, e.target.value);
      } else {
        setCurrentShortLongValue(e.target.value, id);
        changeShortLong(e.target.value, id, true);
      }
    } else {
      setCurrentShortLongValue(e.target.value, id);
      changeShortLong(e.target.value, id);
    }
  };

  const handleClickBackButton = () => {
    setChoiceCheckRequired([]);
    setCurrentShortLongValue("");
    backQuestion(); //Function Passed by Props from Application Container
    animationModalQuestions();
  };

  const handleNextQuestionDisabledRadio = radioQ => {
    nextQuestionDisabled(currentRadioChoice, radioQ, currentOtherValue); //Function Passed by Props from Application Container
    setCurrentOtherValue("");
    setCurrentShortLongValue("");
    animationModalQuestions();
  };

  const handleNextQuestionDisabledCheck = () => {
    nextQuestionDisabledCheck(choiceCheckRequired, currentOtherValueCheck);
    if (window.navigator.onLine) {
      setChoiceCheckRequired([]);
      setCurrentOtherValueCheck("");
      setCurrentShortLongValue("");
    }
    animationModalQuestions();
  };

  const defaultNextQuestion = () => {
    nextQuestion(choiceCheckRequired, currentOtherValueCheck);
    if (window.navigator.onLine) {
      setChoiceCheckRequired([]);
      setCurrentOtherValueCheck("");
      setCurrentShortLongValue("");
      if (document.getElementById(currentQuestion.id) !== null) {
        document.getElementById(currentQuestion.id).value = "";
      }
    }
    animationModalQuestions();
  };

  const reduceContentPadding = () => {
    return (
      designOptions.switchBackButton === "false" &&
      designOptions.switchNextButton === "false" &&
      currentQuestion.question_type === "radio"
    );
  };

  return (
    <div
      className={`question-container four ${
        designOptions.switchShadow === "true" ? "" : "disabledShadow"
      } padding-rows-${currentQuestion.choices_per_row}`}
      css={container({ designOptions, isPreview, currentQuestion })}
    >
      <div
        className="close"
        onClick={() => {
          closeModal();
          $(".mainQuestion").removeClass("four");
        }}
      >
        <img src={iconClose} alt="imgClose" />
      </div>
      <div
        className={`mainQuestionWrapper ${
          designOptions.switchProgressBar === "true" ? "" : "MT0"
        }`}
        css={mainQuestionWrapper({
          reduceContentPadding: reduceContentPadding(),
        })}
      >
        <div className="content">
          {designOptions.switchProgressBar === "true" && (
            <ProgressBar
              numBar={numBar}
              className="progress-bar"
              colorProgressBar={colorProgressBar}
              textColorProgressBar={textColorProgressBar}
            />
          )}

          {currentQuestion.use_image && currentQuestion.image_on_top && (
            <img
              className="mainImage on-top"
              alt="imgUseImage"
              src={currentQuestion.image_url}
            />
          )}
          {
            <Fragment>
              <div
                className="mainTitle"
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.name,
                }}
              />
              <div
                className="question-description"
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.description,
                }}
              />
            </Fragment>
          }
          {currentQuestion.use_image && !currentQuestion.image_on_top && (
            <img
              className="mainImage"
              alt="imgUseImage"
              src={currentQuestion.image_url}
            />
          )}

          {currentQuestion.option_choices &&
            currentQuestion.question_type === "radio" && (
              <Fragment>
                {!currentQuestion.add_image_to_choices ? (
                  <OptionGroupRadio
                    handleChange={changeRadioNew}
                    currentQuestion={currentQuestion}
                    currentRadioValue={currentRadioValue}
                    currentRadioChoice={currentRadioChoice}
                    CurrentOtherValue={currentOtherValue}
                    handleChangeOther={changeRadioOther}
                  />
                ) : (
                  <OptionGroupRadioWithImages
                    handleChange={changeRadioNew}
                    currentQuestion={currentQuestion}
                    currentRadioValue={currentRadioValue}
                    currentRadioChoice={currentRadioChoice}
                    CurrentOtherValue={currentOtherValue}
                    handleChangeOther={changeRadioOther}
                  />
                )}
              </Fragment>
            )}
          {currentQuestion.option_choices &&
            currentQuestion.question_type === "check" && (
              <Fragment>
                {!currentQuestion.add_image_to_choices ? (
                  <OptionGroupCheckBox
                    handleChange={changeCheck}
                    currentQuestion={currentQuestion}
                    currentCheckValues={choiceCheckRequired}
                    CurrentOtherValueCheck={currentOtherValueCheck}
                    handleChangeOtherCheck={changeRadioOtherCheck}
                  />
                ) : (
                  <OptionGroupCheckWithImages
                    handleChange={changeCheck}
                    currentQuestion={currentQuestion}
                    currentCheckValues={choiceCheckRequired}
                    CurrentOtherValueCheck={currentOtherValueCheck}
                    handleChangeOtherCheck={changeRadioOtherCheck}
                  />
                )}
              </Fragment>
            )}

          {currentQuestion.question_type === "short_text" && (
            <input
              type={
                currentQuestion.numeric_only
                  ? "number"
                  : currentQuestion.url_only
                  ? "url"
                  : "text"
              }
              className="inputShort"
              placeholder={currentQuestion.placeholder}
              id={currentQuestion.id}
              value={currentShortLongValue}
              pattern={currentQuestion.url_only ? "https?://.+" : null}
              onChange={e => {
                changeShortLongValue(e, currentQuestion.id);
              }}
            />
          )}

          {currentQuestion.question_type === "long_text" && (
            <textarea
              type="text"
              className="inputLong"
              placeholder={currentQuestion.placeholder}
              rows={currentQuestion.text_box_height}
              id={currentQuestion.id}
              value={currentShortLongValue}
              onChange={e => {
                changeShortLongValue(e, currentQuestion.id);
              }}
            />
          )}
        </div>
        <Footer
          designOptions={designOptions}
          currentQuestion={currentQuestion}
          nextQuestion={defaultNextQuestion}
          handleClickBackButton={handleClickBackButton}
          handleNextQuestionDisabledRadio={handleNextQuestionDisabledRadio}
          handleNextQuestionDisabledCheck={handleNextQuestionDisabledCheck}
          {...rest}
        />
      </div>
    </div>
  );
};

Question.propTypes = {
  numBar: PropTypes.string,
  colorProgressBar: PropTypes.string,
  textColorProgressBar: PropTypes.string,
  closeModal: PropTypes.func,
  currentQuestion: PropTypes.object,
  designOptions: PropTypes.object,
  isPreview: PropTypes.bool,
  changeOtherValue: PropTypes.func,
  nextRadio: PropTypes.func,
  nextCheck: PropTypes.func,
  changeOtherValueCheck: PropTypes.func,
  changeShortLong: PropTypes.func,
  backQuestion: PropTypes.func,
  questionsPassed: PropTypes.number,
  nextQuestionDisabled: PropTypes.func,
};
export default Question;

/**@jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment, useEffect } from "react";
import PlaceholderBuilder from "../placeholderBuilder/PlaceholderBuilder";

export const PostQuizPage = ({
  page,
  isPreview,
  postQuizHtml: html,
  showBannerEmptyPaymentGateway,
}) => {
  useEffect(() => {
    console.log(page);
  }, [page, html]);

  if (!page.fields.html)
    return <PlaceholderBuilder type="PostQuiz" isPreview={isPreview} />;

  return (
    <Fragment>
      <div
        css={css`
          padding-top: ${isPreview || showBannerEmptyPaymentGateway ? 60 : 0}px;
        `}
        className="thank-you-page"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Fragment>
  );
};

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import { Fragment } from "react";

export const OptionGroupCheckBox = ({
  currentQuestion,
  handleChange,
  currentCheckValues,
  CurrentOtherValueCheck,
  handleChangeOtherCheck,
}) => {
  console.log(currentCheckValues, Array.isArray(currentCheckValues));
  const checkItem = (e, item) => {
    console.log(e, currentCheckValues);
    handleChange(e, item);
  };
  const checksArray = internal_id => {
    console.log(currentCheckValues);
    if (!currentCheckValues.length || !Array.isArray(currentCheckValues))
      return false;
    const findCheck = currentCheckValues.filter(ck => {
      return ck.id === internal_id && ck.checked;
    });
    return findCheck.length > 0;
  };

  return (
    <FormControl
      component="fieldset"
      css={css`
        margin: 0;
        width: 100%;
        border: 1px solid red;
        .MuiTypography-body1 {
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          user-select: none;
          word-break: break-word;
        }
        .MuiFormControlLabel-label {
          width: 100%;
          min-height: 22px;
        }
        .MuiFormControlLabel-root {
          margin-bottom: 6px;
        }
        .MuiFormControlLabel-root {
          align-items: flex-start;
        }
        .MuiIconButton-label {
          input[type="radio"] {
            display: none !important;
          }
        }
        .MuiRadio-root {
          padding: 5px 10px;
          padding-right: 12px;
        }
        .MuiCheckbox-root {
          padding: 2px 5px;
          margin-left: 5px;
          margin-right: 7px;
        }
        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.7);
          font-size: 18px;
        }
      `}
    >
      <FormGroup className="options" name="options">
        {currentQuestion.option_choices.map(item => {
          return (
            <Fragment key={item.internal_id}>
              {!item.other && (
                <FormControlLabel
                  value={item.internal_id}
                  control={
                    <Checkbox
                      checked={checksArray(item.internal_id)}
                      onChange={e => checkItem(e, item)}
                      // onChange={e => handleChange(e, item)}
                      value={item.internal_id}
                    />
                  }
                  label={
                    <span
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    ></span>
                  }
                />
              )}
            </Fragment>
          );
        })}
        {currentQuestion.option_choices.map(item => {
          return (
            <Fragment key={item.internal_id}>
              {item.other && item.active && (
                <Fragment>
                  <FormControlLabel
                    css={css`
                      margin-bottom: 0 !important;
                    `}
                    control={
                      <Checkbox
                        checked={checksArray(item.internal_id)}
                        onChange={e => checkItem(e, item)}
                        // onChange={e => handleChange(e, item)}
                        value={item.internal_id}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></span>
                    }
                  />
                  {Array.isArray(currentCheckValues) &&
                    currentCheckValues.map(el => {
                      if (el.checked) {
                        const otherChecked = currentQuestion.option_choices.find(
                          opt => {
                            return opt.internal_id === el.id;
                          },
                        );
                        //console.log(otherChecked);
                        if (otherChecked.other === true) {
                          console.log("entro");
                          return (
                            <input
                              css={css`
                                margin-top: 10px;
                              `}
                              className="inputOther"
                              type="text"
                              value={CurrentOtherValueCheck}
                              placeholder={item.placeholder}
                              onChange={e =>
                                handleChangeOtherCheck(e, currentQuestion)
                              }
                            />
                          );
                        }
                      }
                      return null;
                    })}
                </Fragment>
              )}
            </Fragment>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

OptionGroupCheckBox.propTypes = {
  currentQuestion: PropTypes.object,
  currentCheckValues: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  CurrentOtherValueCheck: PropTypes.string,
  handleChangeOtherCheck: PropTypes.func.isRequired,
};

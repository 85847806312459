import { css } from "@emotion/core";

export const wrapper = isPreview => css`
  top: ${isPreview ? 80 : 0}px;
  position: relative;
  max-width: 770px;
  height: 497px;
  margin: 10% auto;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 1), 0px 0px 25px rgba(0, 0, 0, 1);
  border-radius: 10px;
  background-color: #ffffff;

  #newBody {
    display: inline-block;
    width: 100%;
    min-height: 497px;
  }

  .invalid {
    border-bottom: 2px solid red !important;
  }

  &.placeholder {
    overflow: hidden;
  }

  @media (max-width: 768px) {
    width: 90%;
    &.placeholder {
      overflow: auto;
    }
  }

  @media (max-width: 450px) {
    width: 80%;
  }

  @media (max-width: 330px) {
    height: 350px;
    top: ${isPreview ? 103 : 0}px;
  }

  @media (max-width: 1280px) and (max-height: 750px) {
    margin: 1% auto;
  }

  @media (min-width: 1281px) and (max-height: 750px) {
    margin: 1% auto;
    top: 70px;
  }

  @media (max-width: 830px) and (max-height: 450px) {
    min-height: 270px;
    max-height: 270px;
    margin: 1% auto;
    max-width: 90%;
  }

  @media (max-width: 830px) and (max-height: 330px) {
    min-height: 220px;
    max-height: 220px;
  }

  @media (max-height: 415px) {
    margin: 1% auto;
    min-height: 270px;
    max-height: 270px;
  }
`;

/* eslint no-eval: 0 */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { LoadingScreen } from "../Components";
import * as services from "./Services";
import { LCCss } from "./PagesCss";
import * as _ from "lodash";
import $ from "jquery";
import React, { Component } from "react";

import {
  getScriptTagFromString,
  insertPoweredByBucketFooter,
  renderIframeUrl,
  replaceBackgroundImagesByBackgrounds,
} from "../utils";

import { PreviewMessage } from "./ApplicationContainer.styled";

let isPreview = "";

class PaymentFormViewerContainer extends Component {
  state = {
    isLoadingInitialInfo: true,
    winnerLeadCapture: {},
    htmlPaymentForm: "",
  };

  componentDidMount = async () => {
    try {
      let response = null;
      const { verification_token, lead_base64_id } = this.props.match.params;
      isPreview =
        window.location.href.includes("preview.") === true ||
        window.location.href.includes("preview-2.") === true
          ? true
          : false;

      if (isPreview) response = await services.getPreview(verification_token);

      /* Validate Subscription Active */
      if (typeof response.data.subscription_active !== "undefined") {
        if (!response.data.subscription_active) {
          this.props.history.replace("/404");
          return;
        }
      }

      if (response.data.status === "inactive" && !isPreview) {
        window.location.href = "/404";
      }

      const contentCurrentLeadCapture = response.data.lead_captures.find(lc => {
        return lc.id === Number(atob(lead_base64_id));
      });

      if (typeof contentCurrentLeadCapture === "undefined") {
        throw new Error("Lead Capture Empty");
      }

      //**************** */

      // Anchor Animation
      this.anchorPostWelcome();
      window.stopRedirect = true;

      if (typeof contentCurrentLeadCapture !== "undefined") {
        let contentPageLCTemp = `${contentCurrentLeadCapture.pages[0].fields.html}`;
        let reLC = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

        let matchLC;
        let scripsListLC = [];
        while ((matchLC = reLC.exec(contentPageLCTemp))) {
          if (matchLC[1] === "") {
            const str = matchLC[0];
            const script = getScriptTagFromString(str);

            document.body.appendChild(script);
          }
          scripsListLC.push(matchLC[1]);
        }

        const newCss = replaceBackgroundImagesByBackgrounds(
          contentCurrentLeadCapture.pages[0].fields.css,
        );

        contentPageLCTemp = contentCurrentLeadCapture.pages[0].fields.html;
        // FOR VIMEO/YOUTUBE VIDEOS
        contentPageLCTemp = renderIframeUrl(contentPageLCTemp);

        contentPageLCTemp = `${contentPageLCTemp}<style>${newCss}${LCCss}`;

        this.setState(
          {
            isLoadingInitialInfo: false,
            htmlPaymentForm: contentPageLCTemp,
          },
          () => {
            try {
              scripsListLC.forEach(script => eval(script));
            } catch (e) {
              console.log(e);
            }
            response.data.powered_by_bucket && insertPoweredByBucketFooter();
          },
        );
      }
    } catch (e) {
      if (e.response && Number(e.response.status) === 404) {
        this.props.history.replace("/404");
      }
    }
  };

  scrollToAnchor = () => {
    const anchorUrl = _.split(window.location.href, "#");
    if (anchorUrl[1]) {
      let dest = `#${anchorUrl[1]}`;
      window.setTimeout(function() {
        const offsetTop = document.querySelector(dest)?.offsetTop;

        window.scroll({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  render() {
    return (
      <div>
        {this.state.isLoadingInitialInfo ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            {isPreview && !this.state.isInternet && (
              <PreviewMessage>
                <b>Note:</b> This is the bucket.io page preview mode, and this
                view does not represent a functional page.
              </PreviewMessage>
            )}

            <div
              css={css`
                padding-top: ${isPreview ? 60 : 0}px;
              `}
              dangerouslySetInnerHTML={{
                __html: this.state.htmlPaymentForm,
              }}
            ></div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PaymentFormViewerContainer;

PaymentFormViewerContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

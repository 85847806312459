/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Dialog } from "./Dialog2.styled";
import Question from "./Components/Question/Question";
import { modalContainerStatus } from "../../Containers/constants";
import QuestionWithoutLink from "./Components/QuestionWithoutLink";
import { LeadCapture, LeadCaptureEmpty } from "./Components/LeadCapture";

export const StandartModal2 = ({
  showDialog,
  designOptions,
  isPreview,
  nodesPath,
  closeModal,
  currentQuestion,
  modalStatus,
  leadCapture,
  winnerOutcome,
  quizScore,
  fieldLogicChoicesArray,
  fieldLogicArrayFull,
  submitLeadCapture,
  ...rest
}) => {
  const [designElements, setDesignElements] = useState({
    backgroundColor: "rgba(250,250,250,1)",
    width: "705px",
    backButtonBgColor: "rgba(244,179,1,1)",
    backButtonTextColor: "#585858",
    nextButtonBgColor: "rgba(244,179,1,1)",
    nextButtonTextColor: "#585858",
    boxShadow: "0px 0px 5px rgba(0,0,0,0), 0px 0px 25px rgba(0,0,0,0)",
  });

  useEffect(() => {
    if (
      typeof designOptions === "object" &&
      Object.keys(designOptions).length > 0
    ) {
      const {
        bgBox: { r = 250, g = 250, b = 250, a = 1 },
        width,
        selectWidth,
        back_button_color,
        next_button_color,
        back_button_text_color,
        next_button_text_color,
        shadow_x,
        shadow_y,
        shadowColor,
      } = designOptions;
      setDesignElements(prev => ({
        ...designOptions,
        ...prev,
        backgroundColor: `rgba(${r},${g},${b},${a})`,
        backButtonBgColor: `rgba(${back_button_color.r},${back_button_color.g},${back_button_color.b},${back_button_color.a})`,
        backButtonTextColor: back_button_text_color,
        nextButtonBgColor: `rgba(${next_button_color.r},${next_button_color.g},${next_button_color.b},${next_button_color.a})`,
        nextButtonTextColor: next_button_text_color,
        width: width + selectWidth,
        boxShadow: `${shadow_x}px ${shadow_y}px 5px rgba(${shadowColor.r},${shadowColor.g},${shadowColor.b},${shadowColor.a}), ${shadow_x}px ${shadow_y}px 25px rgba(${shadowColor.r},${shadowColor.g},${shadowColor.b},${shadowColor.a})`,
      }));
    }
  }, [designOptions]);

  return (
    <Dialog open={showDialog} designOptions={designElements}>
      {/* Questions */}
      {currentQuestion &&
        modalStatus === modalContainerStatus.SHOWING_QUESTIONS_MODAL && (
          <Question
            numBar={`${parseFloat(nodesPath).toFixed(0)}%`}
            colorProgressBar={designOptions.colorProgressBar}
            textColorProgressBar={designOptions.textColorProgressBar}
            closeModal={closeModal}
            currentQuestion={currentQuestion}
            designOptions={designElements}
            isPreview={isPreview}
            {...rest}
          />
        )}
      {modalStatus === modalContainerStatus.QUESTION_WITHOUT_LINK && (
        <QuestionWithoutLink
          designOptions={designElements}
          isPreview={isPreview}
          closeModal={closeModal}
        />
      )}
      {/* Lead capture */}
      {modalStatus === modalContainerStatus.SHOWING_LEAD_CAPTURE_MODAL && (
        <LeadCapture
          isPreview={isPreview}
          leadCapture={leadCapture}
          winnerOutcome={winnerOutcome}
          quizScore={quizScore}
          fieldLogicChoicesArray={fieldLogicChoicesArray}
          fieldLogicArrayFull={fieldLogicArrayFull}
          handleSubmitLeadCapture={submitLeadCapture}
        />
      )}
      {modalStatus ===
        modalContainerStatus.SHOWING_EMPTY_LEAD_CAPTURE_MODAL && (
        <LeadCaptureEmpty isPreview={isPreview} />
      )}
    </Dialog>
  );
};

StandartModal2.propTypes = {
  designOptions: PropTypes.any,
  showDialog: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  nodesPath: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
  currentQuestion: PropTypes.object,
  modalStatus: PropTypes.string,
};

export const replaceFieldLogicValues = (
  arrayFieldParams,
  html,
  arrayFieldLogicFull = [],
) => {
  let htmlWithFieldLogicValues = html;
  const arrayField = [...arrayFieldParams];
  if (arrayField.length > 0) {
    let tempHtml = html;
    arrayField.reverse().forEach(el => {
      const replace = `[${el.name}]`;
      tempHtml = tempHtml.split(replace).join(el.value);
    });
    htmlWithFieldLogicValues = tempHtml;
  }

  arrayFieldLogicFull.forEach(value => {
    htmlWithFieldLogicValues = htmlWithFieldLogicValues
      .split(`[${value}]`)
      .join("");
  });
  return htmlWithFieldLogicValues;
};

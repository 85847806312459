/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import ContentLoader from "react-content-loader";
export const LoadingScreen = () => (
  <div
    css={css`
      .desktop,
      .mobile {
        display: none;
      }
      @media (min-width: 769px) {
        .desktop {
          display: block;
        }
      }
      @media (max-width: 768px) {
        .mobile {
          display: block;
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    `}
  >
    <React.Fragment>
      <ContentLoader
        className="desktop"
        speed={2}
        width={1280}
        height={600}
        viewBox="0 0 1280 600"
        backgroundColor="#e9e9e9"
        foregroundColor="#f5f5f5"
      >
        <rect x="62" y="36" rx="10" ry="10" width="1162" height="24" />
        <rect x="516" y="100" rx="6" ry="6" width="708" height="20" />
        <rect x="518" y="139" rx="10" ry="10" width="339" height="20" />
        <rect x="880" y="138" rx="10" ry="10" width="263" height="21" />
        <rect x="517" y="177" rx="10" ry="10" width="223" height="21" />
        <rect x="761" y="177" rx="10" ry="10" width="420" height="21" />
        <rect x="524" y="317" rx="10" ry="10" width="286" height="57" />
        <rect x="66" y="98" rx="15" ry="15" width="417" height="277" />
        <rect x="66" y="419" rx="10" ry="10" width="1162" height="24" />
        <rect x="518" y="219" rx="10" ry="10" width="386" height="21" />
        <rect x="930" y="219" rx="10" ry="10" width="291" height="21" />
        <rect x="522" y="259" rx="10" ry="10" width="381" height="21" />
        <rect x="847" y="476" rx="14" ry="14" width="381" height="105" />
        <rect x="471" y="475" rx="14" ry="14" width="354" height="105" />
        <rect x="66" y="476" rx="14" ry="14" width="381" height="105" />
      </ContentLoader>
      <ContentLoader
        className="mobile"
        speed={2}
        width={350}
        height={730}
        viewBox="0 0 415 730"
        backgroundColor="#e9e9e9"
        foregroundColor="#f5f5f5"
      >
        <rect x="0" y="0" rx="10" ry="10" width="415" height="200" />
        <rect x="23" y="221" rx="6" ry="6" width="362" height="16" />
        <rect x="9" y="251" rx="10" ry="10" width="234" height="16" />
        <rect x="252" y="250" rx="10" ry="10" width="157" height="16" />
        <circle cx="77" cy="324" r="44" />
        <rect x="32" y="382" rx="10" ry="10" width="90" height="12" />
        <circle cx="204" cy="328" r="44" />
        <circle cx="329" cy="331" r="44" />
        <rect x="160" y="383" rx="10" ry="10" width="90" height="12" />
        <rect x="285" y="384" rx="10" ry="10" width="90" height="12" />
        <rect x="114" y="420" rx="11" ry="11" width="181" height="45" />
        <rect x="0" y="488" rx="10" ry="10" width="415" height="71" />
        <rect x="9" y="578" rx="6" ry="6" width="391" height="16" />
        <rect x="6" y="609" rx="10" ry="10" width="234" height="16" />
        <rect x="249" y="608" rx="10" ry="10" width="157" height="16" />
        <rect x="4" y="649" rx="11" ry="11" width="405" height="45" />
      </ContentLoader>
    </React.Fragment>
  </div>
);

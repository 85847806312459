/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import PropTypes from "prop-types";
import { Fragment } from "react";
import * as _ from "lodash";
import imgPlaceHolderChoice from "../../assets/placeholder-choice.svg";

export const OptionGroupRadioWithImages = ({
  currentQuestion,
  handleChange,
  currentRadioValue,
  currentRadioChoice,
  CurrentOtherValue,
  handleChangeOther,
}) => {
  const normalChoices = _.filter(currentQuestion.option_choices, el => {
    return el.other === false;
  });

  const otherChoices = _.filter(currentQuestion.option_choices, el => {
    return el.other === true;
  });
  return (
    <FormControl
      component="fieldset"
      css={css`
        margin: 0;
        width: 100%;
        border: 1px solid red;
        .MuiTypography-body1 {
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          word-break: break-word;
        }
        .MuiFormControlLabel-root,
        .MuiFormControlLabel-label {
          width: 100%;
          padding-top: 3px;
        }
        .MuiIconButton-label {
          input[type="radio"] {
            display: none !important;
          }
        }
        .MuiRadio-root {
          padding: 5px;
          margin-left: 5px;
          margin-right: 7px;
        }
        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.7);
          font-size: 18px;
        }
      `}
    >
      <RadioGroup
        className="options"
        name="options"
        css={css`
          display: inline !important;

          text-align: ${currentQuestion.choices_per_row === 1
            ? "center"
            : "inherit"};
        `}
        value={currentRadioValue}
        onClick={handleChange}
      >
        {normalChoices.map(item => {
          return (
            <div
              key={item.internal_id}
              className={`imagesChoices rows${currentQuestion.choices_per_row}`}
              css={css`
                width: min(calc(93% / ${currentQuestion.choices_per_row}));
              `}
            >
              {!item.other && (
                <Fragment>
                  {!currentQuestion.hide_text_to_choices ? (
                    <Fragment>
                      <label htmlFor={`radio_${item.id}`}>
                        <img
                          src={
                            item.image_url === null
                              ? imgPlaceHolderChoice
                              : item.image_url
                          }
                          alt="ImageChoice"
                          className="imgShow"
                        />
                      </label>
                      <FormControlLabel
                        value={item.internal_id}
                        control={
                          <Radio color="default" id={`radio_${item.id}`} />
                        }
                        label={
                          <span
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          ></span>
                        }
                      />
                    </Fragment>
                  ) : (
                    <FormControlLabel
                      style={{ marginLeft: 0 }}
                      value={item.internal_id}
                      control={
                        <Radio
                          color="default"
                          id={`radio_${item.id}`}
                          style={{ display: "none" }}
                        />
                      }
                      label={
                        <img
                          src={
                            item.image_url === null
                              ? imgPlaceHolderChoice
                              : item.image_url
                          }
                          alt="ImageChoice"
                          className="imgShow"
                          css={css`
                            border: ${currentQuestion.hide_text_to_choices &&
                            currentRadioValue &&
                            currentRadioValue === item.internal_id
                              ? "2px solid #2f80ed;"
                              : "none"};
                          `}
                        />
                      }
                    />
                  )}
                </Fragment>
              )}
            </div>
          );
        })}
        {otherChoices.map(item => {
          return (
            <div
              key={item.internal_id}
              className={`imagesChoices rows${currentQuestion.choices_per_row}`}
              css={css`
                width: calc(93% / ${currentQuestion.choices_per_row});
              `}
            >
              {item.other && item.active && (
                <Fragment>
                  {!currentQuestion.hide_text_to_choices ? (
                    <Fragment>
                      <label htmlFor={`radio_${item.id}`}>
                        <img
                          src={
                            item.image_url === null
                              ? imgPlaceHolderChoice
                              : item.image_url
                          }
                          alt="ImageChoice"
                          className="imgShow"
                        />
                      </label>
                      <FormControlLabel
                        value={item.internal_id}
                        control={
                          <Radio color="default" id={`radio_${item.id}`} />
                        }
                        label={
                          <span
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          ></span>
                        }
                      />
                    </Fragment>
                  ) : (
                    <FormControlLabel
                      style={{ marginLeft: 0 }}
                      value={item.internal_id}
                      control={
                        <Radio
                          color="default"
                          id={`radio_${item.id}`}
                          style={{ display: "none" }}
                        />
                      }
                      label={
                        <Fragment>
                          <img
                            src={
                              item.image_url === null
                                ? imgPlaceHolderChoice
                                : item.image_url
                            }
                            alt="ImageChoice"
                            className="imgShow"
                            css={css`
                              border: ${currentQuestion.hide_text_to_choices &&
                              currentRadioValue &&
                              currentRadioValue === item.internal_id
                                ? "2px solid #2f80ed;"
                                : "none"};
                            `}
                          />
                        </Fragment>
                      }
                    />
                  )}
                  {currentRadioChoice !== null &&
                    typeof currentRadioChoice !== "undefined" &&
                    currentRadioChoice.other === true && (
                      <input
                        css={css`
                          margin-top: 10px;
                        `}
                        className="inputOther"
                        type="text"
                        onClick={e => e.stopPropagation()}
                        placeholder={item.placeholder}
                        value={CurrentOtherValue}
                        onChange={e => handleChangeOther(e, currentRadioChoice)}
                      />
                    )}
                  {/* <input
                    css={css`
                      margin-top: 10px;
                    `}
                    className="inputOther"
                    type="text"
                    placeholder={item.placeholder}
                  /> */}
                </Fragment>
              )}
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

OptionGroupRadioWithImages.propTypes = {
  currentQuestion: PropTypes.object,
  currentRadioValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  currentRadioChoice: PropTypes.object,
  CurrentOtherValue: PropTypes.string,
  handleChangeOther: PropTypes.func.isRequired,
};

/**@jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import checkCircle from "../../assets/check-circle-tk.svg";
//Images
import lockSecureImage from "../../assets/secure-icon.svg";
import warningCircle from "../../assets/warning-circle-tk.svg";
import MetaInformation from "../../Components/MetaInformation/MetaInformation";
//Utils
import { getCurrencyFormat } from "../../utils";
//Services
import * as services from "../Services";
//Components
import PaymentMessaggeScreen from "../UpdateCreditCardStripe/PaymentMessageScreen";
import {
  CardUpdateScreenDesktop,
  CardUpdateScreenMobile,
} from "../UpdateCreditCardStripe/Placeholder";
import SubmitButton from "../UpdateCreditCardStripe/SubmitButton";
//Constants
import { currentStatePaypalScreen } from "./UpdateCreditCardPaypal.constants";
//Styles
import * as styles from "./UpdateCreditCardPaypal.styled";
import paypalZoidComponent from "./PaypalZoidComponent";

const initialValuesScreen = {
  relatedProductHeader: "Pending payment related to",
  headline: "Update Payment Method",
  subheadline:
    // eslint-disable-next-line max-len
    "Your new payment method will be applied to your pending payment and future billing dates. To perform the update you must do the following",
  buttonText: "Update Your Payment Method",
  footerBoxTitle: "For more information contact us here:",
};

const UpdateCreditCardPaypal = () => {
  const [ba_token, setBa_token] = useState("");
  const [data, setData] = useState({ invoice_info: {}, article: {} });
  const [currentState, setCurrentState] = useState(
    currentStatePaypalScreen.FETCH_DATA,
  );

  const token = useParams().token || null;
  const paypalNetInfoRef = useRef(null);
  const zoid = require("zoid");

  const imgUrl =
    "https://bucket-app-utils.s3.amazonaws.com/email-template/placeholder-invoice.png";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await services.verifyRecoveryToken(token, "paypal");
        const newData = {
          article: {
            image: getImage(response.data.article.image),
            name: response.data.article.name,
            price: getCurrencyFormat(
              response.data.article.currency,
              response.data.price / 100,
            ),
            number_of_installments: response.data.number_of_installments,
            current_installment: response.data.current_installment,
          },
          invoice_info: {
            image: getImage(response.data.provider_information.image),
            email_contact: response.data.provider_information.email,
            phone: response.data.provider_information.phone,
            address: response.data.provider_information.address,
            vat_id: response.data.provider_information.vat_id,
          },
          country_code: response.data.country_code,
          transactional_email_copys: getTransactionalEmailInfo(
            response.data.transactional_email,
          ),
        };
        setData(newData);
        setBa_token(response.data.approve_url.ba_token);
        paypalNetInfoRef.current = {
          paypalAccessToken: response.data.paypal_net_access_token,
          paymentMode: response.data.paymentMode,
          urlApproveBA: response.data.approve_url.url.href,
        };
        setCurrentState(currentStatePaypalScreen.IDLE);
      } catch (err) {
        console.log(err);
        setCurrentState(currentStatePaypalScreen.TOKEN_ERROR);
      }
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  const getTransactionalEmailInfo = data => {
    if (!data || (data && !data.credit_card_update_payment_body))
      return initialValuesScreen;

    let dataSaved = {};

    Object.keys(initialValuesScreen).forEach(element => {
      dataSaved[element] = data.credit_card_update_payment_body[
        element
      ].outer.replace("contenteditable", "");
    });
    return dataSaved;
  };

  const getImage = image => (!image ? imgUrl : image.url);

  const onSubmitForm = async e => {
    e.preventDefault();
    window.ZoidComponent = paypalZoidComponent;
    window
      .ZoidComponent({
        url: paypalNetInfoRef.current.urlApproveBA,
        onClose: async () => {
          try {
            setCurrentState(currentStatePaypalScreen.SUBMIT_FORM);
            await services.confirmUpdateProfilePaymentPaypal(token, ba_token);
            setCurrentState(currentStatePaypalScreen.PAYMENT_SUCCEDEED);
          } catch (e) {
            setCurrentState(currentStatePaypalScreen.IDLE);
            console.log(e);
          }
        },
      })
      .render("body", zoid.CONTEXT.POPUP)
      .catch(e => {
        console.log(e);
      });
  };

  if (currentState === currentStatePaypalScreen.PAYMENT_SUCCEDEED)
    return (
      <PaymentMessaggeScreen
        image={checkCircle}
        title="Well done!"
        description="Your payment method has been successfully updated."
      />
    );
  if (currentState === currentStatePaypalScreen.TOKEN_ERROR)
    return (
      <PaymentMessaggeScreen
        image={warningCircle}
        title="Update payment process is no longer available"
        description="This may be due to a wrong, expired or used token."
      />
    );

  return (
    <Fragment>
      <div css={styles.wrapperLoading({ currentState })}>
        <CardUpdateScreenDesktop className="loading-desktop" />
        <CardUpdateScreenMobile className="loading-mobile" />{" "}
      </div>
      <div css={styles.container({ currentState })}>
        <MetaInformation
          settings={{
            metaTitle: "Update Payment Method",
            metaDescription:
              "Your new payment method will be applied to your pending payment and upcoming billing dates.",
          }}
          metaImage={{ url: data.invoice_info.image }}
        />
        <Fragment>
          <header className={!data.invoice_info.image ? "lowHeader" : "header"}>
            <div className="wrapper">
              {data.invoice_info.image && (
                <img src={data.invoice_info.image} alt="Invoice Info" />
              )}
            </div>
          </header>
          <main>
            <div className="box">
              <div className="product-section">
                <div className="product-section__image">
                  <img src={data.article.image} alt="ArticleImage" />
                </div>
                <div className="product-section__info">
                  <p
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.transactional_email_copys?.relatedProductHeader,
                    }}
                  />
                  <p className="product-name">{data.article.name}</p>
                  <p className="installment-info">
                    Installment {data.article.current_installment} of{" "}
                    {data.article.number_of_installments} - {data.article.price}
                  </p>
                </div>
              </div>
              <div className="card-info-section">
                <h2
                  className="card-info-section__headline"
                  dangerouslySetInnerHTML={{
                    __html: data?.transactional_email_copys?.headline,
                  }}
                />
                <p
                  className="card-info-section__subheadline"
                  dangerouslySetInnerHTML={{
                    __html: data?.transactional_email_copys?.subheadline,
                  }}
                />
                <form
                  className="card-info-section__form"
                  onSubmit={onSubmitForm}
                >
                  <div className="buttons">
                    <SubmitButton
                      submitting={
                        currentState === currentStatePaypalScreen.SUBMIT_FORM
                      }
                      text={
                        data &&
                        data.transactional_email_copys &&
                        data.transactional_email_copys.buttonText
                      }
                    />
                  </div>
                </form>
                <div id="card-paypal-popup"></div>
                <div className="card-info-section__disclaimer">
                  <p
                    className="first-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: data?.transactional_email_copys?.footerBoxTitle,
                    }}
                  />
                  <p className="second-paragraph">
                    {data.invoice_info.email_contact} -{" "}
                    {data.invoice_info.phone} <br />
                    {data.invoice_info.address
                      ? `${data.invoice_info.address}`
                      : ""}{" "}
                    {data.invoice_info.vat_id
                      ? ` - TAX ID ${data.invoice_info.vat_id}`
                      : ""}
                  </p>
                </div>
                <div className="card-info-section__secure_image">
                  <img src={lockSecureImage} alt="secure" />
                  <span>Secure Server</span>
                </div>
              </div>
            </div>
          </main>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default UpdateCreditCardPaypal;

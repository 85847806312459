const fontSizeDesktop = `
        .american-typewriter-md{
          font-family:American Typewriter;
        }
        .american-typewriter{
          font-family:American Typewriter;
        }
        .avenir-next-regular{
          font-family:'AvenirNextLTW01-Regular';
        }
        .avenir-next-bold{
          font-family:'Avenir Next LT W01 Bold';
        }
        .merriweather{
          font-family: 'Merriweather', serif;
        }
        .abril-fatface{
          font-family: 'Abril Fatface', cursive;
        }
        .montaga{
          font-family: 'Montaga', serif;
        }
        .sansita-swashed{
          font-family: 'Sansita Swashed', cursive;
        }
        .fredoka-one{
          font-family: 'Fredoka One', cursive;
        }
        .passion-one{
          font-family: 'Passion One', cursive;
        }
        .bebas-neue{
          font-family: 'Bebas Neue', cursive;
        }
        .playball{
          font-family: 'Playball', cursive;
        }
        .allan{
          font-family: 'Allan', cursive;
        }
        .seaweed-script{
          font-family: 'Seaweed Script', cursive;
        }
        .dekko{
          font-family: 'Dekko', cursive;
        }
        .lato{
          font-family: 'Lato', sans-serif;
        }
        .poppins{
          font-family: 'Poppins', sans-serif;
        }
        .roboto-condensed{
          font-family: 'Roboto Condensed', sans-serif;
        }
        .Desktop-10{
          font-size: 10px;
        }
        .Desktop-13{
          font-size: 13px;
        }
        .Desktop-16{
          font-size: 16px;
        }
        .Desktop-18{
          font-size: 18px;
        }
        .Desktop-24{
          font-size: 24px;
        }
        .Desktop-32{
          font-size: 32px;
        }
        .Desktop-48{
          font-size: 48px;
        }
        .Desktop-text-left{
          text-align: left;
        }
        .Desktop-text-center{
          text-align: center;
        }
        .Desktop-text-right{
          text-align: right;
        }
        img{
          max-width:100%;
        }
        .webinar-list {
          display: flex;
          flex-direction: column;
        }
        .webinar-list__item {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: 1px solid #8a8a8a;
          border-radius: 5px;
          background-color: white;
          padding: 20px 21px 24px 19px;
          margin-bottom: 10px;
        }
        .webinar-list__item__title {
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #585858;
        }
        .webinar-list__item input[type="radio"] {
          width: 1px;
          height: 1px;
          display: inline-block;
          vertical-align: middle;
          opacity: 0;
          -webkit-appearance: none;
        }
        .webinar-list__item input[type="radio"]:checked + .radio {
          border-color: #ffa800;
        }
        .webinar-list__item input[type="radio"]:checked + .radio::after {
          background-color: #ffa800;
        }
        .webinar-list__item .radio {
          position: relative;
          width: 18px;
          height: 18px;
          border: 1px solid #8a8a8a;
          border-radius: 50%;
          margin-right: 15px;
          flex-shrink: 0;
          transition: border-color 0.3s ease;
        }
        .webinar-list__item .radio::after {
          content: "";
          position: absolute;
          top: 4px;
          right: 4px;
          bottom: 4px;
          left: 4px;
          background-color: transparent;
          border-radius: 50%;
          transition: background-color 0.3s ease;
        }
        /*tablets, ipads (portrait)*/
        @media (min-width: 768px) and (max-width: 925px){
          .Tablet-10{
            font-size: 10px!important;
          }
          .Tablet-13{
            font-size: 13px!important;
          }
          .Tablet-16{
            font-size: 16px!important;
          }
          .Tablet-18{
            font-size: 18px!important;
          }
          .Tablet-24{
            font-size: 24px!important;
          }
          .Tablet-32{
            font-size: 32px!important;
          }
          .Tablet-48{
            font-size: 48px!important;
          }
          .Tablet-text-left{
            text-align: left;
          }
          .Tablet-text-center{
            text-align: center;
          }
          .Tablet-text-right{
            text-align: right;
          }
        }
        /*smartphones mobiles (portrait)*/
        @media (min-width: 360px) and (max-width: 480px){
          #wrapper.empty{
            background-image: url('http://d3o6h87cto14vv.cloudfront.net/BUILDER_Mobile_Placeholder.svg');
          }
          .Mobile-360-10{
            font-size: 10px!important;
          }
          .Mobile-360-13{
            font-size: 13px!important;
          }
          .Mobile-360-16{
            font-size: 16px!important;
          }
          .Mobile-360-18{
            font-size: 18px!important;
          }
          .Mobile-360-24{
            font-size: 24px!important;
          }
          .Mobile-360-32{
            font-size: 32px!important;
          }
          .Mobile-360-48{
            font-size: 48px!important;
          }
          .Mobile-360-text-left{
            text-align: left;
          }
          .Mobile-360-text-center{
            text-align: center;
          }
          .Mobile-360-text-right{
            text-align: right;
          }
        }`;

export const welcomeCss = `${fontSizeDesktop}.text-center {text-align: center;justify-content: center;}.text-left {text-align: left;justify-content: flex-start;}.text-right {text-align: right;justify-content: flex-end;}.arial-black {
        font-family: Arial Black, Gadget, sans-serif;
      }
      .arial {
        font-family: Arial, Helvetica, sans-serif;
      }
      .brush {
        font-family: Brush Script MT, sans-serif;
      }
      .bomic {
        font-family: Comic Sans MS, cursive, sans-serif;
      }
      .courier-new {
        font-family: Courier New, Courier, monospace;
      }
      .georgia {
        font-family: Georgia, serif;
      }
      .helvetica {
        font-family: Helvetica, serif;
      }
      .impact {
        font-family: Impact, Charcoal, sans-serif;
      }
      .lucida-sans {
        font-family: Lucida Sans Unicode, Lucida Grande, sans-serif;
      }
      .tahoma {
        font-family: Tahoma, Geneva, sans-serif;
      }
      .times-new-roman {
        font-family: Times New Roman, Times, serif;
      }
      .trebuchet {
        font-family: Trebuchet MS, Helvetica, sans-serif;
      }
      .verdana {
        font-family: Verdana, Geneva, sans-serif;
      }
      .btn-builder {
        min-width: 100px;
        padding: 10px;
        font-size: 10px;
        display: block;
        margin: 0 auto;
        font-family: Helvetica, serif;
        cursor:pointer;
      }
      .btn-builder:hover, .btn-builder2:hover, .link-bk-button:hover, .buttonWebinarPageRegister:hover, .outcome-next-button:hover, .leadCaptureForm .buttonLeadCapture:hover{
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
      .embed-video-wrapper {
        width: 100%;
        height: 100%;
      }
      .embed-video-wrapper iframe {
        width: 100%;
        height: 100%;
      }

      .product-info, .button-single-product{
        cursor:pointer;
      }
      .button-single-product p{
        margin: 0;
      }
      </style>`;

export const PostQuizCss = `${fontSizeDesktop}.text-center {text-align: center;justify-content: center;}.text-left {text-align: left;justify-content: flex-start;}.text-right {text-align: right;justify-content: flex-end;}.arial-black {
              font-family: Arial Black, Gadget, sans-serif;
            }
            .arial {
              font-family: Arial, Helvetica, sans-serif;
            }
            .brush {
              font-family: Brush Script MT, sans-serif;
            }
            .bomic {
              font-family: Comic Sans MS, cursive, sans-serif;
            }
            .courier-new {
              font-family: Courier New, Courier, monospace;
            }
            .georgia {
              font-family: Georgia, serif;
            }
            .helvetica {
              font-family: Helvetica, serif;
            }
            .impact {
              font-family: Impact, Charcoal, sans-serif;
            }
            .lucida-sans {
              font-family: Lucida Sans Unicode, Lucida Grande, sans-serif;
            }
            .tahoma {
              font-family: Tahoma, Geneva, sans-serif;
            }
            .times-new-roman {
              font-family: Times New Roman, Times, serif;
            }
            .trebuchet {
              font-family: Trebuchet MS, Helvetica, sans-serif;
            }
            .verdana {
              font-family: Verdana, Geneva, sans-serif;
            }
            .btn-builder {
              min-width: 100px;
              padding: 10px;
              font-size: 10px;
              display: block;
              margin: 0 auto;
              font-family: Helvetica, serif;
              cursor:pointer;
            }
            .btn-builder:hover, .btn-builder2:hover, .link-bk-button:hover, .buttonWebinarPageRegister:hover, .outcome-next-button:hover, .leadCaptureForm .buttonLeadCapture:hover{
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
            .embed-video-wrapper {
              width: 100%;
              height: 100%;
            }
            .embed-video-wrapper iframe {
              width: 100%;
              height: 100%;
            }
            .product-info, .button-single-product{
              cursor:pointer;
            }
            .button-single-product p{
              margin: 0;
            }
            a[link_type="commerce_one_click"]{
              cursor:pointer;
            }
            </style>`;

export const LCCss = `${fontSizeDesktop}img {max-width: 100%;}
                        .card-elements div:first-child {
                          width: 100%;
                        }
                        .embed-video-wrapper {
                          width: 100%;
                          height: 100%;
                        }
                        .embed-video-wrapper iframe {
                          width: 100%;
                          height: 100%;
                        }
                        .authorize_net-card-fields .card-number-container input{
                        width: 100%;
                        }
                        .authorize_net-card-fields .card-date-cvv-container{
                          display: inline-flex;
                          width: 100%;
                          justify-content: space-between;
                          gap: 5px;
                        }
                        .authorize_net-card-fields .card-date-cvv-container input{
                          width: 50%;
                        }
                        .authorize_net-card-fields input::-webkit-input-placeholder{
                          color: rgb(189, 189, 189);
                        }
                        .button.buttonPaymentForm.disabled{
                          background: #ccc!important;
                          cursor: not-allowed;
                        }
                        .authorize_net-card-fields p.message-authorize_net{
                          font-size: 13px;
                          font-weight: 300;
                          line-height: 18px;
                          color: red!important;
                        }
                        a[link_type='commerce_one_click'].disabled{
                          pointer-events: none;
                        }
                        a.btn-one-click[link_type='commerce_one_click'].disabled{
                          background: #ccc!important;
                        }
                </style>`;

/**@jsx jsx */
import { jsx } from "@emotion/core";
import PlaceholderBuilder from "../../../placeholderBuilder/PlaceholderBuilder";
import { wrapper } from "./LeadCapture.styled";

export const LeadCaptureEmpty = ({ isPreview }) => {
  return (
    <div className="placeholder" css={wrapper(isPreview)}>
      <PlaceholderBuilder type="Lead" />
    </div>
  );
};

export const applicationContainerStatus = {
  IDLE: "IDLE",
  FETCHING_DATA: "FETCHING_DATA",
  EMPTY_CANVAS: "EMPTY_CANVAS",
  WELCOME_PAGE_EMPTY: "WELCOME_PAGE_EMPTY",
  SHOWING_WELCOME_PAGE: "SHOWING_WELCOME_PAGE",
  SHOWING_WELCOME_PAGE_WITH_MODAL: "SHOWING_WELCOME_PAGE_WITH_MODAL",
  SHOWING_POSTQUIZ_PAGE: "SHOWING_POSTQUIZ_PAGE",
  SHOWING_PAYMENTFORM_PAGE: "SHOWING_PAYMENTFORM_PAGE",
  SHOWING_EMPTY_PAYMENTFORM_PAGE: "SHOWING_EMPTY_PAYMENTFORM_PAGE",
  SHOWING_DEFAULT_WEBINAR_THANKYOU_PAGE:
    "SHOWING_DEFAULT_WEBINAR_THANKYOU_PAGE",
};

export const defaultDesignOptions = [
  { option: "switchShadow", value: "true" },
  { option: "switchProgressBar", value: "true" },
  { option: "colorProgressBar", value: "#F4b301" },
  { option: "valueProgressBar", value: 50 },
  { option: "switchNextButton", value: "true" },
  { option: "next_button_text_color", value: "#585858" },
  { option: "next_button", value: "Next" },
  { option: "switchBackButton", value: "true" },
  { option: "back_button_text_color", value: "#585858" },
  { option: "back_button", value: "Back" },
];

export const modalContainerStatus = {
  IDLE: "IDLE",
  QUESTION_WITHOUT_LINK: "QUESTION_WITHOUT_LINK",
  ALL_QUESTIONS_CONNECTED: "ALL_QUESTIONS_CONNECTED",
  SHOWING_LEAD_CAPTURE_MODAL: "SHOWING_LEAD_CAPTURE_MODAL",
  SHOWING_EMPTY_LEAD_CAPTURE_MODAL: "SHOWING_EMPTY_LEAD_CAPTURE_MODAL",
  SHOWING_QUESTIONS_MODAL: "SHOWING_QUESTIONS_MODAL",
};

export const internetConnectionStatus = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const checkWebinarWhenOnlyOneExist = () => {
  const webinarsCount = document.querySelectorAll(".webinar-list__item");
  if (webinarsCount.length === 1) {
    try {
      webinarsCount[0].querySelector("input").checked = true;
    } catch (e) {
      console.error(e);
    }
  }
};

/**@jsx jsx */
import { jsx, css } from "@emotion/core";

const styleButton = css`
  background: #80bc3a;
  border: 1px solid #80bc3a;
  border-radius: 3px;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  padding: 8px 16px;
  cursor: pointer;
  &:disabled {
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    cursor: wait;
  }
`;

const SubmitButton = ({ submitting, text }) => {
  return (
    <button
      disabled={submitting}
      css={styleButton}
      dangerouslySetInnerHTML={{ __html: text }}
    ></button>
  );
};

export default SubmitButton;

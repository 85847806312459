import toastr from "toastr";
import "toastr/build/toastr.min.css";

const DEFAULT_OPTS = {
  positionClass: "toast-top-center",
  preventDuplicates: true,
};

function makeToaster(type) {
  const toastFn = toastr[type];

  return function makeToast(toastOpts) {
    const { message, opts = {} } = toastOpts;

    toastr.options = {
      ...DEFAULT_OPTS,
      ...opts,
    };
    toastFn(message);
  };
}

export const toastSuccess = makeToaster("success");
export const toastError = makeToaster("error");

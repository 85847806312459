import { getCurrencyFormat } from "./getCurrencyFormat";
import { getNewDataProductWithPaymentPlan } from "./getNewProductDataWithPaymentPlan";

export const replaceCustomVariables = (
  html = "",
  winnerOutcome,
  quizScore,
  type,
  lcFields,
  lcAllFields = [],
  purchasedProducts = [],
  page = null,
  taxesTotal = 0,
  currencyName,
) => {
  const imgUrl =
    "https://bucket-app-utils.s3.amazonaws.com/email-template/placeholder-invoice.png";
  //Remove All draggable attribute
  const htmlDom = new DOMParser().parseFromString(html, "text/html");
  const draggableElements = htmlDom.querySelectorAll("[draggable]");
  draggableElements.forEach(element => {
    element.removeAttribute("draggable");
  });
  html = htmlDom.body.innerHTML;
  //For Thank You Page (Order Confirmation)
  //Filter Empty Elements and Null Elements
  purchasedProducts = purchasedProducts.filter(
    p => p && Object.keys(p).length > 0,
  );

  if (purchasedProducts.length) {
    //Replace Total Amount
    let sum = 0;
    //Replace Products
    const htmlDom = new DOMParser().parseFromString(html, "text/html");
    const products = htmlDom.body.querySelectorAll(".product-detail");
    if (products) {
      products.forEach(product => {
        const defaultItem = product.outerHTML;
        let newProducts = "";
        purchasedProducts.forEach(product => {
          const { name, description } = product;
          let image =
            typeof product.image === "string"
              ? product.image
              : product.image.url;

          if (!image) {
            image = imgUrl;
          }

          if (product.prices_plan && product.prices_plan.length) {
            product.has_plan = true;
          }

          const {
            price: new_unit_price,
            paymentPlanDescription,
          } = getNewDataProductWithPaymentPlan(product, currencyName);

          sum += new_unit_price;

          newProducts += defaultItem
            .replace(/\[PRODUCT_NAME\]/g, name)
            .replace(
              /\[PRODUCT_PRICE\]/g,
              `${getCurrencyFormat(currencyName, new_unit_price)}`,
            )
            .replace(/src\s*=\s*\\*"(.+?)\\*"\s*/g, `src="${image}"`)
            .replace(/\[PRODUCT_DESCRIPTION\]/g, description)
            .replace(
              /\[PRODUCT_PAYMENT_PLAN_DESCRIPTION\]/g,
              paymentPlanDescription,
            );
        });
        product.outerHTML = newProducts;
        html = htmlDom.body.innerHTML;
      });
    }
    html = html.replace(
      /\[TAXES\]/g,
      `${getCurrencyFormat(currencyName, taxesTotal)}`,
    );
    html = html.replace(
      /\[TOTAL_PURCHASED\]/g,
      `${getCurrencyFormat(currencyName, sum + taxesTotal)}`,
    );
  }

  //Replace Product Price (Sales Page and OneClick Page)
  if (page && page.fields && page.fields.products_detail) {
    const classNameProductBox =
      page.page_type === "commerce_one_click"
        ? "product-info-oneclick"
        : "product-info";

    const htmlDom = new DOMParser().parseFromString(html, "text/html");
    const selection = htmlDom.body.getElementsByClassName(classNameProductBox);
    const elements = Array.prototype.slice.call(selection);
    elements.forEach(el => {
      if (el.dataset["productId"]) {
        const product = page.fields.products_detail.find(
          product => product.id === Number(el.dataset["productId"]),
        );
        let new_price = product.unit_price;
        if (product.prices_plan && product.prices_plan.length) {
          product.has_plan = true;
          const {
            price,
            paymentPlanDescription,
          } = getNewDataProductWithPaymentPlan(product, currencyName);
          new_price = price;
          el.innerHTML = el.innerHTML.replace(
            /\[PAYMENT_PLAN_DETAIL\]/g,
            paymentPlanDescription,
          );
        }
        const image = el.querySelector(".product-image");
        if (image) {
          image.src = image.src.includes("/static/media") ? imgUrl : image.src;
        }

        el.innerHTML = el.innerHTML.replace(
          /\[PRODUCT_PRICE\]/g,
          `${getCurrencyFormat(currencyName, new_price)}`,
        );
      }
    });
    html = htmlDom.body.innerHTML;
  }

  //Replace ORDER DATE
  html = html.replace(/\[ORDER_DATE\]/g, new Date().toLocaleDateString());
  //Replace SCORE
  if (winnerOutcome) html = html.replace(/\[BUCKET\]/g, winnerOutcome.name);
  //Replace Winner Outcome
  html = html.replace(/\[QUIZ_SCORE\]/g, quizScore);
  if (type === "page") {
    //Replace Lead Capture Fields
    lcFields.forEach(item => {
      // eslint-disable-next-line
          const regex = new RegExp(`\\[${item.key}\]`, "g");
      html = html.replace(regex, item.value);
    });
    //Replace lead-capture-field empties
    lcAllFields.forEach(value => {
      html = html.split(`[${value}]`).join("");
    });
  }
  return html;
};

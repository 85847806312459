export function replaceBackgroundImagesByBackgrounds(css) {
  if(!css) return css;

  // Insert [enter] after every (;)
  const cssWithEnter = css.split(";").join(";\n");

  let regexLinearGradient = /background-image:linear-gradient((.*));/g;
  const linearGradientValues = cssWithEnter.match(regexLinearGradient);

  linearGradientValues && linearGradientValues.forEach(linearGradientValue => {
    const colorValues = getColorsFromLinearGradientString(linearGradientValue);
    //Compare if all values are the same
    if (colorValues.every(colorValue => colorValue === colorValues[0])) {
      css = css.replace(linearGradientValue, `background:${colorValues[0]};`);
    }
  });

  return css;
}

export function getColorsFromLinearGradientString(str) {
  let regex = /rgba\([^()]*\)|rgb\([^()]*\)/g;
  return str.match(regex);
}

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as _ from "lodash";
import PropTypes from "prop-types";

//Components
import PlaceholderBuilder from "../../Components/placeholderBuilder/PlaceholderBuilder";
import { Dialog } from "./DialogLeadCapture.styled";

//Utils
import {
  renderIframeUrl,
  checkWebinarWhenOnlyOneExist,
  replaceWebinarDateToLocalDate,
  replaceBackgroundImagesByBackgrounds,
} from "../../utils";

//Styles
import { LCCss } from "../../Containers/PagesCss";

let newStyleLC = "";
let contentPageLC = "";

export const DialogLeadCaptureViewer = props => {
  if (props.showLeadCapture) {
    const newCss = replaceBackgroundImagesByBackgrounds(
      props.leadCapture.pages[0].fields.css,
    );
    newStyleLC = _.replace(newCss, new RegExp("body{", "g"), "#newBody{");

    contentPageLC = props.leadCapture.pages[0].fields.html;
    // FOR VIMEO/YOUTUBE VIDEOS
    contentPageLC = renderIframeUrl(contentPageLC);

    setTimeout(() => {
      checkWebinarWhenOnlyOneExist();
      replaceWebinarDateToLocalDate();
    }, 100);
  }

  return (
    <Dialog
      open={props.showDialog}
      newDesignOpt={props.design}
      isNewPreview={props.isNewPreview}
    >
      <div
        className={` mainLeadCapture ${
          props.design.switchShadow === "true" ? "" : "disabledShadow"
        } `}
      >
        {props.showLeadCapture &&
          Object.keys(props.leadCapture.pages[0].fields).length !== 0 && (
          <div
              id="newBody"
              css={css`
                background-color: white;
              `}
            dangerouslySetInnerHTML={{
              __html: `${contentPageLC}<style>${newStyleLC}${LCCss}`,
            }}
          ></div>
          )}
        {props.showLeadCapture &&
          Object.keys(props.leadCapture.pages[0].fields).length === 0 && (
            <div
            id="newBody"
            css={css`
                background: white;
              `}
          >
              <PlaceholderBuilder type="Lead" />
          </div>
          )}
      </div>
    </Dialog>
  );
};

DialogLeadCaptureViewer.propTypes = {
  design: PropTypes.any,
  showDialog: PropTypes.bool.isRequired,
  showLeadCapture: PropTypes.bool.isRequired,
  leadCapture: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
// export default DialogLeadCaptureViewer;

import { css } from "@emotion/core";
import { keyframes } from "@emotion/core";

const blowUpContent = keyframes`
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
`;

const modalAni = keyframes`
  0%{ opacity: 0;  }
  25%{ opacity: 0.25;  }
  50%{ opacity: 0.50;  }
  75%{ opacity: 0.75;  }
  100{ opacity: 1;  }
`;

export const container = props => css`
  top: ${props.isPreview ? 80 : 0}px;
  max-width: ${props.designOptions.width};
  min-height: 325px;
  max-height: ${props.currentQuestion &&
  props.currentQuestion.choices_per_row === 1
    ? 605
    : 535}px;
  overflow-y: auto;
  position: relative;
  margin: 10% auto;
  padding: 30px 50px 20px 50px;
  border-radius: 10px;
  background-color: ${props.designOptions.backgroundColor};
  box-shadow: ${props.designOptions.boxShadow};
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;

  @media (min-height: 416px) and (max-height: 768px) {
    margin: 1% auto;
  }

  @media (max-height: 415px) {
    margin: 1% auto;
    min-height: 270px;
    max-height: 270px;
  }

  .mainQuestionWrapper.MT0 {
    .mainTitle {
      margin-top: 15px;
    }
  }

  &.disabledShadow {
    box-shadow: none;
  }

  .mainTitle {
    margin-top: 22px;
    margin-bottom: 3px;
  }
  .question-description {
    margin-bottom: 22px;
  }

  .simpleChoices {
    margin-bottom: 18px;
  }

  .imagesChoices {
    display: inline-block;
    vertical-align: top;
    ${""}

    .MuiFormControlLabel-root {
      align-items: flex-start;

      .MuiRadio-root {
        padding-top: 3px;
      }

      .MuiCheckbox-root {
        padding-top: 3px;
      }
    }

    .imgShow {
      width: 100%;
      height: auto;
      max-width: 300px;
      cursor: pointer;
    }
    &.rows4 {
      margin: 5px;
    }

    &.rows3 {
      margin: 6px;
    }

    &.rows2 {
      margin: 10px;
    }
    &.rows1 {
      margin: 0px;
      .MuiFormControlLabel-label,
      .MuiFormControlLabel-root {
        padding-top: 0;
      }
      .imgShow {
        max-width: 310px;
      }
    }

    @media (max-width: 450px) {
      width: 47%;
      margin: 0px 3px 15px 3px !important;
      &.rows1 {
        width: 100%;
        margin: 0 !important;
        .MuiFormControlLabel-root {
          padding: 0;
        }
        img.imgShow {
          max-width: 100%;
        }
      }
    }

    @media (max-width: 330px) {
      width: 100%;
      margin: 0px 0px 15px 0px !important;
    }
  }

  .inputShort {
    margin-top: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    height: 35px;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    box-shadow: none;
  }

  .inputLong {
    margin-top: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    padding: 10px;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    resize: none;
  }

  .inputOther {
    position: relative;
    width: 85%;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    padding: 9px 10px;
    box-sizing: border-box;
    height: auto;
    text-align: left;
    margin-left: 5px;
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
  }

  @media (max-width: 450px) {
    width: 80%;
    padding: 30px 30px 20px 30px;
    margin: 3% auto;
    max-height: ${props.currentQuestion &&
    props.currentQuestion.choices_per_row === 1
      ? 640
      : 535}px;
    &.padding-rows-1 {
      padding: 10px;
      .MuiFormControlLabel-root {
        margin: 0;
      }
      .mainTitle {
        padding: 0 2px;
      }
    }
  }

  @media (max-width: 330px) {
    margin: 10% auto;
  }

  input[type="radio"] {
    .ease-out;
    -webkit-appearance: none;
    background-color: #ff;
    border: 2px solid #000;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    position: relative;
    outline: none;
    width: 16px;
    vertical-align: text-bottom;

    &:after {
      .ease-out;
      background-color: #ff;
      border-radius: 50%;
      content: "";
      height: 16px;
      left: -2px;
      position: absolute;
      pointer-events: none;
      top: -2px;
      .transform(scale(0));
      .transform-origin(50% 50%);
      overflow: hidden;
      width: 16px;
    }

    &:checked:after {
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      background-color: #000;
      border-color: #000;
      transform: scale(0.5);
    }
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
  }

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type="checkbox"]:not(:checked) + div,
  input[type="checkbox"]:checked + div {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }

  input[type="checkbox"]:not(:checked) + div:before,
  input[type="checkbox"]:checked + div:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0.875em;
    height: 0.875em;
    border: 2px solid black;
    background: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  input[type="checkbox"]:not(:checked) + div:after,
  input[type="checkbox"]:checked + div:after {
    content: "✓";
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 1.2em;
    line-height: 0.8;
    color: black;
    transition: all 0.2s;
    font-family: "Open Sans", sans-serif;
  }

  input[type="checkbox"]:not(:checked) + div:after {
    opacity: 0;
    transform: scale(0);
  }
  input[type="checkbox"]:checked + div:after {
    opacity: 1;
    transform: scale(1);
  }

  input[type="checkbox"]:disabled:not(:checked) + div:before,
  input[type="checkbox"]:disabled:checked + div:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  input[type="checkbox"]:disabled:checked + div:after {
    color: #999;
  }
  input[type="checkbox"]:disabled + div {
    color: #aaa;
  }

  &.four {
    position: relative;
    z-index: 1;
    animation: ${blowUpContent} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &.padding-rows-1 {
    padding: 8px;
    .question-description {
      margin-bottom: 11px;
    }
    .progress-bar {
      width: calc(100% - 40px);
      margin-top: 25px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .mainImage {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: block;
    &.on-top {
      margin-top: 20px;
    }
  }

  @media (max-width: 830px) and (max-height: 450px) {
    min-height: 270px;
    max-height: 270px;
    margin: 1% auto;
    max-width: 90%;
  }

  @media (max-width: 830px) and (max-height: 325px) {
    max-height: ${props.isPreview ? 210 : 250}px;
    min-height: ${props.isPreview ? 210 : 250}px;
  }

  @media (max-width: 325px) {
    max-height: ${props.isPreview ? 400 : 450}px;
  }

  @media (min-width: 1279px) and (max-height: 750px) {
    margin: 1% auto;
  }
`;

export const mainQuestionWrapper = props => css`
  position: relative;
  min-height: 275px;
  &.aniModal {
    animation: ${modalAni} 0.25s ease-in forwards;
  }
  .content {
    padding-bottom: ${props.reduceContentPadding ? "10px" : "80px"};
  }
`;

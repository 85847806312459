/* eslint no-eval: 0 */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { LoadingScreen, StandartModal } from "../Components";
import * as services from "./Services";
import { welcomeCss, PostQuizCss } from "./PagesCss";
import * as _ from "lodash";
import $ from "jquery";
import queryString from "query-string";
import React, { Component } from "react";
import { browserName, deviceDetect } from "react-device-detect";

import PlaceholderBuilder from "../Components/placeholderBuilder/PlaceholderBuilder";
import {
  checkWebinarWhenOnlyOneExist,
  getSanitizedHtml,
  getScriptTagFromString,
  renderIframeUrl,
  toastError,
  replaceWebinarDateToLocalDate,
  replaceCustomVariables,
  replaceFieldLogicValues,
  replaceBackgroundImagesByBackgrounds,
  executeTrackingCodeForPage,
  redirectWithUtmParameters,
} from "../utils";

import {
  PreviewMessage,
  InternetMessage,
  WebinarThank,
} from "./ApplicationContainer.styled";
import moment from "moment";
import imgCheck from "../assets/check-circle-tk.svg";

import MetaInformation from "../Components/MetaInformation/MetaInformation";
import { initialStateApplicationContainer } from "./initialState";

let allQuestions = [];
let questions = [];
let modelsDiagramLinks = [];
let currentQuestion = {};
let outcomes = {};
let newDesignOpt = [];
let modelsDiagramNodes = [];
let leadCaptures = [];
let allPages = [];
let htmlBody = "";
let outcomeType = "";
let outcomeArrayWinner = [];
let takerToken = "";
let isPreview = "";
let isNewPreview = "";
let firstQuestion = {};
let answerLCCont = true;
const pagesViewSent = [];
let setIntervalInternet = null;
let cantActual = [];
let UTMShow = "false";
let passVariable = "false";
let passVariableQuestion = "false";
let passScore = "false";
let splitUrlFull = "";
let splitUrl = "";
let fieldLogicChoicesArray = [];
let facebookPixelActive = false;
let facebookPixelTrackerSettings = [];
let winnerOutcomeGlobal = "";
let GAActive = false;
let GATrackerSettings = [];
let totalScore = 0;
let startQuizRef = "";
let globalAnswersJson = {};

class ApplicationContaniner extends Component {
  state = initialStateApplicationContainer;

  componentDidMount = async () => {
    try {
      let response = null;
      const { verification_token } = this.props.match.params;
      isPreview =
        window.location.href.includes("preview.") === true ||
        window.location.href.includes("preview-2.") === true
          ? true
          : false;

      if (isPreview) response = await services.getPreview(verification_token);
      else response = await services.getCloudfrontInfo(verification_token);

      const {
        appendDataLeadCapture,
        passUtmVariables,
        appendDataQuestions,
      } = response.data.settings;
      //UTM LC and Pass toggles
      UTMShow =
        typeof appendDataLeadCapture === "undefined"
          ? "false"
          : appendDataLeadCapture;
      passVariable =
        typeof passUtmVariables === "undefined" ? "false" : passUtmVariables;
      passVariableQuestion =
        typeof appendDataQuestions === "undefined"
          ? "false"
          : appendDataQuestions;
      passScore = typeof passScore === "undefined" ? "false" : passScore;

      //Pass UTM
      splitUrlFull = _.split(window.location.href, "?");
      splitUrl = typeof splitUrlFull[1] === "undefined" ? "" : splitUrlFull[1];

      //console.log(response.data.use_redirect);

      if (response.data.use_redirect) {
        const urlFull = this.redirectLinkUtm(
          response.data.redirect_url,
          passVariable,
          splitUrl,
        );
        window.location.href = urlFull;
        return;
      }
      /* Validate Domain */

      // if (!isPreview && window.location.hostname !== "localhost") {
      //   const currentDomain = queryString.parseUrl(window.location.href).url;

      //   const dbDomain = queryString.parseUrl(response.data.output_link).url;
      //   if (currentDomain !== dbDomain) {
      //     this.props.history.replace("/404");
      //     return;
      //   }
      // }
      /* Validate Subscription Active */
      if (typeof response.data.subscription_active !== "undefined") {
        if (!response.data.subscription_active) {
          this.props.history.replace("/404");
          return;
        }
      }

      const response_fb = response.data.trackers
        ? response.data.trackers.filter(
          opt => opt.tracker_type === "facebook_pixel",
          )
        : [];

      if (response_fb && response_fb.length > 0) {
        facebookPixelActive = response_fb[0].active;
        facebookPixelTrackerSettings = response_fb[0].tracker_settings;
        //console.log(response_fb);
        if (facebookPixelActive) {
          $(response_fb[0].metadata.script).prependTo("body");
        }
      }

      /*********/

      /* Google Analytcis */
      const response_ga = response.data.trackers
        ? response.data.trackers.filter(
          opt => opt.tracker_type === "google_analytics",
          )
        : [];

      if (response_ga && response_ga.length > 0) {
        GAActive = response_ga[0].active;
        GATrackerSettings = response_ga[0].tracker_settings;
        const ga_script = `<!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=${response_ga[0].metadata.script}"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${response_ga[0].metadata.script}');
        </script>`;

        if (GAActive) {
          try {
            $(ga_script).appendTo("head");
          } catch (e) {
            console.log(e);
          }
        }
      }

      /********* */

      //Add Tracking Codes
      if (!isPreview) {
        if (response.data.settings.checkHeadTrackingCode === "true") {
          $(response.data.settings.headTrackingCode).prependTo("head");
        } else {
          $(response.data.settings.headTrackingCode).appendTo("head");
        }
        $(response.data.settings.bodyTrackingCode).appendTo("body");
      }

      if (isPreview) isNewPreview = true;

      //console.log("response-->", response.data);

      if (response.data.status === "inactive" && !isPreview) {
        window.location.href = "/404";
      }

      //Welcome Page
      const welcomePageTemp = response.data.pages.find(wel => {
        return wel.page_type === "welcome";
      });

      console.log("welcome--->", welcomePageTemp);

      if (typeof welcomePageTemp === "undefined") {
        throw new Error("Welcome Page Empty");
      }
      // ********************
      // Execute Tracking Code for Welcome Page

      /* Tracking Pages */
      const pages_tracking_codes = response.data.trackers
        ? response.data.trackers.filter(
          opt => opt.tracker_type === "tracking_pages",
          )
        : [];

      // ********************

      // create taker and welcome view
      !isNewPreview && this.createTaker(response.data.id, welcomePageTemp.id);

      //outcome type
      outcomeType = response.data.outcomes_type;
      //******* */

      //Lead Captures
      leadCaptures = response.data.lead_captures;
      //Get all LeadCapture Fields
      let lcAllFields = [];
      leadCaptures.forEach(lc => {
        const fields = lc.lead_capture_fields.map(field =>
          `${field.title}`.replace(/\s|-/g, "_").toUpperCase(),
        );
        lcAllFields.push(...fields);
      });
      lcAllFields = _.uniq(lcAllFields);
      //**************** */

      // all pages
      allPages = response.data.pages;
      // *****************

      //console.log(welcomePageTemp.fields.html);

      let contentPageWelcome = `${
        welcomePageTemp.fields.html
      }<style>${replaceBackgroundImagesByBackgrounds(
        welcomePageTemp.fields.css,
      )}${welcomeCss}`;

      // FOR SCRIPTS WISTIA
      let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
      let match;
      let scripsList = [];
      while ((match = re.exec(contentPageWelcome))) {
        if (match[1] === "") {
          const str = match[0];
          const script = getScriptTagFromString(str);

          document.body.appendChild(script);
        }
        // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
        scripsList.push(match[1]);
      }

      // FOR VIMEO/YOUTUBE VIDEOS
      contentPageWelcome = renderIframeUrl(contentPageWelcome);

      this.setState(
        {
          welcomePageHtml: `${contentPageWelcome}`,
          welcomePageTemp: welcomePageTemp,
          isLoadingInitialInfo: false,
          showWelcomePage: true,
          metaImage: response.data.meta_image,
          lcAllFields,
          pages_tracking_codes,
        },
        () => {
          // ******* TRACKING CODE FOR PAGES */
          executeTrackingCodeForPage(
            this.state.pages_tracking_codes,
            welcomePageTemp,
          );
          // ******* END TRACKING CODE FOR PAGES */

          try {
            scripsList.forEach(script => {
              try {
                eval(script);
              } catch (ee) {
                console.log(ee);
              }
            });
            this.scrollToAnchor();
          } catch (e) {
            console.log(e);
          }
        },
      );

      const btnWelcomeTemp = document.getElementsByClassName("quiz-start");
      const btnWelcome = Array.prototype.slice.call(btnWelcomeTemp);
      //console.log(btnWelcome);
      btnWelcome.forEach(el => {
        el.addEventListener("click", () => {
          this.startQuiz(welcomePageTemp.id, welcomePageTemp.internal_id);
        });
      });

      // Anchor Animation
      this.anchorPostWelcome();

      // design
      const designFull =
        response.data.funnel_design_options.length === 0
          ? this.state.design
          : response.data.funnel_design_options;
      const settings = response.data.settings;
      const optionsInJSON = [
        "shadowColor",
        "bgBox",
        "next_button_color",
        "back_button_color",
      ];
      designFull.forEach(item => {
        // Object String
        let value = "";
        if (optionsInJSON.indexOf(item.option) !== -1) {
          value = JSON.parse(item.value);
        } else {
          value = item.value;
        }
        //console.log(value);
        newDesignOpt = {
          ...newDesignOpt,
          [item.option]: value,
        };
        // **************
      });

      //***************** */

      questions = response.data.sections[0].questions;

      questions.forEach(q => {
        q.checkView = false;
        q.answer = null;
        q.answerValue = null;
      });

      //console.log(questions);

      const layersTemp = response.data.sections[0].section_json.layers;
      const diagramsNode = layersTemp.find(el => {
        return el.type === "diagram-nodes";
      });
      modelsDiagramNodes = diagramsNode.models;

      const diagramsLinks = layersTemp.find(el => {
        return el.type === "diagram-links";
      });
      modelsDiagramLinks = diagramsLinks.models;

      const welcomeNode = _.find(modelsDiagramNodes, el => {
        return el.type === "js-welcome-node";
      });

      let flagChoiceLink = false;
      questions.forEach(q => {
        if (q.question_type !== "radio") {
          let currentLinkSource = _.find(modelsDiagramLinks, link => {
            return q.internal_id === link.source;
          });
          if (typeof currentLinkSource === "undefined") {
            flagChoiceLink = true;
          }
        } else {
          q.option_choices.forEach(choice => {
            let currentLink = _.find(modelsDiagramLinks, link => {
              return choice.internal_id === link.sourcePort;
            });
            //console.log(currentLink);
            /* flag to search a radio without connection */
            if (!choice.other) {
              if (typeof currentLink === "undefined") {
                flagChoiceLink = true;
              }
            }
          });
        }
      });
      if (flagChoiceLink) {
        this.setState({ showQuestionWithoutLink: true });
      }

      //find first question start
      const idWelcome = welcomeNode.id;
      const targetNodeFirstQuestion = _.find(modelsDiagramLinks, el => {
        return el.source === idWelcome;
      });
      //console.log(targetNodeFirstQuestion);

      if (targetNodeFirstQuestion) {
        firstQuestion = _.find(questions, el => {
          return el.internal_id === targetNodeFirstQuestion.target;
        });
      }

      //console.log(firstQuestion);

      let fieldLogicArrayFull = [];
      questions.forEach(q => {
        if (q.field_logic_data)
          fieldLogicArrayFull.push(...q.field_logic_data.values);

        let currentQ = {
          name: q.name,
          id: q.internal_id,
          index: q.internal_name,
          answers: [],
        };
        if (q.question_type !== "radio") {
          let currentLink = _.find(modelsDiagramLinks, link => {
            return q.internal_id === link.source;
          });
          //console.log(currentLink);

          const nextNode = modelsDiagramNodes[currentLink.target];
          //console.log(nextNode);
          let target = null;
          if (nextNode.typeNode === "QUESTION") target = currentLink.target;
          else target = null;
          currentQ.answers.push({ name: "test", nextQuestion: target });
        } else {
          q.option_choices.forEach(choice => {
            // debugger;
            let currentLink = _.find(modelsDiagramLinks, link => {
              return choice.internal_id === link.sourcePort;
            });

            // /********** */
            let target = null;
            if (typeof currentLink !== "undefined") {
              const nextNode = modelsDiagramNodes[currentLink.target];
              //console.log(nextNode);
              if (nextNode.typeNode === "QUESTION") target = currentLink.target;
              else target = null;
            }
            currentQ.answers.push({ name: "test", nextQuestion: target });
          });
          //console.log(currentQ);
        }
      });

      // ************************
      const outcomesTemp = response.data.outcomes;
      outcomesTemp.forEach(el => {
        el.sum = 0;
      });
      outcomes = outcomesTemp;
      // Outcomes

      // **********************
      //console.log(firstQuestion);

      if (typeof firstQuestion !== "undefined") {
        allQuestions.push(firstQuestion);
        //console.log(allQuestions, firstQuestion);
        if (firstQuestion.random_choice_order) {
          if (
            firstQuestion.question_type === "radio" ||
            firstQuestion.question_type === "check"
          ) {
            const firstQuestionChoiceTemp = this.shuffle(
              firstQuestion.option_choices,
            );
            firstQuestion.option_choices = firstQuestionChoiceTemp;
          }
        }

        currentQuestion = firstQuestion;
        //this.newCalc(currentQuestion);
        this.newProgess(currentQuestion);
      }

      this.setState({
        design: newDesignOpt,
        settings,
        questions: allQuestions,
        currentQuestion: currentQuestion,
        questionPassed: 1,
        fieldLogicArrayFull,
      });

      // Quiz Autostart
      startQuizRef = welcomePageTemp.fields.type_welcome;
      if (startQuizRef === "auto") {
        window.stopRedirect = true;
        this.startQuiz(welcomePageTemp.id, welcomePageTemp.internal_id);
      }

      //console.log(this.state.design);
    } catch (e) {
      console.log(e, e.name, e.message);
      if (e === "Error: Welcome Page Empty") {
        this.setState({ emptyCanvas: true, isLoadingInitialInfo: false });
      }
      if (e.response && Number(e.response.status) === 404) {
        this.props.history.replace("/404");
      }
    }
  };

  createPageView = async (token, id) => {
    try {
      await services.createView(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createPageViewPost = async (token, id) => {
    if (!pagesViewSent.includes[id]) {
      pagesViewSent.push(id);
      try {
        await services.createView(token, id);
      } catch (e) {
        console.log(e);
      }
    }
  };

  createPageContinue = async (token, id) => {
    //console.log(token, id);
    if (this.state.welcomeContinue === false) {
      try {
        await services.createContinue(token, id);
        this.setState({ welcomeContinue: true });
      } catch (e) {
        console.log(e);
      }
    }
  };

  createQuestionView = async (token, id) => {
    try {
      await services.createQuestionView(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createAnswerQuestion = async (token, data, lastQuestion) => {
    //console.log("entro", { ...data }, allQuestions, lastQuestion);
    try {
      if (lastQuestion) {
        const { winnerOutcome, sumTotal } = this.calculaterWinnerOutcome();
        const taker = {
          answer_json: {},
        };
        let newAnswers = [];
        allQuestions.forEach(q => {
          const sent_data = q.sent_data ? q.sent_data.answer : data.answer;
          const ans = {
            ...sent_data,
          };
          newAnswers.push(ans);
        });
        const takerItem = {
          outcome_id: winnerOutcome.id,
          completion: true,
        };
        if (outcomeType === "scored") takerItem.score = sumTotal;
        taker.answer_json = { questions_answers: newAnswers, taker: takerItem };
        globalAnswersJson = taker.answer_json;
        data = { ...data, taker };
        this.setState({ winnerOutcome, quizScore: sumTotal || 0 });
      }
      // console.log({ ...data });
      const responseAnswer = await services.createAnswerQuestion(token, data);
      const addAnswerQuestion = _.find(questions, el => {
        return el.id === data.answer.question_id;
      });
      //console.log(addAnswerQuestion);
      addAnswerQuestion.answer = responseAnswer.data;
      addAnswerQuestion.sent_data = data;
      //console.log(questions);
    } catch (e) {
      console.log(e);
    }
  };

  createLeadCaptureView = async (token, id) => {
    try {
      await services.leadCaptureView(token, id);
    } catch (e) {
      //console.log(e);
    }
  };

  createWinnerOutcomeComplition = async (
    token,
    id_outcome,
    typeMap,
    sumScore,
  ) => {
    try {
      await services.createWinnerOutcome(token, id_outcome, typeMap, sumScore);
    } catch (e) {
      console.log(e);
    }
  };

  deleteAnswerQuestion = async (token, id) => {
    try {
      await services.deleteAnswerQuestion(token, id);
    } catch (e) {
      console.log(e);
    }
  };

  createAnswerLead = async (
    lead,
    inputs,
    webinarSelected,
    checkGDPR,
    textGDPR,
  ) => {
    //console.log(checkGDPR.checked, textGDPR.textContent);
    let leadTemp = {
      lead_capture_answers_params: {
        values: [],
      },
      taker: {
        webinar_integrations: {},
        compliance: {},
      },
    };
    const integrations = {};
    const leadFields = lead.lead_capture_fields;
    let valuesLead = "";

    webinarSelected.forEach(webinar => {
      const provider = webinar.getAttribute("data-webinar-provider");
      const webinarId = webinar.getAttribute("data-webinar-id");
      const occurrenceId = webinar.getAttribute("data-occurrence-id");
      const webinarKey = webinar.getAttribute("data-webinar-key");

      integrations[provider] = [];

      integrations[provider] = integrations[provider].concat({
        id: webinarId,
        occurrence_id: occurrenceId,
        webinar_key: webinarKey,
      });
    });

    leadTemp.taker.webinar_integrations = { ...integrations };
    leadTemp.taker.answer_json = { lead_capture_answers: [] };
    leadTemp.taker.answer_json = {
      ...globalAnswersJson,
      lead_capture_answers: [],
    };
    Object.keys(inputs).forEach(el => {
      //console.log(inputs[el].value, inputs[el].name, inputs[el].getAttribute("datanewid"));
      const leadFieldTemp = leadFields.find(le => {
        return le.internal_id === inputs[el].getAttribute("datanewid");
      });
      //console.log(leadFieldTemp);
      if (
        typeof leadFieldTemp !== "undefined" &&
        inputs[el].value.trim() !== ""
      ) {
        valuesLead = {
          option: inputs[el].name,
          value: inputs[el].value,
          lead_capture_id: lead.id,
          lead_capture_field_id: leadFieldTemp.id,
        };
        leadTemp.lead_capture_answers_params.values.push(valuesLead);
      }
      if (inputs[el].getAttribute("datanewid")) {
        const newInput = {
          internal_id: inputs[el].getAttribute("datanewid"),
          title: inputs[el].name,
          name: inputs[el].getAttribute("typecustom"),
          value: inputs[el].value,
        };
        leadTemp.taker.answer_json.lead_capture_answers.push(newInput);
      }
    });
    //GDPR
    if ((checkGDPR !== null) & (textGDPR !== null)) {
      const dateTimeGDPR = moment()
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const compliance = {
        gdpr: {
          value: checkGDPR.checked,
          text: textGDPR.textContent,
          date: `${dateTimeGDPR} UTC`,
        },
      };
      console.log(compliance, dateTimeGDPR);
      leadTemp.taker.compliance = compliance;
    }

    //console.log(leadTemp, answerLCCont);
    if (answerLCCont) {
      answerLCCont = false;
      try {
        return await services.createAnswerLead(takerToken, leadTemp);
      } catch (e) {
        console.log(e);
      }
    }

    // inputs.forEach(el => {
    //   console.log(el.value);
    // });
  };

  createCustomVariablesFromWinnerLC = inputs => {
    let answers_lc = [];
    Object.keys(inputs).forEach(el => {
      if (inputs[el].getAttribute("datanewid")) {
        const newInput = {
          internal_id: inputs[el].getAttribute("datanewid"),
          title: inputs[el].name,
          name: inputs[el].getAttribute("typecustom"),
          value: inputs[el].value,
        };
        answers_lc.push(newInput);
      }
    });
    const leadCaptureFieldsVariables = [];
    answers_lc.forEach(aw => {
      const key = `${aw.title}`.replace(/\s|-/g, "_").toUpperCase();
      leadCaptureFieldsVariables.push({
        key,
        value: aw.value,
        internal_id: aw.internal_id,
      });
    });
    this.setState({ leadCaptureFieldsVariables });
  };

  createTaker = async (id, idWelcome) => {
    //console.log(id);
    const utm_params = queryString.parse(window.location.search);
    let taker_parameters_attributes = [];
    Object.keys(utm_params).forEach(utm => {
      taker_parameters_attributes.push({
        name: utm,
        value: utm_params[utm],
      });
    });
    const taker = {
      taker: {
        browser: browserName,
        language: navigator.language,
        funnel_id: id,
        ...deviceDetect(),
        taker_parameters_attributes,
      },
    };
    //console.log(taker);

    try {
      const responseTaker = await services.createTaker(taker);
      takerToken = responseTaker.data.access_token;
      //console.log(takerToken);
      this.createPageView(takerToken, idWelcome);
    } catch (e) {
      console.log(e);
    }
  };

  shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  questionUtmFunction = URLFinalR => {
    allQuestions.forEach(qTemp => {
      const splitURLFinalRTemp = _.split(URLFinalR, "?");
      //console.log(splitURLFinalRTemp[1]);
      if (qTemp.utm_parameters.length > 0) {
        if (qTemp.utm_parameters[0].state) {
          if (
            qTemp.utm_parameters[0].utm_value !== "" &&
            qTemp.utm_parameters[0].utm_value !== null
          ) {
            URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
              qTemp.utm_parameters[0].utm_tag
            }=${qTemp.utm_parameters[0].utm_value}`;
          } else {
            if (
              qTemp.question_type === "short_text" ||
              qTemp.question_type === "long_text"
            ) {
              if (qTemp.answerValue !== null) {
                URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                  qTemp.utm_parameters[0].utm_tag
                }=${qTemp.answerValue}`;
              }
            }
            if (qTemp.question_type === "radio") {
              const qChoiceAns = _.find(qTemp.option_choices, el => {
                return el.internal_id === qTemp.answerValue;
              });
              if (qChoiceAns) {
                if (qChoiceAns.other) {
                  URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                    qTemp.utm_parameters[0].utm_tag
                  }=${qChoiceAns.answer}`;
                } else {
                  URLFinalR += `${splitURLFinalRTemp[1] !== "" ? "&" : ""}${
                    qTemp.utm_parameters[0].utm_tag
                  }=${getSanitizedHtml({
                    html: qChoiceAns.name,
                    unescape: true,
                  })}`;
                }
              }
            }
          }
        }
      }
      if (qTemp.question_type === "radio") {
        const splitURLFinalRTemp2 = _.split(URLFinalR, "?");
        //console.log(splitURLFinalRTemp2[1]);
        const qChoiceAns = _.find(qTemp.option_choices, el => {
          return el.internal_id === qTemp.answerValue;
        });
        if (
          qChoiceAns &&
          qChoiceAns.utm_parameters.length > 0 &&
          qChoiceAns.utm_parameters[0].state
        ) {
          if (
            qChoiceAns.utm_parameters[0].utm_value !== "" &&
            qChoiceAns.utm_parameters[0].utm_value !== null
          ) {
            URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
              qChoiceAns.utm_parameters[0].utm_tag
            }=${qChoiceAns.utm_parameters[0].utm_value}`;
          } else {
            if (qChoiceAns.other) {
              URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
                qChoiceAns.utm_parameters[0].utm_tag
              }=${qChoiceAns.answer}`;
            } else {
              URLFinalR += `${splitURLFinalRTemp2[1] !== "" ? "&" : ""}${
                qChoiceAns.utm_parameters[0].utm_tag
              }=${getSanitizedHtml({
                html: qChoiceAns.name,
                unescape: true,
              })}`;
            }
          }
        }
      }
      if (qTemp.question_type === "check") {
        const splitURLFinalRTemp3 = _.split(URLFinalR, "?");
        //console.log(splitURLFinalRTemp3[1]);
        qTemp.answerValue !== null &&
          qTemp.answerValue.forEach(ans => {
            if (ans.checked === true) {
              const qChoiceAns = _.find(qTemp.option_choices, el => {
                return el.internal_id === ans.id;
              });
              if (
                qChoiceAns.utm_parameters.length > 0 &&
                qChoiceAns.utm_parameters[0].state
              ) {
                if (
                  qChoiceAns.utm_parameters[0].utm_value !== "" &&
                  qChoiceAns.utm_parameters[0].utm_value !== null
                ) {
                  URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                    qChoiceAns.utm_parameters[0].utm_tag
                  }=${qChoiceAns.utm_parameters[0].utm_value}`;
                } else {
                  if (qChoiceAns.other) {
                    URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                      qChoiceAns.utm_parameters[0].utm_tag
                    }=${qChoiceAns.answer}`;
                  } else {
                    URLFinalR += `${splitURLFinalRTemp3[1] !== "" ? "&" : ""}${
                      qChoiceAns.utm_parameters[0].utm_tag
                    }=${getSanitizedHtml({
                      html: qChoiceAns.name,
                      unescape: true,
                    })}`;
                  }
                }
              }
            }
          });
      }
    });
    return URLFinalR;
  };

  redirectLinkUtm = (link, passVariable, splitUrlVar) => {
    // PASS UTM
    let URLFinalR = "";
    const splitDefaultUrl = _.split(link, "?");
    if (passVariable === "true") {
      if (splitUrlVar === "") {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinalR = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
        } else {
          URLFinalR = splitDefaultUrl[0];
        }
      } else {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinalR = `${splitDefaultUrl[0]}?${splitUrlVar}&${splitDefaultUrl[1]}`;
        } else {
          URLFinalR = `${splitDefaultUrl[0]}?${splitUrlVar}`;
        }
      }
    } else {
      if (typeof splitDefaultUrl[1] !== "undefined") {
        URLFinalR = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
      } else {
        URLFinalR = splitDefaultUrl[0];
      }
    }

    //console.log(URLFinalRInit);
    if (passVariableQuestion === "true") {
      const URLFinalRInit = _.split(URLFinalR, "?");
      if (typeof URLFinalRInit[1] === "undefined") {
        URLFinalR += "?";
      }
      URLFinalR = this.questionUtmFunction(URLFinalR);
    }

    if (passScore === "true" && outcomeType === "scored") {
      const splitURLFinalR = _.split(URLFinalR, "?");
      //console.log(splitURLFinalR[1], URLFinalR);
      if (typeof splitURLFinalR[1] === "undefined") {
        URLFinalR += `?quiz_score=${totalScore}`;
      } else {
        if (splitURLFinalR[1] === "") {
          URLFinalR += `quiz_score=${totalScore}`;
        } else {
          URLFinalR += `&quiz_score=${totalScore}`;
        }
      }
    }

    return URLFinalR;
  };

  scrollToAnchor = () => {
    const anchorUrl = _.split(window.location.href, "#");
    if (anchorUrl[1]) {
      let dest = `#${anchorUrl[1]}`;
      window.setTimeout(function() {
        const offsetTop = document.querySelector(dest)?.offsetTop;

        window.scroll({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  // Anchor Animation Post Quiz
  anchorPostWelcome = () => {
    $("a[link_type=jump_to]").on("click", function(e) {
      e.preventDefault();
      let dest = $(this).attr("href");
      console.log(dest);
      $("html,body").animate({ scrollTop: $(dest).offset().top }, "slow");
    });
  };

  newProgess = current => {
    try {
      const cantidad_pregunta = questions.length;
      const valueDefault = parseInt(newDesignOpt.valueProgressBar);
      const totalBar = (100 - valueDefault) / cantidad_pregunta;
      const findCurrentActual = _.find(cantActual, el => {
        return current.id === el.current.id;
      });
      //console.log(findCurrentActual, [...cantActual], current);
      if (typeof findCurrentActual === "undefined") {
        let totalBarFull = 0;
        if (cantActual[cantActual.length - 1]) {
          totalBarFull = cantActual[cantActual.length - 1].num + totalBar;
        } else {
          totalBarFull = valueDefault;
        }
        //console.log(totalBarFull, totalBar);
        cantActual.push({ current: current, num: totalBarFull });
        this.setState({ nodesPath: totalBarFull });
      } else {
        const newNum = cantActual[cantActual.length - 1].num;
        cantActual.push({ current: current, num: newNum });
        this.setState({ nodesPath: newNum });
      }

      //console.log(cantActual);
    } catch (e) {
      console.log(e);
    }
  };

  FBPixelEvent = Id => {
    const trackerQuestionOrOutcome = _.find(
      facebookPixelTrackerSettings,
      el => {
        return el.trackable_id === Id;
      },
    );
    //console.log(trackerQuestionOrOutcome);
    if (typeof trackerQuestionOrOutcome !== "undefined") {
      if (trackerQuestionOrOutcome.active) {
        const objTAG = {};
        objTAG[trackerQuestionOrOutcome.external_data.tag.toString()] =
          trackerQuestionOrOutcome.external_data.value;
        //console.log(objTAG, window.FB("track", "PageView"));
        window.fbq(
          "track",
          trackerQuestionOrOutcome.external_data.event,
          objTAG,
        );
      }
    }
  };

  GAEvent = Id => {
    const trackerQuestionOrOutcome = _.find(GATrackerSettings, el => {
      return el.trackable_id === Id;
    });
    //console.log(trackerQuestionOrOutcome);
    if (typeof trackerQuestionOrOutcome !== "undefined") {
      if (trackerQuestionOrOutcome.active) {
        window.gtag("event", "send", {
          event_category: trackerQuestionOrOutcome.external_data.event_category,
          event_action: trackerQuestionOrOutcome.external_data.event_action,
          event_label: trackerQuestionOrOutcome.external_data.event_label,
          value: trackerQuestionOrOutcome.external_data.event_value,
        });
      }
    }
  };

  startQuiz = (welcomeId, welcomeInternal) => {
    if (window.navigator.onLine) {
      //console.log("entro");
      if (startQuizRef !== "auto") {
        document
          .querySelectorAll(".countdown-bucket-cont, .countdown-cont")
          .forEach(el => {
            let id = el.getAttribute("interval");
            console.log(id);
            clearInterval(id);
          });
      }
      htmlBody = document.getElementsByTagName("html");
      htmlBody[0].style.overflow = "hidden";
      //console.log(firstQuestion);
      //this.setState({ showDialogQuestions: true });
      if (typeof firstQuestion !== "undefined") {
        if (Object.keys(firstQuestion).length > 0) {
          this.setState({ showDialogQuestions: true });
          //console.log("entro");
        } else {
          this.setState({
            showDialogQuestions: true,
            showQuestionWithoutLink: true,
          });
          //console.log("entro2");
        }
      } else {
        const targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.source === welcomeInternal;
        });
        //console.log(targetNodeNextQuestion);
        if (typeof targetNodeNextQuestion !== "undefined") {
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.id === targetNodeNextQuestion.target;
          });
          //console.log(bucketNode);
          this.setState({ showDialogQuestions: true });
          //console.log(bucketNode);
          this.nextBuketNode(bucketNode);
        } else {
          this.setState({
            showDialogQuestions: true,
            showQuestionWithoutLink: true,
          });
          //console.log("no hay nada conectado");
        }
      }

      if (!isNewPreview) {
        //console.log(takerToken);
        const setIntervalAuto = setInterval(() => {
          //console.log(takerToken);
          if (takerToken !== "") {
            this.createPageContinue(takerToken, welcomeId);
            const checkViewQuestion = _.find(questions, el => {
              return el.id === firstQuestion.id;
            });
            if (typeof checkViewQuestion !== "undefined") {
              if (checkViewQuestion.checkView === false) {
                !isNewPreview &&
                  this.createQuestionView(takerToken, firstQuestion.id);
                checkViewQuestion.checkView = true;
              }
            }
            clearInterval(setIntervalAuto);
          }
        }, 200);
      }
      if (typeof firstQuestion !== "undefined") {
        /* Facebook Pixel View Question */
        facebookPixelActive && this.FBPixelEvent(firstQuestion.id);
        /* Google Analytics View Question */
        GAActive && this.GAEvent(firstQuestion.id);
      }
    } else {
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  addAndReplaceFieldLogic = (
    fieldLogicChoice,
    fieldLogicChoicesArray,
    currentQuestion,
    answerQuestionRadioChoice,
    useFieldLogic,
    radioQuestion,
  ) => {
    if (useFieldLogic === true && fieldLogicChoice !== null) {
      Object.keys(fieldLogicChoice).forEach(item => {
        //console.log(item, fieldLogicChoice[item]);
        fieldLogicChoicesArray.push({
          question: radioQuestion,
          choice: answerQuestionRadioChoice,
          name: item,
          value: fieldLogicChoice[item],
        });
      });
    }
    const currentQuestionWithFieldLogic = { ...currentQuestion };

    const tempFieldLogicArray = fieldLogicChoicesArray.map(item => item);
    const tempFieldLogicArrayForChoices = fieldLogicChoicesArray
      .map(item => item)
      .reverse();
    // debugger;
    tempFieldLogicArray.reverse().forEach(el => {
      const replace = `[${el.name}]`;
      let nameTemp = currentQuestionWithFieldLogic.name
        .toString()
        .split(replace)
        .join(el.value);

      let descriptionTemp = currentQuestionWithFieldLogic.description
        .toString()
        .split(replace)
        .join(el.value);

      currentQuestionWithFieldLogic.name = nameTemp;
      currentQuestionWithFieldLogic.description = descriptionTemp;
    });

    // //Replace empty fieldlogicvalues for name
    this.state.fieldLogicArrayFull.forEach(value => {
      currentQuestionWithFieldLogic.name = currentQuestionWithFieldLogic.name
        .split(`[${value}]`)
        .join("");
      currentQuestionWithFieldLogic.description = currentQuestionWithFieldLogic.description
        .split(`[${value}]`)
        .join("");
    });

    //Field Logic for Option Choices
    const option_choices = currentQuestionWithFieldLogic.option_choices.map(
      opt => ({ ...opt }),
    );
    option_choices.forEach(opt => {
      tempFieldLogicArrayForChoices.forEach(el => {
        const replace = `[${el.name}]`;
        const nameTemp = opt.name
          .toString()
          .split(replace)
          .join(el.value);
        opt.name = nameTemp;
      });

      this.state.fieldLogicArrayFull.forEach(value => {
        opt.name = opt.name.split(`[${value}]`).join("");
      });
    });
    currentQuestionWithFieldLogic.option_choices = option_choices;
    return currentQuestionWithFieldLogic;
  };

  addAndReplaceFieldLogicBucket = (
    fieldLogicChoice,
    fieldLogicChoicesArray,
    answerQuestionRadioChoice,
    useFieldLogic,
    radioQuestion,
  ) => {
    if (useFieldLogic === true && fieldLogicChoice !== null) {
      Object.keys(fieldLogicChoice).forEach(item => {
        //console.log(item, fieldLogicChoice[item]);
        fieldLogicChoicesArray.push({
          question: radioQuestion,
          choice: answerQuestionRadioChoice,
          name: item,
          value: fieldLogicChoice[item],
        });
      });
    }
  };

  winnerPostQuizReplaceFunction = (html, arrayFieldParams) => {
    let contentPagePostQuizDefault = replaceCustomVariables(
      html,
      this.state.winnerOutcome,
      this.state.quizScore,
      "page",
      this.state.leadCaptureFieldsVariables,
      this.state.lcAllFields,
    );

    return replaceFieldLogicValues(
      arrayFieldParams,
      contentPagePostQuizDefault,
      this.state.fieldLogicArrayFull,
    );
  };

  nextQuestion = (itemCheck, currentOtherValueCheck) => {
    if (window.navigator.onLine) {
      let lastQuestion = false;
      this.setState({ nextDisabled: true });
      //console.log("next", itemCheck, takerToken);
      const targetNext = allQuestions[allQuestions.length - 1].internal_id;
      const questionAnswer = allQuestions[allQuestions.length - 1];
      const targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
        return el.source === targetNext;
      });
      //console.log(targetNodeNextQuestion, targetNext);
      if (allQuestions[allQuestions.length - 1].question_type === "check") {
        // Sum outcome
        if (outcomeType === "mapped") {
          itemCheck.forEach(element => {
            if (element.checked === true) {
              const choiceTemp = _.find(
                allQuestions[allQuestions.length - 1].option_choices,
                el => {
                  return el.internal_id === element.id;
                },
              );
              if (typeof choiceTemp !== "undefined") {
                choiceTemp.mapped_outcomes.forEach(element => {
                  const outcomeMap = _.find(outcomes, el => {
                    return el.id === element.outcome_id;
                  });
                  // outcomeMap.sum = outcomeMap.sum + 1;
                  outcomeArrayWinner.push({
                    outcome: outcomeMap,
                    outcomeId: outcomeMap.id,
                    sum: 1,
                    position: outcomeMap.position,
                    questionId: allQuestions[allQuestions.length - 1].id,
                  });
                  //console.log(outcomeMap, tempSum, outcomeArrayWinner);
                });
              }
            }
          });
        }

        if (outcomeType === "scored") {
          itemCheck.forEach(element => {
            if (element.checked === true) {
              const choiceTemp = _.find(
                allQuestions[allQuestions.length - 1].option_choices,
                el => {
                  return el.internal_id === element.id;
                },
              );
              outcomeArrayWinner.push({
                outcome: "",
                outcomeId: "",
                sum: choiceTemp.score === null ? 0 : choiceTemp.score,
                position: "",
                choice: choiceTemp.internal_id,
                questionId: allQuestions[allQuestions.length - 1].id,
              });
            }
          });
        }
        // console.log(itemCheck, outcomeArrayWinner);
        // *********
      }
      if (typeof targetNodeNextQuestion !== "undefined") {
        const searchNextQuestion = _.find(questions, el => {
          return el.internal_id === targetNodeNextQuestion.target;
        });
        if (typeof searchNextQuestion !== "undefined") {
          currentQuestion = searchNextQuestion;
          //Validate already answer
          //console.log(currentQuestion);
          if (
            currentQuestion.answerValue !== "" &&
            currentQuestion.answerValue !== null
          ) {
            this.setState({ nextDisabled: false });
          }
          //*********************** */
          // Random Choice
          if (currentQuestion.random_choice_order) {
            if (
              currentQuestion.question_type === "radio" ||
              currentQuestion.question_type === "check"
            ) {
              const currentQuestionChoiceTemp = this.shuffle(
                currentQuestion.option_choices,
              );
              currentQuestion.option_choices = currentQuestionChoiceTemp;
            }
          }
          //*********** */
          /* field Logic */
          let currentQuestionToShow = {};
          currentQuestionToShow = this.addAndReplaceFieldLogic(
            null,
            fieldLogicChoicesArray,
            currentQuestion,
            null,
            null,
            null,
          );
          /*********************** */
          this.setState({
            currentQuestion: currentQuestionToShow,
            questionsPassed: allQuestions.length,
          });
          allQuestions.push(searchNextQuestion);
          // View Question
          const checkViewQuestion = _.find(questions, el => {
            return el.id === currentQuestion.id;
          });
          if (checkViewQuestion.checkView === false) {
            !isNewPreview &&
              this.createQuestionView(takerToken, currentQuestion.id);
            checkViewQuestion.checkView = true;
          }
          // ******************
          /* Facebook Pixel */
          facebookPixelActive && this.FBPixelEvent(currentQuestion.id);
          /* Google Analytics View Question */
          GAActive && this.GAEvent(currentQuestion.id);
          /****** */
        } else {
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.id === targetNodeNextQuestion.target;
          });
          this.nextBuketNode(bucketNode);
          lastQuestion = true;
        }
        // Send Answers

        if (
          questionAnswer.question_type === "short_text" ||
          questionAnswer.question_type === "long_text"
        ) {
          const answerQShow = _.find(questions, el => {
            return el.internal_id === targetNext;
          });
          const answerQuestion = {
            answer: {
              other_choice_text: "",
              skipped: answerQShow.answerValue ? false : true,
              text_answer: answerQShow.answerValue,
              question_id: questionAnswer.id,
              answer_option_choices_attributes: [],
            },
          };
          //console.log("Last--->>>", lastQuestion);
          !isNewPreview &&
            this.createAnswerQuestion(takerToken, answerQuestion, lastQuestion);
          this.newProgess(currentQuestion);
        }
        if (questionAnswer.question_type === "check") {
          const answerQuestion = {
            answer: {
              other_choice_text: currentOtherValueCheck,
              skipped: questionAnswer.answerValue ? false : true,
              text_answer: "",
              question_id: questionAnswer.id,
              answer_option_choices_attributes: [],
            },
          };
          itemCheck.forEach(element => {
            if (element.checked === true) {
              const choiceTemp = _.find(questionAnswer.option_choices, el => {
                return el.internal_id === element.id;
              });
              answerQuestion.answer.answer_option_choices_attributes.push({
                option_choice_id: choiceTemp.id,
              });
              /* Facebook Pixel Choice Check */
              facebookPixelActive && this.FBPixelEvent(choiceTemp.id);
              /* Google Analytics View Question */
              GAActive && this.GAEvent(choiceTemp.id);
            }
          });
          //console.log(itemCheck, questionAnswer);
          //console.log("Last--->>>", lastQuestion);
          !isNewPreview &&
            this.createAnswerQuestion(takerToken, answerQuestion, lastQuestion);
          questionAnswer.answerValue = itemCheck;
          //this.newCalc(currentQuestion);
          this.newProgess(currentQuestion);
        }
      } else {
        //console.log("fin sin enlace");
      }
    } else {
      //console.log("no internet", setIntervalInternet);
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  nextQuestionDisabledSL = () => {
    this.setState({ nextDisabled: true });
  };

  nextRadio = (value, optionChoice, radioQ) => {
    if (window.navigator.onLine) {
      let lastAnswerRadio = false;
      //console.log(value, optionChoice, radioQ);
      const useFieldLogic = radioQ.use_field_logic;
      const radioQuestion = radioQ;
      const fieldLogicChoice =
        optionChoice === null ? "" : optionChoice.field_logic_data;
      const questionAnswer = allQuestions[allQuestions.length - 1];
      const answerQuestionRadioChoice = optionChoice;
      if (
        this.state.design.switchNextButton !== "true" &&
        !optionChoice.other
      ) {
        const targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.sourcePort === value;
        });
        if (typeof targetNodeNextQuestion !== "undefined") {
          const searchNextQuestion = _.find(questions, el => {
            return el.internal_id === targetNodeNextQuestion.target;
          });
          //console.log(searchNextQuestion);
          // Sum outcome
          if (outcomeType === "mapped") {
            optionChoice.mapped_outcomes.forEach(element => {
              const outcomeMap = _.find(outcomes, el => {
                return el.id === element.outcome_id;
              });
              // outcomeMap.sum = outcomeMap.sum + 1;
              outcomeArrayWinner.push({
                outcome: outcomeMap,
                outcomeId: outcomeMap.id,
                sum: 1,
                position: outcomeMap.position,
                questionId: radioQ.id,
              });
              //console.log(outcomeMap, tempSum, outcomeArrayWinner);
            });
          }
          if (outcomeType === "scored") {
            outcomeArrayWinner.push({
              outcome: "",
              outcomeId: "",
              sum: optionChoice.score === null ? 0 : optionChoice.score,
              position: "",
              choice: optionChoice.internal_id,
              questionId: radioQ.id,
            });
            //console.log(outcomeArrayWinner);
          }
          // *********
          if (typeof searchNextQuestion !== "undefined") {
            currentQuestion = searchNextQuestion;
            if (
              currentQuestion.answerValue !== "" &&
              currentQuestion.answerValue !== null
            ) {
              this.setState({ nextDisabled: false });
            }
            //console.log("Butotn Next");
            // Random Choice
            if (currentQuestion.random_choice_order) {
              if (
                currentQuestion.question_type === "radio" ||
                currentQuestion.question_type === "check"
              ) {
                const currentQuestionChoiceTemp = this.shuffle(
                  currentQuestion.option_choices,
                );
                currentQuestion.option_choices = currentQuestionChoiceTemp;
              }
            }
            //*********** */
            //Field Logic
            let currentQuestionToShow = {};
            currentQuestionToShow = this.addAndReplaceFieldLogic(
              fieldLogicChoice,
              fieldLogicChoicesArray,
              currentQuestion,
              answerQuestionRadioChoice,
              useFieldLogic,
              radioQuestion,
            );
            //*********** */
            this.setState({
              currentQuestion: currentQuestionToShow,
              questionsPassed: allQuestions.length,
            });
            allQuestions.push(searchNextQuestion);
            // View Question
            const checkViewQuestion = _.find(questions, el => {
              return el.id === currentQuestion.id;
            });
            if (checkViewQuestion.checkView === false) {
              !isNewPreview &&
                this.createQuestionView(takerToken, currentQuestion.id);
              checkViewQuestion.checkView = true;
            }
            /* Facebook Pixel */
            facebookPixelActive && this.FBPixelEvent(currentQuestion.id);
            /* Google Analytics View Question */
            GAActive && this.GAEvent(currentQuestion.id);
            /****** */
          } else {
            //console.log(outcomes);
            const bucketNode = _.find(modelsDiagramNodes, el => {
              return el.id === targetNodeNextQuestion.target;
            });
            this.nextBuketNode(bucketNode);
            lastAnswerRadio = true;
          }
          // Send Data
          const answerQuestion = {
            answer: {
              other_choice_text: "",
              skipped: false,
              text_answer: "",
              question_id: answerQuestionRadioChoice.question_id,
              answer_option_choices_attributes: [
                { option_choice_id: answerQuestionRadioChoice.id },
              ],
            },
          };
          //console.log(answerQuestion, answerQuestionRadioChoice);
          !isNewPreview &&
            this.createAnswerQuestion(
              takerToken,
              answerQuestion,
              lastAnswerRadio,
            );
          questionAnswer.answerValue = answerQuestionRadioChoice.internal_id;
          //this.newCalc(currentQuestion);
          /* Facebook Pixel Choice Question */
          facebookPixelActive &&
            this.FBPixelEvent(answerQuestionRadioChoice.id);
          /* Google Analytics View Question */
          GAActive && this.GAEvent(answerQuestionRadioChoice.id);
          this.newProgess(currentQuestion);
        } else {
          //console.log("fin sin enlace radio");
        }
      } else {
        //console.log("entro required", optionChoice, optionChoice.other);
        if (optionChoice.other) {
          if (
            optionChoice.answer !== "" &&
            typeof optionChoice.answer !== "undefined"
          ) {
            this.setState({
              nextDisabled: false,
              nextOther: false,
              nextOtherAlone: true,
            });
          } else {
            this.setState({
              nextDisabled: true,
              nextOther: true,
              nextOtherAlone: true,
            });
          }
        } else {
          this.setState({ nextDisabled: false, nextOther: false });
        }
      }
    } else {
      console.log("no internet", setIntervalInternet);
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  nextQuestionDisabled = (value, radioQ, otherValue) => {
    if (window.navigator.onLine) {
      let lastAnswerRadioD = false;
      this.setState({ nextDisabled: true });
      //console.log(value, radioQ);
      const useFieldLogic = radioQ.use_field_logic;
      const radioQuestion = radioQ;
      const fieldLogicChoice = value === null ? "" : value.field_logic_data;
      const answerQuestionRadioChoice = value;
      const questionAnswer = allQuestions[allQuestions.length - 1];
      let targetNodeNextQuestion = null;
      if (value !== null) {
        targetNodeNextQuestion = _.find(modelsDiagramLinks, el => {
          return el.sourcePort === value.internal_id;
        });
      }

      //console.log(targetNodeNextQuestion);
      if (targetNodeNextQuestion !== null) {
        const searchNextQuestion = _.find(questions, el => {
          return el.internal_id === targetNodeNextQuestion.target;
        });
        // Sum outcome
        if (outcomeType === "mapped") {
          //console.log(value, outcomes);
          value.mapped_outcomes.forEach(element => {
            const outcomeMap = _.find(outcomes, el => {
              return el.id === element.outcome_id;
            });
            // outcomeMap.sum = outcomeMap.sum + 1;
            //const tempSum = outcomeArrayWinner.length - 1;
            outcomeArrayWinner.push({
              outcome: outcomeMap,
              outcomeId: outcomeMap.id,
              sum: 1,
              position: outcomeMap.position,
              questionId: radioQ.id,
            });
            //console.loconsole.log(outcomeMap, outcomeArrayWinner);
          });
        }
        if (outcomeType === "scored") {
          outcomeArrayWinner.push({
            outcome: "",
            outcomeId: "",
            sum: value.score === null ? 0 : value.score,
            position: "",
            choice: value.internal_id,
            questionId: radioQ.id,
          });
          //console.log(outcomeArrayWinner);
        }
        // *********
        if (typeof searchNextQuestion !== "undefined") {
          currentQuestion = searchNextQuestion;
          if (
            currentQuestion.answerValue !== "" &&
            currentQuestion.answerValue !== null
          ) {
            this.setState({ nextDisabled: false });
          }
          // Random Choice
          if (currentQuestion.random_choice_order) {
            if (
              currentQuestion.question_type === "radio" ||
              currentQuestion.question_type === "check"
            ) {
              const currentQuestionChoiceTemp = this.shuffle(
                currentQuestion.option_choices,
              );
              currentQuestion.option_choices = currentQuestionChoiceTemp;
            }
          }
          //*********** */
          //Field Logic
          let currentQuestionToShow = {};
          currentQuestionToShow = this.addAndReplaceFieldLogic(
            fieldLogicChoice,
            fieldLogicChoicesArray,
            currentQuestion,
            answerQuestionRadioChoice,
            useFieldLogic,
            radioQuestion,
          );
          //*********** */
          this.setState({
            currentQuestion: currentQuestionToShow,
            questionsPassed: allQuestions.length,
          });
          allQuestions.push(searchNextQuestion);
          // View Question
          const checkViewQuestion = _.find(questions, el => {
            return el.id === currentQuestion.id;
          });
          if (checkViewQuestion.checkView === false) {
            !isNewPreview &&
              this.createQuestionView(takerToken, currentQuestion.id);
            checkViewQuestion.checkView = true;
          }
          /* Facebook Pixel */
          facebookPixelActive && this.FBPixelEvent(currentQuestion.id);
          /* Google Analytics View Question */
          GAActive && this.GAEvent(currentQuestion.id);
          /****** */
        } else {
          //Field Logic
          this.addAndReplaceFieldLogicBucket(
            fieldLogicChoice,
            fieldLogicChoicesArray,
            answerQuestionRadioChoice,
            useFieldLogic,
            radioQuestion,
          );
          //*********** */
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.id === targetNodeNextQuestion.target;
          });
          this.nextBuketNode(bucketNode);
          lastAnswerRadioD = true;
        }
        // Send Data
        const answerQuestion = {
          answer: {
            other_choice_text: otherValue,
            skipped: false,
            text_answer: "",
            question_id: answerQuestionRadioChoice.question_id,
            answer_option_choices_attributes: [
              { option_choice_id: answerQuestionRadioChoice.id },
            ],
          },
        };
        //console.log(answerQuestionRadioChoice.id);
        questionAnswer.answerValue = answerQuestionRadioChoice.internal_id;
        !isNewPreview &&
          this.createAnswerQuestion(
            takerToken,
            answerQuestion,
            lastAnswerRadioD,
          );
        /*New bar*/
        /* Facebook Pixel Choice Question */
        facebookPixelActive && this.FBPixelEvent(answerQuestionRadioChoice.id);
        /* Google Analytics View Question */
        GAActive && this.GAEvent(answerQuestionRadioChoice.id);
        this.newProgess(currentQuestion);
      } else {
        console.log("TCL: nextQuestionDisabled -> radioQ", radioQ);
        if (radioQ.skip_goes_to === "-1" || radioQ.skip_goes_to === null) {
          const bucketNode = _.find(modelsDiagramNodes, el => {
            return el.type === "js-bucket-node";
          });
          this.nextBuketNode(bucketNode);
          lastAnswerRadioD = true;
        } else {
          if (radioQ.skip_goes_to !== null) {
            const searchNextQuestion = _.find(questions, el => {
              return el.internal_id === radioQ.skip_goes_to;
            });
            currentQuestion = searchNextQuestion;
            // Random Choice
            if (currentQuestion.random_choice_order) {
              if (
                currentQuestion.question_type === "radio" ||
                currentQuestion.question_type === "check"
              ) {
                const currentQuestionChoiceTemp = this.shuffle(
                  currentQuestion.option_choices,
                );
                currentQuestion.option_choices = currentQuestionChoiceTemp;
              }
            }
            //*********** */
            //Field Logic
            let currentQuestionToShow = {};
            console.log(fieldLogicChoicesArray, currentQuestion);
            currentQuestionToShow = this.addAndReplaceFieldLogic(
              fieldLogicChoice,
              fieldLogicChoicesArray,
              currentQuestion,
              answerQuestionRadioChoice,
              useFieldLogic,
              radioQuestion,
            );
            //*********** */

            this.setState({
              currentQuestion: currentQuestionToShow,
              questionsPassed: allQuestions.length,
            });
            allQuestions.push(searchNextQuestion);
            // View Question
            const checkViewQuestion = _.find(questions, el => {
              return el.id === currentQuestion.id;
            });
            if (checkViewQuestion.checkView === false) {
              !isNewPreview &&
                this.createQuestionView(takerToken, currentQuestion.id);
              checkViewQuestion.checkView = true;
            }
            /* Facebook Pixel */
            facebookPixelActive && this.FBPixelEvent(currentQuestion.id);
            /* Google Analytics View Question */
            GAActive && this.GAEvent(currentQuestion.id);
            /****** */
            console.log(searchNextQuestion);
          }
        }
        // Send Data
        const answerQuestion = {
          answer: {
            other_choice_text: "",
            skipped: true,
            text_answer: "",
            question_id: radioQ.id,
            answer_option_choices_attributes: [{ option_choice_id: [] }],
          },
        };
        //console.log(answerQuestion);
        !isNewPreview &&
          this.createAnswerQuestion(
            takerToken,
            answerQuestion,
            lastAnswerRadioD,
          );
        questionAnswer.answerValue =
          answerQuestionRadioChoice !== null
            ? answerQuestionRadioChoice.internal_id
            : "";
        //this.newCalc(currentQuestion);
        facebookPixelActive && this.FBPixelEvent(answerQuestionRadioChoice.id);
        /* Google Analytics View Question */
        GAActive && this.GAEvent(answerQuestionRadioChoice.id);
        this.newProgess(currentQuestion);
      }
    } else {
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  winnerOutcomeFunction = async (winnerPostQuiz, winnerOutcome) => {
    let contentPagePostQuiz = `${
      winnerPostQuiz.fields.html
    }<style>${replaceBackgroundImagesByBackgrounds(
      winnerPostQuiz.fields.css,
    )}${PostQuizCss}`;
    let rePost2 = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    await this.setState({ winnerOutcome });
    let matchPost2;
    let scripsListPost2 = [];
    while ((matchPost2 = rePost2.exec(contentPagePostQuiz))) {
      if (matchPost2[1] === "") {
        const str = matchPost2[0];
        const script = getScriptTagFromString(str);

        document.body.appendChild(script);
      }
      // full matchPost2 is in matchPost2[0], whereas captured groups are in ...[1], ...[2], etc.
      scripsListPost2.push(matchPost2[1]);
    }

    // FOR VIMEO/YOUTUBE VIDEOS
    contentPagePostQuiz = renderIframeUrl(contentPagePostQuiz);

    const winnerPostquizHtmlReplace = this.winnerPostQuizReplaceFunction(
      contentPagePostQuiz,
      fieldLogicChoicesArray,
    );
    this.setState(
      {
        postQuizHtml: winnerPostquizHtmlReplace,
        postQuizWinner: winnerPostQuiz,
        showDialogQuestions: false,
        showWelcomePage: false,
        showPostQuizPage: true,
      },
      () => {
        // ******* TRACKING CODE FOR POST-QUIZ PAGES */
        executeTrackingCodeForPage(
          this.state.pages_tracking_codes,
          this.state.postQuizWinner,
        );

        // eval(matchPost[1]);
        redirectWithUtmParameters(
          passVariable,
          splitUrl,
          this.redirectLinkUtm,
          this.state.winnerLeadCapture,
          this.state.leadCaptureFieldsVariables,
        );

        scripsListPost2.forEach(script => {
          try {
            eval(script);
          } catch (ee) {
            console.log(ee);
          }
        });
      },
    );
    htmlBody[0].style.overflow = "auto";
    $("html").animate({ scrollTop: "0px" }, 150);
    console.log(winnerPostQuiz);
    !isNewPreview && this.createPageViewPost(takerToken, winnerPostQuiz.id);
    /* Facebook Pixel */
    facebookPixelActive && this.FBPixelEvent(winnerOutcome.id);
    /* Google Analytics View Question */
    GAActive && this.GAEvent(winnerOutcome.id);
    /****** */
    //Anchor PostQuiz
    this.anchorPostWelcome();
  };

  redirectFuntionWithoutLC = (
    allPages,
    targetNodeFinish,
    winnerOutcome,
    type,
  ) => {
    this.setState({ showDialogQuestions: false });
    let redirect = "";

    if (type === "direct") {
      redirect = _.find(allPages, el => {
        return el.internal_id === targetNodeFinish.target;
      });
    }

    if (type === "btn") {
      redirect = targetNodeFinish;
    }

    let URLFinalFull = "";

    // PASS UTM
    URLFinalFull = this.redirectLinkUtm(
      redirect.fields.url,
      passVariable,
      splitUrl,
    );
    /*****************/
    //console.log(redirect);
    /* Facebook Pixel */
    facebookPixelActive && this.FBPixelEvent(winnerOutcome.id);
    /* Google Analytics View Question */
    GAActive && this.GAEvent(winnerOutcome.id);
    /****** */

    window.setTimeout(() => {
      console.log("redirect----bkt-redirectFuntionWithoutLC");
      window.location.href = URLFinalFull;
    }, 300);
  };

  calculaterWinnerOutcome = () => {
    let winnerOutcome = "";
    let sumTotal;
    if (outcomeType === "mapped") {
      if (outcomeArrayWinner.length > 0) {
        const outcomeArrayWinnerTemp = _.orderBy(
          outcomeArrayWinner,
          ["position"],
          ["asc"],
        );
        //console.log(...[outcomeArrayWinnerTemp]);
        //winnerOutcome = winnerOutcomeTemp[0].outcome;
        let summed = _(outcomeArrayWinnerTemp)
          .groupBy("outcomeId")
          .map((objs, key) => {
            //console.log(objs, key);
            return {
              outcomeId: key,
              position: objs[0].position,
              outcome: objs[0].outcome,
              sum: _.sumBy(objs, "sum"),
            };
          })
          .value();
        /*console.log(
            _.orderBy(...[summed], ["sum", "position"], ["desc", "asc"]),
          );*/
        //const winnerOutcomeTemp = _.maxBy(summed, "sum");
        const winnerOutcomeTemp = _.orderBy(
          ...[summed],
          ["sum", "position"],
          ["desc", "asc"],
        );
        //console.log(winnerOutcomeTemp[0]);
        winnerOutcome = winnerOutcomeTemp[0].outcome;
      } else {
        const winnerOutcomeTemp2 = _.orderBy(outcomes, ["position"], ["asc"]);
        winnerOutcome = winnerOutcomeTemp2[0];
      }
    } else {
      sumTotal = _.sumBy(outcomeArrayWinner, "sum");
      const outcomeArrayWinnerTemp = _.filter(outcomes, el => {
        if (el.score_min !== null && el.score_max !== null) {
          return sumTotal >= el.score_min && sumTotal <= el.score_max;
        }
      });
      if (outcomeArrayWinnerTemp.length > 0) {
        winnerOutcome = outcomeArrayWinnerTemp[0];
      } else {
        const winnerOutcomeTemp2 = _.orderBy(outcomes, ["position"], ["asc"]);
        winnerOutcome = winnerOutcomeTemp2[0];
      }
    }
    return { winnerOutcome, sumTotal };
  };

  nextBuketNode = bucketNode => {
    if (bucketNode.type === "js-bucket-node") {
      let { winnerOutcome, sumTotal } = this.calculaterWinnerOutcome();

      console.log(winnerOutcome, modelsDiagramLinks);

      winnerOutcomeGlobal = winnerOutcome;

      const targetNodeFinish = _.find(modelsDiagramLinks, el => {
        return el.sourcePort === winnerOutcome.internal_id;
      });

      console.log(targetNodeFinish);
      this.setState({ winnerOutcome, quizScore: sumTotal || 0 });
      if (typeof targetNodeFinish !== "undefined") {
        const winnerLeadCapture = _.find(leadCaptures, el => {
          return el.internal_id === targetNodeFinish.target;
        });

        if (typeof winnerLeadCapture !== "undefined") {
          let contentPageLCTemp = `${winnerLeadCapture.pages[0].fields.html}`;
          let reLC = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

          let matchLC;
          let scripsListLC = [];
          while ((matchLC = reLC.exec(contentPageLCTemp))) {
            if (matchLC[1] === "") {
              const str = matchLC[0];
              const script = getScriptTagFromString(str);

              document.body.appendChild(script);
            }
            // full matchLC is in matchLC[0], whereas captured groups are in ...[1], ...[2], etc.
            scripsListLC.push(matchLC[1]);
          }
          this.setState(
            {
              showQuestions: false,
              showLeadCapture: true,
              winnerLeadCapture: winnerLeadCapture,
              fieldLogicChoicesArray: fieldLogicChoicesArray,
            },
            () => {
              // ******* TRACKING CODE FOR LC PAGE */
              executeTrackingCodeForPage(
                this.state.pages_tracking_codes,
                this.state.winnerLeadCapture.pages[0],
              );
              // eval(matchPost[1]);
              try {
                scripsListLC.forEach(script => {
                  try {
                    eval(script);
                  } catch (ee) {
                    console.log(ee);
                  }
                });
              } catch (e) {
                console.log(e);
              }
            },
          );
          !isNewPreview &&
            this.createLeadCaptureView(takerToken, winnerLeadCapture.id);
        } else {
          const winnerPostQuiz = _.find(allPages, el => {
            return el.internal_id === targetNodeFinish.target;
          });
          //console.log(winnerPostQuiz.page_type);
          switch (winnerPostQuiz.page_type) {
            case "thank_you":
              this.winnerOutcomeFunction(winnerPostQuiz, winnerOutcome);
              break;

            case "outcome": {
              this.winnerOutcomeFunction(winnerPostQuiz, winnerOutcome);
              const info = {
                targetNodeFinish: targetNodeFinish,
                winnerOutcome: winnerOutcome,
              };
              setTimeout(() => {
                const btnOutcomeTemp = document.getElementsByClassName(
                  "outcome-next-button",
                );
                //console.log(btnOutcomeTemp);
                const btnOutcome = Array.prototype.slice.call(btnOutcomeTemp);
                //console.log(btnOutcome);
                btnOutcome.forEach(el => {
                  el.addEventListener("click", () => {
                    this.btnNextOutcomePage(
                      winnerPostQuiz.internal_id,
                      info,
                      "WLC",
                    );
                  });
                });
              }, 100);
              break;
            }

            case "sales_page":
              this.winnerOutcomeFunction(winnerPostQuiz, winnerOutcome);
              break;

            case "outcome_only":
              this.winnerOutcomeFunction(winnerPostQuiz, winnerOutcome);
              break;

            case "redirect":
              this.redirectFuntionWithoutLC(
                allPages,
                targetNodeFinish,
                winnerOutcome,
                "direct",
              );
              break;
            default:
              console.log("Page not allowed");
              break;
          }
        }

        //console.log(sumTotal);

        !isNewPreview &&
          this.createWinnerOutcomeComplition(
            takerToken,
            winnerOutcome.id,
            outcomeType,
            sumTotal,
          );
        // console.log(winnerOutcome.id);
      } else {
        console.log("entro 5");
        this.setState({ showQuestionWithoutLink: true });
      }
    }
  };

  winnerOutcomeLCFunction = nodeNextOptionLead => {
    console.log("mostrar post quiz");
    const postQuizPage = _.find(allPages, el => {
      return el.internal_id === nodeNextOptionLead.id;
    });
    console.log(postQuizPage);
    let contentPagePostQuiz = `${
      postQuizPage.fields.html
    }<style>${replaceBackgroundImagesByBackgrounds(
      postQuizPage.fields.css,
    )}${PostQuizCss}`;
    let rePost = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

    let matchPost;
    let scripsListPost = [];
    while ((matchPost = rePost.exec(contentPagePostQuiz))) {
      if (matchPost[1] === "") {
        const str = matchPost[0];
        const script = getScriptTagFromString(str);

        document.body.appendChild(script);
      }
      // full matchPost is in matchPost[0], whereas captured groups are in ...[1], ...[2], etc.
      scripsListPost.push(matchPost[1]);
    }

    // FOR VIMEO/YOUTUBE VIDEOS
    contentPagePostQuiz = renderIframeUrl(contentPagePostQuiz);

    !isNewPreview && this.createPageViewPost(takerToken, postQuizPage.id);
    const winnerPostquizHtmlReplace = this.winnerPostQuizReplaceFunction(
      contentPagePostQuiz,
      fieldLogicChoicesArray,
    );
    this.setState(
      {
        postQuizHtml: winnerPostquizHtmlReplace,
        postQuizWinner: postQuizPage,
        showDialogQuestions: false,
        showWelcomePage: false,
        showLeadCapture: false,
        showPostQuizPage: true,
      },
      () => {
        // ******* TRACKING CODE FOR POST-QUIZ PAGES */
        executeTrackingCodeForPage(
          this.state.pages_tracking_codes,
          this.state.postQuizWinner,
        );

        // eval(matchPost[1]);
        redirectWithUtmParameters(
          passVariable,
          splitUrl,
          this.redirectLinkUtm,
          this.state.winnerLeadCapture,
          this.state.leadCaptureFieldsVariables,
        );

        scripsListPost.forEach(script => {
          try {
            eval(script);
          } catch (ee) {
            console.log(ee);
          }
        });
      },
    );
    htmlBody[0].style.overflow = "auto";
    $("html").animate({ scrollTop: "0px" }, 150);
    /* Facebook Pixel */
    facebookPixelActive && this.FBPixelEvent(winnerOutcomeGlobal.id);
    /* Google Analytics View Question */
    GAActive && this.GAEvent(winnerOutcomeGlobal.id);
    /****** */
    //Anchor PostQuiz
    this.anchorPostWelcome();
  };

  validateWebinarSelected = () => {
    const webinarsOfPage = Array.from(
      document.querySelectorAll(".webinars-list .webinar-list__item"),
    );

    const webinarSelectedOfPage = webinarsOfPage.filter(webinar => {
      const inputRadio = webinar.querySelector("input[type= 'radio']");
      return inputRadio.checked;
    });

    if (!webinarSelectedOfPage.length && webinarsOfPage.length) {
      toastError({ message: "You must select a webinar" });
      return { nextToPage: false, webinarSelectedOfPage, webinarsOfPage };
    }
    return { nextToPage: true, webinarSelectedOfPage, webinarsOfPage };
  };

  btnNextOutcomePage = (nodeNextOptionLeadID, info, typeRedirect) => {
    const {
      nextToPage,
      webinarSelectedOfPage,
      webinarsOfPage,
    } = this.validateWebinarSelected();
    if (nextToPage) {
      if (webinarsOfPage.length) {
        this.saveSelectedWebinar(webinarSelectedOfPage);
      }
      const targetNodeFinish = _.find(modelsDiagramLinks, el => {
        return el.source === nodeNextOptionLeadID;
      });
      const resultPage = _.find(allPages, el => {
        return el.internal_id === targetNodeFinish.target;
      });
      console.log(resultPage);
      if (resultPage.page_type === "redirect") {
        console.log("redirect", typeRedirect, info);
        if (typeRedirect === "LC") {
          this.externalURlLC(info.lead, info.inputs, resultPage, "btn");
        }

        if (typeRedirect === "WLC") {
          setTimeout(() => {
            this.redirectFuntionWithoutLC(
              allPages,
              resultPage,
              info.winnerOutcome,
              "btn",
            );
          }, 300);
        }
      } else {
        let contentPagePostQuiz = `${
          resultPage.fields.html
        }<style>${replaceBackgroundImagesByBackgrounds(
          resultPage.fields.css,
        )}${PostQuizCss}`;
        let rePost = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

        let matchPost;
        let scripsListPost = [];
        while ((matchPost = rePost.exec(contentPagePostQuiz))) {
          if (matchPost[1] === "") {
            const str = matchPost[0];
            const script = getScriptTagFromString(str);

            document.body.appendChild(script);
          }
          // full matchPost is in matchPost[0], whereas captured groups are in ...[1], ...[2], etc.
          scripsListPost.push(matchPost[1]);
        }

        // FOR VIMEO/YOUTUBE VIDEOS
        contentPagePostQuiz = renderIframeUrl(contentPagePostQuiz);

        !isNewPreview && this.createPageViewPost(takerToken, resultPage.id);
        const winnerPostquizHtmlReplace = this.winnerPostQuizReplaceFunction(
          contentPagePostQuiz,
          fieldLogicChoicesArray,
        );
        this.setState(
          {
            postQuizHtml: winnerPostquizHtmlReplace,
            postQuizWinner: resultPage,
            showDialogQuestions: false,
            showWelcomePage: false,
            showPostQuizPage: true,
          },
          () => {
            // ******* TRACKING CODE FOR POST-QUIZ PAGES */
            executeTrackingCodeForPage(
              this.state.pages_tracking_codes,
              this.state.postQuizWinner,
            );
            // eval(matchPost[1]);
            redirectWithUtmParameters(
              passVariable,
              splitUrl,
              this.redirectLinkUtm,
              this.state.winnerLeadCapture,
              this.state.leadCaptureFieldsVariables,
            );

            scripsListPost.forEach(script => {
              try {
                eval(script);
              } catch (ee) {
                console.error(ee);
              }
            });
          },
        );
        htmlBody[0].style.overflow = "auto";
        $("html").animate({ scrollTop: "0px" }, 150);
        /* Facebook Pixel */
        facebookPixelActive && this.FBPixelEvent(winnerOutcomeGlobal.id);
        /* Google Analytics View Question */
        GAActive && this.GAEvent(winnerOutcomeGlobal.id);

        //Anchor PostQuiz
        this.anchorPostWelcome();

        if (resultPage.page_type === "outcome") {
          const btnOutcomeTemp = document.getElementsByClassName(
            "outcome-next-button",
          );
          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();
          console.log(btnOutcomeTemp);
          const btnOutcome = Array.prototype.slice.call(btnOutcomeTemp);
          btnOutcome.forEach(el => {
            el.addEventListener("click", () => {
              this.btnNextOutcomePage(
                resultPage.internal_id,
                info,
                typeRedirect,
              );
            });
          });
        }

        if (resultPage.page_type === "webinar_page") {
          const btnWebinarPageTemp = document.getElementsByClassName(
            "buttonWebinarPageRegister",
          );
          checkWebinarWhenOnlyOneExist();
          replaceWebinarDateToLocalDate();
          const btnWebinarPage = Array.prototype.slice.call(btnWebinarPageTemp);
          btnWebinarPage.forEach(el => {
            el.addEventListener("click", () => {
              this.btnNextWebinarPage();
            });
          });
        }
      }
    }
  };

  saveSelectedWebinar = webinarSelectedOfPage => {
    let leadTemp = {
      taker: {
        webinar_integrations: {},
      },
    };
    const integrations = {};

    webinarSelectedOfPage.forEach(webinar => {
      const provider = webinar.getAttribute("data-webinar-provider");
      const webinarId = webinar.getAttribute("data-webinar-id");
      const occurrenceId = webinar.getAttribute("data-occurrence-id");
      const webinarKey = webinar.getAttribute("data-webinar-key");

      integrations[provider] = [];

      integrations[provider] = integrations[provider].concat({
        id: webinarId,
        occurrence_id: occurrenceId,
        webinar_key: webinarKey,
      });
    });

    leadTemp.taker.webinar_integrations = { ...integrations };
    // console.log(leadTemp);
    if (!isNewPreview) {
      try {
        return services.updateTaker(takerToken, leadTemp);
      } catch (e) {
        console.log(e);
      }
    }
  };

  btnNextWebinarPage = () => {
    const {
      nextToPage,
      webinarSelectedOfPage,
    } = this.validateWebinarSelected();
    if (nextToPage) {
      this.saveSelectedWebinar(webinarSelectedOfPage);
      this.setState({
        showThankWebinarPage: true,
        showPostQuizPage: false,
        showLeadCapture: false,
        showDialogQuestions: false,
        showWelcomePage: false,
      });
    }
  };

  externalURlLC = (lead, inputs, targetNodeNextOptionLead, type) => {
    //console.log("lead-->", lead, inputs, targetNodeNextOptionLead, allPages);
    let urlUTMLC = "";
    let URLFinal = "";
    let redirect = "";
    if (type === "direct") {
      redirect = _.find(allPages, el => {
        return el.internal_id === targetNodeNextOptionLead.target;
      });
    }

    if (type === "btn") {
      redirect = targetNodeNextOptionLead;
    }

    const splitDefaultUrl = _.split(redirect.fields.url, "?");
    //console.log(targetNodeNextOptionLead, allPages);
    if (UTMShow === "true") {
      //console.log(lead);
      /* UTMs **** */
      if (lead.utm_parameters.length > 0) {
        if (lead.utm_parameters[0].state) {
          Object.keys(inputs).forEach(el => {
            if (inputs[el].className !== "checkbox-gdpr") {
              const leadFieldTemp = lead.lead_capture_fields.find(le => {
                return le.internal_id === inputs[el].getAttribute("datanewid");
              });
              //console.log("field, input--->", leadFieldTemp, lead);
              leadFieldTemp.utm_parameters.forEach(utmP => {
                if (
                  utmP.state &&
                  inputs[el].value !== "" &&
                  inputs[el].value !== null
                ) {
                  urlUTMLC += `${urlUTMLC !== "" ? "&" : ""}${utmP.utm_tag}=${
                    inputs[el].value
                  }`;
                }
              });
            }
          });
        }
      }
      if (passVariable === "true") {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          if (urlUTMLC !== "") {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${urlUTMLC}&${splitDefaultUrl[1]}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}&${splitDefaultUrl[1]}`;
            }
          } else {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${splitDefaultUrl[1]}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
            }
          }
        } else {
          if (urlUTMLC !== "") {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${urlUTMLC}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}`;
            }
          } else {
            if (splitUrl !== "") {
              URLFinal = `${splitDefaultUrl[0]}?${splitUrl}`;
            } else {
              URLFinal = `${splitDefaultUrl[0]}`;
            }
          }
        }
      } else {
        //console.log("entrooo sin pass->",splitDefaultUrl[0], urlUTMLC);
        if (urlUTMLC === "") {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
          } else {
            URLFinal = splitDefaultUrl[0];
          }
        } else {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}&${splitDefaultUrl[1]}`;
          } else {
            URLFinal = `${splitDefaultUrl[0]}?${urlUTMLC}`;
          }
        }
      }
      /**************************/
    } else {
      if (passVariable === "true") {
        //console.log("entro sin LC pero con PAss--->",splitDefaultUrl[1], splitUrl);
        //URLFinal = splitDefaultUrl[0] + "?" + splitUrl;
        if (splitUrl === "") {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
          } else {
            URLFinal = splitDefaultUrl[0];
          }
        } else {
          if (typeof splitDefaultUrl[1] !== "undefined") {
            URLFinal = `${splitDefaultUrl[0]}?${splitUrl}&${splitDefaultUrl[1]}`;
          } else {
            URLFinal = `${splitDefaultUrl[0]}?${splitUrl}`;
          }
        }
      } else {
        if (typeof splitDefaultUrl[1] !== "undefined") {
          URLFinal = `${splitDefaultUrl[0]}?${splitDefaultUrl[1]}`;
        } else {
          URLFinal = splitDefaultUrl[0];
        }
      }
    }
    //console.log(splitUrl);
    if (passVariableQuestion === "true") {
      const URLFinalRInit = _.split(URLFinal, "?");
      if (typeof URLFinalRInit[1] === "undefined") {
        URLFinal += "?";
      }
      URLFinal = this.questionUtmFunction(URLFinal);
    }
    if (passScore === "true" && outcomeType === "scored") {
      const splitURLFinal = _.split(URLFinal, "?");
      if (typeof splitURLFinal[1] === "undefined") {
        URLFinal += `?quiz_score=${totalScore}`;
      } else {
        if (splitURLFinal[1] === "") {
          URLFinal += `quiz_score=${totalScore}`;
        } else {
          URLFinal += `&quiz_score=${totalScore}`;
        }
      }
      //console.log(splitURLFinalR[0], splitURLFinalR[1]);
    }
    /* Facebook Pixel */
    facebookPixelActive && this.FBPixelEvent(winnerOutcomeGlobal.id);
    /* Google Analytics View Question */
    GAActive && this.GAEvent(winnerOutcomeGlobal.id);
    /****** */
    window.setTimeout(() => {
      console.log("redirect----bkt-externalURlLC");
      window.location.href = URLFinal;
    }, 300);
    //console.log("mandar a external redirect from lead", URLFinal);
  };

  submitLeadCapture = async (
    lead,
    inputs,
    webinarSelected,
    checkGDPR,
    textGDPR,
  ) => {
    // debugger;
    if (window.navigator.onLine) {
      //console.log("submit lead", lead, inputs);
      document
        .querySelectorAll(
          ".mainLeadCapture .countdown-bucket-cont, .mainLeadCapture .countdown-cont",
        )
        .forEach(el => {
          let id = el.getAttribute("interval");
          //console.log(id);
          clearInterval(id);
        });
      const targetNodeNextOptionLead = _.find(modelsDiagramLinks, el => {
        return el.source === lead.internal_id;
      });
      if (typeof targetNodeNextOptionLead !== "undefined") {
        const nodeNextOptionLead = _.find(modelsDiagramNodes, el => {
          return el.id === targetNodeNextOptionLead.target;
        });

        this.createCustomVariablesFromWinnerLC(inputs);

        !isNewPreview &&
          (await this.createAnswerLead(
            lead,
            inputs,
            webinarSelected,
            checkGDPR,
            textGDPR,
          ));
        //console.log(nodeNextOptionLead);
        switch (nodeNextOptionLead.type) {
          case "js-thankyou-node":
            this.winnerOutcomeLCFunction(nodeNextOptionLead);
            break;

          case "js-outcome-page-node": {
            this.winnerOutcomeLCFunction(nodeNextOptionLead);
            const info = {
              lead: lead,
              inputs: inputs,
              targetNodeNextOptionLead: targetNodeNextOptionLead,
            };
            setTimeout(() => {
              const btnOutcomeTemp = document.getElementsByClassName(
                "outcome-next-button",
              );
              const btnOutcome = Array.prototype.slice.call(btnOutcomeTemp);
              btnOutcome.forEach(el => {
                el.addEventListener("click", () => {
                  this.btnNextOutcomePage(nodeNextOptionLead.id, info, "LC");
                });
              });
            }, 100);
            checkWebinarWhenOnlyOneExist();
            replaceWebinarDateToLocalDate();
            break;
          }

          case "js-sales-page-node":
            this.winnerOutcomeLCFunction(nodeNextOptionLead);
            break;

          case "js-webinar-page-node": {
            this.winnerOutcomeLCFunction(nodeNextOptionLead);
            const btnWebinarPageTemp = document.getElementsByClassName(
              "buttonWebinarPageRegister",
            );
            const btnWebinarPage = Array.prototype.slice.call(
              btnWebinarPageTemp,
            );
            btnWebinarPage.forEach(el => {
              el.addEventListener("click", () => {
                this.btnNextWebinarPage(nodeNextOptionLead.id);
              });
            });
            checkWebinarWhenOnlyOneExist();
            replaceWebinarDateToLocalDate();
            break;
          }

          case "js-outcome-only-node":
            this.winnerOutcomeLCFunction(nodeNextOptionLead);
            break;

          case "js-external-redirect-node":
            setTimeout(() => {
              this.externalURlLC(
                lead,
                inputs,
                targetNodeNextOptionLead,
                "direct",
              );
            }, 300);
            break;
          default:
            console.log("Option not allowed");
            break;
        }
      } else {
        console.log("acaba en lead, mostrar thank you default");
      }
      //console.log(targetNodeNextOptionLead, modelsDiagramNodes);
    } else {
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  closeModal = () => {
    this.setState({ showDialogQuestions: false });
    htmlBody[0].style.overflow = "auto";
  };

  changeShortLong = (value, id, typeIfUrl, valueTypeIfUrl) => {
    //console.log(id, value);
    const CQ = _.find(questions, el => {
      return el.id === id;
    });
    CQ.answerValue = value;
    if (value === "") {
      this.setState({ nextDisabled: true });
    } else {
      this.setState({ nextDisabled: false });
    }
    if (CQ.required === false) {
      if (valueTypeIfUrl === "" && typeIfUrl === false) {
        //console.log("entro 1 url");
        this.setState({ nextDisabledUrlShort: false });
      }
      if (valueTypeIfUrl !== "" && typeIfUrl === false) {
        //console.log("entro 2 url");
        this.setState({ nextDisabledUrlShort: true });
      }
      if (typeIfUrl === true) {
        //console.log("entro 3 url");
        this.setState({ nextDisabledUrlShort: false });
      }
    }
  };

  nextCheck = (items, value, item, CQ) => {
    //console.log(value.target.checked, value.target.value, item, items, CQ);
    const CQTemp = _.find(questions, el => {
      return el.id === CQ.id;
    });
    CQTemp.answerValue = items;
    const searchCheckItem = _.filter(items, el => {
      return el.checked === false;
    });
    //console.log(items, searchCheckItem);
    if (!searchCheckItem.length) {
      //console.log("entro 3");
      this.setState({ nextDisabled: false });
    } else {
      if (searchCheckItem.length > 0) {
        //console.log("entro 1");
        const searchCheckItem2 = _.filter(items, el => {
          return el.checked === true;
        });
        if (searchCheckItem2.length > 0) {
          this.setState({ nextDisabled: false });
        } else {
          this.setState({ nextDisabled: true });
        }
      } else {
        //console.log("entro 2");
        this.setState({ nextDisabled: false });
      }
    }
    if (item.other) {
      //console.log(item.answer);
      if (value.target.checked) {
        if (typeof item.answer !== "undefined" && item.answer !== "") {
          this.setState({ nextCheckOther: false });
        } else {
          this.setState({ nextCheckOther: true });
        }
      } else {
        this.setState({ nextCheckOther: false });
      }
    }
  };

  nextQuestionDisabledCheck = (itemCheck, currentOtherValueCheck) => {
    console.log(itemCheck);
    this.nextQuestion(itemCheck, currentOtherValueCheck);
  };

  backQuestion = () => {
    if (window.navigator.onLine) {
      allQuestions.pop();
      const newCurrent = allQuestions[allQuestions.length - 1];
      _.remove(outcomeArrayWinner, function(n) {
        return n.questionId === newCurrent.id;
      });
      /* field Logic */
      const newFieldLogicChoicesArray = [...fieldLogicChoicesArray];
      _.remove(newFieldLogicChoicesArray, function(n) {
        return n.question.id === newCurrent.id;
      });
      fieldLogicChoicesArray = newFieldLogicChoicesArray;
      let currentQuestionToShow = {};
      currentQuestionToShow = this.addAndReplaceFieldLogic(
        null,
        fieldLogicChoicesArray,
        newCurrent,
        null,
        null,
        null,
      );
      /*********************** */
      this.setState({
        currentQuestion: currentQuestionToShow,
        questionsPassed: allQuestions.length - 1,
        nextDisabled: false,
      });
      !isNewPreview &&
        this.deleteAnswerQuestion(takerToken, newCurrent.answer.id);

      /* Progress Bar*/
      cantActual.pop();
      cantActual.pop();
      this.newProgess(newCurrent);
      /************************/
    } else {
      this.setState({ isInternet: true });
      if (setIntervalInternet === null) {
        this.internetVerification();
      }
    }
  };

  changeOtherValue = (value, choice) => {
    const checkRadio = _.find(questions, el => {
      return el.id === choice.question_id;
    });
    const checkChoiceRadio = _.find(checkRadio.option_choices, el => {
      return el.other === true;
    });
    checkChoiceRadio.answer = value;
    console.log(value, questions);
    if (value === "") {
      this.setState({ nextDisabled: true, nextOther: true });
    } else {
      this.setState({ nextDisabled: false, nextOther: false });
    }
  };

  changeOtherValueCheck = (value, question) => {
    //console.log(value, question, questions);
    const otherChoices = _.find(question.option_choices, el => {
      return el.other === true;
    });
    const checkFromQuestions = _.find(questions, el => {
      return el.id === question.id;
    });
    //console.log(checkFromQuestions, otherChoices);
    const checkChoiceFromQuestions = _.find(
      checkFromQuestions.option_choices,
      el => {
        return el.id === otherChoices.id;
      },
    );

    checkChoiceFromQuestions.answer = value;
    //console.log(questions);
    if (value === "") {
      this.setState({ nextCheckOther: true });
    } else {
      this.setState({ nextCheckOther: false });
    }
  };

  internetVerification = () => {
    //console.log(window.navigator.onLine);
    //this.setState({
    setIntervalInternet = setInterval(() => {
      this.internetVerificationDisabled();
    }, 2000);
    //});
  };

  internetVerificationDisabled = () => {
    console.log(window.navigator.onLine);
    if (window.navigator.onLine) {
      console.log("si hay loop");
      clearInterval(setIntervalInternet);
      setIntervalInternet = null;
      //this.setState({setIntervalInternet: null});
      this.setState({ isInternet: false });
    }
  };

  render() {
    //console.log(this.state.emptyCanvas, this.state.isLoadingInitialInfo);
    return (
      <div>
        {this.state.isLoadingInitialInfo ? (
          <LoadingScreen />
        ) : this.state.emptyCanvas ? (
          <PlaceholderBuilder type="emptyCanvas" />
        ) : !this.state.welcomePageTemp.fields.html ? (
          <PlaceholderBuilder type="Welcome" />
        ) : (
          <React.Fragment>
            {this.state.settings && (
              <MetaInformation
                settings={this.state.settings}
                metaImage={this.state.metaImage}
              />
            )}
            {isNewPreview && !this.state.isInternet && (
              <PreviewMessage>
                <b>Note:</b> This is the bucket.io preview mode. To start
                collecting data, publish this Funnel.
              </PreviewMessage>
            )}
            {this.state.isInternet && (
              <InternetMessage>
                Your Internet connection seems to be unstable - please verify
                your connection and submit your answer again.
              </InternetMessage>
            )}
            {this.state.showWelcomePage && (
              <div
                css={css`
                  padding-top: ${isNewPreview ? 60 : 0}px;
                `}
                dangerouslySetInnerHTML={{ __html: this.state.welcomePageHtml }}
              ></div>
            )}
            <StandartModal
              showDialog={this.state.showDialogQuestions}
              design={this.state.design}
              questions={this.state.questions}
              nextQuestion={this.nextQuestion}
              currentQuestion={this.state.currentQuestion}
              nextRadio={this.nextRadio}
              showQuestions={this.state.showQuestions}
              showLeadCapture={this.state.showLeadCapture}
              leadCapture={this.state.winnerLeadCapture}
              submitLeadCapture={this.submitLeadCapture}
              closeModal={this.closeModal}
              nextDisabled={this.state.nextDisabled}
              nextQuestionDisabled={this.nextQuestionDisabled}
              nodesPath={this.state.nodesPath}
              questionsPassed={this.state.questionsPassed}
              nextQuestionDisabledSL={this.nextQuestionDisabledSL}
              changeShortLong={this.changeShortLong}
              nextCheck={this.nextCheck}
              nextQuestionDisabledCheck={this.nextQuestionDisabledCheck}
              backQuestion={this.backQuestion}
              showQuestionWithoutLink={this.state.showQuestionWithoutLink}
              changeOtherValue={this.changeOtherValue}
              nextOther={this.state.nextOther}
              nextOtherAlone={this.state.nextOtherAlone}
              nextCheckOther={this.state.nextCheckOther}
              changeOtherValueCheck={this.changeOtherValueCheck}
              isNewPreview={isNewPreview}
              nextDisabledUrlShort={this.state.nextDisabledUrlShort}
              fieldLogicChoicesArray={fieldLogicChoicesArray}
              customVariablesInfo={{
                quizScore: this.state.quizScore,
                winnerOutcome: this.state.winnerOutcome,
              }}
              fieldLogicArrayFull={this.state.fieldLogicArrayFull}
            ></StandartModal>
            {this.state.showPostQuizPage &&
              this.state.postQuizWinner.fields.html && (
              <div
                css={css`
                    padding-top: ${isNewPreview ? 60 : 0}px;
                  `}
                dangerouslySetInnerHTML={{ __html: this.state.postQuizHtml }}
              ></div>
              )}
            {this.state.showPostQuizPage &&
              !this.state.postQuizWinner.fields.html && (
              <div>
                <PlaceholderBuilder type="PostQuiz" />
              </div>
              )}
            {this.state.showThankWebinarPage && (
              <WebinarThank>
                <img src={imgCheck} alt={imgCheck} />
                <p className="title">All Done!</p>
                <p className="text">Your answers were sent successfully.</p>
              </WebinarThank>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ApplicationContaniner;

ApplicationContaniner.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

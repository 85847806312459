/**@jsx jsx */
import { jsx } from "@emotion/core";
import { loadingStripe } from "./Pages.styles";

const StripeLoading = () => {
  return (
    <div css={loadingStripe} className="loading-progress">
      <span className="message">
        We are processing your payment
        <br />
        Please wait a moment
      </span>
      <div className="progress-bar">
        <span className="bar">
          <span className="progress"></span>
        </span>
      </div>
    </div>
  );
};

export default StripeLoading;

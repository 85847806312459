import sanitizeHtml from "sanitize-html";
import { unescape as lodashUnescape } from "lodash";

export function getSanitizedHtml({
  html = "",
  config = { allowedTags: [] },
  unescape = false,
} = {}) {
  if (unescape) {
    return lodashUnescape(sanitizeHtml(html, config));
  }

  return sanitizeHtml(html, config);
}

import axios from "axios";

export const getPreview = (verification_token = "db433f1e") => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_API_URL}funnels/${verification_token}/preview`,
  });
};
export const getCloudfrontInfo = (verification_token = "db433f1e") => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_CLOUDFRONT_BASE_URL}${verification_token}.json`,
  });
};

// Page Views

export const createView = (verification_token, pageID, data = {}) => {
  console.log(verification_token);
  return axios({
    method: "POST",
    data: {
      page: {
        id: pageID,
        ...data,
      },
    },
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}register_stats/create_page_view`,
  });
};

// Page continue

export const createContinue = (verification_token, pageID) => {
  console.log(verification_token);
  return axios({
    method: "POST",
    data: {
      page: {
        id: pageID,
      },
    },
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}register_stats/create_page_continues`,
  });
};

// Taker

export const createTaker = data => {
  return axios({
    method: "POST",
    data: data,
    url: `${process.env.REACT_APP_BASE_API_URL}takers`,
  });
};
export const updateTaker = (verification_token, data) => {
  return axios({
    method: "PUT",
    data: data,
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}takers`,
  });
};

// Questions Views

export const createQuestionView = (verification_token, pageID) => {
  console.log(verification_token);
  return axios({
    method: "POST",
    data: {
      question: {
        id: pageID,
      },
    },
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}register_stats/create_question_view`,
  });
};

// Question Answer

export const createAnswerQuestion = (verification_token, data) => {
  return axios({
    method: "POST",
    data: data,
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}answers`,
  });
};

// Lead Answer

export const createAnswerLead = (verification_token, data) => {
  return axios({
    method: "POST",
    data: data,
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}lead_capture_answers`,
  });
};

// Question Answer Delete

export const deleteAnswerQuestion = (verification_token, id) => {
  return axios({
    method: "DELETE",
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}answers/${id}`,
  });
};

// Lead Capture Views

export const leadCaptureView = (verification_token, pageID) => {
  console.log(verification_token);
  return axios({
    method: "POST",
    data: {
      lead_capture: {
        id: pageID,
      },
    },
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}register_stats/create_lead_capture_view`,
  });
};

// Winner Outcome

export const createWinnerOutcome = (
  verification_token,
  outcome_id,
  typeMap,
  sumScore,
) => {
  let data;
  if (typeMap === "scored") {
    data = {
      taker: {
        outcome_id: outcome_id,
        completion: true,
        score: sumScore,
      },
    };
  } else {
    data = {
      taker: {
        outcome_id: outcome_id,
        completion: true,
      },
    };
  }

  return axios({
    method: "PUT",
    data: data,
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}takers`,
  });
};

//---------------------SINGLE PAGES ----------------------------------
export const getPreviewSinglePage = (verification_token = "db433f1e") => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_API_URL}single_pages/${verification_token}/preview`,
  });
};
export const getCloudfrontInfoSinglePage = (
  verification_token = "db433f1e",
) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_CLOUDFRONT_BASE_URL}single_page_${verification_token}.json`,
  });
};

//---------------------URL ROTATOR  ----------------------------------
export const getABTestingURL = (verification_token = "") => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_API_URL}outputs/${verification_token}/?t=ab`,
  });
};

// Payment Services

export const createPaymentIntent = (
  verification_token,
  article_id,
  page_id,
  customer_info,
  payment_gateway = "stripe",
  payment_mode = "test_mode",
  location_data,
) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: {
      fields: customer_info,
      location_data,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${article_id}/create_payment_intent?page_id=${page_id}&payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}`,
  });
};

export const deleteCustomerPayment = (
  verification_token,
  paymentIntent,
  customerId,
  userId,
  payment_gateway = "stripe",
  payment_mode = "test_mode",
) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/delete_customer_payment?customer_id=${customerId}&user_id=${userId}&payment_intent=${paymentIntent}&payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}`,
  });
};

export const payOneClickProduct = (
  verification_token,
  article_id,
  page_id,
  payment_gateway = "stripe",
  payment_mode = "test_mode",
  location_data,
  PAYPAL_CLIENT_METADATA_ID,
) => {
  let route = "";
  let urlPaypalMetadata = "";
  switch (payment_gateway) {
    case "stripe":
      route = "pay_one_click";
      break;
    case "paypal":
      route = "one_click_paypal";
      urlPaypalMetadata = `&paypal_client_metadata_id=${PAYPAL_CLIENT_METADATA_ID}`;
      break;
    case "authorize_net":
      route = "one_click_authorize_net";
      break;
    default:
      route = "pay_one_click";
      break;
  }

  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: {
      location_data,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${article_id}/${route}?page_id=${page_id}&payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}${urlPaypalMetadata}`,
  });
};

//---------------------VALIDATE PRODUCT STOCK  ----------------------------------
export const validateProductStock = (
  verification_token,
  productId,
  paymentGatewayInfo,
) => {
  return axios({
    method: "GET",
    headers: {
      Authorization: verification_token,
    },
    url: `${
      process.env.REACT_APP_BASE_API_URL
    }articles/${productId}/validate_stock?payment_gateway_type=${
      paymentGatewayInfo.payment_gateway_type
    }&payment_mode=${paymentGatewayInfo.payment_mode}&pk_payment_gateway=${
      paymentGatewayInfo[`${paymentGatewayInfo.payment_gateway_type}_key`]
    }`,
  });
};

export const calculateTaxes = (verification_token, productId, locationData) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: locationData,
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${productId}/calculate_tax`,
  });
};

export const FBConversionAPI = (verification_token, data) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: data,
    url: `${process.env.REACT_APP_BASE_API_URL}takers/facebook_pixels`,
  });
};

export const verifyRecoveryToken = (
  recovery_token = "db433f1e",
  paymentGateway = "stripe",
) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_API_URL}orders/verify_recovery_token?token=${recovery_token}&payment_gateway_type=${paymentGateway}`,
  });
};
export const invalidateRecoveryToken = (recovery_token = "db433f1e") => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_API_URL}orders/invalidate_recovery_token?token=${recovery_token}`,
  });
};

export const createBillingAgreementToken = (
  verification_token,
  article_id,
  payment_gateway,
  payment_mode,
) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${article_id}/create_billing_agreement_token?payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}`,
  });
};

export const createPaymentInPaypal = (
  verification_token,
  article_id,
  page_id,
  customer_info,
  payment_gateway = "stripe",
  payment_mode = "test_mode",
  location_data,
  ba_token,
  PAYPAL_CLIENT_METADATA_ID,
) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: {
      fields: customer_info,
      location_data,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${article_id}/payment_paypal?page_id=${page_id}&payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}&ba_token=${ba_token}&paypal_client_metadata_id=${PAYPAL_CLIENT_METADATA_ID}`,
  });
};

//AUTHORIZE_NET
export const createOpaqueData = (cardData, accessToken, paymentMode) => {
  const baseUrl =
    paymentMode === "live"
      ? process.env.REACT_APP_REQUEST_TOKEN_API_URL
      : process.env.REACT_APP_REQUEST_TOKEN_API_URL_TEST_MODE;
  return axios({
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: {
      securePaymentContainerRequest: {
        merchantAuthentication: {
          accessToken: atob(accessToken),
        },
        data: {
          type: "TOKEN",
          id: new Date().getTime(),
          token: {
            ...cardData,
          },
        },
      },
    },
    url: baseUrl,
  });
};

export const confirmAuthorizePayment = (
  verification_token,
  article_id,
  page_id,
  customer_info,
  payment_gateway = "stripe",
  payment_mode = "test_mode",
  location_data,
  transactionData,
) => {
  return axios({
    method: "POST",
    headers: {
      Authorization: verification_token,
    },
    data: {
      fields: customer_info,
      location_data,
      transaction: transactionData,
    },
    url: `${process.env.REACT_APP_BASE_API_URL}articles/${article_id}/payment_authorize_net?page_id=${page_id}&payment_gateway_type=${payment_gateway}&payment_mode=${payment_mode}`,
  });
};

export const confirmUpdateProfilePaymentAuthorizeNet = (
  token,
  customer_info,
  countryCode,
  transactionData,
) => {
  return axios({
    method: "POST",
    data: {
      fields: customer_info,
      transaction: transactionData,
      location_data: {
        location: {
          abbreviation: countryCode,
        },
      },
    },
    url: `${process.env.REACT_APP_BASE_API_URL}orders/confirm_payment?token=${token}`,
  });
};

export const confirmUpdateProfilePaymentPaypal = (
  token,
  ba_token,
  paymentGateway = "paypal",
 ) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_API_URL}orders/payment_failed_charge?ba_token=${ba_token}&token=${token}&payment_gateway_type=${paymentGateway}`,
  });
 };
 
